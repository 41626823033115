import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import TodayIcon from '@mui/icons-material/Today'
import {
  Box,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  Paper,
  SxProps,
  Typography,
  TypographyProps
} from '@mui/material'
import {get} from 'lodash'
import React, {SyntheticEvent, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventListItemFieldsFragment,
  LocaleCode,
  TranslatedFieldsFragment
} from '../../__generated__/schema'
import {ImageWithPlaceholder} from '../../components/atoms/ImageWithPlaceholder'
import {Tooltip} from '../../components/atoms/Tooltip'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../components/types'
import {EventButtonTarget} from '../../types'
import {useThemeContextProvider} from '../components/atoms/ThemeContext'
import {useFormatEventDurationDate} from '../hooks/formatting'
import {
  useTranslateAgeClassification,
  useTranslateAgeClassificationAbbreviation
} from '../hooks/translateAgeClassification'
import {
  useTranslateShowFormat,
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMix,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersion,
  useTranslateShowVersionAbbreviation
} from '../hooks/translateDistributions'
import {useGetTranslatedValue} from '../hooks/translateLocales'
import {
  useTranslateShowContentDescriptorCode,
  useTranslateShowContentDescriptorCodeAbbreviation
} from '../hooks/translateShowContentDescriptorCode'
import {PrimaryImageType} from '../types'
import {EventAvailabilityOption, EventListItemSize} from './types'

interface ITranslatedProps {
  sx?: SxProps
  translated: TranslatedFieldsFragment
  clientLocales: LocaleCode[]
  availableRowsCount?: number
  typographyProps: TypographyProps
}

const Translated: React.FC<ITranslatedProps> = ({
  translated,
  sx,
  clientLocales,
  availableRowsCount = 1,
  typographyProps
}: ITranslatedProps) => {
  const translatedValue = useGetTranslatedValue(clientLocales)
  return (
    <Typography
      {...typographyProps}
      sx={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: availableRowsCount.toString(),
        overflow: 'hidden',
        ...sx
      }}
    >
      {translatedValue(translated)}
    </Typography>
  )
}

interface IVariantProps {
  item: EventListItemFieldsFragment
  showShortcuts?: boolean
  availableRowsCount?: number
  sx?: SxProps
  color?: string
}

const Variant: React.FC<IVariantProps> = ({
  item,
  showShortcuts,
  availableRowsCount = 2,
  color,
  sx
}: IVariantProps) => {
  const {
    formatCode,
    versionCode,
    soundMixCode,
    ageClassificationCode,
    show: {contentDescriptorCodes}
  } = item
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translateShowFormat = useTranslateShowFormat()
  const translateShowSoundMix = useTranslateShowSoundMix()
  const translateShowVersion = useTranslateShowVersion()
  const translateAgeClassification = useTranslateAgeClassification()
  const translateShowContentDescriptorCode =
    useTranslateShowContentDescriptorCode()
  const translateShowContentDescriptorCodeAbbreviation =
    useTranslateShowContentDescriptorCodeAbbreviation()
  const distributionLabel = (
    showShortcuts
      ? [
          formatCode && translateShowFormatAbbreviation(formatCode),
          versionCode && translateShowVersionAbbreviation(versionCode),
          soundMixCode && translateShowSoundMixAbbreviation(soundMixCode),
          ageClassificationCode &&
            translateAgeClassificationAbbreviation(ageClassificationCode),
          contentDescriptorCodes &&
            contentDescriptorCodes.length > 0 &&
            contentDescriptorCodes
              .map((code) =>
                translateShowContentDescriptorCodeAbbreviation(code)
              )
              .join(', ')
        ]
      : [
          formatCode && translateShowFormat(formatCode),
          versionCode && translateShowVersion(versionCode),
          soundMixCode && translateShowSoundMix(soundMixCode),
          ageClassificationCode &&
            translateAgeClassification(ageClassificationCode),
          contentDescriptorCodes &&
            contentDescriptorCodes.length > 0 &&
            contentDescriptorCodes
              .map((code) => translateShowContentDescriptorCode(code))
              .join(', ')
        ]
  )
    .filter(Boolean)
    .join(' • ')
  return (
    <Typography
      variant="caption"
      color={color ?? 'textSecondary'}
      sx={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: availableRowsCount.toString(),
        overflow: 'hidden',
        ...sx
      }}
    >
      {distributionLabel}
    </Typography>
  )
}

interface IVenueLabelProps {
  label: string
  sx?: SxProps
  showTinyIcon?: boolean
  color?: string
}

const VenueLabel: React.FC<IVenueLabelProps> = ({
  sx,
  label,
  showTinyIcon,
  color
}: IVenueLabelProps) => {
  const {t} = useTranslation()
  return (
    <Box sx={{display: 'flex', gap: 0.5, alignItems: 'center', ...sx}}>
      <Tooltip
        title={t('Venue')}
        sx={
          showTinyIcon
            ? {height: '1em', fontSize: '1rem'}
            : {fontSize: '1.25rem', height: '1em'}
        }
      >
        <PlaceOutlinedIcon
          sx={{color: color ?? 'grey.A700'}}
          fontSize="inherit"
        />
      </Tooltip>
      <Typography
        variant="body2"
        sx={{
          '&::first-letter': {
            textTransform: 'uppercase'
          },
          color
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}

interface IStartsAtLabelProps {
  startsAt: EventListItemFieldsFragment['startsAt']
  endsAt: EventListItemFieldsFragment['endsAt']
  sx?: SxProps
  showTinyIcon?: boolean
  color?: string
}

const StartsAtLabel = ({
  sx,
  startsAt,
  endsAt,
  showTinyIcon,
  color
}: IStartsAtLabelProps) => {
  const formatEventDurationDate = useFormatEventDurationDate()
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.5,
        alignItems: 'center',
        ...sx
      }}
    >
      <Tooltip
        title={t('Event start')}
        sx={
          showTinyIcon
            ? {height: '1em', fontSize: '1rem'}
            : {fontSize: '1.25rem', height: '1em'}
        }
      >
        <TodayIcon sx={{color: color ?? 'grey.A700'}} fontSize="inherit" />
      </Tooltip>
      <Typography
        variant="body2"
        sx={{
          '&::first-letter': {
            textTransform: 'uppercase'
          },
          color
        }}
      >
        {formatEventDurationDate(startsAt, endsAt)}
      </Typography>
    </Box>
  )
}

const useGetAvailabilityChipColorAndLabel = (
  availableSeatsCount: number,
  auditoriumLayoutCapacity: number
) => {
  const {t} = useTranslation()
  const isLastFew = availableSeatsCount <= 10 && availableSeatsCount > 0
  const isLimitedAvailability =
    availableSeatsCount / auditoriumLayoutCapacity <= 0.2 &&
    availableSeatsCount > 10
  const getChipColor = useCallback(
    (): ChipProps['color'] =>
      isLimitedAvailability ? 'warning' : isLastFew ? 'error' : 'success',
    [isLastFew, isLimitedAvailability]
  )
  const getLabelForStatusOption = useCallback(() => {
    if (isLimitedAvailability) {
      return t('EventAvailability->LimitedAvailability')
    } else if (isLastFew) {
      return t('EventAvailability->LastFewTickets')
    } else {
      return t('EventAvailability->Available')
    }
  }, [isLastFew, isLimitedAvailability, t])
  const getLabelForCountOption = useCallback(() => {
    if (isLastFew) {
      return t('Only {{count}} tickets', {count: availableSeatsCount})
    } else {
      return t('{{tickets}} available', {tickets: availableSeatsCount})
    }
  }, [availableSeatsCount, isLastFew, t])
  return useMemo(
    () => ({
      getChipColor,
      getLabelForStatusOption,
      getLabelForCountOption
    }),
    [getChipColor, getLabelForCountOption, getLabelForStatusOption]
  )
}

interface IAvailabilityChipProps {
  eventAvailabilityOption: EventAvailabilityOption
  availableSeatsCount: number
  auditoriumLayoutCapacity: number
}

const AvailabilityChip: React.FC<IAvailabilityChipProps> = ({
  eventAvailabilityOption,
  availableSeatsCount,
  auditoriumLayoutCapacity
}: IAvailabilityChipProps) => {
  const {getChipColor, getLabelForStatusOption, getLabelForCountOption} =
    useGetAvailabilityChipColorAndLabel(
      availableSeatsCount,
      auditoriumLayoutCapacity
    )
  if (eventAvailabilityOption === EventAvailabilityOption.Status) {
    return (
      <Chip
        variant="outlined"
        color={getChipColor()}
        label={getLabelForStatusOption()}
        size="small"
      />
    )
  }
  if (eventAvailabilityOption === EventAvailabilityOption.Count) {
    return (
      <Chip
        variant="outlined"
        color={getChipColor()}
        label={getLabelForCountOption()}
        size="small"
      />
    )
  }
  return null
}

interface IItemActionsProps {
  isSoldOut?: boolean
  eventButtonProps: ButtonProps<'a'>
  sx?: SxProps
  eventAvailabilityOption: EventAvailabilityOption
  availableSeatsCount: number
  auditoriumLayoutCapacity: number
  isMobileWithImage: boolean
}

const ItemActions: React.FC<IItemActionsProps> = ({
  isSoldOut,
  sx,
  eventButtonProps,
  isMobileWithImage,
  eventAvailabilityOption,
  ...rest
}: IItemActionsProps) => {
  const {t} = useTranslation()
  return (
    <Box sx={sx}>
      {isSoldOut ? (
        <Chip
          sx={{
            color: 'error.main',
            alignSelf: 'center',
            backgroundColor: 'error.light'
          }}
          label={t('Sold out')}
          size="medium"
        />
      ) : (
        <>
          <AvailabilityChip
            eventAvailabilityOption={eventAvailabilityOption}
            {...rest}
          />
          <Button
            variant="contained"
            color="primary"
            component="a"
            {...eventButtonProps}
          >
            {isMobileWithImage &&
            eventAvailabilityOption !== EventAvailabilityOption.None ? (
              <ShoppingCartIcon />
            ) : (
              t('Get tickets')
            )}
          </Button>
        </>
      )}
    </Box>
  )
}

enum RootGridAreas {
  Main = 'main',
  Image = 'image'
}

enum MainGridAreas {
  Name = 'name',
  Tagline = 'tagline',
  StartsAt = 'startsAt',
  Venue = 'venue',
  Variant = 'variant',
  ItemActions = 'itemActions'
}

const getImageSxProps = ({
  size,
  primaryImageType,
  isSoldOut
}: {
  size: EventListItemSize
  primaryImageType: PrimaryImageType.Poster | PrimaryImageType.Photo
  isSoldOut: boolean
}): SxProps => ({
  gridArea: RootGridAreas.Image,
  opacity: isSoldOut ? '30%' : undefined,
  ...(size === EventListItemSize.Mobile &&
  primaryImageType === PrimaryImageType.Photo
    ? {width: '100%', height: 202}
    : {minHeight: 202, height: '100%'})
})

const getImageBorders = (
  size: EventListItemSize,
  primaryImageType: PrimaryImageType.Poster | PrimaryImageType.Photo
) =>
  size === EventListItemSize.Mobile &&
  primaryImageType === PrimaryImageType.Photo
    ? {
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8
      }
    : {
        borderBottomLeftRadius: 8,
        borderTopLeftRadius: 8
      }

export const useGetEventListItemSize = () => {
  const isSmallMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const isDesktop = useIsMediaSize(MediaSizes.DesktopPlus)
  return isSmallMobile
    ? EventListItemSize.Mobile
    : isDesktop
    ? EventListItemSize.Desktop
    : EventListItemSize.Tablet
}

const getPaperSxProps = (
  size: EventListItemSize,
  primaryImageType: PrimaryImageType.Photo | PrimaryImageType.Poster
): SxProps => {
  if (primaryImageType === PrimaryImageType.Poster) {
    return {
      display: 'grid',
      borderRadius: 2,
      gridTemplateAreas: `"${RootGridAreas.Image} ${RootGridAreas.Main}"`,
      gridTemplateColumns: `135px 1fr`
    }
  }
  switch (size) {
    case EventListItemSize.Mobile:
      return {
        display: 'grid',
        borderRadius: 2,
        gridTemplateAreas: `
          "${RootGridAreas.Image}"
          "${RootGridAreas.Main}"
        `
      }
    case EventListItemSize.Tablet:
    case EventListItemSize.Desktop:
    default:
      return {
        display: 'grid',
        borderRadius: 2,
        gridTemplateAreas: `"${RootGridAreas.Image} ${RootGridAreas.Main}"`,
        gridTemplateColumns: `min(360px, 50%) 1fr`
      }
  }
}

interface IEventListItemProps {
  item: EventListItemFieldsFragment
  clientLocales: LocaleCode[]
  primaryImageType: PrimaryImageType
  eventButtonTarget: EventButtonTarget
  size: EventListItemSize
  eventAvailabilityOption: EventAvailabilityOption
}

const getMainSxProps = (size: EventListItemSize): SxProps => {
  switch (size) {
    case EventListItemSize.Desktop:
      return {
        px: 3,
        py: 2,
        display: 'grid',
        gridArea: RootGridAreas.Main,
        gridTemplateColumns: 'auto 1fr',
        columnGap: 2,
        rowGap: 1,
        gridTemplateRows: 'repeat(4, auto) 1fr',
        gridTemplateAreas: `
          "${MainGridAreas.Name} ${MainGridAreas.Name}"
          "${MainGridAreas.StartsAt} ${MainGridAreas.Venue}"
          "${MainGridAreas.Tagline} ${MainGridAreas.Tagline}"
          "${MainGridAreas.Variant} ${MainGridAreas.Variant}"
          "${MainGridAreas.ItemActions} ${MainGridAreas.ItemActions}"
        `
      }
    case EventListItemSize.Tablet:
      return {
        px: 2,
        py: 1.5,
        display: 'grid',
        gridArea: RootGridAreas.Main,
        gridTemplateColumns: 'auto 1fr',
        columnGap: 2,
        rowGap: 1,
        gridTemplateRows: 'repeat(3, auto) 1fr',
        gridTemplateAreas: `
          "${MainGridAreas.Name} ${MainGridAreas.Name}"
          "${MainGridAreas.StartsAt} ${MainGridAreas.Venue}"
          "${MainGridAreas.Variant} ${MainGridAreas.Variant}"
          "${MainGridAreas.ItemActions} ${MainGridAreas.ItemActions}"
        `
      }
    case EventListItemSize.Mobile:
    default:
      return {
        px: 1.5,
        py: 1.5,
        display: 'grid',
        gridArea: RootGridAreas.Main,
        gridTemplateRows: 'repeat(4, auto) 1fr',
        gridTemplateAreas: `
          "${MainGridAreas.Name}"
          "${MainGridAreas.StartsAt}" 
          "${MainGridAreas.Venue}"
          "${MainGridAreas.Variant}"
          "${MainGridAreas.ItemActions}"
        `
      }
  }
}

interface IMainProps extends IEventListItemProps {
  sx?: SxProps
  isSoldOut: boolean
}

const Main: React.FC<IMainProps> = ({
  item,
  clientLocales,
  size,
  eventButtonTarget,
  isSoldOut,
  sx,
  eventAvailabilityOption,
  primaryImageType
}: IMainProps) => {
  const {theme} = useThemeContextProvider()
  const buttonHref =
    theme && eventButtonTarget === EventButtonTarget.Blank
      ? `/event/${item.id}?theme=${theme}`
      : `/event/${item.id}`
  return (
    <Box sx={sx}>
      <Translated
        sx={{
          gridArea: MainGridAreas.Name
        }}
        translated={item.names}
        clientLocales={clientLocales}
        availableRowsCount={size === EventListItemSize.Desktop ? 1 : 2}
        typographyProps={{
          variant: size === EventListItemSize.Mobile ? 'subtitle1' : 'h6',
          color: isSoldOut ? 'text.disabled' : undefined
        }}
      />
      {size === EventListItemSize.Desktop && (
        <Translated
          sx={{
            gridArea: MainGridAreas.Tagline
          }}
          translated={item.show.translations.reduce(
            (acc, {tagline, localeCode}) => ({
              [localeCode]: tagline
            }),
            {}
          )}
          clientLocales={clientLocales}
          typographyProps={{
            variant: 'body2',
            color: isSoldOut ? 'text.disabled' : 'textSecondary'
          }}
        />
      )}
      <StartsAtLabel
        sx={{
          gridArea: MainGridAreas.StartsAt
        }}
        startsAt={item.startsAt}
        endsAt={item.endsAt}
        showTinyIcon={size === EventListItemSize.Mobile}
        color={isSoldOut ? 'text.disabled' : undefined}
      />
      <VenueLabel
        sx={{
          gridArea: MainGridAreas.Venue
        }}
        label={item.venue.name}
        showTinyIcon={size === EventListItemSize.Mobile}
        color={isSoldOut ? 'text.disabled' : undefined}
      />
      <Variant
        sx={{
          gridArea: MainGridAreas.Variant
        }}
        color={isSoldOut ? 'text.disabled' : undefined}
        item={item}
        showShortcuts={size === EventListItemSize.Mobile}
      />
      <ItemActions
        sx={{
          gridArea: MainGridAreas.ItemActions,
          justifySelf: 'flex-end',
          alignSelf: 'flex-end',
          pt: 0.5,
          gap: 1,
          display: 'flex',
          alignItems: 'center'
        }}
        isSoldOut={isSoldOut}
        eventButtonProps={{
          target: eventButtonTarget,
          href: buttonHref,
          onClick: (e: SyntheticEvent) => e.stopPropagation()
        }}
        eventAvailabilityOption={eventAvailabilityOption}
        availableSeatsCount={item.availableSeatsCount}
        auditoriumLayoutCapacity={item.auditoriumLayout.capacity}
        isMobileWithImage={
          size === EventListItemSize.Mobile &&
          primaryImageType !== PrimaryImageType.None
        }
      />
    </Box>
  )
}

export const EventListItem: React.FC<IEventListItemProps> = (
  props: IEventListItemProps
) => {
  const {item, primaryImageType, size} = props
  const isSoldOut = item.availableSeatsCount === 0
  if (primaryImageType === PrimaryImageType.None) {
    return (
      <Paper variant="outlined" sx={{borderRadius: 2}}>
        <Main {...props} sx={getMainSxProps(size)} isSoldOut={isSoldOut} />
      </Paper>
    )
  }
  return (
    <Paper variant="outlined" sx={getPaperSxProps(size, primaryImageType)}>
      <ImageWithPlaceholder
        src={
          primaryImageType === PrimaryImageType.Photo
            ? get(item, 'show.photoPrimaryImage.thumbnails[0].url')
            : get(item, 'show.posterPrimaryImage.thumbnails[0].url')
        }
        {...getImageBorders(size, primaryImageType)}
        sx={getImageSxProps({
          size,
          primaryImageType,
          isSoldOut
        })}
      />
      <Main {...props} sx={getMainSxProps(size)} isSoldOut={isSoldOut} />
    </Paper>
  )
}
