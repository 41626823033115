import {useQuery} from '@apollo/client'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetBackendConfigQuery,
  GetBackendConfigQueryVariables
} from '../../../../__generated__/schema'
import {RenderOnData} from '../../../../components/molecules/RenderOnData'
import {GET_BACKEND_CONFIG} from './graphql'

interface IRenderOnBackendConfigProps {
  children: (data: GetBackendConfigQuery) => React.ReactElement
}

export const RenderOnBackendConfig: React.FC<IRenderOnBackendConfigProps> = ({
  children
}: IRenderOnBackendConfigProps) => {
  const {data, loading, error} =
    useQuery<GetBackendConfigQuery, GetBackendConfigQueryVariables>(
      GET_BACKEND_CONFIG
    )
  const {t} = useTranslation()
  return (
    <RenderOnData<GetBackendConfigQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Failed to fetch backend config')}
      // eslint-disable-next-line react/no-children-prop
      children={children}
    />
  )
}
