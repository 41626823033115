import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useCurrentCart} from './components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from './components/atoms/CurrentCartFab'
import {Warning, WarningColorCombination} from './components/atoms/Warning'
import {BaseHeader} from './components/molecules/BaseHeader'
import {BlankPage} from './components/organisms/BlankPage'

export const PageNotFound: React.FC = () => {
  const {t} = useTranslation()
  const {currentCart} = useCurrentCart()
  return (
    <BlankPage header={<BaseHeader title={t('attendio')} />}>
      <>
        <Warning
          title={t('Page not found')}
          description={t(
            'The requested URL was not found on this server. Please, check your URL if there is some type.'
          )}
          colorCombination={WarningColorCombination.Warning}
          icon={
            <SentimentDissatisfiedIcon
              sx={{color: 'text.primary', width: 48, height: 48}}
            />
          }
        />
        <TentativeCurrentCartFab currentCart={currentCart} />
      </>
    </BlankPage>
  )
}
