import {useTranslation} from 'react-i18next'
import {ShowContentDescriptorCode} from '../../__generated__/schema'

export const useTranslateShowContentDescriptorCode = () => {
  const {t} = useTranslation()
  const translated: Record<ShowContentDescriptorCode, string> = {
    [ShowContentDescriptorCode.SkViolence]: t(
      'ShowContentDescriptorCode->SkViolence'
    ),
    [ShowContentDescriptorCode.SkDiscrimination]: t(
      'ShowContentDescriptorCode->SkDiscrimination'
    ),
    [ShowContentDescriptorCode.SkFear]: t('ShowContentDescriptorCode->SkFear'),
    [ShowContentDescriptorCode.SkAddiction]: t(
      'ShowContentDescriptorCode->SkAddiction'
    ),
    [ShowContentDescriptorCode.SkSex]: t('ShowContentDescriptorCode->SkSex'),
    [ShowContentDescriptorCode.SkVulgarisms]: t(
      'ShowContentDescriptorCode->SkVulgarisms'
    ),
    [ShowContentDescriptorCode.SkNudity]: t(
      'ShowContentDescriptorCode->SkNudity'
    )
  }
  return (code: ShowContentDescriptorCode) => translated[code]
}

export const useTranslateShowContentDescriptorCodeAbbreviation = () => {
  const {t} = useTranslation()
  const translated: Record<ShowContentDescriptorCode, string> = {
    [ShowContentDescriptorCode.SkViolence]: t(
      'ShowContentDescriptorCodeAbbreviation->SkViolence'
    ),
    [ShowContentDescriptorCode.SkDiscrimination]: t(
      'ShowContentDescriptorCodeAbbreviation->SkDiscrimination'
    ),
    [ShowContentDescriptorCode.SkFear]: t(
      'ShowContentDescriptorCodeAbbreviation->SkFear'
    ),
    [ShowContentDescriptorCode.SkAddiction]: t(
      'ShowContentDescriptorCodeAbbreviation->SkAddiction'
    ),
    [ShowContentDescriptorCode.SkSex]: t(
      'ShowContentDescriptorCodeAbbreviation->SkSex'
    ),
    [ShowContentDescriptorCode.SkVulgarisms]: t(
      'ShowContentDescriptorCodeAbbreviation->SkVulgarisms'
    ),
    [ShowContentDescriptorCode.SkNudity]: t(
      'ShowContentDescriptorCodeAbbreviation->SkNudity'
    )
  }
  return (code: ShowContentDescriptorCode) => translated[code]
}
