import CropFreeIcon from '@mui/icons-material/CropFree'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import {Box, Fab, styled, SxProps, Theme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '../../components/atoms/Tooltip'

const StyledFab = styled(Fab)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: 0
}))

interface IZoomPanelProps {
  sx?: SxProps<Theme>
  isMobile?: boolean
  onZoomInClick: () => void
  onZoomOutClick: () => void
  onFitToScreenClick: () => void
  isZoomOutDisabled: boolean
  isZoomInDisabled: boolean
}

export const ZoomPanel: React.FC<IZoomPanelProps> = ({
  sx,
  onZoomOutClick,
  onFitToScreenClick,
  isZoomOutDisabled,
  onZoomInClick,
  isZoomInDisabled,
  isMobile
}: IZoomPanelProps) => {
  const {t} = useTranslation()
  return (
    <Box sx={sx}>
      <Tooltip title={t<string>('Fit to screen')}>
        <StyledFab
          onClick={onFitToScreenClick}
          size="medium"
          variant="extended"
          sx={{
            backgroundColor: 'white'
          }}
        >
          <CropFreeIcon sx={{mr: 1}} />
          {t('Center')}
        </StyledFab>
      </Tooltip>
      {!isMobile && (
        <>
          <Tooltip title={t<string>('Zoom out')}>
            <StyledFab
              onClick={onZoomOutClick}
              disabled={isZoomOutDisabled}
              size="small"
            >
              <ZoomOutIcon />
            </StyledFab>
          </Tooltip>
          <Tooltip title={t<string>('Zoom in')}>
            <StyledFab
              onClick={onZoomInClick}
              disabled={isZoomInDisabled}
              size="small"
            >
              <ZoomInIcon />
            </StyledFab>
          </Tooltip>
        </>
      )}
    </Box>
  )
}
