import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button, Dialog, DialogContent, Typography} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Currency} from '../../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../../components/atoms/DialogTitleWithCloseButton'
import {OutlinedCountController} from '../../../components/atoms/OutlinedCountController'
import {useTranslatePrice} from '../../hooks/translateCurrency'

interface IEditZoneTicketQuantityDialogProps {
  zoneUuid: string
  zoneLabel: string
  zoneDescription?: string
  zonePrice: number
  ticketsInMyCartQuantity: number
  availableTicketsQuantity: number
  currency: Currency
  onConfirm: (args: {
    zoneUuid: string
    decrement?: number
    increment?: number
  }) => void
  onClose: () => void
}

export const EditZoneTicketQuantityDialog: React.FC<IEditZoneTicketQuantityDialogProps> =
  ({
    zoneUuid,
    zoneLabel,
    zoneDescription,
    zonePrice,
    ticketsInMyCartQuantity,
    availableTicketsQuantity,
    currency,
    onConfirm,
    onClose
  }: IEditZoneTicketQuantityDialogProps) => {
    const {t} = useTranslation()
    const translatePrice = useTranslatePrice(currency)
    const [quantity, setQuantity] = useState<number>(ticketsInMyCartQuantity)
    const handleIncrementClick = useCallback(() => {
      setQuantity((prevQuantity) => prevQuantity + 1)
    }, [])
    const handleDecrementClick = useCallback(() => {
      setQuantity((prevQuantity) =>
        prevQuantity > 0 ? prevQuantity - 1 : prevQuantity
      )
    }, [])
    const handleOnConfirmClick = useCallback(() => {
      const change = quantity - ticketsInMyCartQuantity
      onConfirm({
        zoneUuid,
        decrement: change < 0 ? -change : undefined,
        increment: change > 0 ? change : undefined
      })
    }, [onConfirm, quantity, ticketsInMyCartQuantity, zoneUuid])
    return (
      <Dialog onClose={onClose} maxWidth="xs" fullWidth open>
        <DialogTitleWithCloseButton onCloseIconClick={onClose}>
          {t('Select ticket quantity')}
        </DialogTitleWithCloseButton>
        <DialogContent sx={{py: 1}} dividers>
          <Box
            sx={{
              display: 'grid',
              gridTemplateAreas: `
              "title        price"
              "description  description"
              `,
              pt: 2
            }}
          >
            <Typography variant="subtitle1">{zoneLabel}</Typography>
            <Typography variant="body1" align="right">
              {translatePrice(zonePrice)}
            </Typography>
            <Typography
              variant="body2"
              sx={{gridArea: 'description'}}
              color="textSecondary"
            >
              {zoneDescription}
            </Typography>
          </Box>
          {ticketsInMyCartQuantity > 0 && (
            <Typography variant="body2" color="textSecondary" sx={{pt: 3}}>
              {t('You have {{count}} ticket already in cart.', {
                count: ticketsInMyCartQuantity
              })}
            </Typography>
          )}
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              justifyContent: 'space-between',
              pt: 3
            }}
          >
            <OutlinedCountController
              count={quantity}
              maxCount={availableTicketsQuantity}
              onIncrementButtonClick={handleIncrementClick}
              onDecrementButtonClick={handleDecrementClick}
              sx={{minWidth: 113}}
            />
            <Button
              color="primary"
              variant="contained"
              startIcon={
                quantity === 0 && ticketsInMyCartQuantity > 0 ? (
                  <DeleteIcon />
                ) : undefined
              }
              onClick={
                ticketsInMyCartQuantity === quantity
                  ? onClose
                  : handleOnConfirmClick
              }
            >
              {ticketsInMyCartQuantity === 0
                ? t('Add')
                : quantity === 0 && ticketsInMyCartQuantity > 0
                ? t('Remove')
                : t('Update')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
