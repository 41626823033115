import LandscapeIcon from '@mui/icons-material/Landscape'
import {Box, styled, SxProps, Theme} from '@mui/material'
import React, {useCallback, useState} from 'react'

interface IImageWithPlaceholderProps {
  src?: string
  sx?: SxProps<Theme>
  borderTopRightRadius?: number
  borderTopLeftRadius?: number
  borderBottomLeftRadius?: number
  borderBottomRightRadius?: number
}

const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const ImageWithPlaceholder: React.FC<IImageWithPlaceholderProps> = ({
  src,
  sx,
  borderTopRightRadius,
  borderTopLeftRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius
}: IImageWithPlaceholderProps) => {
  const [isPlaceholderVisible, setIsPlaceholderVisible] =
    useState<boolean>(true)
  const handleLoad = useCallback(() => {
    setIsPlaceholderVisible(false)
  }, [])
  return (
    <Box
      sx={{
        position: 'relative',
        ...sx
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'grey.100',
          display: isPlaceholderVisible ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          borderTopRightRadius,
          borderTopLeftRadius,
          borderBottomLeftRadius,
          borderBottomRightRadius
        }}
      >
        <LandscapeIcon
          sx={{
            color: 'rgba(0, 0, 0, 30%)',
            width: 96,
            height: 96,
            maxWidth: '80%',
            maxHeight: '80%'
          }}
        />
      </Box>
      {src && (
        <StyledImage
          style={{visibility: isPlaceholderVisible ? 'hidden' : 'visible'}}
          sx={{
            borderTopRightRadius,
            borderTopLeftRadius,
            borderBottomLeftRadius,
            borderBottomRightRadius
          }}
          src={src}
          alt={''}
          onLoad={handleLoad}
        />
      )}
    </Box>
  )
}
