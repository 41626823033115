import {Maybe, Sale, TicketItem, TourItem} from '../../__generated__/schema'
import {isTourOrTicketItem} from '../utils'

export const useDownloadSalesPdf =
  () =>
  async (
    sales: (Pick<Sale, 'id'> & {
      items?: Maybe<
        Array<
          | ({__typename?: 'TicketItem'} & Pick<TicketItem, 'id'>)
          | {__typename?: 'ProductItem'}
          | ({__typename?: 'TourItem'} & Pick<TourItem, 'id'>)
        >
      >
    })[]
  ) =>
    await Promise.all(
      sales.map(async (sale) => {
        const fileName = `tickets_ID${sale.id}_${Date.now()}`
        await fetch(
          `/pdf/saleTickets?saleId=${sale.id}&itemIds=${sale.items
            ?.filter(isTourOrTicketItem)
            .map((item) => item.id)
            .join(',')}&pdfFilename=${fileName}`
        )
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}.pdf`)
            document.body.appendChild(link)
            link.click()
            link.parentNode?.removeChild(link)
          })
      })
    )
