import PersonIcon from '@mui/icons-material/Person'
import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router-dom'
import {useBooleanState} from '../../hooks/state'
import {useIsCustomerSignedIn} from '../auth'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from '../components/atoms/CurrentCartFab'
import {Warning, WarningColorCombination} from '../components/atoms/Warning'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {CreateAccountDrawer} from '../components/organisms/account/CreateAccountDrawer'
import {SignInDrawer} from '../components/organisms/account/SignInDrawer'
import {BlankPage} from '../components/organisms/BlankPage'

export const SignedOut: React.FC = () => {
  const {t} = useTranslation()
  const {currentCart} = useCurrentCart()
  const isCustomerSignedIn = useIsCustomerSignedIn()
  const {
    state: isSignInDrawerOpen,
    setFalse: closeSignInDrawer,
    setTrue: openSignInDrawer
  } = useBooleanState(false)
  const {
    state: isSignUpDrawerOpen,
    setFalse: closeSignUpDrawer,
    setTrue: openSignUpDrawer
  } = useBooleanState(false)
  const handleCreateAccountLinkClick = useCallback(() => {
    closeSignInDrawer()
    openSignUpDrawer()
  }, [closeSignInDrawer, openSignUpDrawer])
  const handleSignInLinkClick = useCallback(() => {
    closeSignUpDrawer()
    openSignInDrawer()
  }, [closeSignUpDrawer, openSignInDrawer])
  return isCustomerSignedIn ? (
    <Navigate to="/myAccount" />
  ) : (
    <>
      <BlankPage header={<BaseHeader title={t('Signed out')} />}>
        <Warning
          colorCombination={WarningColorCombination.White}
          title={t('You have been signed out')}
          description={t(
            'Access to your account and purchases history. Proceed to sign in.'
          )}
          icon={<PersonIcon sx={{width: 64, height: 64}} />}
          actions={
            <Button
              variant="contained"
              color="primary"
              onClick={openSignInDrawer}
            >
              {t('Sign in')}
            </Button>
          }
        />
      </BlankPage>
      {isSignInDrawerOpen && (
        <SignInDrawer
          onExited={closeSignInDrawer}
          onCreateAccountLinkClick={handleCreateAccountLinkClick}
        />
      )}
      {isSignUpDrawerOpen && (
        <CreateAccountDrawer
          onExited={closeSignUpDrawer}
          onSignInLinkClick={handleSignInLinkClick}
        />
      )}
      <TentativeCurrentCartFab currentCart={currentCart} />
    </>
  )
}
