import {useCallback, useState} from 'react'

export const useBooleanState = (defaultState: boolean) => {
  const [state, setState] = useState(defaultState)

  const toggle = useCallback(() => setState((state) => !state), [setState])
  const setTrue = useCallback(() => setState(true), [setState])
  const setFalse = useCallback(() => setState(false), [setState])

  return {state, toggle, setTrue, setFalse}
}
