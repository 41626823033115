import {gql} from '@apollo/client'
import {EVENT_DETAIL_FIELDS, SHOW_FIELDS, VENUE_FIELDS} from '../graphql'

export const GET_EVENT_OVERVIEW = gql`
  ${EVENT_DETAIL_FIELDS}
  ${SHOW_FIELDS}
  ${VENUE_FIELDS}
  query GetEventOverview($id: Int!) {
    event(id: $id) {
      ...EventDetailFields
      ...ShowFields
      venue {
        ...VenueFields
      }
    }
  }
`
