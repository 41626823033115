import {useMutation} from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import {Box, Button, Paper, Typography} from '@mui/material'
import {sortBy, uniqBy} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  AddReservationToCartMutation,
  AddReservationToCartMutationVariables,
  CancelReservationMutation,
  CancelReservationMutationVariables,
  CartState,
  GetCartSummaryQuery,
  Maybe,
  ReservationState
} from '../../__generated__/schema'
import {CenteredLayout} from '../../components/atoms/CenteredLayout'
import {NavigationSection} from '../../components/atoms/NavigationSection'
import {SideNavigationList} from '../../components/atoms/SideNavigationList'
import {SimpleDialog} from '../../components/atoms/SimpleDialog'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {AccountActions} from '../../components/molecules/AccountActions'
import {MediaSizes} from '../../components/types'
import {useBooleanState} from '../../hooks/state'
import {safeSum} from '../../utils/math'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {Powered} from '../components/atoms/Powered'
import {PriceRow} from '../components/atoms/PriceRow'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {CartSummaryWarning} from '../components/molecules/CartSummaryWarning'
import {
  EventSummaryPaper,
  eventSummaryPaperSx
} from '../components/molecules/EventSummaryPaper'
import {LeadDataReadOnlyPaper} from '../components/molecules/LeadDataReadOnlyPaper'
import {ReservationStateBar} from '../components/molecules/ReservationStateBar'
import {
  TourSummaryPaper,
  tourSummaryPaperSx
} from '../components/molecules/TourSummaryPaper'
import {useDownloadSalesPdf} from '../hooks/downloadSalesPdf'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {
  ReservationErrorHandlerLocation,
  useReservationErrorHandler
} from '../hooks/reservationErrorHandler'
import {useTranslateCartState} from '../hooks/translateCartState'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {
  getTicketItemsByEventIdFilter,
  getTourItemsByTourTimeSlotIdFilter,
  isTicketItem,
  isTourItem
} from '../utils'
import {ADD_RESERVATION_TO_CART, CANCEL_RESERVATION} from './graphql'
import {SummaryPaper} from './SummaryPaper'

interface IContentProps {
  cart: GetCartSummaryQuery['cartSummary']
  onRefetch: () => void
}

export const Content: React.FC<IContentProps> = ({
  cart,
  onRefetch
}: IContentProps) => {
  const {initializeCurrentCart} = useCurrentCart()
  const {t} = useTranslation()
  const translateCartState = useTranslateCartState()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const translatePrice = useTranslatePrice(cart.client.currency)
  const {setShowBackdrop, displayInfoNotification} =
    useMutationAssistanceHooks()
  const reservationErrorHandler = useReservationErrorHandler()
  const [reservationIdentifiers, setReservationIdentifiers] =
    useState<{id: number; uuid: string; hash: string} | undefined>(undefined)
  const {
    state: isDeleteReservationDialogOpen,
    setTrue: openDeleteReservationDialog,
    setFalse: closeDeleteReservationDialog
  } = useBooleanState(false)
  const allTicketItems = (cart.items || []).filter(isTicketItem)
  const allEvents = sortBy(
    uniqBy(
      allTicketItems.map((ticketItem) => ticketItem.eventSeat.event),
      'id'
    ),
    'startsAt'
  )
  const allTourItems = (cart.items || []).filter(isTourItem)
  const allTourTimeSlots = sortBy(
    uniqBy(
      allTourItems.map((tourItem) => tourItem.tourTimeSlot),
      'id'
    ),
    'startsAt'
  )
  const sideNavigationItems = useMemo(
    () => ({
      event: {
        id: 'events',
        label: t('Events')
      },
      ...(cart.state === CartState.Reserved
        ? {
            paymentMethods: {
              id: 'paymentMethods',
              label: t('Payment methods')
            }
          }
        : {}),
      contactInformation: {
        id: 'contactInformation',
        label: t('Contact information')
      },
      ...([CartState.Sold, CartState.Pending].includes(cart.state)
        ? {
            summary: {
              id: 'summary',
              label: t('Summary')
            }
          }
        : {})
    }),
    [cart.state, t]
  )
  const navigate = useNavigate()
  const getAddTicketItemButtonClickHandler = useCallback(
    (eventId: number) => () => {
      navigate(`/event/${eventId}`)
    },
    [navigate]
  )
  const getAddTourItemButtonClickHandler = useCallback(
    (tourTimeSlotId: number) => () =>
      navigate(`/tourTimeSlot/${tourTimeSlotId}`),
    [navigate]
  )
  const downloadSalesPdf = useDownloadSalesPdf()
  const handleDownloadTicketsButtonClick = useCallback(
    (sale) => async () => {
      setShowBackdrop(true)
      await downloadSalesPdf([sale])
      setShowBackdrop(false)
    },
    [downloadSalesPdf, setShowBackdrop]
  )
  const handleDeleteReservationButtonClick = useCallback(
    (reservationId: number) => () => {
      setReservationIdentifiers(
        cart.reservations?.find(
          (reservation) => reservation.id === reservationId
        )
      )
      openDeleteReservationDialog()
    },
    [openDeleteReservationDialog, cart]
  )
  const [addReservationToCart] = useMutation<
    AddReservationToCartMutation,
    AddReservationToCartMutationVariables
  >(ADD_RESERVATION_TO_CART)
  const getPayNowButtonClickHandler = useCallback(
    (reservation: {
        id: number
        uuid: string
        hash: string
        event?: Maybe<{id: number}>
      }) =>
      async () => {
        try {
          setShowBackdrop(true)
          const {data} = await addReservationToCart({
            variables: {
              reservationId: reservation.id,
              reservationEcommerceIdentifiers: {
                uuid: reservation.uuid,
                hash: reservation.hash
              }
            }
          })
          if (data?.addReservationToCart) {
            initializeCurrentCart(data.addReservationToCart)
            navigate(`/${data.addReservationToCart.clientId}/cart`)
          }
        } catch (error) {
          reservationErrorHandler({
            error,
            location: ReservationErrorHandlerLocation.Pay,
            onRefetch,
            eventId: reservation.event!.id
          })
        } finally {
          setShowBackdrop(false)
        }
      },
    [
      addReservationToCart,
      initializeCurrentCart,
      navigate,
      onRefetch,
      reservationErrorHandler,
      setShowBackdrop
    ]
  )
  const [cancelReservation] =
    useMutation<CancelReservationMutation, CancelReservationMutationVariables>(
      CANCEL_RESERVATION
    )
  const handleDeleteReservationConfirmation = useCallback(async () => {
    if (reservationIdentifiers) {
      try {
        setShowBackdrop(true)
        await cancelReservation({
          variables: {
            reservationId: reservationIdentifiers.id,
            reservationEcommerceIdentifiers: {
              uuid: reservationIdentifiers.uuid,
              hash: reservationIdentifiers.hash
            }
          }
        })
        onRefetch()
        displayInfoNotification(t('Reservation has been deleted'))
      } catch (error) {
        reservationErrorHandler({
          error,
          location: ReservationErrorHandlerLocation.Delete,
          onRefetch
        })
      } finally {
        setShowBackdrop(false)
        closeDeleteReservationDialog()
      }
    }
  }, [
    cancelReservation,
    closeDeleteReservationDialog,
    displayInfoNotification,
    onRefetch,
    reservationErrorHandler,
    reservationIdentifiers,
    setShowBackdrop,
    t
  ])
  const handleSignInButtonClick = useCallback(
    () => navigate('signIn'),
    [navigate]
  )
  return (
    <>
      <CenteredLayout
        header={
          <BaseHeader
            title={t('Cart #{{id}} • {{cartState}}', {
              id: cart.id,
              cartState: translateCartState(cart.state)
            })}
          >
            <AccountActions onSignInButtonClick={handleSignInButtonClick} />
          </BaseHeader>
        }
        leftSidebar={
          cart.state !== CartState.Failed && (
            <SideNavigationList
              sx={{pl: 1, pt: 2}}
              items={sideNavigationItems}
            />
          )
        }
        childrenSx={{display: 'flex', flexDirection: 'column'}}
      >
        <CartSummaryWarning
          cartState={cart.state}
          email={cart.lead?.data.email}
          cartId={cart.id}
          sx={{pt: 4}}
          onRefetch={onRefetch}
        />
        <NavigationSection
          label={sideNavigationItems.event.label}
          id={sideNavigationItems.event.id}
          sx={{pt: 4}}
        >
          {cart.state === CartState.Reserved &&
            (cart.reservations || []).map((reservation) =>
              reservation.event
                ? reservation.event && (
                    <EventSummaryPaper
                      id={`reservation${reservation.id}`}
                      key={reservation.event.id}
                      event={reservation.event}
                      isMobile={isMobile}
                      eventTicketItems={sortBy(
                        reservation.items || [],
                        'id'
                      ).filter(isTicketItem)}
                      topBar={<ReservationStateBar reservation={reservation} />}
                      sx={eventSummaryPaperSx}
                    >
                      <PriceRow
                        primaryPrice={translatePrice(reservation.price)}
                      >
                        {reservation.state === ReservationState.Active && (
                          <>
                            <Button
                              onClick={getPayNowButtonClickHandler(reservation)}
                              color="primary"
                              variant="contained"
                            >
                              {reservation.price === 0
                                ? t('Confirm now')
                                : t('Pay now')}
                            </Button>
                            <Button
                              startIcon={<DeleteIcon />}
                              onClick={handleDeleteReservationButtonClick(
                                reservation.id
                              )}
                              variant="text"
                            >
                              {t('Delete')}
                            </Button>
                          </>
                        )}
                        {reservation.state === ReservationState.Fulfilled &&
                          reservation.relatedSale && (
                            <Button
                              startIcon={<DownloadIcon />}
                              onClick={handleDownloadTicketsButtonClick(
                                reservation.relatedSale
                              )}
                              variant="contained"
                            >
                              {t('Download tickets')}
                            </Button>
                          )}
                      </PriceRow>
                    </EventSummaryPaper>
                  )
                : reservation.tourTimeSlot && (
                    <TourSummaryPaper
                      key={`reservation${reservation.id}`}
                      isMobile={isMobile}
                      tourItems={sortBy(reservation.items || [], 'id').filter(
                        isTourItem
                      )}
                      tourTimeSlot={reservation.tourTimeSlot}
                      topBar={<ReservationStateBar reservation={reservation} />}
                      sx={tourSummaryPaperSx}
                    >
                      <PriceRow
                        primaryPrice={translatePrice(reservation.price)}
                      >
                        {reservation.state === ReservationState.Active && (
                          <>
                            <Button
                              onClick={getPayNowButtonClickHandler(reservation)}
                              color="primary"
                              variant="contained"
                            >
                              {reservation.price === 0
                                ? t('Confirm now')
                                : t('Pay now')}
                            </Button>
                            <Button
                              startIcon={<DeleteIcon />}
                              onClick={handleDeleteReservationButtonClick(
                                reservation.id
                              )}
                              variant="text"
                            >
                              {t('Delete')}
                            </Button>
                          </>
                        )}
                        {reservation.state === ReservationState.Fulfilled &&
                          reservation.relatedSale && (
                            <Button
                              startIcon={<DownloadIcon />}
                              onClick={handleDownloadTicketsButtonClick(
                                reservation.relatedSale
                              )}
                              variant="contained"
                            >
                              {t('Download tickets')}
                            </Button>
                          )}
                      </PriceRow>
                    </TourSummaryPaper>
                  )
            )}
          {cart.state === CartState.Sold &&
            (cart.sales || []).map((sale) =>
              sale.event ? (
                <EventSummaryPaper
                  id={`sale${sale.id}`}
                  key={sale.event.id}
                  event={sale.event}
                  isMobile={isMobile}
                  eventTicketItems={(sale.items || []).filter(isTicketItem)}
                  sx={eventSummaryPaperSx}
                >
                  <PriceRow primaryPrice={translatePrice(sale.price)}>
                    <Button
                      startIcon={<DownloadIcon />}
                      onClick={handleDownloadTicketsButtonClick(sale)}
                      variant="contained"
                    >
                      {t('Download tickets')}
                    </Button>
                  </PriceRow>
                </EventSummaryPaper>
              ) : (
                sale.tourTimeSlot && (
                  <TourSummaryPaper
                    id={`tour-sale${sale.id}`}
                    key={`tour-sale${sale.id}`}
                    isMobile={isMobile}
                    tourItems={(sale.items || []).filter(isTourItem)}
                    tourTimeSlot={sale.tourTimeSlot}
                    sx={tourSummaryPaperSx}
                  >
                    <PriceRow primaryPrice={translatePrice(sale.price)}>
                      <Button
                        startIcon={<DownloadIcon />}
                        onClick={handleDownloadTicketsButtonClick(sale)}
                        variant="contained"
                      >
                        {t('Download tickets')}
                      </Button>
                    </PriceRow>
                  </TourSummaryPaper>
                )
              )
            )}
          {cart.state === CartState.Pending && (
            <>
              {allEvents.map((event) => (
                <EventSummaryPaper
                  key={event.id}
                  event={event}
                  isMobile={isMobile}
                  eventTicketItems={allTicketItems.filter(
                    getTicketItemsByEventIdFilter(event.id)
                  )}
                  sx={eventSummaryPaperSx}
                >
                  <PriceRow
                    primaryPrice={translatePrice(
                      safeSum(
                        allTicketItems
                          .filter(getTicketItemsByEventIdFilter(event.id))
                          .map((ti) => ti.price)
                      )
                    )}
                  />
                </EventSummaryPaper>
              ))}
              {allTourTimeSlots.map((tourTimeSlot) => (
                <TourSummaryPaper
                  key={tourTimeSlot.id}
                  isMobile={isMobile}
                  tourItems={allTourItems.filter(
                    getTourItemsByTourTimeSlotIdFilter(tourTimeSlot.id)
                  )}
                  tourTimeSlot={tourTimeSlot}
                  sx={tourSummaryPaperSx}
                >
                  <PriceRow
                    primaryPrice={translatePrice(
                      safeSum(
                        allTourItems
                          .filter(
                            getTourItemsByTourTimeSlotIdFilter(tourTimeSlot.id)
                          )
                          .map(({price}) => price)
                      )
                    )}
                  />
                </TourSummaryPaper>
              ))}
            </>
          )}
          {cart.state === CartState.Failed && (
            <>
              {allEvents.map((event) => (
                <EventSummaryPaper
                  key={event.id}
                  event={event}
                  isMobile={isMobile}
                  eventTicketItems={[]}
                  sx={eventSummaryPaperSx}
                >
                  <PriceRow primaryPrice={''}>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      onClick={getAddTicketItemButtonClickHandler(event.id)}
                    >
                      {t('Add tickets')}
                    </Button>
                  </PriceRow>
                </EventSummaryPaper>
              ))}
              {allTourTimeSlots.map((tourTimeSlot) => (
                <TourSummaryPaper
                  key={tourTimeSlot.id}
                  isMobile={isMobile}
                  tourItems={[]}
                  tourTimeSlot={tourTimeSlot}
                  sx={tourSummaryPaperSx}
                >
                  <PriceRow primaryPrice={''}>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      onClick={getAddTourItemButtonClickHandler(
                        tourTimeSlot.id
                      )}
                    >
                      {t('Add tickets')}
                    </Button>
                  </PriceRow>
                </TourSummaryPaper>
              ))}
            </>
          )}
        </NavigationSection>
        {cart.state === CartState.Reserved &&
          sideNavigationItems.paymentMethods && (
            <NavigationSection
              label={sideNavigationItems.paymentMethods.label}
              id={sideNavigationItems.paymentMethods.id}
              sx={{pt: 4, pb: 3}}
            >
              <Paper sx={{borderRadius: 4, px: 3, py: 1}} variant="outlined">
                <Typography variant="subtitle2">{t('Reservation')}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {t('You can pay for your reservation later')}
                </Typography>
              </Paper>
            </NavigationSection>
          )}
        {cart.state !== CartState.Failed && (
          <NavigationSection
            label={sideNavigationItems.contactInformation.label}
            id={sideNavigationItems.contactInformation.id}
            sx={{pt: 4, pb: 3}}
          >
            <LeadDataReadOnlyPaper leadData={cart.lead?.data || {}} />
          </NavigationSection>
        )}
        {[CartState.Sold, CartState.Pending].includes(cart.state) &&
          sideNavigationItems.summary && (
            <NavigationSection
              label={sideNavigationItems.summary.label}
              id={sideNavigationItems.summary.id}
              sx={{pt: 4, pb: 3}}
            >
              <SummaryPaper
                cart={cart}
                sx={{
                  px: 3,
                  py: 1.5
                }}
              />
            </NavigationSection>
          )}
        <Box sx={{flex: 1}} />
        <Powered />
      </CenteredLayout>
      <SimpleDialog
        isOpen={isDeleteReservationDialogOpen}
        title={t('Delete reservation?')}
        content={t(
          'Do you really want to delete this reservation? This action is permanent and irreversible.'
        )}
        actions={
          <>
            <Button variant="text" onClick={closeDeleteReservationDialog}>
              {t('Cancel')}
            </Button>
            <Button
              variant="text"
              onClick={handleDeleteReservationConfirmation}
            >
              {t('Delete')}
            </Button>
          </>
        }
      />
    </>
  )
}
