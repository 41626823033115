import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useFormContext, ValidationOptions} from 'react-hook-form'
import {TFunction, useTranslation} from 'react-i18next'
import {
  ExistingCountryCode,
  LeadField,
  LeadInputStatus,
  LeadOptionFieldsFragment
} from '../../__generated__/schema'
import {FormAutocomplete} from '../components/molecules/FormAutocomplete'
import {UncontrolledFormTextInput} from '../components/molecules/UncontrolledFormTextInput'
import {useIsValidEmail, useIsValidPhone} from '../hooks/formsValidations'
import {useTranslateCountry} from '../hooks/translateCountry'
import {useTranslateLeadField} from '../hooks/translateLeadField'
import {ICartPageForm} from './types'

interface IOptionsAccordionProps {
  label: string
  expanded?: boolean
  children: React.ReactNode
}

const OptionsAccordion: React.FC<IOptionsAccordionProps> = ({
  label,
  expanded,
  children
}: IOptionsAccordionProps) => (
  <Accordion
    elevation={0}
    square
    disableGutters
    defaultExpanded={expanded}
    sx={{
      px: 3,
      py: 1.5,
      borderRadius: 4,
      border: (theme) => `solid ${theme.palette.divider} 1px`,
      '&:before': {
        display: 'none'
      }
    }}
  >
    <AccordionSummary
      sx={{p: 0, pointerEvents: expanded ? 'none' : 'auto'}}
      expandIcon={expanded ? undefined : <ExpandMoreIcon />}
    >
      <Typography variant="subtitle2">{label}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{p: 0, pt: 1}}>{children}</AccordionDetails>
  </Accordion>
)

export const getHelperNote = (
  t: TFunction,
  leadOption: LeadOptionFieldsFragment
): string | undefined =>
  leadOption.inputStatus === LeadInputStatus.Recommended
    ? leadOption.helperText
      ? t<string>('*Recommended. {{helperText}}', {
          helperText: leadOption.helperText
        })
      : t('*Recommended')
    : leadOption.helperText ?? undefined

const isAccordionExpanded = (
  leadFields: LeadField[],
  leadOptions: LeadOptionFieldsFragment[]
) =>
  leadOptions
    .filter(({field}) => leadFields.includes(field))
    .some(({inputStatus}) =>
      [LeadInputStatus.Recommended, LeadInputStatus.Required].includes(
        inputStatus
      )
    )

interface ILeadFormTextInputProps {
  leadOption?: LeadOptionFieldsFragment
  validationOptions?: ValidationOptions
  onBlur?: () => void
}

export const LeadFormTextInput: React.FC<ILeadFormTextInputProps> = ({
  leadOption,
  validationOptions,
  onBlur
}: ILeadFormTextInputProps) => {
  const {t} = useTranslation()
  const {errors, control} = useFormContext<ICartPageForm>()
  const translateLeadField = useTranslateLeadField()
  return leadOption ? (
    <UncontrolledFormTextInput<ICartPageForm>
      fullWidth
      errors={errors}
      control={control}
      sx={{
        pb: 2,
        '&:last-child': {
          pb: 0
        }
      }}
      label={translateLeadField(leadOption.field)}
      name={leadOption.field}
      helperText={getHelperNote(t, leadOption)}
      validationOptions={{
        required: leadOption.inputStatus === LeadInputStatus.Required,
        ...validationOptions
      }}
      onBlur={onBlur}
    />
  ) : null
}

export const LeadFormAutocomplete: React.FC<ILeadFormTextInputProps> = ({
  leadOption
}: ILeadFormTextInputProps) => {
  const {t} = useTranslation()
  const {errors, control} = useFormContext<ICartPageForm>()
  const translateLeadField = useTranslateLeadField()
  const translateCountry = useTranslateCountry()
  const autocompleteOptions = useMemo(
    () =>
      Object.values(ExistingCountryCode).map((code: ExistingCountryCode) => ({
        value: code,
        name: translateCountry(code)
      })),
    [translateCountry]
  )
  return leadOption ? (
    <FormAutocomplete<ICartPageForm>
      fullWidth
      errors={errors}
      control={control}
      autocompleteOptions={autocompleteOptions}
      sx={{
        pb: 2,
        '&:last-child': {
          pb: 0
        }
      }}
      label={translateLeadField(leadOption.field)}
      name={leadOption.field}
      helperText={getHelperNote(t, leadOption)}
      validationOptions={{
        required: leadOption.inputStatus === LeadInputStatus.Required
      }}
    />
  ) : null
}

const getFinder =
  (leadField: LeadField) => (leadOption: LeadOptionFieldsFragment) =>
    leadOption.field === leadField

interface IContactInformationContentProps {
  sx: SxProps<Theme>
  leadOptions: LeadOptionFieldsFragment[]
}

export const ContactInformationContent: React.FC<IContactInformationContentProps> =
  ({sx, leadOptions}: IContactInformationContentProps) => {
    const {t} = useTranslation()
    const {watch, setValue} = useFormContext<ICartPageForm>()
    const visibleLeadOptions = leadOptions.filter((lo) =>
      [
        LeadInputStatus.Recommended,
        LeadInputStatus.Required,
        LeadInputStatus.Optional
      ].includes(lo.inputStatus)
    )
    const contactPersonLeadOptions = visibleLeadOptions.filter((lo) =>
      [
        LeadField.Name,
        LeadField.Phone,
        LeadField.Email,
        LeadField.Note
      ].includes(lo.field)
    )
    const companyLeadOptions = visibleLeadOptions.filter((lo) =>
      [
        LeadField.CompanyName,
        LeadField.CompanyIdNumber,
        LeadField.VatId,
        LeadField.TaxId
      ].includes(lo.field)
    )
    const billingAddressLeadOptions = visibleLeadOptions.filter((lo) =>
      [
        LeadField.BillingAddressStreet,
        LeadField.BillingAddressTown,
        LeadField.BillingPostalCode,
        LeadField.BillingAddressCountry
      ].includes(lo.field)
    )
    const deliveryAddressLeadOptions = visibleLeadOptions.filter((lo) =>
      [
        LeadField.DeliveryAddressee,
        LeadField.DeliveryAddressStreet,
        LeadField.DeliveryAddressTown,
        LeadField.DeliveryPostalCode,
        LeadField.DeliveryAddressCountry
      ].includes(lo.field)
    )
    const isValidEmail = useIsValidEmail()
    const isValidPhone = useIsValidPhone()
    const handlePhoneInputOnBlur = useCallback(
      () =>
        setValue(LeadField.Phone, watch(LeadField.Phone).replaceAll(/\s/g, '')),
      [setValue, watch]
    )
    const handleEmailInputOnBlur = useCallback(
      () =>
        setValue(LeadField.Email, watch(LeadField.Email).replaceAll(/\s/g, '')),
      [setValue, watch]
    )
    return (
      <Box sx={sx}>
        {contactPersonLeadOptions.length > 0 && (
          <OptionsAccordion
            label={t('Contact person')}
            expanded={isAccordionExpanded(
              [
                LeadField.Name,
                LeadField.Phone,
                LeadField.Email,
                LeadField.Note
              ],
              leadOptions
            )}
          >
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(getFinder(LeadField.Name))}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(getFinder(LeadField.Phone))}
              validationOptions={{
                validate: isValidPhone
              }}
              onBlur={handlePhoneInputOnBlur}
            />
            <LeadFormTextInput
              key={LeadField.Email}
              leadOption={visibleLeadOptions.find(getFinder(LeadField.Email))}
              validationOptions={{
                validate: isValidEmail
              }}
              onBlur={handleEmailInputOnBlur}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(getFinder(LeadField.Note))}
            />
          </OptionsAccordion>
        )}
        {companyLeadOptions.length > 0 && (
          <OptionsAccordion
            label={t('Company')}
            expanded={isAccordionExpanded(
              [
                LeadField.CompanyName,
                LeadField.CompanyIdNumber,
                LeadField.VatId,
                LeadField.TaxId
              ],
              leadOptions
            )}
          >
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.CompanyName)
              )}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.CompanyIdNumber)
              )}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(getFinder(LeadField.VatId))}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(getFinder(LeadField.TaxId))}
            />
          </OptionsAccordion>
        )}
        {billingAddressLeadOptions.length > 0 && (
          <OptionsAccordion
            label={t('Billing address')}
            expanded={isAccordionExpanded(
              [
                LeadField.BillingAddressStreet,
                LeadField.BillingAddressTown,
                LeadField.BillingPostalCode,
                LeadField.BillingAddressCountry
              ],
              leadOptions
            )}
          >
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.BillingAddressStreet)
              )}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.BillingAddressTown)
              )}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.BillingPostalCode)
              )}
            />
            <LeadFormAutocomplete
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.BillingAddressCountry)
              )}
            />
          </OptionsAccordion>
        )}
        {deliveryAddressLeadOptions.length > 0 && (
          <OptionsAccordion
            label={t('Delivery address')}
            expanded={isAccordionExpanded(
              [
                LeadField.DeliveryAddressee,
                LeadField.DeliveryAddressStreet,
                LeadField.DeliveryAddressTown,
                LeadField.DeliveryPostalCode,
                LeadField.DeliveryAddressCountry
              ],
              leadOptions
            )}
          >
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.DeliveryAddressee)
              )}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.DeliveryAddressStreet)
              )}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.DeliveryAddressTown)
              )}
            />
            <LeadFormTextInput
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.DeliveryPostalCode)
              )}
            />
            <LeadFormAutocomplete
              leadOption={visibleLeadOptions.find(
                getFinder(LeadField.DeliveryAddressCountry)
              )}
            />
          </OptionsAccordion>
        )}
      </Box>
    )
  }
