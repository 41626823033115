import {useQuery} from '@apollo/client'
import EditIcon from '@mui/icons-material/Edit'
import {Button} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Routes, useNavigate} from 'react-router-dom'
import {
  CustomerPurchaseHistoryQuery,
  CustomerPurchaseHistoryQueryVariables
} from '../../__generated__/schema'
import {CenteredLayout} from '../../components/atoms/CenteredLayout'
import {SideNavigationList} from '../../components/atoms/SideNavigationList'
import {SignOutButton} from '../../components/atoms/SignOutButton'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {ChildrenOnSignedInCustomer} from '../../components/molecules/ChildrenOnSignedInCustomer'
import {RenderOnData} from '../../components/molecules/RenderOnData'
import {MediaSizes} from '../../components/types'
import {RemainingLabel} from '../cart/RemainingLabel'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from '../components/atoms/CurrentCartFab'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {LeadDataReadOnlyPaper} from '../components/molecules/LeadDataReadOnlyPaper'
import {EditProfileDrawer} from '../components/organisms/account/editProfile/EditProfileDrawer'
import {CUSTOMER_PURCHASE_HISTORY} from '../graphql'
import {AccountSection} from './AccountSection'
import {MyReservationsSection} from './MyReservationsSection'
import {PurchaseHistorySection} from './PurchaseHistorySection'

const useSideNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    () => ({
      account: {
        id: 'account',
        label: t('Account')
      },
      profile: {
        id: 'profile',
        label: t('Profile')
      },
      myReservations: {
        id: 'myReservations',
        label: t('My reservations')
      },
      purchaseHistory: {
        id: 'purchaseHistory',
        label: t('Purchased events')
      }
    }),
    [t]
  )
}

export const MyAccount: React.FC = () => {
  const {t} = useTranslation()
  const {currentCart} = useCurrentCart()
  const navigate = useNavigate()
  const {data, loading, error, refetch} = useQuery<
    CustomerPurchaseHistoryQuery,
    CustomerPurchaseHistoryQueryVariables
  >(CUSTOMER_PURCHASE_HISTORY, {fetchPolicy: 'network-only'})
  const sideNavigationItems = useSideNavigationItems()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const handleEditButtonClick = useCallback(() => navigate('edit'), [navigate])
  const handleExited = useCallback(() => navigate('/myAccount'), [navigate])
  return (
    <ChildrenOnSignedInCustomer>
      {(customer) => (
        <>
          <CenteredLayout
            header={
              <BaseHeader title={t('My account')} sx={{gap: isMobile ? 0 : 3}}>
                {currentCart && (
                  <RemainingLabel expiresAt={currentCart.expiresAt} />
                )}
                <SignOutButton />
              </BaseHeader>
            }
            leftSidebar={
              <SideNavigationList
                sx={{pl: 1, pt: 2}}
                items={sideNavigationItems}
              />
            }
            childrenSx={{display: 'flex', flexDirection: 'column', gap: 4}}
          >
            <AccountSection
              customer={customer}
              id={sideNavigationItems.account.id}
              sx={{pt: 3}}
              isMobile={isMobile}
            />
            <LeadDataReadOnlyPaper
              id={sideNavigationItems.profile.id}
              title={sideNavigationItems.profile.label}
              leadData={customer.lead.data}
              firstSectionTitle={t<string>('My profile')}
              sx={{display: 'flex', flexDirection: 'column', gap: 1}}
              action={
                <Button
                  onClick={handleEditButtonClick}
                  variant="text"
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  {t('Edit')}
                </Button>
              }
            />
            <RenderOnData<CustomerPurchaseHistoryQuery>
              data={data}
              loading={loading}
              error={error}
              errorMessage={t<string>('Error while loading purchase history')}
              dataCondition={(data) => Boolean(data.signedInCustomer)}
            >
              {({signedInCustomer}) => (
                <>
                  <MyReservationsSection
                    id={sideNavigationItems.myReservations.id}
                    title={sideNavigationItems.myReservations.label}
                    sx={{display: 'flex', flexDirection: 'column', gap: 1}}
                    reservations={signedInCustomer.reservations}
                    currency={customer.client.currency}
                    onRefetch={refetch}
                  />
                  <PurchaseHistorySection
                    id={sideNavigationItems.purchaseHistory.id}
                    title={sideNavigationItems.purchaseHistory.label}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      pb: isMobile && currentCart ? 11 : 4
                    }}
                    sales={signedInCustomer.sales}
                    currency={customer.client.currency}
                    isMobile={isMobile}
                  />
                </>
              )}
            </RenderOnData>
          </CenteredLayout>
          <TentativeCurrentCartFab currentCart={currentCart} />
          <Routes>
            <Route
              path="edit"
              element={
                <EditProfileDrawer
                  onExited={handleExited}
                  customer={customer}
                />
              }
            />
          </Routes>
        </>
      )}
    </ChildrenOnSignedInCustomer>
  )
}
