import {useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  RemoveTicketItemFromCartMutation,
  RemoveTicketItemFromCartMutationVariables
} from '../../__generated__/schema'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {REMOVE_TICKET_ITEM_FROM_CART} from '../graphql'
import {getGraphQLErrorRelatedToErrorMessage, isTicketItem} from '../utils'
import {useEcommerceErrorHandlers} from './ecommerceErrorHandlers'
import {useMutationAssistanceHooks} from './mutationAssistanceHooks'

export const useGetTicketItemFromCartRemover = () => {
  const {updateCurrentCart, currentCart, currentCartId} = useCurrentCart()
  const {t} = useTranslation()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const [removeTicketItemFromCart] = useMutation<
    RemoveTicketItemFromCartMutation,
    RemoveTicketItemFromCartMutationVariables
  >(REMOVE_TICKET_ITEM_FROM_CART)
  const {invalidCartStateErrorHandler} = useEcommerceErrorHandlers()
  return useCallback(
    (uuid: string, eventSeatId: number) => async () => {
      try {
        setShowBackdrop(true)
        if (currentCartId && currentCart) {
          const itemId = (currentCart.items || [])
            .filter(isTicketItem)
            .find((item) => item.eventSeat.id === eventSeatId)?.id
          if (itemId) {
            const {data} = await removeTicketItemFromCart({
              variables: {
                cartId: currentCartId,
                itemId
              }
            })
            if (data) {
              updateCurrentCart()
            }
          }
        }
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error})
        } else {
          defaultErrorHandler(error, {
            description: t<string>('Removing ticket item failed')
          })
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      currentCart,
      currentCartId,
      defaultErrorHandler,
      invalidCartStateErrorHandler,
      removeTicketItemFromCart,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )
}
