import assert from 'assert'
import packageJson from '../package.json'

const graphqlServerUrl = process.env.REACT_APP_GRAPHQL_SERVER_URL
assert(graphqlServerUrl, 'Graphql server url must be set')

const graphqlServerSubscriptionsPath =
  process.env.REACT_APP_GRAPHQL_SERVER_SUBSCRIPTIONS_PATH
assert(
  graphqlServerSubscriptionsPath,
  'Graphql server subscriptions url must be set'
)

const serverSessionCookieMaxAge = parseInt(
  process.env.REACT_APP_SERVER_SESSION_COOKIE_MAX_AGE || '36000000',
  10
)

const isProduction = process.env.NODE_ENV === 'production'

const sentryDSN = process.env.REACT_APP_SENTRY_DSN

if (isProduction) {
  assert(sentryDSN, 'Sentry DSN needs to be set')
}

export const config = {
  sentryDSN,
  isProduction,
  serverSessionCookieMaxAge,
  graphqlServerUrl,
  graphqlServerSubscriptionsPath,
  eCommerceProjectName: packageJson.name,
  eCommerceProjectVersion: packageJson.version
}
