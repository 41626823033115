import {useTranslation} from 'react-i18next'
import {CartState} from '../../__generated__/schema'

export const useTranslateCartState = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<CartState, string> = {
    [CartState.Draft]: t('CartState->Draft'),
    [CartState.Sold]: t('CartState->Sold'),
    [CartState.Discarded]: t('CartState->Discarded'),
    [CartState.Expired]: t('CartState->Expired'),
    [CartState.Failed]: t('CartState->Failed'),
    [CartState.Reserved]: t('CartState->Reserved'),
    [CartState.Pending]: t('CartState->Pending')
  }
  return (cartState: CartState): string => translatedCodes[cartState]
}
