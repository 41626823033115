import {Button, Drawer} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {CustomerFieldsFragment} from '../../../../../__generated__/schema'
import {DrawerTemplateFooter} from '../../../../../components/atoms/DrawerTemplateFooter'
import {DrawerTemplateHeader} from '../../../../../components/atoms/DrawerTemplateHeader'
import {DrawerTemplate} from '../../../../../components/molecules/DrawerTemplate'
import {useBooleanState} from '../../../../../hooks/state'
import {useUpdateCustomer} from '../../../../auth'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {AccountFormField} from '../types'
import {EditProfileForm} from './EditProfileForm'
import {IEditProfileForm, transformEditProfileDataToInput} from './types'

const EDIT_PROFILE_FORM_ID = 'editProfileForm'

interface IEditProfileDrawerProps {
  onExited: () => void
  customer: CustomerFieldsFragment
}

export const EditProfileDrawer: React.FC<IEditProfileDrawerProps> = ({
  onExited,
  customer
}: IEditProfileDrawerProps) => {
  const {t} = useTranslation()
  const updateCustomer = useUpdateCustomer()
  const {setShowBackdrop, displayInfoNotification} =
    useMutationAssistanceHooks()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  useEffect(() => {
    openDrawer()
  }, [openDrawer])
  const handleSubmit = useCallback(
    async (data: IEditProfileForm) => {
      try {
        setShowBackdrop(true)
        await updateCustomer(transformEditProfileDataToInput(data))
        displayInfoNotification(t('Profile updated'))
        onExited()
      } finally {
        setShowBackdrop(false)
      }
    },
    [displayInfoNotification, onExited, setShowBackdrop, t, updateCustomer]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={closeDrawer}
      anchor="right"
      SlideProps={{onExited}}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 480
        }
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Edit profile')}
          />
        }
        footer={
          <DrawerTemplateFooter>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              form={EDIT_PROFILE_FORM_ID}
            >
              {t('Save')}
            </Button>
          </DrawerTemplateFooter>
        }
      >
        <EditProfileForm
          formId={EDIT_PROFILE_FORM_ID}
          onSubmit={handleSubmit}
          defaultValues={{
            [AccountFormField.Username]: customer.username,
            [AccountFormField.WouldLikeToReceiveLatestNews]:
              !!customer.privacyPolicyConsentGrantedAt,
            [AccountFormField.Name]: customer.lead.data.name || '',
            [AccountFormField.Email]: customer.lead.data.email || '',
            [AccountFormField.Phone]: customer.lead.data.phone || '',
            [AccountFormField.Note]: customer.lead.data.note || '',
            [AccountFormField.InternalNote]:
              customer.lead.data.internalNote || '',
            [AccountFormField.CompanyName]:
              customer.lead.data.companyName || '',
            [AccountFormField.CompanyIdNumber]:
              customer.lead.data.companyIdNumber || '',
            [AccountFormField.VatId]: customer.lead.data.VATId || '',
            [AccountFormField.TaxId]: customer.lead.data.TAXId || '',
            [AccountFormField.BillingAddressStreet]:
              customer.lead.data.billingAddressStreet || '',
            [AccountFormField.BillingAddressTown]:
              customer.lead.data.billingAddressTown || '',
            [AccountFormField.BillingPostalCode]:
              customer.lead.data.billingPostalCode || '',
            [AccountFormField.BillingAddressCountry]:
              customer.lead.data.billingAddressCountry || null,
            [AccountFormField.DeliveryAddressee]:
              customer.lead.data.deliveryAddressee || '',
            [AccountFormField.DeliveryAddressStreet]:
              customer.lead.data.deliveryAddressStreet || '',
            [AccountFormField.DeliveryAddressTown]:
              customer.lead.data.deliveryAddressTown || '',
            [AccountFormField.DeliveryPostalCode]:
              customer.lead.data.deliveryPostalCode || '',
            [AccountFormField.DeliveryAddressCountry]:
              customer.lead.data.deliveryAddressCountry || null
          }}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
