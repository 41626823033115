import Joi from 'joi'
import queryString from 'query-string'
import {useLocation} from 'react-router-dom'
import {AttributeAnchorTarget, EventButtonTarget} from '../../../../types'
import {AppBackgroundColor, IAppParams} from './types'

const defaultAppParams: IAppParams = {
  showCartButton: true,
  gatewayButtonTarget: AttributeAnchorTarget.Self,
  eventOverviewEnabled: false,
  eventButtonTarget: EventButtonTarget.Top,
  backgroundColor: AppBackgroundColor.Grey
}

const getInitialShowCartButton = (parsedSearch: object) => {
  const {value} = Joi.object<Pick<IAppParams, 'showCartButton'>>({
    showCartButton: Joi.boolean().default(defaultAppParams.showCartButton)
  })
    .options({allowUnknown: true})
    .validate(parsedSearch)
  return value ? value.showCartButton : defaultAppParams.showCartButton
}

const getInitialGatewayButtonTarget = (parsedSearch: object) => {
  const {value} = Joi.object<Pick<IAppParams, 'gatewayButtonTarget'>>({
    gatewayButtonTarget: Joi.string()
      .default(defaultAppParams.gatewayButtonTarget)
      .valid(
        AttributeAnchorTarget.Self,
        AttributeAnchorTarget.Top,
        AttributeAnchorTarget.Blank
      )
  })
    .options({allowUnknown: true})
    .validate(parsedSearch)
  return value
    ? value.gatewayButtonTarget
    : defaultAppParams.gatewayButtonTarget
}

const getInitialEventOverview = (parsedSearch: object) => {
  const {value} = Joi.object<Pick<IAppParams, 'eventOverviewEnabled'>>({
    eventOverviewEnabled: Joi.boolean().default(
      defaultAppParams.eventOverviewEnabled
    )
  })
    .options({allowUnknown: true})
    .validate(parsedSearch)
  return value
    ? value.eventOverviewEnabled
    : defaultAppParams.eventOverviewEnabled
}

const getInitialEventButtonTarget = (parsedSearch: object) => {
  const {value} = Joi.object<Pick<IAppParams, 'eventButtonTarget'>>({
    eventButtonTarget: Joi.string()
      .valid(...Object.values(EventButtonTarget))
      .default(defaultAppParams.eventButtonTarget)
  })
    .options({allowUnknown: true})
    .validate(parsedSearch)
  return value ? value.eventButtonTarget : defaultAppParams.eventButtonTarget
}

const getInitialBackgroundColor = (parsedSearch: object) => {
  const {value} = Joi.object<Pick<IAppParams, 'backgroundColor'>>({
    backgroundColor: Joi.string()
      .valid(...Object.values(AppBackgroundColor))
      .default(defaultAppParams.backgroundColor)
  })
    .options({allowUnknown: true})
    .validate(parsedSearch)
  return value ? value.backgroundColor : defaultAppParams.backgroundColor
}

export const useInitialAppParams = (): IAppParams => {
  const location = useLocation()
  const parsedSearch = queryString.parse(location.search)

  return {
    showCartButton: getInitialShowCartButton(parsedSearch),
    gatewayButtonTarget: getInitialGatewayButtonTarget(parsedSearch),
    eventOverviewEnabled: getInitialEventOverview(parsedSearch),
    eventButtonTarget: getInitialEventButtonTarget(parsedSearch),
    backgroundColor: getInitialBackgroundColor(parsedSearch)
  }
}
