import {Box, Chip, Divider, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'
import {Currency, TicketItemFieldsFragment} from '../../__generated__/schema'
import {FullTicketItemLabel} from '../components/atoms/FullTicketItemLabel'

interface IEventTicketItemProps {
  ticketItem: TicketItemFieldsFragment
  translatePrice: (value: number, currency?: Currency | undefined) => string
  sx?: SxProps<Theme>
}

export const EventTicketItem: React.FC<IEventTicketItemProps> = ({
  ticketItem,
  translatePrice,
  sx
}: IEventTicketItemProps) => {
  const hasDiscounts = ticketItem.appliedDiscounts.length > 0
  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: hasDiscounts ? '1fr auto auto' : '1fr auto',
          columnGap: 1,
          alignItems: 'center',
          minHeight: 56
        }}
      >
        <FullTicketItemLabel eventSeat={ticketItem.eventSeat} />
        {hasDiscounts && (
          <Chip
            sx={{
              p: 0.5,
              maxWidth: 184,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
            label={ticketItem.appliedDiscounts[0].discount.name}
          />
        )}
        <Typography variant="body2">
          {translatePrice(ticketItem.price)}
        </Typography>
      </Box>
      <Divider />
    </Box>
  )
}
