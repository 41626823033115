import {SxProps, Typography} from '@mui/material'
import React from 'react'
import {GetEventDetailQuery} from '../../__generated__/schema'
import {ReadOnlyPaper, ReadOnlyRow} from '../../components/atoms/ReadOnly'
import {useTranslateShowCrewRole} from '../hooks/translateCrewRole'

interface ICrewReadOnlyPaperProps {
  event: GetEventDetailQuery['event']
  id?: string
  title?: string
  sx?: SxProps
}

export const CrewReadOnlyPaper: React.FC<ICrewReadOnlyPaperProps> = ({
  event,
  id,
  title,
  sx
}: ICrewReadOnlyPaperProps) => {
  const translateShowCrewRole = useTranslateShowCrewRole()
  return (
    <ReadOnlyPaper sx={sx} id={id} title={title}>
      {event.show.crew.map(({crewRoleCode, persons}, index) => (
        <ReadOnlyRow key={index} label={translateShowCrewRole(crewRoleCode)}>
          {persons.map((person, index) => (
            <Typography
              key={`person-${index}`}
              sx={{
                pb: 1
              }}
              variant="body2"
            >
              {`${person.name}`}
              {person.description && ` (${person.description})`}
            </Typography>
          ))}
        </ReadOnlyRow>
      ))}
    </ReadOnlyPaper>
  )
}
