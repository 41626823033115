import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import {isNil} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CheckDiscountCodeForCartQuery,
  Currency,
  TicketItemFieldsFragment,
  TourItemFieldsFragment
} from '../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../components/atoms/DialogTitleWithCloseButton'
import {useFormatDiscountValue} from '../hooks/formatting'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {getTicketItemLabel, isTicketItemFieldsFragment} from '../utils'
import {
  useDiscountCodeUsageLimitPerOrderHelper,
  useDiscountUsageLimitPerOrderHelper
} from './utils'

interface IListItemRowProps {
  translatedPrice: string
  translatedPriceBeforeDiscount: string
  checkBoxProps: CheckboxProps
  hasDivider?: boolean
  hasAppliedDiscount?: boolean
  hasEnabledDiscounts?: boolean
  primaryLabel: string
  secondaryLabel?: string
}

const ListItemRow: React.FC<IListItemRowProps> = ({
  translatedPrice,
  translatedPriceBeforeDiscount,
  hasAppliedDiscount,
  hasEnabledDiscounts,
  checkBoxProps,
  hasDivider,
  primaryLabel,
  secondaryLabel
}: IListItemRowProps) => (
  <ListItem
    sx={{
      color:
        hasAppliedDiscount || !hasEnabledDiscounts
          ? 'text.disabled'
          : 'inherit',
      py: 1
    }}
    secondaryAction={
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography variant="body2">{translatedPrice}</Typography>
        {hasAppliedDiscount && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              textDecorationLine: 'line-through',
              color: 'text.disabled'
            }}
          >
            {translatedPriceBeforeDiscount}
          </Typography>
        )}
      </Box>
    }
    divider={hasDivider}
    disablePadding
  >
    {!hasAppliedDiscount && hasEnabledDiscounts && (
      <Checkbox {...checkBoxProps} edge="start" />
    )}
    <ListItemText
      primary={primaryLabel}
      primaryTypographyProps={{variant: 'subtitle2'}}
      secondary={secondaryLabel}
      secondaryTypographyProps={{variant: 'caption', color: 'text.disabled'}}
      inset={hasAppliedDiscount || !hasEnabledDiscounts}
      sx={{
        '&.MuiListItemText-inset': {
          pl: 4
        }
      }}
    />
  </ListItem>
)

interface ISelectItemsForDiscountModalContentProps {
  discountCode: CheckDiscountCodeForCartQuery['checkDiscountCodeForCart']
  items: TicketItemFieldsFragment[] | TourItemFieldsFragment[]
  selectedItemIds: number[]
  currency: Currency
  getCheckBoxClickHandler: (itemId: number) => (e: React.MouseEvent) => void
  canBeUsed?: boolean
  remainingDiscountUsageLimitPerOrder?: number
}

const SelectItemsForDiscountModalContent: React.FC<ISelectItemsForDiscountModalContentProps> =
  ({
    discountCode,
    items,
    selectedItemIds,
    currency,
    getCheckBoxClickHandler,
    canBeUsed,
    remainingDiscountUsageLimitPerOrder
  }: ISelectItemsForDiscountModalContentProps) => {
    const {t} = useTranslation()
    const formatDiscountValue = useFormatDiscountValue(currency, true)
    const translatePrice = useTranslatePrice(currency)
    return (
      <DialogContent
        sx={{
          px: 3,
          py: 1.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5
        }}
        dividers
      >
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography variant="subtitle1">
            {t('Discount: {{discountName}}', {
              discountName: discountCode.discount.name
            })}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {[
              discountCode.usageLimit
                ? t(
                    '{{value}} • {{codeName}} can be redeemed {{count}} time.',
                    {
                      value: formatDiscountValue(discountCode.discount),
                      codeName: discountCode.name,
                      count: discountCode.usageLimit - discountCode.usageCount
                    }
                  )
                : t(
                    '{{value}} • Code {{codeName}} has unlimited number of use.',
                    {
                      value: formatDiscountValue(discountCode.discount),
                      codeName: discountCode.name
                    }
                  ),
              !isNil(discountCode.discount.maxUsageLimitPerOrder) &&
                t('Can be redeemed {{count}} times per order.', {
                  count: remainingDiscountUsageLimitPerOrder
                }),
              !isNil(discountCode.usageLimitPerOrder) &&
                t('Code can be redeemed {{count}} time per order.', {
                  count: discountCode.usageLimitPerOrder
                })
            ]
              .filter(Boolean)
              .join(' ')}
          </Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <List disablePadding>
            {items.map(
              (
                item: TourItemFieldsFragment | TicketItemFieldsFragment,
                index: number,
                array: TourItemFieldsFragment[] | TicketItemFieldsFragment[]
              ) =>
                isTicketItemFieldsFragment(item) ? (
                  <ListItemRow
                    key={item.id}
                    hasAppliedDiscount={item.appliedDiscounts.length > 0}
                    hasEnabledDiscounts={
                      item.eventPricingToTicketType.promotions
                    }
                    translatedPrice={translatePrice(item.price)}
                    translatedPriceBeforeDiscount={translatePrice(
                      item.priceBeforeDiscount
                    )}
                    checkBoxProps={{
                      checked:
                        !!selectedItemIds.find(
                          (selectedItem) => selectedItem === item.id
                        ) || false,
                      onClick: getCheckBoxClickHandler(item.id),
                      disabled: !canBeUsed
                    }}
                    primaryLabel={getTicketItemLabel(item.eventSeat)}
                    secondaryLabel={
                      item.appliedDiscounts.length > 0
                        ? t('Already discounted')
                        : !item.eventPricingToTicketType.promotions
                        ? t("{{name}} can't be discounted", {
                            name: item.eventPricingToTicketType.name
                          })
                        : undefined
                    }
                    hasDivider={index !== array.length - 1}
                  />
                ) : (
                  <ListItemRow
                    key={item.id}
                    hasAppliedDiscount={item.appliedDiscounts.length > 0}
                    hasEnabledDiscounts={
                      item.admissionTypeAssignment
                        .hasEnabledDiscountsOnECommerce
                    }
                    translatedPrice={translatePrice(item.price)}
                    translatedPriceBeforeDiscount={translatePrice(
                      item.priceBeforeDiscount
                    )}
                    checkBoxProps={{
                      checked:
                        !!selectedItemIds.find(
                          (selectedItem) => selectedItem === item.id
                        ) || false,
                      onClick: getCheckBoxClickHandler(item.id)
                    }}
                    primaryLabel={item.name}
                    secondaryLabel={
                      item.appliedDiscounts.length > 0
                        ? t('Already discounted')
                        : !item.admissionTypeAssignment
                            .hasEnabledDiscountsOnECommerce
                        ? t("{{name}} can't be discounted", {
                            name: item.name
                          })
                        : undefined
                    }
                  />
                )
            )}
          </List>
        </Box>
      </DialogContent>
    )
  }

interface ISelectItemsForDiscountModalProps {
  isOpen: boolean
  onClose: () => void
  isMobile: boolean
  currency: Currency
  discountCode: CheckDiscountCodeForCartQuery['checkDiscountCodeForCart']
  items: TicketItemFieldsFragment[] | TourItemFieldsFragment[]
  onSubmit: (
    itemIds: number[],
    discountId: number,
    discountCodeId: number
  ) => Promise<void>
  eventId?: number
  tourTimeSlotId?: number
}

export const SelectItemsForDiscountModal: React.FC<ISelectItemsForDiscountModalProps> =
  ({
    isOpen,
    onClose,
    isMobile,
    currency,
    discountCode,
    items,
    onSubmit,
    eventId,
    tourTimeSlotId
  }: ISelectItemsForDiscountModalProps) => {
    const {t} = useTranslation()
    const {canBeUsed, numberOfUsageLeft} =
      useDiscountCodeUsageLimitPerOrderHelper({
        selectedDiscountCode: discountCode,
        eventId,
        tourTimeSlotId
      })
    const discountUsageLimitPerOrderHelper =
      useDiscountUsageLimitPerOrderHelper()
    const {
      canBeUsed: canDiscountBeUsed,
      numberOfUsageLeft: numberOfDiscountUsageLeft
    } = discountUsageLimitPerOrderHelper({
      selectedDiscount: discountCode.discount,
      eventId,
      tourTimeSlotId
    })
    const [selectedItemIds, setSelectedItemIds] = useState<number[]>([])
    const handleOnClose = useCallback(() => {
      setSelectedItemIds([])
      onClose()
    }, [onClose])
    const handleSubmit = useCallback(() => {
      onSubmit(selectedItemIds, discountCode.discount.id, discountCode.id)
      setSelectedItemIds([])
    }, [discountCode.discount.id, discountCode.id, onSubmit, selectedItemIds])
    const createItemCheckboxClickHandler = useCallback(
      (itemId: number) => (e: React.MouseEvent) => {
        e.stopPropagation()
        setSelectedItemIds((ids) =>
          ids.includes(itemId)
            ? ids.filter((id) => id !== itemId)
            : [...ids, itemId]
        )
      },
      []
    )
    return isMobile ? (
      <Drawer anchor="bottom" open={isOpen} onClose={handleOnClose}>
        <DialogTitleWithCloseButton onCloseIconClick={handleOnClose}>
          {t('Select items')}
        </DialogTitleWithCloseButton>
        <SelectItemsForDiscountModalContent
          items={items}
          currency={currency}
          discountCode={discountCode}
          getCheckBoxClickHandler={createItemCheckboxClickHandler}
          selectedItemIds={selectedItemIds}
          canBeUsed={canDiscountBeUsed || canBeUsed}
          remainingDiscountUsageLimitPerOrder={numberOfDiscountUsageLeft}
        />
        <DialogActions sx={{px: 3, py: 1.5}}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              selectedItemIds.length === 0 ||
              (!isNil(numberOfDiscountUsageLeft) &&
                selectedItemIds.length > numberOfDiscountUsageLeft) ||
              (!isNil(numberOfUsageLeft) &&
                selectedItemIds.length > numberOfUsageLeft)
            }
          >
            {t('Add discount')}
          </Button>
        </DialogActions>
      </Drawer>
    ) : (
      <Dialog open={isOpen} maxWidth="xs" scroll="paper" fullWidth>
        <DialogTitleWithCloseButton onCloseIconClick={handleOnClose}>
          {t('Select items')}
        </DialogTitleWithCloseButton>
        <SelectItemsForDiscountModalContent
          items={items}
          currency={currency}
          discountCode={discountCode}
          getCheckBoxClickHandler={createItemCheckboxClickHandler}
          selectedItemIds={selectedItemIds}
          canBeUsed={canBeUsed || canDiscountBeUsed}
          remainingDiscountUsageLimitPerOrder={numberOfDiscountUsageLeft}
        />
        <DialogActions sx={{px: 3, py: 1.5}}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              selectedItemIds.length === 0 ||
              (!isNil(numberOfDiscountUsageLeft) &&
                selectedItemIds.length > numberOfDiscountUsageLeft) ||
              (!isNil(numberOfUsageLeft) &&
                selectedItemIds.length > numberOfUsageLeft)
            }
          >
            {t('Add discount')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
