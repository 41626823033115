import {useQuery} from '@apollo/client'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom'
import {
  CartState,
  ErrorMessages,
  GetCartSummaryQuery,
  GetCartSummaryQueryVariables
} from '../../__generated__/schema'
import {RenderOnData} from '../../components/molecules/RenderOnData'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {CreateAccountDrawer} from '../components/organisms/account/CreateAccountDrawer'
import {SignInDrawer} from '../components/organisms/account/SignInDrawer'
import {CartNotFoundBlankPage} from '../components/organisms/CartNotFoundBlankPage'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {Content} from './Content'
import {GET_CART_SUMMARY} from './graphql'

export const CartSummary: React.FC = () => {
  const params = useParams<{uuid: string; hash: string}>()
  const {uuid, hash} = params
  const {data, loading, error, refetch} = useQuery<
    GetCartSummaryQuery,
    GetCartSummaryQueryVariables
  >(GET_CART_SUMMARY, {
    variables: {
      uuid: uuid!,
      hash: hash!
    },
    skip: !uuid || !hash
  })
  const {t} = useTranslation()
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const navigate = useNavigate()
  const handleRefetch = useCallback(async () => {
    setShowBackdrop(true)
    try {
      await refetch()
    } finally {
      setShowBackdrop(false)
    }
  }, [refetch, setShowBackdrop])
  const handleExited = useCallback(
    () => navigate(`/cartSummary/${uuid}/${hash}`),
    [hash, navigate, uuid]
  )
  const handleCreateAccountLinkClick = useCallback(
    () => navigate('createAccount'),
    [navigate]
  )
  const handleSignInLinkClick = useCallback(
    () => navigate('signIn'),
    [navigate]
  )
  const {initializeCurrentCart} = useCurrentCart()
  const {cartSummary} = data || {}
  useEffect(() => {
    if (cartSummary && cartSummary.state === CartState.Draft) {
      initializeCurrentCart(cartSummary)
      navigate(`/${cartSummary.client.id}/cart/`)
    }
  }, [cartSummary, initializeCurrentCart, navigate])
  return (
    <RenderOnData<GetCartSummaryQuery>
      data={data}
      loading={loading}
      error={error}
      {...(error?.message === ErrorMessages.CartNotFound
        ? {
            errorElement: <CartNotFoundBlankPage />
          }
        : {
            errorMessage: t<string>('Loading cart summary failed')
          })}
    >
      {(data) => (
        <>
          <Content cart={data.cartSummary} onRefetch={handleRefetch} />
          <Routes>
            <Route
              path="signIn"
              element={
                <SignInDrawer
                  onExited={handleExited}
                  onCreateAccountLinkClick={handleCreateAccountLinkClick}
                  clientId={data.cartSummary.client.id}
                />
              }
            />
            <Route
              path="createAccount"
              element={
                <CreateAccountDrawer
                  onExited={handleExited}
                  onSignInLinkClick={handleSignInLinkClick}
                  clientId={data.cartSummary.client.id}
                />
              }
            />
          </Routes>
          <Outlet />
        </>
      )}
    </RenderOnData>
  )
}
