import {useQuery} from '@apollo/client'
import {Drawer} from '@mui/material'
import Decimal from 'decimal.js'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
  DiscountApplication,
  GetEventDetailQuery,
  GetEventDetailQueryVariables,
  SellingChannel
} from '../../__generated__/schema'
import {DrawerTemplateHeader} from '../../components/atoms/DrawerTemplateHeader'
import {useBooleanState} from '../../hooks/state'
import {DrawerTemplateWithSideNavigation} from '../components/molecules/DrawerTemplateWithSideNavigation'
import {CrewReadOnlyPaper} from './CrewReadOnlyPaper'
import {EventReadOnlyPaper} from './EventReadOnlyPaper'
import {GET_EVENT_DETAIL} from './graphql'
import {OverviewReadOnlyPaper} from './OverviewReadOnlyPaper'
import {PricesAndDiscountsReadOnlyPaper} from './PricesAndDiscountsReadOnlyPaper'
import {VenueReadOnlyPaper} from './VenueReadOnlyPaper'

const useNavigationItems = (isCrewAvailable: boolean) => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      event: {id: string; label: string}
      venue: {id: string; label: string}
      pricesAndDiscounts: {id: string; label: string}
      overview: {id: string; label: string}
      crew?: {id: string; label: string}
    } => ({
      event: {
        id: 'event',
        label: t('Event')
      },
      venue: {
        id: 'venue',
        label: t('Venue')
      },
      pricesAndDiscounts: {
        id: 'prices',
        label: t('Prices and discounts')
      },
      overview: {
        id: 'overview',
        label: t('Overview')
      },
      ...(isCrewAvailable
        ? {
            crew: {
              id: 'crew',
              label: t('Crew')
            }
          }
        : {})
    }),
    [isCrewAvailable, t]
  )
}

export const WIDE_DRAWER_SX_PROPS = {
  width: '100%',
  maxWidth: 960
}

interface IEventDetailDrawerProps {
  onExited: () => void
}

export const EventDetailDrawer: React.FC<IEventDetailDrawerProps> = ({
  onExited
}: IEventDetailDrawerProps) => {
  const {t} = useTranslation()
  const params = useParams()
  const eventId = new Decimal(params.eventId || NaN).toNumber()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)

  useEffect(() => {
    openDrawer()
  }, [openDrawer])

  const {data, loading, error} = useQuery<
    GetEventDetailQuery,
    GetEventDetailQueryVariables
  >(GET_EVENT_DETAIL, {
    variables: {
      id: eventId,
      discountsFilter: {
        channel: SellingChannel.ECommerce,
        discountApplications: [
          DiscountApplication.Selectable,
          DiscountApplication.Customer
        ]
      }
    }
  })
  const navigationItems = useNavigationItems(
    data?.event ? data.event.show.crew.length > 0 : false
  )
  return (
    <Drawer
      open={isOpen}
      onClose={closeDrawer}
      anchor="right"
      SlideProps={{onExited}}
      PaperProps={{
        sx: WIDE_DRAWER_SX_PROPS
      }}
    >
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          isLoading: loading,
          errorMessage: error && t('Loading event data failed'),
          header: (
            <DrawerTemplateHeader
              title={t('More information')}
              onLeftActionClick={closeDrawer}
            />
          )
        }}
        navigationItems={navigationItems}
      >
        {data?.event && (
          <>
            <EventReadOnlyPaper
              event={data.event}
              id={navigationItems.event.id}
              title={navigationItems.event.label}
              sx={{py: 2}}
            />
            <VenueReadOnlyPaper
              event={data.event}
              id={navigationItems.venue.id}
              title={navigationItems.venue.label}
              sx={{py: 2}}
            />
            <PricesAndDiscountsReadOnlyPaper
              id={navigationItems.pricesAndDiscounts.id}
              title={navigationItems.pricesAndDiscounts.label}
              event={data.event}
              sx={{py: 2}}
            />
            <OverviewReadOnlyPaper
              id={navigationItems.overview.id}
              title={navigationItems.overview.label}
              event={data.event}
              sx={{py: 2}}
            />
            {navigationItems.crew && (
              <CrewReadOnlyPaper
                id={navigationItems.crew.id}
                title={navigationItems.crew.label}
                event={data.event}
                sx={{py: 2}}
              />
            )}
          </>
        )}
      </DrawerTemplateWithSideNavigation>
    </Drawer>
  )
}
