import React from 'react'
import {EventDetailFieldsFragment} from '../../../__generated__/schema'
import {EventHeader, IEventHeaderProps} from '../atoms/EventHeader'
import {CartPaperLayout, ICartPaperLayoutProps} from './CartPaperLayout'

export interface IEventPaperProps
  extends Omit<ICartPaperLayoutProps, 'header'> {
  event: EventDetailFieldsFragment
  rightAction?: IEventHeaderProps['rightAction']
}

export const EventPaper: React.FC<IEventPaperProps> = ({
  event,
  isMobile,
  rightAction,
  children,
  ...rest
}: IEventPaperProps) => (
  <CartPaperLayout
    {...rest}
    isMobile={isMobile}
    header={
      <EventHeader
        event={event}
        isMobile={isMobile}
        rightAction={rightAction}
        sx={
          isMobile
            ? {
                py: 2,
                px: 3
              }
            : {
                pt: 2,
                px: 3,
                pb: 1.5
              }
        }
      />
    }
  >
    {children}
  </CartPaperLayout>
)
