import {useQuery} from '@apollo/client'
import {Box, GlobalStyles} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
  GetEventOverviewQuery,
  GetEventOverviewQueryVariables
} from '../../__generated__/schema'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {RenderOnData} from '../../components/molecules/RenderOnData'
import {MediaSizes} from '../../components/types'
import {useAppParamsContext} from '../components/atoms/AppParamsProvider'
import {AppBackgroundColor} from '../components/atoms/AppParamsProvider/types'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from '../components/atoms/CurrentCartFab'
import {PageNotFound} from '../PageNotFound'
import {EventDetails} from './EventDetails'
import {EventGallery} from './EventGallery'
import {EventRow} from './EventRow'
import {GET_EVENT_OVERVIEW} from './graphql'

export const EventOverview: React.FC = () => {
  const {t} = useTranslation()
  const params = useParams()
  const {appParams} = useAppParamsContext()
  const {currentCart} = useCurrentCart()
  const eventId = parseInt(params.eventId ?? '', 10)
  const {data, loading, error} = useQuery<
    GetEventOverviewQuery,
    GetEventOverviewQueryVariables
  >(GET_EVENT_OVERVIEW, {variables: {id: eventId}})
  const isTabletPlus = useIsMediaSize(MediaSizes.TabletPlus)
  const isLargeDesktop = useIsMediaSize(MediaSizes.LargeDesktop)
  if (isNaN(eventId)) {
    return <PageNotFound />
  }
  return (
    <>
      {appParams.backgroundColor === AppBackgroundColor.Transparent && (
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: 'transparent'
            }
          }}
        />
      )}
      <RenderOnData<GetEventOverviewQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Could not load event data')}
        dataCondition={(data) => Boolean(data.event)}
      >
        {({event}) => (
          <Box
            sx={{
              py: isLargeDesktop || isTabletPlus ? 3 : 0,
              mx: 'auto',
              maxWidth: 1019
            }}
          >
            <EventRow
              isLargeDesktop={isLargeDesktop}
              isTabletPlus={isTabletPlus}
              eventButtonTarget={appParams.eventButtonTarget}
              event={event}
            />
            <EventDetails event={event} />
            <EventGallery images={event.show.images} />
          </Box>
        )}
      </RenderOnData>
      {appParams.showCartButton && (
        <TentativeCurrentCartFab currentCart={currentCart} />
      )}
    </>
  )
}
