import {useMutation} from '@apollo/client'
import {Button, Dialog, DialogContent, styled, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ChangePasswordForSignedInCustomerMutation,
  ChangePasswordForSignedInCustomerMutationVariables
} from '../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../components/atoms/DialogTitleWithCloseButton'
import {PasswordInputAdornment} from '../../components/atoms/PasswordInputAdornment'
import {useBooleanState} from '../../hooks/state'
import {UncontrolledFormTextInput} from '../components/molecules/UncontrolledFormTextInput'
import {
  AccountFormField,
  INewPasswordForm
} from '../components/organisms/account/types'
import {CHANGE_PASSWORD_FOR_SIGNED_IN_CUSTOMER} from '../graphql'
import {useIsValidPassword} from '../hooks/formsValidations'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'

const NEW_PASSWORD_FORM_ID = 'newPasswordForm'

const StyledForm = styled('form')(({theme}) => ({
  paddingTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%'
}))

interface INewPasswordDialogProps {
  isOpen: boolean
  onClose: () => void
}

export const NewPasswordDialog: React.FC<INewPasswordDialogProps> = ({
  isOpen,
  onClose
}: INewPasswordDialogProps) => {
  const {t} = useTranslation()
  const {control, errors, getValues, handleSubmit} = useForm<INewPasswordForm>({
    defaultValues: {
      [AccountFormField.Password]: '',
      [AccountFormField.ConfirmPassword]: ''
    },
    reValidateMode: 'onBlur'
  })
  const [changePassword] = useMutation<
    ChangePasswordForSignedInCustomerMutation,
    ChangePasswordForSignedInCustomerMutationVariables
  >(CHANGE_PASSWORD_FOR_SIGNED_IN_CUSTOMER)
  const {setShowBackdrop, displayInfoNotification, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const {state: isPasswordVisibilityOn, toggle: togglePasswordVisibility} =
    useBooleanState(false)
  const {
    state: isConfirmPasswordVisibilityOn,
    toggle: toggleConfirmPasswordVisibility
  } = useBooleanState(false)
  const isValidPassword = useIsValidPassword()
  const onSubmit = useCallback(
    async (data: INewPasswordForm) => {
      try {
        setShowBackdrop(true)
        await changePassword({
          variables: {password: data[AccountFormField.Password]}
        })
        onClose()
        displayInfoNotification(t('Password has been updated'))
      } catch (error) {
        defaultErrorHandler(error, {
          title: t('Error while changing customer password')
        })
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      changePassword,
      defaultErrorHandler,
      displayInfoNotification,
      onClose,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Dialog
      open={isOpen}
      PaperProps={{sx: {width: 360}}}
      scroll="paper"
      fullWidth
    >
      <DialogTitleWithCloseButton onCloseIconClick={onClose}>
        {t<string>('New password')}
      </DialogTitleWithCloseButton>
      <DialogContent
        sx={(theme) => ({
          borderTop: `solid ${theme.palette.divider} 1px`,
          pb: 3
        })}
      >
        <StyledForm
          id={NEW_PASSWORD_FORM_ID}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Typography variant="caption" color="textSecondary">
            {t('Enter your new password')}
          </Typography>
          <UncontrolledFormTextInput<INewPasswordForm>
            errors={errors}
            control={control}
            name={AccountFormField.Password}
            label={t('Password')}
            fullWidth
            validationOptions={{required: true, validate: isValidPassword}}
            type={isPasswordVisibilityOn ? 'text' : 'password'}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <PasswordInputAdornment
                  onClick={togglePasswordVisibility}
                  isPasswordVisibilityOn={isPasswordVisibilityOn}
                />
              )
            }}
          />
          <UncontrolledFormTextInput<INewPasswordForm>
            errors={errors}
            control={control}
            name={AccountFormField.ConfirmPassword}
            label={t('Confirm password')}
            fullWidth
            autoComplete="new-password"
            validationOptions={{
              required: true,
              validate: (confirmPassword) => {
                const password = getValues(AccountFormField.Password)
                return (
                  password === confirmPassword || t('Passwords did not match.')
                )
              }
            }}
            type={isConfirmPasswordVisibilityOn ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <PasswordInputAdornment
                  onClick={toggleConfirmPasswordVisibility}
                  isPasswordVisibilityOn={isConfirmPasswordVisibilityOn}
                />
              )
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form={NEW_PASSWORD_FORM_ID}
          >
            {t('Save')}
          </Button>
        </StyledForm>
      </DialogContent>
    </Dialog>
  )
}
