import {List, ListItemButton, ListItemText, SxProps} from '@mui/material'
import React, {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

interface ISideNavigationListProps {
  items: {[key: string]: {id: string; label: string}}
  sx?: SxProps
}

export const SideNavigationList: React.FC<ISideNavigationListProps> = ({
  items,
  sx
}: ISideNavigationListProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const {hash} = location
    const element = document.getElementById(hash.replace('#', ''))

    setTimeout(() => {
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 100)
  }, [location])
  return (
    <List sx={sx}>
      {Object.values(items).map((item) => (
        <ListItemButton
          key={item.id}
          onClick={() => {
            navigate(`#${item.id}`, {replace: true})
          }}
        >
          <ListItemText primary={item.label} />
        </ListItemButton>
      ))}
    </List>
  )
}
