import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNotifications} from '../components/molecules/NotificationContext'

export const useDisplayZoneTicketsAddedToCartNotification = () => {
  const {t} = useTranslation()
  const {displayInfoNotification} = useNotifications()
  return useCallback(
    ({
      insertedTicketsCount,
      expectedTicketsCount
    }: {
      insertedTicketsCount: number
      expectedTicketsCount: number
    }) => {
      if (insertedTicketsCount === 0) {
        displayInfoNotification(t('No tickets added to the cart'))
      } else if (insertedTicketsCount === expectedTicketsCount) {
        displayInfoNotification(
          t('{{count}} tickets added to the cart', {
            count: insertedTicketsCount
          })
        )
      } else {
        displayInfoNotification(
          t('{{count}} tickets added to cart. Unable to add more.', {
            count: insertedTicketsCount
          })
        )
      }
    },
    [displayInfoNotification, t]
  )
}
