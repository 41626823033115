import {useQuery} from '@apollo/client'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom'
import {
  GetCurrentCartQuery,
  GetCurrentCartQueryVariables
} from '../../__generated__/schema'
import {RenderOnData} from '../../components/molecules/RenderOnData'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {CreateAccountDrawer} from '../components/organisms/account/CreateAccountDrawer'
import {SignInDrawer} from '../components/organisms/account/SignInDrawer'
import {CartNotFoundBlankPage} from '../components/organisms/CartNotFoundBlankPage'
import {EventDetailDrawer} from '../eventDetail/EventDetailDrawer'
import {GET_CURRENT_CART_QUERY} from '../graphql'
import {Content} from './Content'
import {DiscountCodeContextProvider} from './DiscountCodeContext'
import {ExpiredCartPage} from './ExpiredCartPage'

export const Cart: React.FC = () => {
  const {currentCartId, updateCurrentCart} = useCurrentCart()
  const {data, loading, error} = useQuery<
    GetCurrentCartQuery,
    GetCurrentCartQueryVariables
  >(GET_CURRENT_CART_QUERY, {
    variables: {
      cartId: currentCartId!
    },
    skip: !currentCartId,
    onCompleted: updateCurrentCart,
    fetchPolicy: 'network-only'
  })
  const {t} = useTranslation()
  const params = useParams()
  const clientId = new Decimal(params.clientId || NaN).toNumber()
  const navigate = useNavigate()
  const handleExited = useCallback(
    () => navigate(`/${clientId}/cart`),
    [clientId, navigate]
  )
  const handleCreateAccountLinkClick = useCallback(
    () => navigate('createAccount'),
    [navigate]
  )
  const handleSignInLinkClick = useCallback(
    () => navigate('signIn'),
    [navigate]
  )
  return currentCartId ? (
    <RenderOnData<GetCurrentCartQuery>
      data={data}
      loading={loading}
      error={error}
      dataCondition={(data) => Boolean(data.cart)}
      errorMessage={t<string>('Could not load cart data')}
    >
      {(data) => (
        <>
          {dayjs().isAfter(data.cart.expiresAt) ? (
            <ExpiredCartPage cart={data.cart} />
          ) : (
            <DiscountCodeContextProvider
              cartId={data.cart.id}
              currency={data.cart.client.currency}
            >
              <Content cart={data.cart} />
            </DiscountCodeContextProvider>
          )}
          <Routes>
            <Route
              path="createAccount"
              element={
                <CreateAccountDrawer
                  onExited={handleExited}
                  onSignInLinkClick={handleSignInLinkClick}
                />
              }
            />
            <Route
              path="signIn"
              element={
                <SignInDrawer
                  onExited={handleExited}
                  onCreateAccountLinkClick={handleCreateAccountLinkClick}
                />
              }
            />
            <Route
              path=":eventId/eventInfo"
              element={<EventDetailDrawer onExited={handleExited} />}
            />
          </Routes>
          <Outlet />
        </>
      )}
    </RenderOnData>
  ) : (
    <CartNotFoundBlankPage />
  )
}
