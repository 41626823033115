import {Dialog, Drawer} from '@mui/material'
import React from 'react'
import {useIsMediaSize} from '../atoms/WindowInnerWidthContext'
import {MediaSizes} from '../types'

interface IResponsiveDialogProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactElement
}

export const ResponsiveDialog: React.FC<IResponsiveDialogProps> = ({
  isOpen,
  onClose,
  children
}: IResponsiveDialogProps) => {
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  return isMobile ? (
    <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
      {children}
    </Drawer>
  ) : (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      {children}
    </Dialog>
  )
}
