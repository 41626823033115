import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Menu,
  SvgIconProps,
  Typography
} from '@mui/material'
import React, {useCallback} from 'react'
import {useBooleanState} from '../../../hooks/state'

interface IMobileHeaderProps {
  title: string
  onCloseIconClick: () => void
}

const MobileHeader: React.FC<IMobileHeaderProps> = ({
  title,
  onCloseIconClick
}: IMobileHeaderProps) => {
  return (
    <Box
      sx={(theme) => ({
        position: 'sticky',
        top: 0,
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderBottom: `solid ${theme.palette.divider} 1px`,
        px: 2.5,
        py: 1
      })}
    >
      <Typography variant="h6">{title}</Typography>
      <IconButton onClick={onCloseIconClick} color="inherit">
        <CloseIcon color="inherit" />
      </IconButton>
    </Box>
  )
}

export interface IChipWithListOfItemsProps {
  label: string
  isMobile: boolean
  mobileHeaderTitle: string
  children: (onClose: () => void) => React.ReactNode
  ChipIcon?: React.FC<SvgIconProps>
}

export const ChipWithListOfItems: React.FC<IChipWithListOfItemsProps> = ({
  label,
  mobileHeaderTitle,
  isMobile,
  children,
  ChipIcon = AddIcon
}: IChipWithListOfItemsProps) => {
  const {
    state: isMenuOpen,
    setTrue: openMenu,
    setFalse: closeMenu
  } = useBooleanState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleCloseDiscountsMenu = useCallback(() => {
    setAnchorEl(null)
    closeMenu()
  }, [closeMenu])
  const handleOnAddDiscountClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      openMenu()
      setAnchorEl(event.currentTarget)
    },
    [openMenu]
  )
  return (
    <>
      <Chip
        sx={{p: 0.5}}
        label={label}
        icon={<ChipIcon />}
        variant="outlined"
        onClick={handleOnAddDiscountClick}
      />
      {isMobile ? (
        <Drawer anchor="bottom" open={isMenuOpen} onClose={closeMenu}>
          <Box
            sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}
          >
            <MobileHeader
              title={mobileHeaderTitle}
              onCloseIconClick={closeMenu}
            />
            <Box sx={{overflow: 'auto'}}>{children(closeMenu)}</Box>
          </Box>
        </Drawer>
      ) : (
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && isMenuOpen}
          onClose={handleCloseDiscountsMenu}
          MenuListProps={{
            disablePadding: true
          }}
        >
          {children(closeMenu)}
        </Menu>
      )}
    </>
  )
}
