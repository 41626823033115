import Joi from 'joi'
import queryString from 'query-string'
import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {
  ECommerceLocaleCode,
  ShowAgeClassificationCode,
  ShowFormatCode,
  ShowSoundMixCode,
  ShowTypeCode,
  ShowVersionCode
} from '../../__generated__/schema'
import {PageNotFound} from '../PageNotFound'
import {PrimaryImageType} from '../types'
import {EventAvailabilityOption} from './types'

export interface IEventsListSearchParams {
  venueId?: number
  divisionId?: number
  primaryImageType: PrimaryImageType
  hasText?: string
  typeCode?: ShowTypeCode
  from?: string
  to?: string
  ageClassificationCode?: ShowAgeClassificationCode
  formatCode?: ShowFormatCode
  soundMixCode?: ShowSoundMixCode
  versionCode?: ShowVersionCode
  limit: number
  localeCode?: ECommerceLocaleCode
  showEventAvailability: EventAvailabilityOption
  showId?: number
  eventCategoryId?: number
  marketingLabelId?: number
}

const eventsListSearchParamsSchema = Joi.object<IEventsListSearchParams>({
  venueId: Joi.number().positive().integer().optional(),
  divisionId: Joi.number().positive().integer().optional(),
  primaryImageType: Joi.string()
    .valid(...Object.values(PrimaryImageType))
    .default(PrimaryImageType.None),
  hasText: Joi.string().optional(),
  typeCode: Joi.string()
    .valid(...Object.values(ShowTypeCode))
    .optional(),
  ageClassificationCode: Joi.string()
    .valid(...Object.values(ShowAgeClassificationCode))
    .optional(),
  formatCode: Joi.string()
    .valid(...Object.values(ShowFormatCode))
    .optional(),
  soundMixCode: Joi.string()
    .valid(...Object.values(ShowSoundMixCode))
    .optional(),
  versionCode: Joi.string()
    .valid(...Object.values(ShowVersionCode))
    .optional(),
  from: Joi.date().iso().optional(),
  to: Joi.date().iso().optional(),
  limit: Joi.number().positive().integer().optional().default(30),
  localeCode: Joi.string()
    .valid(...Object.values(ECommerceLocaleCode))
    .optional(),
  showEventAvailability: Joi.string()
    .valid(...Object.values(EventAvailabilityOption))
    .default(EventAvailabilityOption.None),
  showId: Joi.number().positive().integer().optional(),
  eventCategoryId: Joi.number().positive().integer().optional(),
  marketingLabelId: Joi.number().positive().integer().optional()
}).options({stripUnknown: true})

export interface IEventsListProps {
  clientId: number
  searchParams: IEventsListSearchParams
}

export const withEventListProps = (Component: React.FC<IEventsListProps>) => {
  return function WithEventListPropsComponent() {
    const location = useLocation()
    const validationResult = eventsListSearchParamsSchema.validate(
      queryString.parse(location.search)
    )

    const params = useParams<{clientId: string}>()
    const clientId = parseInt(params.clientId ?? '', 10)
    if (isNaN(clientId) || validationResult.error) {
      return <PageNotFound />
    }
    return (
      <Component clientId={clientId} searchParams={validationResult.value} />
    )
  }
}
