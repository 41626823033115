import {ApolloError} from '@apollo/client'
import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {useMutationAssistanceHooks} from './mutationAssistanceHooks'

export const useEcommerceErrorHandlers = () => {
  const {t} = useTranslation()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const {currentCart} = useCurrentCart()
  const navigate = useNavigate()
  const invalidCheckoutOptionsErrorHandler = useCallback(
    ({error}: {error: ApolloError}) =>
      defaultErrorHandler(error, {
        title: t('Unable to add item to cart'),
        description: t(
          'Please, finish ongoing cart first and after that you can get tickets for this event.'
        ),
        renderActions: function renderActions(onClose) {
          return (
            <Button
              variant="text"
              onClick={() => {
                onClose()
                navigate(`/${currentCart?.clientId}/cart`)
              }}
            >
              {t('Cart')}
            </Button>
          )
        }
      }),
    [currentCart?.clientId, defaultErrorHandler, navigate, t]
  )

  const invalidCartStateErrorHandler = useCallback(
    ({error, onReload}: {error: ApolloError; onReload?: () => void}) => {
      defaultErrorHandler(error, {
        title: t('Something went wrong'),
        description: t('Please, reload page and try it again.'),
        renderActions: function renderActions(onClose) {
          return (
            <Button
              variant="text"
              onClick={() => {
                onClose()
                if (onReload) {
                  onReload()
                } else {
                  navigate(0)
                }
              }}
            >
              {t('Reload')}
            </Button>
          )
        }
      })
    },
    [defaultErrorHandler, navigate, t]
  )

  return {invalidCheckoutOptionsErrorHandler, invalidCartStateErrorHandler}
}
