import {gql} from '@apollo/client'

export const TRANSLATED_FIELDS = gql`
  fragment TranslatedFields on Translated {
    en
    sk
    cs
    hu
  }
`

export const ECOMMERCE_TRANSLATED_FIELDS = gql`
  fragment ECommerceTranslatedFields on ECommerceTranslated {
    en
    sk
    cs
    hu
  }
`

export const PAGINATION_FIELDS = gql`
  fragment PaginationFields on Pagination {
    hasMore
    offset
    limit
  }
`

export const ENABLED_DISCOUNT_FIELDS = gql`
  fragment EnabledDiscountFields on Discount {
    id
    name
    value
    application
    type
    maxUsageLimitPerOrder
  }
`

export const LEAD_OPTION_FIELDS = gql`
  fragment LeadOptionFields on LeadOption {
    id
    field
    inputStatus
    helperText
  }
`

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    username
    firstName
    lastName
  }
`

export const LEAD_DATA_FIELDS = gql`
  fragment LeadDataFields on LeadData {
    name
    phone
    email
    note
    internalNote
    companyName
    companyIdNumber
    VATId
    TAXId
    billingAddressStreet
    billingAddressTown
    billingPostalCode
    billingAddressCountry
    deliveryAddressee
    deliveryAddressStreet
    deliveryAddressTown
    deliveryPostalCode
    deliveryAddressCountry
    isPrivacyPolicyConsentGranted
  }
`

export const CLIENT_DETAIL_FIELDS = gql`
  ${LEAD_OPTION_FIELDS}
  ${TRANSLATED_FIELDS}
  fragment ClientDetailFields on Client {
    id
    currency
    name
    countryCode
    termsOfServiceUrl
    marketingInformationUrl
    appProviderB2CTermsOfServiceUrl
    eCommercePaymentMethods(state: active) {
      id
      name
      description {
        ...TranslatedFields
      }
      serviceProvider
      paymentServiceProviderConfig {
        ... on GoPayPaymentServiceProviderConfig {
          paymentServiceProvider
          defaultPaymentInstrument
        }
        ... on BesteronPaymentServiceProviderConfig {
          paymentServiceProvider
          paymentInstruments
        }
      }
    }
    eCommerceLeadOptions {
      ...LeadOptionFields
    }
    eCommerceReservationLeadOptions {
      ...LeadOptionFields
    }
  }
`

export const EVENT_DETAIL_FIELDS = gql`
  ${TRANSLATED_FIELDS}
  fragment EventDetailFields on Event {
    id
    state
    startsAt
    endsAt
    updatedAt
    duration
    formatCode
    soundMixCode
    versionCode
    ecommerceEventURL
    ageClassificationCode
    availableSeatsCount
    division {
      id
      client {
        id
        currency
        localeCodes
      }
    }
    names {
      ...TranslatedFields
    }
    auditorium {
      id
      name
    }
    auditoriumLayout {
      capacity
      type
      id
      layout
    }
    auditoriumLayoutPricing {
      id
      pricing
      maxTicketsPerOrder
    }
    venue {
      id
      name
    }
    activePricing {
      id
      ticketTypes {
        id
        name
        price
        color
        ticketTypeId
        description
      }
    }
    salesAndReservation {
      pointOfSale {
        saleStart
        saleEnd
        saleActive
        reservationStart
        reservationEnd
        reservationActive
      }
      online {
        saleStart
        saleEnd
        saleActive
        reservationStart
        reservationEnd
        reservationActive
      }
    }
  }
`

export const SALES_AND_RESERVATION_FIELDS = gql`
  fragment SalesAndReservationFields on Event {
    salesAndReservation {
      pointOfSale {
        saleStart
        saleEnd
        saleActive
        reservationStart
        reservationEnd
        reservationActive
      }
      online {
        saleStart
        saleEnd
        saleActive
        reservationStart
        reservationEnd
        reservationActive
      }
    }
  }
`

export const VENUE_FIELDS = gql`
  fragment VenueFields on Venue {
    id
    name
    address {
      complex
      street
      postalCode
      town
    }
  }
`

export const SHOW_FIELDS = gql`
  fragment ShowFields on Event {
    show {
      id
      duration
      genreCodes
      typeCode
      globalReleaseDate
      productionYear
      primaryImage(type: poster) {
        id
        url
        thumbnails(size: [medium]) {
          id
          url
        }
      }
      translations {
        localeCode
        description
        tagline
      }
      images(type: [photo, poster]) {
        id
        url
        type
        thumbnails(size: [large]) {
          id
          url
          type
        }
      }
      crew {
        crewRoleCode
        persons {
          name
          description
        }
      }
      contentDescriptorCodes
    }
  }
`

export const TICKET_ITEM_FIELDS = gql`
  ${EVENT_DETAIL_FIELDS}
  ${ENABLED_DISCOUNT_FIELDS}
  fragment TicketItemFields on TicketItem {
    id
    price
    priceBeforeDiscount
    appliedDiscounts {
      discountCode {
        id
      }
      discount {
        id
        name
      }
    }
    eventPricingToTicketType {
      name
      promotions
    }
    reservation {
      id
    }
    eventSeat {
      id
      uuid
      label
      row
      section
      floor
      type
      event {
        ...EventDetailFields
        enabledDiscounts(
          discountsFilter: {
            channel: eCommerce
            discountApplications: [selectable, customer]
          }
        ) {
          ...EnabledDiscountFields
        }
      }
    }
  }
`

export const TOUR_ITEMS_STATE_COUNTS_FIELDS = gql`
  fragment TourItemsStateCountsFields on TourItemsStateCounts {
    inCart
    reserved
    sold
  }
`

export const PRODUCT_ITEM_FIELDS = gql`
  fragment ProductItemFields on ProductItem {
    id
  }
`

export const TOUR_TIME_SLOT_FIELDS = gql`
  ${USER_FIELDS}
  ${TRANSLATED_FIELDS}
  ${TOUR_ITEMS_STATE_COUNTS_FIELDS}
  fragment TourTimeSlotFields on TourTimeSlot {
    id
    duration
    startsAt
    endsAt
    names {
      ...TranslatedFields
    }
    venue {
      id
      name
    }
    client {
      id
      currency
      localeCodes
    }
    versionCode
    ageClassificationCode
    guide {
      ...UserFields
    }
    tourItemsStateCounts {
      ...TourItemsStateCountsFields
    }
    eCommerceAttendeesLimit
    retailAttendeesLimit
  }
`

export const TOUR_ITEM_FIELDS = gql`
  ${TRANSLATED_FIELDS}
  ${TOUR_TIME_SLOT_FIELDS}
  fragment TourItemFields on TourItem {
    id
    name
    price
    priceBeforeDiscount
    tourTimeSlotId
    appliedDiscounts {
      discountCode {
        id
      }
      discount {
        id
        name
      }
    }
    admissionTypeAssignmentId
    admissionTypeAssignment {
      id
      hasEnabledDiscountsOnECommerce
    }
    tourTimeSlot {
      ...TourTimeSlotFields
      enabledDiscounts(
        discountsFilter: {
          channel: eCommerce
          discountApplications: [selectable]
        }
      ) {
        ...EnabledDiscountFields
      }
    }
    division {
      id
      client {
        id
        localeCodes
        currency
      }
    }
  }
`

export const ERROR_PROPERTIES_FIELDS = gql`
  fragment ErrorResultFields on ErrorResult {
    errorCode
  }
`

export const BASIC_CART_FIELDS = gql`
  ${TICKET_ITEM_FIELDS}
  ${PRODUCT_ITEM_FIELDS}
  ${TOUR_ITEM_FIELDS}
  ${LEAD_DATA_FIELDS}
  fragment BasicCartFields on Cart {
    id
    state
    price
    expiresAt
    clientId
    paymentIntents {
      id
      state
    }
    uniqueLeads {
      id
      data {
        ...LeadDataFields
      }
    }
    items {
      ...TicketItemFields
      ...ProductItemFields
      ...TourItemFields
    }
  }
`

export const CUSTOMER_FIELDS = gql`
  ${LEAD_DATA_FIELDS}
  fragment CustomerFields on Customer {
    id
    clientId
    username
    state
    leadId
    loyaltyId
    privacyPolicyConsentGrantedAt
    lead {
      id
      data {
        ...LeadDataFields
      }
    }
    client {
      id
      localeCodes
      currency
    }
    createdAt
  }
`

export const GET_CURRENT_CART_QUERY = gql`
  ${CLIENT_DETAIL_FIELDS}
  ${BASIC_CART_FIELDS}
  ${LEAD_DATA_FIELDS}
  query GetCurrentCart($cartId: Int!) {
    cart(cartId: $cartId) {
      ...BasicCartFields
      checkoutOptions
      client {
        ...ClientDetailFields
      }
      lead {
        id
        data {
          ...LeadDataFields
        }
      }
    }
  }
`

export const REMOVE_TICKET_ITEM_FROM_CART = gql`
  ${BASIC_CART_FIELDS}
  mutation RemoveTicketItemFromCart($itemId: Int!, $cartId: Int!) {
    removeTicketItemFromCart(itemId: $itemId, cartId: $cartId) {
      ...BasicCartFields
      checkoutOptions
    }
  }
`

export const SIGN_OUT_CUSTOMER = gql`
  mutation SignOutCustomer {
    signOutCustomer
  }
`

export const RECOVER_CUSTOMER_PASSWORD = gql`
  mutation RecoverCustomerPassword(
    $token: NonEmptyString!
    $password: NonEmptyString!
  ) {
    recoverCustomerPassword(token: $token, password: $password)
  }
`

export const CHANGE_PASSWORD_FOR_SIGNED_IN_CUSTOMER = gql`
  mutation ChangePasswordForSignedInCustomer($password: NonEmptyString!) {
    changePasswordForSignedInCustomer(password: $password) {
      id
    }
  }
`

export const UPDATE_SIGNED_IN_CUSTOMER = gql`
  ${CUSTOMER_FIELDS}
  mutation UpdateSignedInCustomer($input: UpdateSignedInCustomerInput!) {
    updateSignedInCustomer(input: $input) {
      ...CustomerFields
    }
  }
`

export const CUSTOMER_PURCHASE_HISTORY = gql`
  ${EVENT_DETAIL_FIELDS}
  ${TICKET_ITEM_FIELDS}
  ${PRODUCT_ITEM_FIELDS}
  ${TOUR_ITEM_FIELDS}
  ${TRANSLATED_FIELDS}
  query CustomerPurchaseHistory {
    signedInCustomer {
      id
      sales {
        id
        price
        itemType
        event {
          ...EventDetailFields
        }
        tourTimeSlot {
          id
          names {
            ...TranslatedFields
          }
          startsAt
          client {
            id
            localeCodes
          }
        }
        items {
          ...TicketItemFields
          ...ProductItemFields
          ...TourItemFields
        }
      }
      reservations(states: [active, inCart]) {
        id
        price
        state
        updatedAt
        expireAt
        uuid
        hash
        itemType
        tourTimeSlot {
          id
          names {
            ...TranslatedFields
          }
          startsAt
          client {
            id
            localeCodes
          }
        }
        event {
          ...EventDetailFields
        }
        items {
          ...TicketItemFields
          ...ProductItemFields
          ...TourItemFields
        }
      }
    }
  }
`
