import {Box, Button, Dialog, Divider, Drawer, TextField} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DialogTitleWithCloseButton} from '../../components/atoms/DialogTitleWithCloseButton'

interface ICheckDiscountCodeModalContentProps {
  inputText: string
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmitButtonClick: () => void
  errorMessage?: string
}

const CheckDiscountCodeModalContent: React.FC<ICheckDiscountCodeModalContentProps> =
  ({
    inputText,
    onInputChange,
    onSubmitButtonClick,
    errorMessage
  }: ICheckDiscountCodeModalContentProps) => {
    const {t} = useTranslation()
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          gap: 2,
          p: 3
        }}
      >
        <TextField
          variant="outlined"
          value={inputText}
          onChange={onInputChange}
          fullWidth
          placeholder={t('Enter discount code here')}
          autoFocus
          error={Boolean(errorMessage)}
          helperText={errorMessage}
        />
        <Button
          onClick={onSubmitButtonClick}
          sx={{width: 84}}
          variant="contained"
          disabled={inputText.length === 0}
        >
          {t('Check')}
        </Button>
      </Box>
    )
  }

interface ICheckDiscountCodeModalProps {
  isOpen: boolean
  onClose: () => void
  isMobile: boolean
  onSubmitButtonClick: (codeName: string) => Promise<void>
  errorMessage?: string
}

export const CheckDiscountCodeModal: React.FC<ICheckDiscountCodeModalProps> = ({
  isOpen,
  onClose,
  isMobile,
  onSubmitButtonClick,
  errorMessage
}: ICheckDiscountCodeModalProps) => {
  const {t} = useTranslation()
  const [code, setCode] = useState<string>('')
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setCode(e.target.value.toUpperCase()),
    []
  )
  const handleClose = useCallback(() => {
    setCode('')
    onClose()
  }, [onClose])
  const handleSubmitButtonClick = useCallback(async () => {
    await onSubmitButtonClick(code)
  }, [code, onSubmitButtonClick])
  useEffect(() => {
    if (!isOpen) {
      setCode('')
    }
  }, [isOpen])
  return isMobile ? (
    <Drawer anchor="bottom" open={isOpen} onClose={handleClose}>
      <DialogTitleWithCloseButton onCloseIconClick={handleClose}>
        {t('Check discount code')}
      </DialogTitleWithCloseButton>
      <Divider />
      <CheckDiscountCodeModalContent
        inputText={code}
        onInputChange={handleInputChange}
        onSubmitButtonClick={handleSubmitButtonClick}
        errorMessage={errorMessage}
      />
    </Drawer>
  ) : (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onCloseIconClick={handleClose}>
        {t('Check discount code')}
      </DialogTitleWithCloseButton>
      <Divider />
      <CheckDiscountCodeModalContent
        inputText={code}
        onInputChange={handleInputChange}
        onSubmitButtonClick={handleSubmitButtonClick}
        errorMessage={errorMessage}
      />
    </Dialog>
  )
}
