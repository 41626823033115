import {Button} from '@mui/material'
import dayjs from 'dayjs'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SimpleDialog} from '../../../../components/atoms/SimpleDialog'
import {useBooleanState} from '../../../../hooks/state'
import {formatDuration} from '../../../utils/formatDuration'

const PERIOD_BETWEEN_OPENING_CART_EXPIRES_SOON_DIALOG_AND_CART_EXPIRATION_IN_SECONDS = 180

interface ICartExpiresSoonDialogProps {
  expiresAt: string
}

export const CartExpiresSoonDialog: React.FC<ICartExpiresSoonDialogProps> = ({
  expiresAt
}: ICartExpiresSoonDialogProps) => {
  const opensAt = useMemo(
    () =>
      dayjs(expiresAt)
        .add(
          -PERIOD_BETWEEN_OPENING_CART_EXPIRES_SOON_DIALOG_AND_CART_EXPIRATION_IN_SECONDS,
          's'
        )
        .add(-100, 'ms')
        .toISOString(),
    [expiresAt]
  )
  const {
    state: isOpen,
    setFalse: closeDialog,
    setTrue: openDialog
  } = useBooleanState(dayjs().isAfter(opensAt) && dayjs().isBefore(expiresAt))
  const {t} = useTranslation()
  const [remainingPeriod, setRemainingPeriod] = useState<string>(
    isOpen ? formatDuration(dayjs().toISOString(), expiresAt) : ''
  )
  useEffect(() => {
    let timeout: number
    if (!isOpen && dayjs().isBefore(opensAt)) {
      const timeToOpenDialog = new Date(opensAt).getTime() - Date.now()
      if (timeToOpenDialog) {
        timeout = window.setTimeout(() => {
          setRemainingPeriod(formatDuration(dayjs().toISOString(), expiresAt))
          openDialog()
        }, timeToOpenDialog)
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [expiresAt, isOpen, openDialog, opensAt])
  useEffect(() => {
    let timeoutToClose: number
    if (isOpen && dayjs().isBefore(expiresAt)) {
      const timeToCloseDialog = new Date(expiresAt).getTime() - Date.now()
      if (timeToCloseDialog) {
        timeoutToClose = window.setTimeout(closeDialog, timeToCloseDialog)
      }
    }
    return () => {
      if (timeoutToClose) {
        clearTimeout(timeoutToClose)
      }
    }
  }, [isOpen, closeDialog, expiresAt])
  useEffect(() => {
    let interval: number
    if (isOpen) {
      interval = window.setInterval(() => {
        setRemainingPeriod(formatDuration(dayjs().toISOString(), expiresAt))
      }, 1000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [expiresAt, isOpen])
  return (
    <SimpleDialog
      isOpen={isOpen}
      title={t('Cart expires soon')}
      content={t(
        'Your cart expires in {{remainingPeriod}}. Please, hurry up to finish your order.',
        {remainingPeriod}
      )}
      actions={<Button onClick={closeDialog}>{t('Got it')}</Button>}
    />
  )
}
