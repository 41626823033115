import {gql} from '@apollo/client'
import {
  BASIC_CART_FIELDS,
  ECOMMERCE_TRANSLATED_FIELDS,
  TRANSLATED_FIELDS,
  VENUE_FIELDS
} from '../graphql'

export const TOUR_TIME_SLOT_BASE_FIELDS = gql`
  ${VENUE_FIELDS}
  fragment TourTimeSlotBaseFields on TourTimeSlot {
    id
    names {
      ...TranslatedFields
    }
    tour {
      id
      name
    }
    versionCode
    ageClassificationCode
    venue {
      ...VenueFields
    }
    client {
      id
      localeCodes
      currency
    }
    startsAt
    endsAt
  }
`

export const TOUR_TIME_SLOT_SALE_FIELDS = gql`
  fragment TourTimeSlotSaleFields on TourTimeSlot {
    eCommerceSaleStartsAt
    eCommerceSaleEndsAt
    eCommerceReservationStartsAt
    eCommerceReservationEndsAt
    eCommerceAttendeesLimit
    eCommerceOrderAttendeesLimit
    isECommerceSaleActive
    isECommerceReservationActive
    isRetailSaleActive
    isRetailReservationActive
    state
  }
`
export const GET_TOUR_TIME_SLOT_DETAIL = gql`
  ${TOUR_TIME_SLOT_SALE_FIELDS}
  ${TRANSLATED_FIELDS}
  ${ECOMMERCE_TRANSLATED_FIELDS}
  ${TOUR_TIME_SLOT_BASE_FIELDS}
  query GetTourTimeSlotDetail($id: PositiveInt!) {
    tourTimeSlot(id: $id) {
      id
      ...TourTimeSlotBaseFields
      ...TourTimeSlotSaleFields
      admissionRate {
        id
        admissionTypesAssignments(state: active, channel: eCommerce) {
          id
          enabledOnECommerce
          eCommercePrice
          admissionType {
            id
            eCommerceNames {
              ...ECommerceTranslatedFields
            }
            eCommerceDescriptions {
              ...ECommerceTranslatedFields
            }
            startingQuantity
            name
          }
        }
      }
    }
  }
`

export const INCREMENT_TOUR_ITEM_QUANTITY = gql`
  ${BASIC_CART_FIELDS}
  mutation IncrementTourItemQuantity(
    $cartId: PositiveInt
    $tourTimeSlotId: PositiveInt!
    $admissionTypeAssignmentId: PositiveInt!
    $increment: PositiveInt!
  ) {
    incrementTourItemQuantity(
      cartId: $cartId
      tourTimeSlotId: $tourTimeSlotId
      admissionTypeAssignmentId: $admissionTypeAssignmentId
      increment: $increment
    ) {
      ...BasicCartFields
    }
  }
`

export const DECREMENT_TOUR_ITEM_QUANTITY = gql`
  ${BASIC_CART_FIELDS}
  mutation DecrementTourItemQuantity(
    $cartId: PositiveInt!
    $tourTimeSlotId: PositiveInt!
    $admissionTypeAssignmentId: PositiveInt!
    $decrement: PositiveInt!
  ) {
    decrementTourItemQuantity(
      cartId: $cartId
      tourTimeSlotId: $tourTimeSlotId
      admissionTypeAssignmentId: $admissionTypeAssignmentId
      decrement: $decrement
    ) {
      ...BasicCartFields
    }
  }
`
