import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {Typography} from '@mui/material'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ClientDetailFieldsFragment,
  ExistingCountryCode
} from '../../__generated__/schema'
import {UncontrolledFormRadioGroup} from '../components/molecules/UncontrolledFormRadioGroup'
import {PaymentMethodOptionLabel} from './PaymentMethodOptionLabel'
import {
  CartPageFormField,
  ICartPageForm,
  PMRadioGroupOption,
  PMRadioGroupOptionType
} from './types'

interface IPaymentMethodsContentProps {
  pMRadioGroupOptions: PMRadioGroupOption[]
  eCommercePaymentMethods: ClientDetailFieldsFragment['eCommercePaymentMethods']
  remainingPriceLabel?: string
  selectedPMRadioGroupOptionKey: null | string
  countryCode: ExistingCountryCode
}

export const PaymentMethodsContent: React.FC<IPaymentMethodsContentProps> = ({
  pMRadioGroupOptions,
  eCommercePaymentMethods,
  remainingPriceLabel,
  selectedPMRadioGroupOptionKey,
  countryCode
}: IPaymentMethodsContentProps) => {
  const {t} = useTranslation()
  const {control, errors} = useFormContext<ICartPageForm>()
  return pMRadioGroupOptions.length === 0 ? (
    <Typography variant="caption" color="error">
      {t(
        'We are sorry, but it is not allowed to finish order right now.  Please, try it again later or you can contact us by mail if this happens again.'
      )}
    </Typography>
  ) : (
    <UncontrolledFormRadioGroup<ICartPageForm, string>
      options={pMRadioGroupOptions.map(
        (pMRadioGroupOption: PMRadioGroupOption) => ({
          value: pMRadioGroupOption.key,
          isDisabled: pMRadioGroupOption.isDisabled,
          label: (
            <PaymentMethodOptionLabel
              countryCode={countryCode}
              pMRadioGroupOption={pMRadioGroupOption}
              eCommercePaymentMethod={
                pMRadioGroupOption.type ===
                PMRadioGroupOptionType.RegularPaymentMethod
                  ? eCommercePaymentMethods.find(
                      (pm) =>
                        pm.id === pMRadioGroupOption.eCommercePaymentMethodId
                    )
                  : undefined
              }
              remainingPriceLabel={
                selectedPMRadioGroupOptionKey === pMRadioGroupOption.key
                  ? remainingPriceLabel
                  : undefined
              }
            />
          )
        })
      )}
      checkedIcon={<CheckCircleIcon />}
      fullWidth
      name={CartPageFormField.SelectedPMRadioGroupOptionKey}
      control={control}
      errors={errors}
    />
  )
}
