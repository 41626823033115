import {
  Autocomplete,
  FormControl,
  FormControlProps,
  Paper,
  PaperProps,
  TextField,
  Typography
} from '@mui/material'
import React, {useRef} from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {FormSubText} from '../atoms/FormSubText'
import {getFormError} from '../formUtils'
import {FormFieldName} from '../types'

const PaperComponent = ({children}: PaperProps) => (
  <Paper elevation={8}>{children}</Paper>
)

export interface IFormAutocompleteProps<
  FormValues extends FieldValues = FieldValues
> extends Pick<
    FormControlProps<typeof TextField>,
    'inputMode' | 'label' | 'fullWidth' | 'sx' | 'disabled'
  > {
  errors: FieldErrors<FormValues>
  control: FormContextValues<FormValues>['control']
  validationOptions?: ValidationOptions
  helperText?: string
  noOptionsText?: string
  name: FormFieldName<FormValues>
  autocompleteOptions: {
    value: FormValues[FormFieldName<FormValues>]
    name: string
  }[]
}

export const FormAutocomplete = <FormValues extends FieldValues = FieldValues>({
  errors,
  control,
  validationOptions,
  helperText,
  name,
  disabled,
  inputMode,
  label,
  fullWidth,
  autocompleteOptions,
  noOptionsText,
  sx
}: IFormAutocompleteProps<FormValues>) => {
  const error = getFormError(errors, name)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const isRequired = Boolean(validationOptions?.required)
  return (
    <FormControl
      sx={sx}
      fullWidth={fullWidth}
      disabled={disabled}
      error={Boolean(error)}
      variant="outlined"
      size="small"
      inputMode={inputMode}
      required={isRequired}
    >
      <Controller
        as={(props) => (
          <Autocomplete
            key="autocomplete"
            size="small"
            options={autocompleteOptions}
            noOptionsText={noOptionsText}
            onChange={(e, autocompleteValue) => {
              props.onChange(autocompleteValue?.value, true)
              if (!props.value && autocompleteValue?.value) {
                control.triggerValidation(name)
              }
            }}
            PaperComponent={PaperComponent}
            autoComplete={false}
            value={
              autocompleteOptions.find((o) => o.value === props.value) || null
            }
            getOptionLabel={(option) => option.name}
            renderOption={(p, option) => (
              <Typography {...p} component="li" variant="body2">
                {option.name}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField
                error={Boolean(error)}
                {...params}
                label={label}
                required={isRequired}
                variant="outlined"
                color="primary"
                autoComplete="off"
                inputRef={inputRef}
                inputProps={{
                  ...params.inputProps,
                  form: {
                    autocomplete: 'off'
                  }
                }}
              />
            )}
          />
        )}
        onFocus={() => {
          inputRef.current?.focus()
        }}
        control={control}
        rules={validationOptions}
        name={name}
      />
      <FormSubText
        error={error}
        helperText={helperText}
        validationOptions={validationOptions}
      />
    </FormControl>
  )
}
