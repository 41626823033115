import {Box, SxProps, Theme, Typography} from '@mui/material'
import {SystemCssProperties} from '@mui/system'
import React from 'react'

enum WarningAlignment {
  Center = 'center',
  Left = 'left'
}

export enum WarningColorCombination {
  Transparent = 'transparent',
  White = 'white',
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning'
}

export interface IWarningProps {
  title: string
  icon?: React.ReactNode
  description?: React.ReactNode
  actions?: React.ReactNode
  alignment?: WarningAlignment
  maxWidth?: SystemCssProperties<Theme>['maxWidth']
  colorCombination?: WarningColorCombination
  sx?: SxProps<Theme>
}

const getColors = (
  colorCombination: WarningColorCombination
): {
  borderColor?: SystemCssProperties<Theme>['borderColor']
  iconColor?: SystemCssProperties<Theme>['borderColor']
  bgcolor?: SystemCssProperties<Theme>['bgcolor']
} => {
  switch (colorCombination) {
    case WarningColorCombination.Error:
      return {
        bgcolor: 'error.light',
        borderColor: 'error.main',
        iconColor: 'error.main'
      }
    case WarningColorCombination.Success:
      return {
        bgcolor: 'success.light',
        borderColor: 'success.main',
        iconColor: 'success.main'
      }
    case WarningColorCombination.Warning:
      return {
        bgcolor: 'warning.light',
        borderColor: 'warning.main',
        iconColor: 'warning.main'
      }
    case WarningColorCombination.Info:
      return {
        bgcolor: 'info.light',
        borderColor: 'info.main',
        iconColor: 'info.main'
      }
    case WarningColorCombination.White:
      return {
        bgcolor: 'background.paper',
        borderColor: 'divider'
      }
    case WarningColorCombination.Transparent:
    default:
      return {
        borderColor: 'transparent'
      }
  }
}

export const Warning: React.FC<IWarningProps> = ({
  title,
  icon,
  description,
  actions,
  alignment = WarningAlignment.Center,
  maxWidth = 360,
  colorCombination = WarningColorCombination.Transparent,
  sx
}: IWarningProps) => {
  const {bgcolor, borderColor, iconColor} = getColors(colorCombination)
  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'grid',
          flexGrow: 1,
          gap: 2,
          p: 2,
          justifyItems:
            alignment === WarningAlignment.Left ? 'flex-start' : 'center',
          borderRadius: 4,
          gridAutoFlow: 'row',
          maxWidth,
          bgcolor,
          borderColor,
          borderStyle: 'solid',
          borderWidth: 1,
          color: iconColor
        }}
      >
        {icon}
        <Typography
          variant="h6"
          color="textPrimary"
          textAlign={alignment === WarningAlignment.Left ? 'left' : 'center'}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            variant="body1"
            color="textSecondary"
            textAlign={alignment === WarningAlignment.Left ? 'left' : 'center'}
          >
            {description}
          </Typography>
        )}
        {actions && <Box sx={{display: 'flex', gap: 1}}>{actions}</Box>}
      </Box>
    </Box>
  )
}
