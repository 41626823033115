import {ApolloError} from '@apollo/client'
import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  BasicCartFieldsFragment,
  ErrorMessages
} from '../../__generated__/schema'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {
  getGraphQLErrorRelatedToErrorMessage,
  isTourItemPropertiesFragment
} from '../utils'

const getItemsLength = (
  cart: BasicCartFieldsFragment | null,
  tourTimeSlotId: number,
  admissionTypeAssignmentId: number
) =>
  (cart?.items || []).filter(
    (i) =>
      isTourItemPropertiesFragment(i) &&
      i.tourTimeSlotId === tourTimeSlotId &&
      i.admissionTypeAssignmentId === admissionTypeAssignmentId
  ).length

export const useGetTourItemQuantity = () => {
  const {currentCart} = useCurrentCart()
  const getIncrementTourItemQuantity = useCallback(
    (startingQuantity: number, assigmentId: number, tourTimeSlotId: number) =>
      getItemsLength(currentCart, tourTimeSlotId, assigmentId) === 0
        ? startingQuantity
        : 1,
    [currentCart]
  )
  const getDecrementTourItemQuantity = useCallback(
    (startingQuantity: number, assigmentId: number, tourTimeSlotId: number) =>
      getItemsLength(currentCart, tourTimeSlotId, assigmentId) ===
      startingQuantity
        ? startingQuantity
        : 1,
    [currentCart]
  )
  return {getIncrementTourItemQuantity, getDecrementTourItemQuantity}
}

export const useIncrementErrorHandler = () => {
  const {t} = useTranslation()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const handleReload = useCallback(
    (onClose: () => void) => () => {
      onClose()
      window.location.reload()
    },
    []
  )
  return useCallback(
    (error: ApolloError, admissionTypeName: string) => {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.EcommerceOrderAttendeesLimitPerTourTimeSlotExceeded
        )
      ) {
        defaultErrorHandler(error, {
          title: t('Unable to add more items to cart'),
          description: t(
            "We're sorry, but you have reached the maximum number of attendees allowed per online order. Please complete this order, and then you can select more items in a new one."
          ),
          renderActions: function renderActions(onClose) {
            return (
              <Button variant="text" onClick={handleReload(onClose)}>
                {t('Got it')}
              </Button>
            )
          }
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.EcommerceAttendeesLimitPerTourTimeSlotExceeded
        )
      ) {
        defaultErrorHandler(error, {
          title: t('Time slot is sold out on ecommerce'),
          description: t(
            "We're sorry, but this time slot is sold out on ecommerce. Please try it again later or pick another one."
          ),
          renderActions: function renderActions(onClose) {
            return (
              <Button variant="text" onClick={handleReload(onClose)}>
                {t('Got it')}
              </Button>
            )
          }
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.RetailAttendeesLimitPerTourTimeSlotExceeded
        )
      ) {
        defaultErrorHandler(error, {
          title: t('Time slot is sold out on retail'),
          description: t(
            "We're sorry, but this time slot is sold out on retail. Please try it again later or pick another one."
          ),
          renderActions: function renderActions(onClose) {
            return (
              <Button variant="text" onClick={handleReload(onClose)}>
                {t('Got it')}
              </Button>
            )
          }
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.AdmissionTypeAssignmentLimitPerTimeSlotExceeded
        )
      ) {
        defaultErrorHandler(error, {
          title: t('Item {{admissionTypeName}} is sold out', {
            admissionTypeName
          }),
          description: t(
            "We're sorry, but item {{admissionTypeName}} is sold out for this time slot. Please try it again later or pick another item or time slot.",
            {admissionTypeName}
          ),
          renderActions: function renderActions(onClose) {
            return (
              <Button variant="text" onClick={handleReload(onClose)}>
                {t('Got it')}
              </Button>
            )
          }
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidTourTimeSlotState
        )
      ) {
        defaultErrorHandler(error, {
          title: t('Time slot is unavailable'),
          description: t(
            "We're sorry, but this time slot is no longer available. Please try to pick another one."
          ),
          renderActions: function renderActions(onClose) {
            return (
              <Button variant="text" onClick={handleReload(onClose)}>
                {t('Got it')}
              </Button>
            )
          }
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidCartState
        )
      ) {
        defaultErrorHandler(error, {
          title: t('Invalid cart state'),
          description: t(
            "We're sorry, but your cart is currently in an invalid state. Please reload your page and try again. If the issue persists, contact our customer support."
          ),
          renderActions: function renderActions(onClose) {
            return (
              <Button variant="text" onClick={handleReload(onClose)}>
                {t('Got it')}
              </Button>
            )
          }
        })
      } else {
        defaultErrorHandler(error, {
          title: t('Error while incrementing tour item quantity')
        })
      }
    },
    [defaultErrorHandler, handleReload, t]
  )
}
