import {ApolloError} from '@apollo/client'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {ErrorMessages} from '../../__generated__/schema'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {useMutationAssistanceHooks} from './mutationAssistanceHooks'

export enum ReservationErrorHandlerLocation {
  Pay,
  Delete
}

export const useReservationErrorHandler = () => {
  const {t} = useTranslation()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const navigate = useNavigate()
  return ({
    error,
    location,
    onRefetch,
    eventId
  }: {
    error: ApolloError
    location: ReservationErrorHandlerLocation
    onRefetch: () => void
    eventId?: number
  }) => {
    const isLocationPay = location === ReservationErrorHandlerLocation.Pay
    const title = isLocationPay
      ? t('Unable to pay for reservation')
      : t('Unable to delete reservation')
    if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.ReservationIsBeingProcessed
      )
    ) {
      defaultErrorHandler(error, {
        title,
        description: isLocationPay
          ? t(
              'Reservation is being processed right now. If this action was performed by you, please check state for your payment.'
            )
          : t('Reservation is being processed right now and can’t be deleted.'),
        renderActions: function renderActions(onClose) {
          return (
            <Button
              variant="text"
              onClick={() => {
                onClose()
                onRefetch()
              }}
            >
              {t('Got it')}
            </Button>
          )
        }
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.ReservationHasBeenAlreadyPaid
      )
    ) {
      defaultErrorHandler(error, {
        title,
        description: t(
          'Reservation has been paid already. Please, check your inbox for confirmation e-mail with tickets.'
        ),
        renderActions: function renderActions(onClose) {
          return (
            <Button
              variant="text"
              onClick={() => {
                onClose()
                onRefetch()
              }}
            >
              {t('Got it')}
            </Button>
          )
        }
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.ReservationExpired
      )
    ) {
      defaultErrorHandler(error, {
        title,
        description: isLocationPay
          ? t(
              'Reservation has expired already. You can check event, if there are still some available seats.'
            )
          : t('Reservation has expired already and can’t be deleted.'),
        renderActions: function renderActions(onClose) {
          return (
            <>
              <Button
                variant="text"
                onClick={() => {
                  onClose()
                  onRefetch()
                }}
              >
                {isLocationPay ? t('Not interested') : t('Got it')}
              </Button>
              {eventId && (
                <Button
                  variant="text"
                  onClick={() => {
                    onClose()
                    navigate(`/event/${eventId}`)
                  }}
                >
                  {t('Get tickets')}
                </Button>
              )}
            </>
          )
        }
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.ReservationDeleted
      )
    ) {
      defaultErrorHandler(error, {
        title,
        description: isLocationPay
          ? t(
              'Reservation has been deleted. You can check event, if there are still some available seats.'
            )
          : t('Reservation has been deleted already.'),
        renderActions: function renderActions(onClose) {
          return (
            <>
              <Button
                variant="text"
                onClick={() => {
                  onClose()
                  onRefetch()
                }}
              >
                {isLocationPay ? t('Not interested') : t('Got it')}
              </Button>
              {eventId && (
                <Button
                  variant="text"
                  onClick={() => {
                    onClose()
                    navigate(`/event/${eventId}`)
                  }}
                >
                  {t('Get tickets')}
                </Button>
              )}
            </>
          )
        }
      })
    } else {
      defaultErrorHandler(
        error,
        isLocationPay
          ? t('Adding reservation to cart failed')
          : t('Error while deleting reservation')
      )
    }
  }
}
