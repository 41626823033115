import {Box, Chip, Divider, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'
import {Currency, TourItemFieldsFragment} from '../../__generated__/schema'

interface ITourItemProps {
  tourItem: TourItemFieldsFragment
  translatePrice: (value: number, currency?: Currency | undefined) => string
  sx?: SxProps<Theme>
}

export const TourItem: React.FC<ITourItemProps> = ({
  tourItem,
  translatePrice,
  sx
}: ITourItemProps) => {
  const hasDiscounts = tourItem.appliedDiscounts.length > 0
  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: hasDiscounts ? '1fr auto auto' : '1fr auto',
          columnGap: 1,
          alignItems: 'center',
          minHeight: 56
        }}
      >
        <Typography variant="subtitle2">{tourItem.name}</Typography>
        {hasDiscounts && (
          <Chip
            sx={{
              p: 0.5,
              maxWidth: 184,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
            label={tourItem.appliedDiscounts[0].discount.name}
          />
        )}
        <Typography variant="body2">
          {translatePrice(tourItem.price)}
        </Typography>
      </Box>
      <Divider />
    </Box>
  )
}
