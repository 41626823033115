import {Box, Button, Chip, Paper, styled, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetEventOverviewQuery} from '../../__generated__/schema'
import {EventButtonTarget} from '../../types'
import {useLocale} from '../components/atoms/LocaleContext'
import {useThemeContextProvider} from '../components/atoms/ThemeContext'
import {useFormatEventDurationDate} from '../hooks/formatting'
import {isLocaleCode, useGetTranslatedValue} from '../hooks/translateLocales'

const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

interface IEventRowProps {
  event: GetEventOverviewQuery['event']
  isLargeDesktop: boolean
  isTabletPlus: boolean
  eventButtonTarget: EventButtonTarget
}

export const EventRow: React.FC<IEventRowProps> = ({
  event,
  isLargeDesktop,
  isTabletPlus,
  eventButtonTarget
}: IEventRowProps) => {
  const {t} = useTranslation()
  const [locale] = useLocale()
  const localeCode = isLocaleCode(locale)
    ? locale
    : event.division.client.localeCodes[0]
  const translatedValue = useGetTranslatedValue([localeCode])
  const formatEventDurationDate = useFormatEventDurationDate()
  const thumbnailUrl = event.show.primaryImage?.thumbnails[0]?.url
  const {theme} = useThemeContextProvider()
  const buttonHref =
    theme && eventButtonTarget === EventButtonTarget.Blank
      ? `/event/${event.id}?theme=${theme}`
      : `/event/${event.id}`
  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        display: 'grid',
        borderRadius: isLargeDesktop || isTabletPlus ? 2 : 0,
        gridTemplateColumns: thumbnailUrl ? '135px 1fr' : '1fr',
        border:
          isTabletPlus || isLargeDesktop
            ? `1px solid ${theme.palette.divider}`
            : 'none',
        borderBottom: `1px solid ${theme.palette.divider}`
      })}
    >
      {thumbnailUrl && (
        <StyledImage
          sx={{
            borderBottomLeftRadius: isLargeDesktop || isTabletPlus ? 8 : 0,
            borderTopLeftRadius: isLargeDesktop || isTabletPlus ? 8 : 0
          }}
          src={thumbnailUrl}
        />
      )}
      <Box
        sx={{
          px: 2,
          py: 1.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant="h6">{translatedValue(event.names)}</Typography>
          <Typography variant="body1">
            {formatEventDurationDate(event.startsAt, event.endsAt)}
          </Typography>
        </Box>
        {event.availableSeatsCount === 0 ? (
          <Chip
            sx={{
              color: 'error.main',
              alignSelf: 'flex-end',
              backgroundColor: 'error.light'
            }}
            label={t('Sold out')}
            size="medium"
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            component="a"
            href={buttonHref}
            target={eventButtonTarget}
            sx={{alignSelf: 'flex-end'}}
          >
            {t('Get tickets')}
          </Button>
        )}
      </Box>
    </Paper>
  )
}
