import dayjs from 'dayjs'
import Joi from 'joi'
import queryString from 'query-string'
import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {
  ECommerceLocaleCode,
  ShowAgeClassificationCode,
  ShowVersionCode
} from '../../__generated__/schema'
import {EventAvailabilityOption} from '../eventsList/types'
import {PageNotFound} from '../PageNotFound'
import {PrimaryImageType} from '../types'

export interface IToursTimeSlotsListSearchParams {
  startsAtFrom?: string
  startsAtTo?: string
  tourId?: number
  versionCode?: ShowVersionCode
  ageClassificationCode?: ShowAgeClassificationCode
  guideId?: number
  eventCategoryId?: number
  marketingLabelId?: number
  venueId?: number
  primaryImageType: PrimaryImageType
  backgroundColor: 'transparent' | 'grey'
  limit: number
  localeCode?: ECommerceLocaleCode
  showEventAvailability: EventAvailabilityOption
}

const toursTimeSlotsListSearchParamsSchema =
  Joi.object<IToursTimeSlotsListSearchParams>({
    startsAtFrom: Joi.date().iso().optional().default(dayjs().toISOString()),
    startsAtTo: Joi.date().iso().optional(),
    tourId: Joi.number().positive().integer().optional(),
    versionCode: Joi.string()
      .valid(...Object.values(ShowVersionCode))
      .optional(),
    ageClassificationCode: Joi.string()
      .valid(...Object.values(ShowAgeClassificationCode))
      .optional(),
    guideId: Joi.number().integer().positive().optional(),
    eventCategoryId: Joi.number().integer().positive().optional(),
    marketingLabelId: Joi.number().integer().positive().optional(),
    venueId: Joi.number().integer().positive().optional(),
    backgroundColor: Joi.string().valid('transparent', 'grey').default('grey'),
    limit: Joi.number().positive().integer().optional().default(30),
    localeCode: Joi.string()
      .valid(...Object.values(ECommerceLocaleCode))
      .optional(),
    showEventAvailability: Joi.string()
      .valid(...Object.values(EventAvailabilityOption))
      .default(EventAvailabilityOption.None)
  }).options({stripUnknown: true})

export interface IToursTimeSlotsListProps {
  clientId: number
  searchParams: IToursTimeSlotsListSearchParams
}

export const withToursTimeSlotsListsProps = (
  Component: React.FC<IToursTimeSlotsListProps>
) => {
  return function WithToursTimeSlotsListPropsComponent() {
    const location = useLocation()
    const validationResult = toursTimeSlotsListSearchParamsSchema.validate(
      queryString.parse(location.search)
    )
    const params = useParams<{clientId: string}>()
    const clientId = parseInt(params.clientId ?? '', 10)
    if (isNaN(clientId) || validationResult.error) {
      return <PageNotFound />
    }
    return (
      <Component clientId={clientId} searchParams={validationResult.value} />
    )
  }
}
