import React from 'react'
import {FieldValues, useFormContext} from 'react-hook-form'
import {LeadField} from '../../../../__generated__/schema'
import {useTranslateLeadField} from '../../../hooks/translateLeadField'
import {UncontrolledFormTextInput} from '../../molecules/UncontrolledFormTextInput'
import {FormFieldName} from '../../types'
import {AccountFormField} from './types'

export const CompanyFormPart = <
  FormValues extends FieldValues = FieldValues
>() => {
  const translateLeadField = useTranslateLeadField()
  const {errors, control} = useFormContext<FormValues>()
  return (
    <>
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.CompanyName as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.CompanyName)}
        fullWidth
      />
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.CompanyIdNumber as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.CompanyIdNumber)}
        fullWidth
      />
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.TaxId as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.TaxId)}
        fullWidth
      />
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.VatId as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.VatId)}
        fullWidth
      />
    </>
  )
}
