import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  Chip,
  Divider,
  IconButton,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import React from 'react'
import {
  Currency,
  EnabledDiscountFieldsFragment,
  TicketItemFieldsFragment
} from '../../__generated__/schema'
import {Tooltip} from '../../components/atoms/Tooltip'
import {FullTicketItemLabel} from '../components/atoms/FullTicketItemLabel'
import {AddDiscountChip} from './AddDiscountChip'

interface IEventTicketItemProps {
  ticketItem: TicketItemFieldsFragment
  translatePrice: (value: number, currency?: Currency | undefined) => string
  sx?: SxProps<Theme>
  getRemoveButtonClickHandler: (
    uuid: string,
    eventSeatId: number
  ) => () => Promise<void>
  removeButtonTooltip: string
  enabledDiscounts: EnabledDiscountFieldsFragment[]
  handleOnAppliedDiscountChipDeleteClick: (
    ticketItemId: number,
    discountId: number
  ) => () => void
  isMobile: boolean
  cartId: number
  onIHaveDiscountCodeClick: () => void
  isRemoveButtonHidden: boolean
  onCustomerDiscountClick: (
    discount: (EnabledDiscountFieldsFragment & {ticketItemId: number}) | null
  ) => void
}

export const EventTicketItem: React.FC<IEventTicketItemProps> = ({
  ticketItem,
  translatePrice,
  getRemoveButtonClickHandler,
  removeButtonTooltip,
  isMobile,
  enabledDiscounts,
  handleOnAppliedDiscountChipDeleteClick,
  sx,
  cartId,
  onIHaveDiscountCodeClick,
  isRemoveButtonHidden,
  onCustomerDiscountClick
}: IEventTicketItemProps) => {
  const hasAppliedDiscount = ticketItem.appliedDiscounts.length > 0
  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: isMobile
            ? '40px 1fr auto'
            : '40px 1fr auto auto',
          gridTemplateAreas: isMobile
            ? `
                "removeIcon label       price"
                "removeIcon addDiscount price"
              `
            : `
                "removeIcon label addDiscount price"
              `,
          columnGap: 1,
          alignItems: 'center',
          minHeight: 56,
          my: isMobile ? 1 : 0
        }}
      >
        <Box sx={{gridArea: 'removeIcon'}}>
          {!isRemoveButtonHidden && (
            <Tooltip title={removeButtonTooltip}>
              <IconButton
                color="primary"
                onClick={getRemoveButtonClickHandler(
                  ticketItem.eventSeat.uuid,
                  ticketItem.eventSeat.id
                )}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {ticketItem.eventPricingToTicketType.promotions && (
          <Box
            sx={{
              gridArea: 'addDiscount',
              justifySelf: isMobile ? 'start' : 'end'
            }}
          >
            {hasAppliedDiscount && ticketItem.appliedDiscounts[0]?.discount ? (
              <Chip
                sx={{
                  p: 0.5,
                  maxWidth: 184,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                label={ticketItem.appliedDiscounts[0].discount.name}
                onDelete={handleOnAppliedDiscountChipDeleteClick(
                  ticketItem.id,
                  ticketItem.appliedDiscounts[0].discount.id
                )}
              />
            ) : (
              enabledDiscounts.length > 0 && (
                <AddDiscountChip
                  eventId={ticketItem.eventSeat.event.id}
                  isMobile={isMobile}
                  cartId={cartId}
                  ticketItemId={ticketItem.id}
                  currency={ticketItem.eventSeat.event.division.client.currency}
                  onIHaveDiscountCodeClick={onIHaveDiscountCodeClick}
                  onCustomerDiscountClick={onCustomerDiscountClick}
                />
              )
            )}
          </Box>
        )}
        <FullTicketItemLabel
          eventSeat={ticketItem.eventSeat}
          sx={{gridArea: 'label'}}
        />
        <Box
          sx={{
            gridArea: 'price',
            alignSelf: isMobile ? 'start' : undefined,
            textAlign: 'right'
          }}
        >
          <Typography variant="body2">
            {translatePrice(ticketItem.price)}
          </Typography>
          {hasAppliedDiscount && (
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{textDecorationLine: 'line-through'}}
            >
              {translatePrice(ticketItem.priceBeforeDiscount)}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}
