import {Box, SxProps} from '@mui/material'
import React from 'react'
import {MediaSizes} from '../types'
import {useIsMediaSize} from './WindowInnerWidthContext'

interface IListWrapperProps {
  sx?: SxProps
  children: React.ReactNode
}

export const ListWrapper: React.FC<IListWrapperProps> = ({
  sx,
  children
}: IListWrapperProps) => {
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  return (
    <Box
      sx={{
        ...(isMobile
          ? {}
          : {
              px: 3
            }),
        overflow: 'auto',
        height: '100%',
        ...sx
      }}
    >
      <Box
        sx={{
          py: 1,
          display: 'grid',
          gap: 1,
          ...(isMobile
            ? {}
            : {
                maxWidth: 800,
                mx: 'auto'
              })
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
