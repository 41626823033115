import LanguageIcon from '@mui/icons-material/Language'
import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@mui/material'
import React, {useCallback} from 'react'
import {ECommerceLocaleCode} from '../../../__generated__/schema'
import {useTranslateLocaleCode} from '../../hooks/translateLocales'
import {useLocale} from '../atoms/LocaleContext'

export const LanguageSelect: React.FC = () => {
  const [locale, changeLocale] = useLocale()
  const [open, setOpen] = React.useState(false)
  const translateLocaleCode = useTranslateLocaleCode()
  const anchorRef = React.useRef(null)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const handleMenuItemClick = useCallback(
    async (e, localeCode) => {
      await changeLocale(localeCode)
      setOpen(false)
    },
    [changeLocale]
  )

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        startIcon={<LanguageIcon />}
        color="inherit"
        onClick={() => setOpen(true)}
      >
        {locale}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper sx={{width: 260, maxWidth: '100%'}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {Object.entries(ECommerceLocaleCode).map(
                    ([label, localeCode]) => (
                      <MenuItem
                        key={localeCode}
                        onClick={(event) =>
                          handleMenuItemClick(event, localeCode)
                        }
                        sx={{
                          px: 3,
                          py: 2
                        }}
                      >
                        <ListItemText>
                          <Typography variant="body2" color="text.primary">
                            {translateLocaleCode(localeCode)}
                          </Typography>
                        </ListItemText>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          sx={{textTransform: 'uppercase'}}
                        >
                          {label}
                        </Typography>
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
