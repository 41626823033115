import React, {useCallback} from 'react'
import {matchPath, useLocation, useNavigate} from 'react-router-dom'
import {CartState} from '../../../../__generated__/schema'
import {useCurrentCart} from '../../atoms/CurrentCartContext'
import {CartExpiredDialog} from './CartExpiredDialog'
import {CartExpiresSoonDialog} from './CartExpiresSoonDialog'

interface ICartExpirationControllerProps {
  children: React.ReactNode
}

export const CartExpirationController: React.FC<ICartExpirationControllerProps> =
  ({children}: ICartExpirationControllerProps) => {
    const {currentCart, resetCurrentCart} = useCurrentCart()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const handleGotItToButtonClick = useCallback(() => {
      if (matchPath('/:clientId/events', pathname)) {
        resetCurrentCart()
        navigate(0)
      } else {
        navigate(`/${currentCart?.clientId}/cart`, {replace: true})
      }
    }, [currentCart?.clientId, navigate, pathname, resetCurrentCart])
    return (
      <>
        {children}
        {currentCart && currentCart.state === CartState.Draft && (
          <CartExpiresSoonDialog expiresAt={currentCart.expiresAt} />
        )}
        {currentCart &&
          [CartState.Draft, CartState.Expired].includes(currentCart.state) && (
            <CartExpiredDialog
              expiresAt={currentCart.expiresAt}
              onGotItToButtonClick={handleGotItToButtonClick}
            />
          )}
      </>
    )
  }
