import {Button} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {BasicCartFieldsFragment, IntentState} from '../../__generated__/schema'
import {SimpleDialog} from '../../components/atoms/SimpleDialog'
import {useLocalStorageState} from '../hooks/storage'
import {UNSUCCESSFUL_PAYMENT_DIALOG_DATA} from '../localStorageKeys'

interface IUnsuccessfulPaymentProps {
  paymentIntents: BasicCartFieldsFragment['paymentIntents']
}

export const UnsuccessfulPayment: React.FC<IUnsuccessfulPaymentProps> = ({
  paymentIntents
}: IUnsuccessfulPaymentProps) => {
  const {t} = useTranslation()
  const [paymentDialogData, setPaymentDialogData] = useLocalStorageState<
    string | null
  >(UNSUCCESSFUL_PAYMENT_DIALOG_DATA, null)
  const [isDialogOpen, setDialogOpen] = useState(false)
  useEffect(() => {
    if (
      paymentIntents.every(({state}) => IntentState.Failed === state) &&
      paymentIntents.length > 0
    ) {
      const data = JSON.stringify(paymentIntents)
      if (data !== paymentDialogData) {
        setPaymentDialogData(data)
        setDialogOpen(true)
      }
    }
  }, [paymentDialogData, paymentIntents, setPaymentDialogData])
  return (
    <SimpleDialog
      title={t('Payment unsuccessful')}
      isOpen={isDialogOpen}
      content={t(
        "We're sorry, but we were unable to process your payment. This could be due to various reasons, such as incorrect card details, insufficient funds, or an issue with your bank. Please try to make the payment again, or consider using an alternative payment method or a different credit/debit card."
      )}
      actions={
        <Button onClick={() => setDialogOpen(false)}>{t('Got it')}</Button>
      }
    />
  )
}
