import {UpdateSignedInCustomerInput} from '../../../../../__generated__/schema'
import {AccountFormField, IAccountForm} from '../types'

export interface IEditProfileForm
  extends Omit<IAccountForm, 'accountPassword' | 'accountConfirmPassword'> {}

export const transformEditProfileDataToInput = (
  data: IEditProfileForm
): UpdateSignedInCustomerInput => ({
  username: data[AccountFormField.Username],
  privacyPolicyConsentGranted: Boolean(
    data[AccountFormField.WouldLikeToReceiveLatestNews]
  ),
  leadData: {
    name: data[AccountFormField.Name] || undefined,
    phone: data[AccountFormField.Phone] || undefined,
    email: data[AccountFormField.Email],
    note: data[AccountFormField.Note] || undefined,
    internalNote: data[AccountFormField.InternalNote] || undefined,
    companyName: data[AccountFormField.CompanyName] || undefined,
    companyIdNumber: data[AccountFormField.CompanyIdNumber] || undefined,
    VATId: data[AccountFormField.VatId] || undefined,
    TAXId: data[AccountFormField.TaxId] || undefined,
    billingAddressStreet:
      data[AccountFormField.BillingAddressStreet] || undefined,
    billingAddressTown: data[AccountFormField.BillingAddressTown] || undefined,
    billingPostalCode: data[AccountFormField.BillingPostalCode] || undefined,
    billingAddressCountry:
      data[AccountFormField.BillingAddressCountry] || undefined,
    deliveryAddressee: data[AccountFormField.DeliveryAddressee] || undefined,
    deliveryAddressStreet:
      data[AccountFormField.DeliveryAddressStreet] || undefined,
    deliveryAddressTown:
      data[AccountFormField.DeliveryAddressTown] || undefined,
    deliveryPostalCode: data[AccountFormField.DeliveryPostalCode] || undefined,
    deliveryAddressCountry:
      data[AccountFormField.DeliveryAddressCountry] || undefined
  }
})
