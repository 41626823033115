import {useMutation} from '@apollo/client'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import {Box, Divider, IconButton} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DecrementTourItemQuantityMutation,
  DecrementTourItemQuantityMutationVariables,
  GetTourTimeSlotDetailQuery,
  IncrementTourItemQuantityMutation,
  IncrementTourItemQuantityMutationVariables
} from '../../__generated__/schema'
import {ListWrapper} from '../../components/atoms/ListWrapper'
import {Tooltip} from '../../components/atoms/Tooltip'
import {ZoneListItem} from '../../components/molecules/ZoneListItem'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {Powered} from '../components/atoms/Powered'
import {TourTimeSlotHeader} from '../components/atoms/TourTimeSlotHeader'
import {LanguageSelect} from '../components/molecules/LanguageSelect'
import {MobileMoreMenu} from '../components/molecules/MobileMoreMenu'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {useGetTranslatedValue} from '../hooks/translateLocales'
import {isTourItemPropertiesFragment} from '../utils'
import {
  DECREMENT_TOUR_ITEM_QUANTITY,
  INCREMENT_TOUR_ITEM_QUANTITY
} from './graphql'
import {useGetTourItemQuantity, useIncrementErrorHandler} from './utils'

interface IContentProps {
  tourTimeSlot: GetTourTimeSlotDetailQuery['tourTimeSlot']
  isMobile: boolean
}

export const Content: React.FC<IContentProps> = ({
  tourTimeSlot,
  isMobile
}: IContentProps) => {
  const {t} = useTranslation()
  const [incrementTourItemQuantity] = useMutation<
    IncrementTourItemQuantityMutation,
    IncrementTourItemQuantityMutationVariables
  >(INCREMENT_TOUR_ITEM_QUANTITY)
  const [decrementTourItemQuantity] = useMutation<
    DecrementTourItemQuantityMutation,
    DecrementTourItemQuantityMutationVariables
  >(DECREMENT_TOUR_ITEM_QUANTITY)
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const {currentCartId, currentCart, initializeCurrentCart, updateCurrentCart} =
    useCurrentCart()
  const {getIncrementTourItemQuantity, getDecrementTourItemQuantity} =
    useGetTourItemQuantity()
  const incrementErrorHandler = useIncrementErrorHandler()
  const translatePrice = useTranslatePrice(tourTimeSlot.client.currency)
  const getTranslatedValue = useGetTranslatedValue(
    tourTimeSlot.client.localeCodes
  )
  const handleAddButtonClick = useCallback(
    (
        admissionTypeAssignmentId: number,
        increment: number,
        admissionTypeName: string
      ) =>
      async () => {
        try {
          setShowBackdrop(true)
          const {data} = await incrementTourItemQuantity({
            variables: {
              cartId: currentCartId,
              tourTimeSlotId: tourTimeSlot.id,
              admissionTypeAssignmentId,
              increment
            }
          })
          if (data?.incrementTourItemQuantity) {
            if (!currentCartId) {
              initializeCurrentCart(data.incrementTourItemQuantity)
            } else {
              updateCurrentCart()
            }
          }
        } catch (error) {
          incrementErrorHandler(error, admissionTypeName)
        } finally {
          setShowBackdrop(false)
        }
      },
    [
      currentCartId,
      incrementErrorHandler,
      incrementTourItemQuantity,
      initializeCurrentCart,
      setShowBackdrop,
      tourTimeSlot.id,
      updateCurrentCart
    ]
  )
  const handleRemoveButtonClick = useCallback(
    (admissionTypeAssignmentId: number, decrement: number) => async () => {
      if (currentCartId) {
        try {
          setShowBackdrop(true)
          const {data} = await decrementTourItemQuantity({
            variables: {
              cartId: currentCartId,
              tourTimeSlotId: tourTimeSlot.id,
              admissionTypeAssignmentId,
              decrement
            }
          })
          if (data?.decrementTourItemQuantity) {
            updateCurrentCart()
          }
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while decrementing tour item quantity')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      currentCartId,
      decrementTourItemQuantity,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      tourTimeSlot.id,
      updateCurrentCart
    ]
  )
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <TourTimeSlotHeader
        isMobile={isMobile}
        tourTimeSlot={tourTimeSlot}
        sx={{
          backgroundColor: 'background.paper',
          px: 3,
          ...(isMobile
            ? {
                py: 2
              }
            : {
                pt: 1,
                pb: 2
              })
        }}
        mobileMoreMenu={<MobileMoreMenu />}
        helpIconButton={
          <Tooltip title={t('I need help')}>
            <IconButton color="inherit">
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        }
        languageSelect={
          <Tooltip title={t('Switch language')}>
            <LanguageSelect />
          </Tooltip>
        }
      />
      <Divider />
      <ListWrapper sx={{flex: 1, py: 1}}>
        {tourTimeSlot.admissionRate.admissionTypesAssignments
          .filter((ata) => ata.enabledOnECommerce)
          .map(({id, admissionType, eCommercePrice}) => (
            <ZoneListItem
              key={id}
              primaryLabel={
                getTranslatedValue(admissionType.eCommerceNames ?? {}) ??
                admissionType.name
              }
              secondaryLabel={[
                translatePrice(eCommercePrice),
                getTranslatedValue(admissionType.eCommerceDescriptions ?? {})
              ]
                .filter(Boolean)
                .join(' • ')}
              isAddButtonDisabled={false}
              isSoldOut={false}
              onAddButtonClick={handleAddButtonClick(
                id,
                getIncrementTourItemQuantity(
                  admissionType.startingQuantity,
                  id,
                  tourTimeSlot.id
                ),
                admissionType.name
              )}
              onRemoveButtonClick={handleRemoveButtonClick(
                id,
                getDecrementTourItemQuantity(
                  admissionType.startingQuantity,
                  id,
                  tourTimeSlot.id
                )
              )}
              ticketsInCartCount={
                (currentCart?.items || []).filter(
                  (item) =>
                    isTourItemPropertiesFragment(item) &&
                    item.tourTimeSlotId === tourTimeSlot.id &&
                    item.admissionTypeAssignmentId === id
                ).length
              }
              disabledAddButtonTooltipLabel={'to be defined'}
            />
          ))}
      </ListWrapper>
      <Powered />
    </Box>
  )
}
