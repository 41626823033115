import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  Typography
} from '@mui/material'
import React from 'react'

interface ISimpleDialogProps {
  title: string
  content: DialogContentProps['children']
  isOpen?: boolean
  actions: DialogActionsProps['children']
}

export const SimpleDialog: React.FC<ISimpleDialogProps> = ({
  title,
  isOpen = false,
  content,
  actions
}: ISimpleDialogProps) => {
  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
}
