import i18n from 'i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import Joi from 'joi'
import queryString from 'query-string'
import {initReactI18next} from 'react-i18next'
import {Currency, ECommerceLocaleCode} from '../__generated__/schema'
import {getParsedItemFromStorage} from '../features/hooks/storage'
import {LOCALE_CODE} from '../features/localStorageKeys'
import cs from './cs'
import de from './de'
import en from './en'
import hu from './hu'
import pl from './pl'
import sk from './sk'

export const DEFAULT_LOCALE = ECommerceLocaleCode.En

const getLocaleFromNavigator = (): ECommerceLocaleCode | null => {
  const locale = navigator.language.split('-')[0]
  return Object.values(ECommerceLocaleCode).find((l) => l === locale)
    ? (locale as ECommerceLocaleCode)
    : null
}

const localeCodeSearchParamsSchema = Joi.object<{
  localeCode?: ECommerceLocaleCode
}>({
  localeCode: Joi.string()
    .valid(...Object.values(ECommerceLocaleCode))
    .optional()
}).options({stripUnknown: true})

const getLocaleCodeFromLocationSearch = () => {
  const locationObject = queryString.parse(location.search)
  return localeCodeSearchParamsSchema.validate(locationObject).value?.localeCode
}

export const getInitialLocale = () =>
  getLocaleCodeFromLocationSearch() ||
  getParsedItemFromStorage<ECommerceLocaleCode>(localStorage, LOCALE_CODE) ||
  getLocaleFromNavigator() ||
  DEFAULT_LOCALE

interface IFormatPrice {
  currency: Currency
  value: number
}

const formatPrice = (
  {currency, value}: IFormatPrice,
  lng: string | undefined
) =>
  new Intl.NumberFormat(lng, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency
  }).format(value)

export const init18next = () => {
  const initialLocale = getInitialLocale()
  if (
    getParsedItemFromStorage<ECommerceLocaleCode>(localStorage, LOCALE_CODE) !==
    initialLocale
  ) {
    localStorage.setItem(LOCALE_CODE, initialLocale)
  }
  i18n
    .use(intervalPlural)
    .use(initReactI18next)
    .init({
      resources: {
        en,
        sk,
        cs,
        hu,
        pl,
        de
      },
      compatibilityJSON: 'v3',
      lng: initialLocale,
      fallbackLng: DEFAULT_LOCALE,
      defaultNS: 'common',
      fallbackNS: 'common',
      nsSeparator: '|',
      keySeparator: false,
      interpolation: {
        format: (value, format, lng) => {
          if (format === 'formatPrice') {
            return formatPrice(value, lng)
          }
          return value
        }
      }
    })
}
