import {useTranslation} from 'react-i18next'
import validator from 'validator'

export const useIsValidEmail = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isEmail(value) ? t('Invalid email address') : undefined
}

export const useIsValidPhone = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isMobilePhone(value)
      ? t('Invalid phone number')
      : undefined
}

export const useIsValidPassword = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && value.length < 8 ? t('Required at least 8 characters.') : undefined
}

export const useIsStringWithMinLength = (min: number) => {
  const {t} = useTranslation()
  return (value: any) =>
    value && (typeof value !== 'string' || value.length < min)
      ? t('Min allowed length is {{min}}', {min})
      : undefined
}
