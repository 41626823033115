import {AttributeAnchorTarget} from '../types'

const clickOnBlankLink = (url: string, target: AttributeAnchorTarget) => {
  const anchor = window.document.createElement('a')
  anchor.target = target
  anchor.href = url
  document.body.append(anchor)
  anchor.click()
}

export const replaceUrlByTarget = (
  url: string,
  target: AttributeAnchorTarget
) => {
  switch (target) {
    case AttributeAnchorTarget.Blank:
      clickOnBlankLink(url, target)
      break
    case AttributeAnchorTarget.Top:
      top.window.location.replace(url)
      break
    case AttributeAnchorTarget.Parent:
      window.parent.location.replace(url)
      break
    case AttributeAnchorTarget.Self:
    default:
      window.location.replace(url)
  }
}
