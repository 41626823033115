import {Typography} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../components/types'
import {formatDuration} from '../utils/formatDuration'

interface IRemainingLabelProps {
  expiresAt: string
}

export const RemainingLabel: React.FC<IRemainingLabelProps> = ({
  expiresAt
}: IRemainingLabelProps) => {
  const {t} = useTranslation()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)

  const formRemainingLabel = useCallback(
    (expiresAt: string, isMobile: boolean) =>
      dayjs().isAfter(expiresAt)
        ? t('Expired')
        : isMobile
        ? formatDuration(dayjs().toISOString(), expiresAt)
        : t('Remaining {{duration}}', {
            duration: formatDuration(dayjs().toISOString(), expiresAt)
          }),
    [t]
  )
  const [remainingLabel, setRemainingLabel] = useState<string>(
    formRemainingLabel(expiresAt, isMobile)
  )
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingLabel(formRemainingLabel(expiresAt, isMobile))
    }, 1000)
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [expiresAt, formRemainingLabel, isMobile, t])
  return (
    <Typography variant="body2" color="textSecondary">
      {remainingLabel}
    </Typography>
  )
}
