import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ExistingCountryCode,
  LeadDataFieldsFragment,
  LeadField
} from '../../../__generated__/schema'
import {
  IReadOnlyPaperProps,
  ReadOnlyLabel,
  ReadOnlyPaper,
  ReadOnlyRow
} from '../../../components/atoms/ReadOnly'
import {useTranslateCountry} from '../../hooks/translateCountry'
import {useTranslateLeadField} from '../../hooks/translateLeadField'

interface ILeadDataReadOnlyRowProps {
  leadData: LeadDataFieldsFragment
  field: LeadField
}

export const LeadDataReadOnlyRow: React.FC<ILeadDataReadOnlyRowProps> = ({
  leadData,
  field
}: ILeadDataReadOnlyRowProps) => {
  const translateLeadField = useTranslateLeadField()
  const translateCountry = useTranslateCountry()
  return leadData[field] ? (
    <ReadOnlyRow label={translateLeadField(field)}>
      {[
        LeadField.DeliveryAddressCountry,
        LeadField.BillingAddressCountry
      ].includes(field)
        ? translateCountry(leadData[field] as ExistingCountryCode)
        : leadData[field]}
    </ReadOnlyRow>
  ) : null
}

interface ILeadDataBlockProps {
  blockFields: LeadField[]
  leadData: LeadDataFieldsFragment
  label: string
}

export const LeadDataBlock: React.FC<ILeadDataBlockProps> = ({
  blockFields,
  leadData,
  label
}: ILeadDataBlockProps) =>
  blockFields.find((f) => leadData[f]) ? (
    <Box sx={{pb: 2, '&:last-child': {pb: 0}}}>
      <ReadOnlyLabel label={label} />
      {blockFields.map((field) => (
        <LeadDataReadOnlyRow key={field} leadData={leadData} field={field} />
      ))}
    </Box>
  ) : null

interface ILeadDataReadOnlyPaperProps
  extends Omit<IReadOnlyPaperProps, 'children'> {
  leadData: LeadDataFieldsFragment
  firstSectionTitle?: string
  action?: React.ReactNode
  id?: string
}

export const LeadDataReadOnlyPaper: React.FC<ILeadDataReadOnlyPaperProps> = ({
  leadData,
  title,
  titleVariant,
  sx,
  firstSectionTitle,
  action,
  id
}: ILeadDataReadOnlyPaperProps) => {
  const {t} = useTranslation()
  return (
    <ReadOnlyPaper
      title={title}
      titleVariant={titleVariant}
      action={action}
      sx={sx}
      id={id}
    >
      {Object.values(leadData).length ? (
        <>
          <LeadDataBlock
            leadData={leadData}
            label={firstSectionTitle || t<string>('Contact person')}
            blockFields={[
              LeadField.Name,
              LeadField.Phone,
              LeadField.Email,
              LeadField.Note
            ]}
          />
          <LeadDataBlock
            leadData={leadData}
            label={t<string>('Company')}
            blockFields={[
              LeadField.CompanyName,
              LeadField.CompanyIdNumber,
              LeadField.TaxId,
              LeadField.VatId
            ]}
          />
          <LeadDataBlock
            leadData={leadData}
            label={t<string>('Billing address')}
            blockFields={[
              LeadField.BillingAddressStreet,
              LeadField.BillingPostalCode,
              LeadField.BillingAddressTown,
              LeadField.BillingAddressCountry
            ]}
          />
          <LeadDataBlock
            leadData={leadData}
            label={t<string>('Delivery address')}
            blockFields={[
              LeadField.DeliveryAddressee,
              LeadField.DeliveryAddressStreet,
              LeadField.DeliveryPostalCode,
              LeadField.DeliveryAddressTown,
              LeadField.DeliveryAddressCountry
            ]}
          />
        </>
      ) : (
        <Typography variant="subtitle2" color="textSecondary">
          {t('No additional information found')}
        </Typography>
      )}
    </ReadOnlyPaper>
  )
}
