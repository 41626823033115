/**
 * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attributes - Check description of target attribute
 */
export enum AttributeAnchorTarget {
  Self = '_self',
  Blank = '_blank',
  Parent = '_parent',
  Top = '_top'
}

export enum EventButtonTarget {
  Top = '_top',
  Blank = '_blank',
  Self = '_self'
}
