import React from 'react'

export enum ThemeType {
  RubyRed = 'rubyRed',
  CrimsonRed = 'crimsonRed',
  OrangeRed = 'orangeRed',
  Coral = 'coral',
  DarkOrange = 'darkOrange',
  BronzeBrown = 'bronzeBrown',
  LimeGreen = 'limeGreen',
  ForestGreen = 'forestGreen',
  SeaGreen = 'seaGreen',
  EmeraldGreen = 'emeraldGreen',
  Teal = 'teal',
  SteelBlue = 'steelBlue',
  DodgerBlue = 'dodgerBlue',
  ElectricIndigo = 'electricIndigo',
  NavyBlue = 'navyBlue',
  DeepPurple = 'deepPurple',
  Purple = 'purple',
  Amethyst = 'amethyst',
  DarkGray = 'darkGray'
}
export enum NotificationType {
  Error = 'error',
  Info = 'info'
}

export interface IInfoNotification {
  id: string
  type: NotificationType.Info
  message: string
}

export interface IErrorNotification {
  id: string
  type: NotificationType.Error
  title?: string
  body?: React.ReactElement
  description?: string
  renderActions?: (onClose: () => void) => React.ReactNode
}

export enum PrimaryImageType {
  None = 'none',
  Poster = 'poster',
  Photo = 'photo'
}
