import {useLazyQuery} from '@apollo/client'
import {noop} from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CheckDiscountCodeForCartQuery,
  CheckDiscountCodeForCartQueryVariables,
  CheckDiscountCodeForTourItemsQuery,
  CheckDiscountCodeForTourItemsQueryVariables,
  Currency,
  ErrorMessages,
  TicketItemFieldsFragment,
  TourItemFieldsFragment
} from '../../__generated__/schema'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../components/types'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {CheckDiscountCodeModal} from './CheckDiscountCodeModal'
import {
  CHECK_DISCOUNT_CODE_FOR_CART,
  CHECK_DISCOUNT_CODE_FOR_TOUR_ITEMS
} from './graphql'
import {SelectItemsForDiscountModal} from './SelectItemsForDiscountModal'
import {useDiscountErrorHandler} from './utils'

type CheckAndApplyDiscountCodeFnProps = {
  items: TicketItemFieldsFragment[] | TourItemFieldsFragment[]
  onSubmit: (
    itemIds: number[],
    discountId: number,
    discountCodeId: number
  ) => Promise<void>
} & (
  | {eventId: number; tourTimeSlotId?: never}
  | {eventId?: never; tourTimeSlotId: number}
)

interface IDiscountCodeContext {
  checkAndApplyDiscountCode: (props: CheckAndApplyDiscountCodeFnProps) => void
  closeSelectItemsForDiscountModal: () => void
}

const DiscountCodeContext = createContext<IDiscountCodeContext>({
  checkAndApplyDiscountCode: noop,
  closeSelectItemsForDiscountModal: noop
})

interface IDiscountCodeContextProviderProps {
  cartId: number
  currency: Currency
  children: React.ReactElement
}

const discountCodeDefaultState = {
  discount: null,
  isSelectItemsForDiscountModalOpen: false,
  isCheckDiscountCodeModalOpen: false,
  items: [],
  onSubmit: () => Promise.resolve()
}

export const DiscountCodeContextProvider: React.FC<IDiscountCodeContextProviderProps> =
  ({cartId, currency, children}: IDiscountCodeContextProviderProps) => {
    const {t} = useTranslation()
    const {setShowBackdrop} = useMutationAssistanceHooks()
    const {invalidCartStateErrorHandler} = useEcommerceErrorHandlers()
    const discountErrorHandler = useDiscountErrorHandler()
    const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
    const [discountCodeState, setDiscountCodeState] = useState<{
      discount:
        | CheckDiscountCodeForTourItemsQuery['checkDiscountCodeForTourItems']
        | null
      errorMessage?: string
      isSelectItemsForDiscountModalOpen: boolean
      isCheckDiscountCodeModalOpen: boolean
      items: TicketItemFieldsFragment[] | TourItemFieldsFragment[]
      eventId?: number
      tourTimeSlotId?: number
      onSubmit: (
        itemIds: number[],
        discountId: number,
        discountCodeId: number
      ) => Promise<void>
    }>(discountCodeDefaultState)
    const [
      checkDiscountCodeForTourItems,
      {loading: isDiscountCodeForTourItemsDataLoading}
    ] = useLazyQuery<
      CheckDiscountCodeForTourItemsQuery,
      CheckDiscountCodeForTourItemsQueryVariables
    >(CHECK_DISCOUNT_CODE_FOR_TOUR_ITEMS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setDiscountCodeState((prevState) => ({
          ...prevState,
          discount: data.checkDiscountCodeForTourItems,
          errorMessage: undefined,
          isSelectItemsForDiscountModalOpen: true,
          isCheckDiscountCodeModalOpen: false
        }))
      },
      onError: (error) => {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({
            error
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.DiscountCodeNotFound
          )
        ) {
          setDiscountCodeState((prevState) => ({
            ...prevState,
            errorMessage: t('Discount code doesn’t exist or is misspelled.')
          }))
        } else {
          discountErrorHandler(error, t('Error while checking discount code'))
        }
      }
    })
    const [
      checkDiscountCodeForTicketItems,
      {loading: isDiscountCodeForTicketItemsDataLoading}
    ] = useLazyQuery<
      CheckDiscountCodeForCartQuery,
      CheckDiscountCodeForCartQueryVariables
    >(CHECK_DISCOUNT_CODE_FOR_CART, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setDiscountCodeState((prevState) => ({
          ...prevState,
          discount: data.checkDiscountCodeForCart,
          errorMessage: undefined,
          isSelectItemsForDiscountModalOpen: true,
          isCheckDiscountCodeModalOpen: false
        }))
      },
      onError: (error) => {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({
            error
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.DiscountCodeNotFound
          )
        ) {
          setDiscountCodeState((prevState) => ({
            ...prevState,
            errorMessage: t('Discount code doesn’t exist or is misspelled.')
          }))
        } else {
          discountErrorHandler(error, t('Error while checking discount code'))
        }
      }
    })
    const handleSubmitCheckDiscountCode = useCallback(
      async (codeName: string) => {
        if (discountCodeState.eventId) {
          await checkDiscountCodeForTicketItems({
            variables: {cartId, eventId: discountCodeState.eventId, codeName}
          })
        }
        if (discountCodeState.tourTimeSlotId) {
          await checkDiscountCodeForTourItems({
            variables: {
              cartId,
              tourTimeSlotId: discountCodeState.tourTimeSlotId,
              discountCodeName: codeName
            }
          })
        }
      },
      [
        cartId,
        checkDiscountCodeForTicketItems,
        checkDiscountCodeForTourItems,
        discountCodeState.eventId,
        discountCodeState.tourTimeSlotId
      ]
    )
    const closeCheckDiscountCodeModal = useCallback(
      () =>
        setDiscountCodeState((prevState) => ({
          ...prevState,
          isCheckDiscountCodeModalOpen: false,
          errorMessage: undefined,
          onSubmit: () => Promise.resolve()
        })),
      []
    )
    const handleCheckAndApplyDiscountCode = useCallback(
      ({
        items,
        eventId,
        tourTimeSlotId,
        onSubmit
      }: CheckAndApplyDiscountCodeFnProps) => {
        setDiscountCodeState((prevState) => ({
          ...prevState,
          items,
          eventId,
          tourTimeSlotId,
          onSubmit,
          isCheckDiscountCodeModalOpen: true
        }))
      },
      []
    )
    const closeSelectItemsForDiscountModal = useCallback(
      () => setDiscountCodeState(discountCodeDefaultState),
      []
    )
    useEffect(() => {
      if (
        isDiscountCodeForTourItemsDataLoading ||
        isDiscountCodeForTicketItemsDataLoading
      ) {
        setShowBackdrop(true)
      } else {
        setShowBackdrop(false)
      }
    }, [
      isDiscountCodeForTicketItemsDataLoading,
      isDiscountCodeForTourItemsDataLoading,
      setShowBackdrop
    ])
    return (
      <DiscountCodeContext.Provider
        value={{
          checkAndApplyDiscountCode: handleCheckAndApplyDiscountCode,
          closeSelectItemsForDiscountModal
        }}
      >
        <CheckDiscountCodeModal
          isOpen={discountCodeState.isCheckDiscountCodeModalOpen}
          onClose={closeCheckDiscountCodeModal}
          isMobile={isMobile}
          onSubmitButtonClick={handleSubmitCheckDiscountCode}
          errorMessage={discountCodeState.errorMessage}
        />
        {discountCodeState.discount && (
          <SelectItemsForDiscountModal
            isOpen={
              discountCodeState.isSelectItemsForDiscountModalOpen &&
              !isDiscountCodeForTourItemsDataLoading &&
              !isDiscountCodeForTicketItemsDataLoading
            }
            onClose={closeSelectItemsForDiscountModal}
            isMobile={isMobile}
            currency={currency}
            discountCode={discountCodeState.discount}
            items={discountCodeState.items}
            onSubmit={discountCodeState.onSubmit}
            tourTimeSlotId={discountCodeState.tourTimeSlotId}
            eventId={discountCodeState.eventId}
          />
        )}
        {children}
      </DiscountCodeContext.Provider>
    )
  }

export const useDiscountCodeContext = () => useContext(DiscountCodeContext)
