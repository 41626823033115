import {
  Fade,
  styled,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  Typography
} from '@mui/material'
import React from 'react'

const DummySpanEnablesToShowTooltipOverDisabledChildren = styled('span')``

export const Tooltip: React.FC<MuiTooltipProps> = ({
  title,
  children,
  ...rest
}: MuiTooltipProps) => (
  <MuiTooltip
    enterDelay={300}
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: 'grey.500'
        }
      }
    }}
    TransitionComponent={Fade}
    TransitionProps={{timeout: 600}}
    title={<Typography variant="body2">{title}</Typography>}
    {...rest}
  >
    <DummySpanEnablesToShowTooltipOverDisabledChildren>
      {children}
    </DummySpanEnablesToShowTooltipOverDisabledChildren>
  </MuiTooltip>
)
