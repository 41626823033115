import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {WebSocketLink} from '@apollo/client/link/ws'
import {getMainDefinition} from '@apollo/client/utilities'
import {SubscriptionClient} from 'subscriptions-transport-ws'
import possibleTypes from '../__generated__/possibleTypes.json'
import {config} from '../config'
import {
  getParsedItemFromStorage,
  SessionStorageKey
} from '../features/hooks/storage'
import {getSentry} from '../sentry'

const wsLink = new WebSocketLink(
  new SubscriptionClient(
    `${config.isProduction ? 'wss://' : 'ws://'}${window.location.host}${
      config.graphqlServerSubscriptionsPath
    }`,
    {
      reconnect: true,
      reconnectionAttempts: 5,
      connectionParams: () => {
        const authContextValue = getParsedItemFromStorage<{eSid: string}>(
          sessionStorage,
          SessionStorageKey.AuthContextValue
        )
        return authContextValue?.eSid
          ? {
              headers: {
                'e-sid': authContextValue.eSid
              }
            }
          : {}
      }
    }
  )
)

const httpLink = createHttpLink({
  uri: config.graphqlServerUrl,
  credentials: 'include'
})

const linkWithESid = setContext((_, {headers}) => {
  const authContextValue = getParsedItemFromStorage<{eSid: string}>(
    sessionStorage,
    SessionStorageKey.AuthContextValue
  )
  if (authContextValue?.eSid) {
    return {
      headers: {
        ...headers,
        'e-sid': authContextValue.eSid
      }
    }
  }
  return {
    headers
  }
})

const errorLink = onError(({graphQLErrors, networkError, operation}) => {
  const Sentry = getSentry()
  if (graphQLErrors) {
    graphQLErrors.map((graphQLError) => {
      const {message, locations, path} = graphQLError
      Sentry.withScope((scope) => {
        scope.setExtras({
          locations,
          path,
          operationName: operation.operationName,
          variables: operation.variables
        })
        Sentry.captureException(new Error(message))
      })
    })
  }

  if (networkError) {
    Sentry.captureException(networkError)
  }
})

const aggregatedHttpLink = errorLink.concat(linkWithESid).concat(httpLink)

const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  aggregatedHttpLink
)

const mergePaginationSuccessResults = (
  existing = {items: []},
  incoming = {items: []}
) => ({
  ...existing,
  ...incoming,
  items: [...existing?.items, ...incoming.items]
})

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          paginatedEventsOnEcommerce: {
            keyArgs: ['filter', 'clientId'],
            merge: mergePaginationSuccessResults
          }
        }
      }
    }
  }),
  connectToDevTools: !config.isProduction
})
