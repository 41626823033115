export enum EventListItemSize {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

export enum EventAvailabilityOption {
  Status = 'status',
  Count = 'count',
  None = 'none'
}
