import {Backdrop, CircularProgress} from '@mui/material'
import {noop} from 'lodash'
import React, {createContext, useContext, useState} from 'react'

const BackdropContext = createContext<[boolean, (newState: boolean) => void]>([
  false,
  noop
])

interface IBackdropProviderProps {
  children: React.ReactElement
}

export const BackdropProvider: React.FC<IBackdropProviderProps> = ({
  children
}: IBackdropProviderProps) => {
  const backdropTuple = useState<boolean>(false)

  return (
    <BackdropContext.Provider value={backdropTuple}>
      {children}
      <Backdrop
        open={backdropTuple[0]}
        timeout={0}
        sx={{
          color: 'background.default',
          zIndex: (theme) => theme.zIndex.modal + 1
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </BackdropContext.Provider>
  )
}

export const useBackdrop = () => useContext(BackdropContext)
