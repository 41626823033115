import {useCallback, useEffect, useState} from 'react'

export enum SessionStorageKey {
  RedeemedVouchers = 'redeemedVouchers',
  AuthContextValue = 'authContextValue',
  ThemeContextValue = 'themeContextValue',
  AppParams = 'appParams'
}

const getItemFromStorage = (storage: Storage, key: string) =>
  storage.getItem(key)

export const getParsedItemFromStorage = <T>(
  storage: Storage,
  key: string
): T | null => {
  const savedItem = getItemFromStorage(storage, key)
  if (savedItem) {
    try {
      return JSON.parse(savedItem)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Invalid item in storage')
      return null
    }
  }
  return null
}

const useStorageState = <T>(
  storage: Storage,
  key: string,
  initialState: T
): [T, (newState: T) => void] => {
  useEffect(() => {
    const savedParsedItem = getParsedItemFromStorage<T>(storage, key)
    if (savedParsedItem === null) {
      storage.setItem(key, JSON.stringify(initialState))
    }
    // eslint-disable-next-line
  }, [])
  const savedParsedItem = getParsedItemFromStorage<T>(storage, key)
  const [state, setState] = useState<T>(savedParsedItem || initialState)

  const setNewState = useCallback(
    (newState: T) => {
      const stringifiedNewState = JSON.stringify(newState)
      const stringifiedStorageState = getItemFromStorage(storage, key)
      if (stringifiedNewState !== stringifiedStorageState) {
        storage.setItem(key, stringifiedNewState)
        setState(newState)
      }
    },
    [key, storage]
  )

  return [state, setNewState]
}

export const useLocalStorageState = <T>(
  key: string,
  initialState: T
): [T, (newState: T) => void] =>
  useStorageState<T>(localStorage, key, initialState)

export const useSessionStorageState = <T>(
  key: string,
  initialState: T
): [T, (newState: T) => void] =>
  useStorageState<T>(sessionStorage, key, initialState)
