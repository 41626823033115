import AddIcon from '@mui/icons-material/Add'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, Button, Chip} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  EventDetailFieldsFragment,
  TourTimeSlotFieldsFragment
} from '../../__generated__/schema'
import {EventPaper} from '../components/molecules/EventPaper'
import {TourPaper} from '../components/molecules/TourPaper'

interface IAddTicketsProps {
  onAddMoreClick: () => void
}

const AddTickets: React.FC<IAddTicketsProps> = ({
  onAddMoreClick
}: IAddTicketsProps) => {
  const {t} = useTranslation()
  return (
    <Box
      minHeight={60}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 3
      }}
    >
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={onAddMoreClick}
      >
        {t('Add tickets')}
      </Button>
    </Box>
  )
}

type ExpiredPaperProps = (
  | {
      event: EventDetailFieldsFragment
      tourTimeSlot?: never
    }
  | {
      event?: never
      tourTimeSlot: TourTimeSlotFieldsFragment
    }
) & {
  isMobile: boolean
}

export const ExpiredPaper: React.FC<ExpiredPaperProps> = ({
  event,
  tourTimeSlot,
  isMobile
}: ExpiredPaperProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const handleMoreInfoClick = useCallback(
    () => (event ? navigate(`${event.id}/eventInfo`) : undefined),
    [event, navigate]
  )
  const handleAddMoreClick = useCallback(
    () =>
      event
        ? navigate(`/event/${event.id}`)
        : tourTimeSlot
        ? navigate(`/tourTimeSlot/${tourTimeSlot.id}`)
        : undefined,
    [event, navigate, tourTimeSlot]
  )
  return event ? (
    <EventPaper
      key={event.id}
      sx={{
        mb: 2,
        ':last-child': {
          mb: 0
        }
      }}
      event={event}
      isMobile={isMobile}
      rightAction={
        <Chip
          label={t('More info')}
          size="small"
          onClick={handleMoreInfoClick}
          icon={<InfoOutlinedIcon />}
          variant="outlined"
        />
      }
    >
      <AddTickets onAddMoreClick={handleAddMoreClick} />
    </EventPaper>
  ) : tourTimeSlot ? (
    <TourPaper
      key={tourTimeSlot.id}
      sx={{
        mb: 2,
        ':last-child': {
          mb: 0
        }
      }}
      tourTimeSlot={tourTimeSlot}
      isMobile={isMobile}
    >
      <AddTickets onAddMoreClick={handleAddMoreClick} />
    </TourPaper>
  ) : null
}
