import {Box, SxProps, Typography} from '@mui/material'
import React from 'react'

export interface IHeaderLayoutProps {
  sx?: SxProps
  title: string
  mobileMoreMenu?: React.ReactNode
  startsAt: React.ReactNode
  venue: React.ReactNode
  distributionLabel?: string
  rightAction?: React.ReactNode
  helpIconButton?: React.ReactNode
  languageSelect?: React.ReactNode
  isMobile: boolean
}

interface IMobileHeaderLayoutProps
  extends Omit<IHeaderLayoutProps, 'helpIconButton' | 'languageSelect'> {}

const MobileHeaderLayout: React.FC<IMobileHeaderLayoutProps> = ({
  sx,
  title,
  mobileMoreMenu,
  startsAt,
  venue,
  distributionLabel,
  rightAction
}: IMobileHeaderLayoutProps) => (
  <Box
    sx={{
      display: 'grid',
      columnGap: 1,
      rowGap: 0.5,
      gridTemplateAreas: `
          "title title moreVert"
          "startsAt startsAt moreVert"
          "venue rightAction rightAction"
          "distributionLabel rightAction rightAction"`,
      ...sx
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{
        gridArea: 'title'
      }}
    >
      {title}
    </Typography>
    <Box
      sx={{
        gridArea: 'moreVert',
        justifySelf: 'end',
        alignSelf: 'start'
      }}
    >
      {mobileMoreMenu}
    </Box>
    <Box sx={{gridArea: 'startsAt'}}>{startsAt}</Box>
    <Typography
      variant="body2"
      sx={{
        gridArea: 'venue',
        alignSelf: 'center'
      }}
      component="div"
    >
      {venue}
    </Typography>
    {distributionLabel && (
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          lineHeight: 'unset'
        }}
      >
        {distributionLabel}
      </Typography>
    )}
    <Box
      sx={{
        gridArea: 'rightAction',
        justifySelf: 'end',
        alignSelf: 'end'
      }}
    >
      {rightAction}
    </Box>
  </Box>
)

interface IDesktopHeaderLayoutProps
  extends Omit<IHeaderLayoutProps, 'mobileMoreMenu'> {}

const DesktopHeaderLayout: React.FC<IDesktopHeaderLayoutProps> = ({
  sx,
  title,
  helpIconButton,
  languageSelect,
  startsAt,
  distributionLabel,
  rightAction,
  venue
}: IDesktopHeaderLayoutProps) => (
  <Box
    sx={{
      display: 'grid',
      ...sx
    }}
  >
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr auto auto',
        alignItems: 'center'
      }}
    >
      <Typography variant="subtitle1" sx={{py: 0.5}}>
        {title}
      </Typography>
      {helpIconButton}
      {languageSelect}
    </Box>
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: `
            "eventInfo rightAction"
            "distributionLabel rightAction"
          `,
        justifyContent: 'space-between',
        gridAutoFlow: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridArea: 'eventInfo',
          pb: 0.5,
          gridAutoFlow: 'column',
          gridTemplateColumns: 'auto auto auto auto 1fr',
          gap: 1
        }}
      >
        {startsAt}
        <Box sx={{display: 'grid', pl: 1.5, gridAutoFlow: 'column', gap: 0.5}}>
          {venue}
        </Box>
      </Box>
      {distributionLabel && (
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{
            gridArea: 'distributionLabel'
          }}
        >
          {distributionLabel}
        </Typography>
      )}
      <Box sx={{gridArea: 'rightAction', pb: 0.5}}>{rightAction}</Box>
    </Box>
  </Box>
)

export const HeaderLayout: React.FC<IHeaderLayoutProps> = ({
  mobileMoreMenu,
  helpIconButton,
  languageSelect,
  ...props
}: IHeaderLayoutProps) =>
  props.isMobile ? (
    <MobileHeaderLayout {...props} mobileMoreMenu={mobileMoreMenu} />
  ) : (
    <DesktopHeaderLayout
      {...props}
      helpIconButton={helpIconButton}
      languageSelect={languageSelect}
    />
  )
