import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import TodayIcon from '@mui/icons-material/Today'
import {Box, SxProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClientShow,
  EventDetailFieldsFragment
} from '../../../__generated__/schema'
import {Tooltip} from '../../../components/atoms/Tooltip'
import {useFormatEventDurationDate} from '../../hooks/formatting'
import {
  useTranslateAgeClassification,
  useTranslateAgeClassificationAbbreviation
} from '../../hooks/translateAgeClassification'
import {
  useTranslateShowFormat,
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMix,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersion,
  useTranslateShowVersionAbbreviation
} from '../../hooks/translateDistributions'
import {useGetTranslatedValue} from '../../hooks/translateLocales'
import {
  useTranslateShowContentDescriptorCode,
  useTranslateShowContentDescriptorCodeAbbreviation
} from '../../hooks/translateShowContentDescriptorCode'

interface IEventStartsAtLabelProps {
  eventStartsAt: EventDetailFieldsFragment['startsAt']
  eventEndsAt: EventDetailFieldsFragment['endsAt']
  sx?: SxProps
}

const EventStartsAtLabel = ({
  sx,
  eventStartsAt,
  eventEndsAt
}: IEventStartsAtLabelProps) => {
  const formatEventDurationDate = useFormatEventDurationDate()
  return (
    <Typography
      variant="body2"
      sx={{
        '&::first-letter': {
          textTransform: 'uppercase'
        },
        ...sx
      }}
    >
      {formatEventDurationDate(eventStartsAt, eventEndsAt)}
    </Typography>
  )
}

export interface IEventHeaderProps {
  event: EventDetailFieldsFragment & {
    show?: Pick<ClientShow, 'contentDescriptorCodes'>
  }
  rightAction?: React.ReactNode
  sx?: SxProps
  isMobile: boolean
  mobileMoreMenu?: React.ReactNode
  helpIconButton?: React.ReactNode
  languageSelect?: React.ReactNode
}

interface IMobileHeaderProps
  extends Omit<IEventHeaderProps, 'helpIconButton' | 'languageSelect'> {}

const MobileEventHeader: React.FC<IMobileHeaderProps> = ({
  event,
  rightAction,
  sx,
  mobileMoreMenu
}: IMobileHeaderProps) => {
  const {formatCode, versionCode, soundMixCode, ageClassificationCode} = event
  const getTranslatedValue = useGetTranslatedValue(
    event.division.client.localeCodes
  )
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translateShowContentDescriptorCodeAbbreviation =
    useTranslateShowContentDescriptorCodeAbbreviation()
  const distributionLabel = [
    formatCode && translateShowFormatAbbreviation(formatCode),
    versionCode && translateShowVersionAbbreviation(versionCode),
    soundMixCode && translateShowSoundMixAbbreviation(soundMixCode),
    ageClassificationCode &&
      translateAgeClassificationAbbreviation(ageClassificationCode),
    event.show?.contentDescriptorCodes &&
      event.show.contentDescriptorCodes.length > 0 &&
      event.show.contentDescriptorCodes
        .map((code) => translateShowContentDescriptorCodeAbbreviation(code))
        .join(', ')
  ]
    .filter(Boolean)
    .join(' • ')

  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 1,
        rowGap: 0.5,
        gridTemplateAreas: `
          "title title moreVert"
          "startsAt startsAt moreVert"
          "venue rightAction rightAction"
          "distributionLabel rightAction rightAction"`,
        ...sx
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          gridArea: 'title'
        }}
      >
        {getTranslatedValue(event.names)}
      </Typography>
      <Box
        sx={{
          gridArea: 'moreVert',
          justifySelf: 'end',
          alignSelf: 'start'
        }}
      >
        {mobileMoreMenu}
      </Box>
      <EventStartsAtLabel
        eventStartsAt={event.startsAt}
        eventEndsAt={event.endsAt}
        sx={{
          gridArea: 'startsAt'
        }}
      />
      <Typography
        variant="body2"
        sx={{
          gridArea: 'venue',
          alignSelf: 'center'
        }}
      >
        {event.venue.name}
      </Typography>
      {distributionLabel && (
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{
            lineHeight: 'unset'
          }}
        >
          {distributionLabel}
        </Typography>
      )}
      <Box
        sx={{
          gridArea: 'rightAction',
          justifySelf: 'end',
          alignSelf: 'end'
        }}
      >
        {rightAction}
      </Box>
    </Box>
  )
}

interface IDesktopHeaderProps
  extends Omit<IEventHeaderProps, 'mobileMoreMenu'> {}

const DesktopEventHeader: React.FC<IDesktopHeaderProps> = ({
  event,
  rightAction,
  sx,
  helpIconButton,
  languageSelect
}: IDesktopHeaderProps) => {
  const {formatCode, versionCode, soundMixCode, ageClassificationCode} = event
  const {t} = useTranslation()
  const getTranslatedValue = useGetTranslatedValue(
    event.division.client.localeCodes
  )
  const translateShowFormat = useTranslateShowFormat()
  const translateShowSoundMix = useTranslateShowSoundMix()
  const translateShowVersion = useTranslateShowVersion()
  const translateAgeClassification = useTranslateAgeClassification()
  const translateShowContentDescriptorCode =
    useTranslateShowContentDescriptorCode()
  const distributionLabel = [
    formatCode && translateShowFormat(formatCode),
    versionCode && translateShowVersion(versionCode),
    soundMixCode && translateShowSoundMix(soundMixCode),
    ageClassificationCode && translateAgeClassification(ageClassificationCode),
    event.show?.contentDescriptorCodes &&
      event.show.contentDescriptorCodes.length > 0 &&
      event.show.contentDescriptorCodes
        .map((code) => translateShowContentDescriptorCode(code))
        .join(', ')
  ]
    .filter(Boolean)
    .join(' • ')
  return (
    <Box
      sx={{
        display: 'grid',
        ...sx
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: '1fr auto auto',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1" sx={{py: 0.5}}>
          {getTranslatedValue(event.names)}
        </Typography>
        {helpIconButton}
        {languageSelect}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `
            "eventInfo rightAction"
            "distributionLabel rightAction"
          `,
          justifyContent: 'space-between',
          gridAutoFlow: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridArea: 'eventInfo',
            pb: 0.5,
            gridAutoFlow: 'column',
            gridTemplateColumns: 'auto auto auto auto 1fr',
            gap: 1
          }}
        >
          <Tooltip title={t('Event start')}>
            <TodayIcon
              sx={{
                color: 'grey.A700'
              }}
              fontSize="small"
            />
          </Tooltip>
          <EventStartsAtLabel
            eventStartsAt={event.startsAt}
            eventEndsAt={event.endsAt}
          />
          <Box
            sx={{display: 'grid', pl: 1.5, gridAutoFlow: 'column', gap: 0.5}}
          >
            <Tooltip title={t('Venue')}>
              <PlaceOutlinedIcon
                sx={{
                  color: 'grey.A700'
                }}
                fontSize="small"
              />
            </Tooltip>
            <Typography variant="body2">{event.venue.name}</Typography>
          </Box>
        </Box>
        {distributionLabel && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              gridArea: 'distributionLabel'
            }}
          >
            {distributionLabel}
          </Typography>
        )}
        <Box sx={{gridArea: 'rightAction', pb: 0.5}}>{rightAction}</Box>
      </Box>
    </Box>
  )
}

export const EventHeader: React.FC<IEventHeaderProps> = ({
  mobileMoreMenu,
  helpIconButton,
  languageSelect,
  ...props
}: IEventHeaderProps) =>
  props.isMobile ? (
    <MobileEventHeader {...props} mobileMoreMenu={mobileMoreMenu} />
  ) : (
    <DesktopEventHeader
      {...props}
      helpIconButton={helpIconButton}
      languageSelect={languageSelect}
    />
  )
