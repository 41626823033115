import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import {Button, ListItemIcon, Menu, MenuItem, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useIsCustomerSignedIn, useSignOutCustomer} from '../../features/auth'
import {useAuthContext} from '../../features/auth/AuthContext'
import {useMutationAssistanceHooks} from '../../features/hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../hooks/state'
import {useIsMediaSize} from '../atoms/WindowInnerWidthContext'
import {MediaSizes} from '../types'

interface IAccountActionsProps {
  onSignInButtonClick: () => void
}

export const AccountActions: React.FC<IAccountActionsProps> = ({
  onSignInButtonClick
}: IAccountActionsProps) => {
  const {t} = useTranslation()
  const isCustomerSignedIn = useIsCustomerSignedIn()
  const {
    value: {customer}
  } = useAuthContext()
  const {
    state: isMenuOpen,
    setTrue: openMenu,
    setFalse: closeMenu
  } = useBooleanState(false)
  const signOutCustomer = useSignOutCustomer()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleCloseMyAccountMenu = useCallback(() => {
    setAnchorEl(null)
    closeMenu()
  }, [closeMenu])
  const handleOpenMyAccountMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      openMenu()
      setAnchorEl(event.currentTarget)
    },
    [openMenu]
  )
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const handleMyProfileClick = useCallback(
    () => navigate('/myAccount'),
    [navigate]
  )
  const handleSignOutClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await signOutCustomer()
      navigate(`/${customer?.client.id}/signedOut`, {replace: true})
    } catch (error) {
      defaultErrorHandler(error, t('Error while signing out customer'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    customer?.client.id,
    defaultErrorHandler,
    navigate,
    setShowBackdrop,
    signOutCustomer,
    t
  ])
  return isCustomerSignedIn ? (
    <>
      <Button
        onClick={handleOpenMyAccountMenu}
        variant="text"
        color="primary"
        startIcon={isMobile ? undefined : <PersonIcon />}
      >
        {isMobile ? <PersonIcon /> : t('My account')}
      </Button>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && isMenuOpen}
        onClose={handleCloseMyAccountMenu}
        MenuListProps={{sx: {width: 280}}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            px: 3,
            py: 2,
            pointerEvents: 'none'
          }}
        >
          <Typography variant="h6">{customer?.lead.data.name}</Typography>
          <Typography variant="caption" color="textSecondary">
            {customer?.username}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleMyProfileClick} sx={{px: 3, py: 2}}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          {t('My profile')}
        </MenuItem>
        <MenuItem onClick={handleSignOutClick} sx={{px: 3, py: 2}}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('Sign out')}
        </MenuItem>
      </Menu>
    </>
  ) : (
    <Button
      onClick={onSignInButtonClick}
      color="primary"
      variant="text"
      startIcon={isMobile ? undefined : <LoginIcon />}
    >
      {isMobile ? <LoginIcon /> : t('Sign in')}
    </Button>
  )
}
