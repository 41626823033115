import {useMutation} from '@apollo/client'
import {Box, Button, Drawer, Link, Typography} from '@mui/material'
import Decimal from 'decimal.js'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {
  SignUpCustomerMutation,
  SignUpCustomerMutationVariables
} from '../../../../__generated__/schema'
import {DrawerTemplateHeader} from '../../../../components/atoms/DrawerTemplateHeader'
import {SimpleDialog} from '../../../../components/atoms/SimpleDialog'
import {DrawerTemplate} from '../../../../components/molecules/DrawerTemplate'
import {useBooleanState} from '../../../../hooks/state'
import {useIsCustomerSignedIn, useSignInCustomer} from '../../../auth'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {useCurrentCart} from '../../atoms/CurrentCartContext'
import {AccountForm} from './AccountForm'
import {SIGN_UP_CUSTOMER} from './graphql'
import {
  AccountFormField,
  IAccountForm,
  transformAccountDataToInput
} from './types'

const CREATE_ACCOUNT_FORM_ID = 'createAccountForm'

interface ICreateAccountDrawerProps {
  onExited: () => void
  onSignInLinkClick: () => void
  clientId?: number
}

export const CreateAccountDrawer: React.FC<ICreateAccountDrawerProps> = ({
  onExited,
  onSignInLinkClick,
  clientId
}: ICreateAccountDrawerProps) => {
  const {t} = useTranslation()
  const [signUpCustomer] =
    useMutation<SignUpCustomerMutation, SignUpCustomerMutationVariables>(
      SIGN_UP_CUSTOMER
    )
  const signInCustomer = useSignInCustomer()
  const {currentCartId} = useCurrentCart()
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const navigate = useNavigate()
  const params = useParams()
  const _clientId = clientId || new Decimal(params.clientId || NaN).toNumber()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const {
    state: isSuccessfulRegistrationDialogOpen,
    setTrue: openSuccessfulRegistrationDialog,
    setFalse: closeSuccessfulRegistrationDialog
  } = useBooleanState(false)
  const isCustomerSignedIn = useIsCustomerSignedIn()
  useEffect(() => {
    openDrawer()
  }, [openDrawer])
  const handleMyProfileButtonClick = useCallback(() => {
    closeSuccessfulRegistrationDialog()
    navigate('/myAccount')
  }, [closeSuccessfulRegistrationDialog, navigate])
  const handleProceedToCartButtonClick = useCallback(() => {
    closeSuccessfulRegistrationDialog()
    navigate(`/${_clientId}/cart`)
  }, [_clientId, closeSuccessfulRegistrationDialog, navigate])
  const handleSubmit = useCallback(
    async (data: IAccountForm) => {
      try {
        setShowBackdrop(true)
        await signUpCustomer({
          variables: {input: transformAccountDataToInput(data, _clientId)}
        })
        await signInCustomer({
          variables: {
            clientId: _clientId,
            username: data[AccountFormField.Email],
            password: data[AccountFormField.Password]
          }
        })
        openSuccessfulRegistrationDialog()
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      _clientId,
      openSuccessfulRegistrationDialog,
      setShowBackdrop,
      signInCustomer,
      signUpCustomer
    ]
  )
  useEffect(() => {
    if (isCustomerSignedIn) {
      openSuccessfulRegistrationDialog()
    }
  }, [isCustomerSignedIn, openSuccessfulRegistrationDialog])
  return (
    <>
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        anchor="right"
        SlideProps={{onExited}}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 480
          }
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Create account')}
            />
          }
          footer={
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                borderTop: `solid ${theme.palette.divider} 1px`
              })}
            >
              <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                <Typography variant="subtitle2">
                  {t('Have an account ?')}
                </Typography>
                <Link
                  component="button"
                  color="primary"
                  variant="subtitle2"
                  underline="none"
                  onClick={onSignInLinkClick}
                >
                  {t('Sign in')}
                </Link>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                form={CREATE_ACCOUNT_FORM_ID}
              >
                {t('Create account')}
              </Button>
            </Box>
          }
        >
          <AccountForm
            formId={CREATE_ACCOUNT_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{
              [AccountFormField.Password]: '',
              [AccountFormField.ConfirmPassword]: '',
              [AccountFormField.WouldLikeToReceiveLatestNews]: false,
              [AccountFormField.Name]: '',
              [AccountFormField.Phone]: '',
              [AccountFormField.Email]: '',
              [AccountFormField.Note]: '',
              [AccountFormField.InternalNote]: '',
              [AccountFormField.CompanyName]: '',
              [AccountFormField.CompanyIdNumber]: '',
              [AccountFormField.VatId]: '',
              [AccountFormField.TaxId]: '',
              [AccountFormField.BillingAddressStreet]: '',
              [AccountFormField.BillingAddressTown]: '',
              [AccountFormField.BillingPostalCode]: '',
              [AccountFormField.BillingAddressCountry]: null,
              [AccountFormField.DeliveryAddressee]: '',
              [AccountFormField.DeliveryAddressStreet]: '',
              [AccountFormField.DeliveryAddressTown]: '',
              [AccountFormField.DeliveryPostalCode]: '',
              [AccountFormField.DeliveryAddressCountry]: null
            }}
          />
        </DrawerTemplate>
      </Drawer>
      <SimpleDialog
        isOpen={isSuccessfulRegistrationDialogOpen}
        title={t('Thank you for joining in')}
        content={t(
          'Your new account was successfully created. You can check your purchase history in account profile.'
        )}
        actions={
          <>
            {currentCartId && _clientId ? (
              <Button onClick={handleProceedToCartButtonClick}>
                {t('Proceed to cart')}
              </Button>
            ) : (
              <Button onClick={closeDrawer}>{t('Got it')}</Button>
            )}
            <Button onClick={handleMyProfileButtonClick}>
              {t('My profile')}
            </Button>
          </>
        }
      />
    </>
  )
}
