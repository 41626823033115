import {useMutation} from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import {Box, Button, Chip, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  AddDiscountToTourItemsMutation,
  AddDiscountToTourItemsMutationVariables,
  RemoveTourItemFromCartMutation,
  RemoveTourItemFromCartMutationVariables,
  TourItemFieldsFragment,
  TourTimeSlotFieldsFragment
} from '../../__generated__/schema'
import {safeSum} from '../../utils/math'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TourPaper} from '../components/molecules/TourPaper'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {useDiscountCodeContext} from './DiscountCodeContext'
import {ADD_DISCOUNT_TO_TOUR_ITEMS, REMOVE_TOUR_ITEM_FROM_CART} from './graphql'
import {TourItem} from './TourItem'
import {useDiscountErrorHandler} from './utils'

interface ICartTourPaperProps {
  tourTimeSlot: TourTimeSlotFieldsFragment
  tourItems: TourItemFieldsFragment[]
  cartId: number
  isMobile: boolean
  onNotInterestedClick: () => void
}

export const CartTourPaper: React.FC<ICartTourPaperProps> = ({
  tourTimeSlot,
  tourItems,
  cartId,
  isMobile,
  onNotInterestedClick
}: ICartTourPaperProps) => {
  const {t} = useTranslation()
  const {updateCurrentCart} = useCurrentCart()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const discountErrorHandler = useDiscountErrorHandler()
  const {closeSelectItemsForDiscountModal, checkAndApplyDiscountCode} =
    useDiscountCodeContext()
  const [removeTourItemFromCart] = useMutation<
    RemoveTourItemFromCartMutation,
    RemoveTourItemFromCartMutationVariables
  >(REMOVE_TOUR_ITEM_FROM_CART)
  const [addDiscountToTourItems] = useMutation<
    AddDiscountToTourItemsMutation,
    AddDiscountToTourItemsMutationVariables
  >(ADD_DISCOUNT_TO_TOUR_ITEMS)
  const translatePrice = useTranslatePrice(tourTimeSlot.client.currency)
  const navigate = useNavigate()
  const total = safeSum(tourItems.map(({price}) => price))
  const handleRemoveButtonClick = useCallback(
    (itemId: number) => async () => {
      try {
        setShowBackdrop(true)
        const {data} = await removeTourItemFromCart({
          variables: {cartId, itemId}
        })
        if (data) {
          updateCurrentCart()
        }
      } catch (error) {
        defaultErrorHandler(error, t('Error while removing tour item'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      cartId,
      defaultErrorHandler,
      removeTourItemFromCart,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )
  const handleAddMoreClick = useCallback(() => {
    navigate(`/tourTimeSlot/${tourTimeSlot.id}`)
  }, [navigate, tourTimeSlot.id])
  const handleSubmitItemsForDiscount = useCallback(
    async (itemIds: number[], discountId: number, discountCodeId: number) => {
      try {
        setShowBackdrop(true)
        await addDiscountToTourItems({
          variables: {
            cartId,
            discountId,
            itemIds,
            discountCodeId
          }
        })
        closeSelectItemsForDiscountModal()
      } catch (error) {
        discountErrorHandler(error, t('Error while adding discount'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addDiscountToTourItems,
      cartId,
      closeSelectItemsForDiscountModal,
      discountErrorHandler,
      setShowBackdrop,
      t
    ]
  )
  const handleOpenDiscountCodeModal = useCallback(() => {
    checkAndApplyDiscountCode({
      items: tourItems,
      tourTimeSlotId: tourTimeSlot.id,
      onSubmit: handleSubmitItemsForDiscount
    })
  }, [
    checkAndApplyDiscountCode,
    handleSubmitItemsForDiscount,
    tourItems,
    tourTimeSlot.id
  ])
  return (
    <TourPaper
      tourTimeSlot={tourTimeSlot}
      isMobile={isMobile}
      sx={{
        mb: 2,
        ':last-child': {
          mb: 0
        }
      }}
    >
      {tourItems.map((tourItem) => (
        <TourItem
          key={tourItem.id}
          tourItem={tourItem}
          isMobile={isMobile}
          sx={{px: 3}}
          getRemoveButtonClickHandler={handleRemoveButtonClick}
          cartId={cartId}
          onIHaveDiscountCodeClick={handleOpenDiscountCodeModal}
        />
      ))}
      <Box
        minHeight={60}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 3
        }}
      >
        <Button
          startIcon={isMobile ? undefined : <AddIcon />}
          variant="contained"
          onClick={handleAddMoreClick}
        >
          {isMobile ? t('Add') : t('Add more')}
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifySelf: 'flex-end',
            gap: 1
          }}
        >
          {tourItems.length > 0 && (
            <Chip
              variant="outlined"
              label={t('Discount code')}
              onClick={handleOpenDiscountCodeModal}
            />
          )}
          {tourItems.length > 0 ? (
            <Typography variant="subtitle2" sx={{fontWeight: 500}}>
              {translatePrice(total)}
            </Typography>
          ) : (
            <Button onClick={onNotInterestedClick}>
              {t('Not interested')}
            </Button>
          )}
        </Box>
      </Box>
    </TourPaper>
  )
}
