import React, {useCallback} from 'react'
import {FieldValues, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {LeadField} from '../../../../__generated__/schema'
import {useIsValidEmail, useIsValidPhone} from '../../../hooks/formsValidations'
import {useTranslateLeadField} from '../../../hooks/translateLeadField'
import {UncontrolledFormTextInput} from '../../molecules/UncontrolledFormTextInput'
import {FormFieldName} from '../../types'
import {AccountFormField} from './types'

export const ContactInformationFormPart = <
  FormValues extends FieldValues = FieldValues
>() => {
  const {t} = useTranslation()
  const translateLeadField = useTranslateLeadField()
  const {errors, control, setValue, getValues} = useFormContext<FormValues>()
  const isValidPhone = useIsValidPhone()
  const isValidEmail = useIsValidEmail()
  const handlePhoneInputOnBlur = useCallback(() => {
    const value = getValues<string, string>(AccountFormField.Phone)
    setValue(AccountFormField.Phone, value.replaceAll(/\s/g, ''), true)
  }, [getValues, setValue])
  return (
    <>
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.Name as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.Name)}
        fullWidth
        validationOptions={{required: true}}
      />
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.Email as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.Email)}
        fullWidth
        validationOptions={{required: true, validate: isValidEmail}}
      />
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.Phone as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.Phone)}
        fullWidth
        validationOptions={{required: true, validate: isValidPhone}}
        onBlur={handlePhoneInputOnBlur}
      />
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.Note as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.Note)}
        fullWidth
        InputProps={{multiline: true, rows: 4}}
        helperText={t(
          'Will be visible for our staff in all your orders and reservations. '
        )}
      />
    </>
  )
}
