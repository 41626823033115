import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton, SvgIconProps, Typography} from '@mui/material'
import React from 'react'

interface IDrawerTemplateHeaderProps {
  onLeftActionClick: () => void
  LeftActionIcon?: React.FC<SvgIconProps>
  title: string
}

export const DrawerTemplateHeader: React.FC<IDrawerTemplateHeaderProps> = ({
  onLeftActionClick,
  LeftActionIcon = CloseIcon,
  title
}: IDrawerTemplateHeaderProps) => (
  <Box
    sx={(theme) => ({
      borderBottom: `solid ${theme.palette.divider} 1px`,
      background: 'background.paper',
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'center',
      px: 2,
      gap: 1
    })}
  >
    <IconButton onClick={onLeftActionClick} color="inherit">
      <LeftActionIcon color="inherit" />
    </IconButton>
    <Typography variant="h6">{title}</Typography>
  </Box>
)
