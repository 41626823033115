import {Box, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'

interface INavigationSectionProps {
  label: string
  id: string
  children: React.ReactNode
  sx?: SxProps<Theme>
}

export const NavigationSection: React.FC<INavigationSectionProps> = ({
  id,
  label,
  children,
  sx
}: INavigationSectionProps) => {
  return (
    <Box sx={sx}>
      <Typography sx={{py: 2, pl: 3}} variant="h6" id={id}>
        {label}
      </Typography>
      {children}
    </Box>
  )
}
