import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import {Box, IconButton, SxProps} from '@mui/material'
import React from 'react'

interface IOutlinedCountControllerProps {
  count: number
  maxCount?: number
  onDecrementButtonClick?: () => void
  onIncrementButtonClick?: () => void
  sx?: SxProps
}

export const OutlinedCountController: React.FC<IOutlinedCountControllerProps> =
  ({
    count,
    maxCount,
    onDecrementButtonClick,
    onIncrementButtonClick,
    sx
  }: IOutlinedCountControllerProps) => (
    <Box
      sx={{
        display: 'inline-grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        border: 1,
        borderRadius: 6,
        borderColor: 'divider',
        ...sx
      }}
    >
      <IconButton
        color="primary"
        onClick={onDecrementButtonClick}
        disabled={count === 0}
      >
        <RemoveCircleIcon />
      </IconButton>
      <Box sx={{px: 1}}>{count}</Box>
      <IconButton
        color="primary"
        onClick={onIncrementButtonClick}
        disabled={maxCount ? count >= maxCount : undefined}
      >
        <AddCircleIcon />
      </IconButton>
    </Box>
  )
