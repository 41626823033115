import {useMutation} from '@apollo/client'
import {Box, Button} from '@mui/material'
import dayjs from 'dayjs'
import Joi from 'joi'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {
  MutationNotifyUserReturnedFromPaymentGatewayArgs,
  NotifyUserReturnedFromPaymentGatewayMutation,
  NotifyUserReturnedFromPaymentGatewayMutationVariables,
  PaymentServiceProvider
} from '../../__generated__/schema'
import {CenteredLayout} from '../../components/atoms/CenteredLayout'
import {Powered} from '../components/atoms/Powered'
import {Warning, WarningColorCombination} from '../components/atoms/Warning'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {RenderOnBackendConfig} from '../components/molecules/RenderOnBackendConfig'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {PageNotFound} from '../PageNotFound'
import {Countdown} from './Countdown'
import {NOTIFY_USER_RETURNED_FROM_PAYMENT_GATEWAY} from './graphql'

const TIMEOUT_PERIOD = 60
const VISIBLE_COUNTDOWN_PERIOD = 55

interface IPaymentProcessingParams
  extends MutationNotifyUserReturnedFromPaymentGatewayArgs {
  cartId: number
}

interface IContentProps {
  params: IPaymentProcessingParams
}
const Content: React.FC<IContentProps> = ({params}: IContentProps) => {
  const {cartId, uuid, hash} = params
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [notifyUserReturnedFromPaymentGateway, {called}] = useMutation<
    NotifyUserReturnedFromPaymentGatewayMutation,
    NotifyUserReturnedFromPaymentGatewayMutationVariables
  >(NOTIFY_USER_RETURNED_FROM_PAYMENT_GATEWAY)
  const navigateToCartSummary = useCallback(() => {
    navigate(`/cartSummary/${uuid}/${hash}`)
  }, [hash, navigate, uuid])
  const {defaultErrorHandler} = useMutationAssistanceHooks()

  const [textInCountdown, setTextInCountdown] = useState<string | null>(null)
  const timeoutDatetime = useRef(
    dayjs().add(TIMEOUT_PERIOD, 'second').toISOString()
  )
  const interval = useRef<null | number>(null)
  useEffect(() => {
    interval.current = setInterval(() => {
      const diff = dayjs().diff(dayjs(timeoutDatetime.current), 'second')
      if (diff < -VISIBLE_COUNTDOWN_PERIOD) {
        // do nothing
      } else if (diff < 0) {
        setTextInCountdown(String(Math.abs(diff)))
      } else {
        setTextInCountdown(null)
        navigateToCartSummary()
      }
    }, 1000) as unknown as number
  }, [navigateToCartSummary])

  useEffect(() => {
    const intervalId = interval.current
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [])

  const notify = useCallback(
    async (
      variables: NotifyUserReturnedFromPaymentGatewayMutationVariables
    ) => {
      try {
        await notifyUserReturnedFromPaymentGateway({
          variables
        })
        navigateToCartSummary()
      } catch (e) {
        if (interval.current) {
          clearInterval(interval.current)
          interval.current = null
        }
        setTextInCountdown(null)
        defaultErrorHandler(e, {
          description: t(
            'Error occurred while calling notifyUserReturnedFromPaymentGateway, try to refresh'
          ),
          renderActions: function renderActions() {
            return (
              <Button
                variant="text"
                onClick={() => {
                  navigate(0)
                }}
              >
                {t('Reload')}
              </Button>
            )
          }
        })
      }
    },
    [
      defaultErrorHandler,
      navigate,
      navigateToCartSummary,
      notifyUserReturnedFromPaymentGateway,
      t
    ]
  )

  useEffect(() => {
    if (!called) {
      notify(params)
    }
  }, [called, notify, params])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (interval.current) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload, {capture: true})

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, {
        capture: true
      })
    }
  }, [])

  return (
    <CenteredLayout
      header={<BaseHeader title={t('Processing cart #{{cartId}}', {cartId})} />}
      childrenSx={{display: 'flex', flexDirection: 'column'}}
    >
      <RenderOnBackendConfig>
        {(data) => (
          <Warning
            title={t(
              'Your payment is being processed, please note your cart number: {{cartId}}.',
              {
                cartId
              }
            )}
            maxWidth="100%"
            sx={{pt: 8}}
            description={t(
              'Please wait for the payment process to complete. You will be redirected in a few seconds. Upon successful payment, your tickets will be sent to your email. If you cannot find your tickets in your inbox, or other folders such as Spam, Junk, Ads, or Social Networks, etc., please contact us via email at {{supportEmail}}',
              {
                supportEmail: data.supportEmail
              }
            )}
            colorCombination={WarningColorCombination.Warning}
            icon={<Countdown text={textInCountdown} />}
          />
        )}
      </RenderOnBackendConfig>
      <Box sx={{flex: 1}} />
      <Powered />
    </CenteredLayout>
  )
}

const paymentProcessingParamsSchema = Joi.object<IPaymentProcessingParams>({
  cartId: Joi.number().positive().integer().required(),
  paymentIntentId: Joi.number().positive().integer().required(),
  paymentServiceProvider: Joi.string()
    .valid(...Object.values(PaymentServiceProvider))
    .required(),
  uuid: Joi.string().required(),
  hash: Joi.string().required()
}).options({stripUnknown: true})

export const PaymentProcessing = () => {
  const params =
    useParams<{
      paymentServiceProvider: string
      uuid: string
      hash: string
      cartId: string
      paymentIntentId: string
    }>()

  const {value, error} = paymentProcessingParamsSchema.validate(params)
  if (error || !value) {
    return <PageNotFound />
  }
  return <Content params={value} />
}
