import EventBusyIcon from '@mui/icons-material/EventBusy'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import {IconButton} from '@mui/material'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import React from 'react'
import {TFunction, useTranslation} from 'react-i18next'
import {
  TourTimeSlotBaseFieldsFragment,
  TourTimeSlotSaleFieldsFragment,
  TourTimeSlotState
} from '../../__generated__/schema'
import {Tooltip} from '../../components/atoms/Tooltip'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {CalendarAlertIcon} from '../../components/icons'
import {MediaSizes} from '../../components/types'
import {TourTimeSlotHeader} from '../components/atoms/TourTimeSlotHeader'
import {Warning, WarningColorCombination} from '../components/atoms/Warning'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {LanguageSelect} from '../components/molecules/LanguageSelect'
import {MobileMoreMenu} from '../components/molecules/MobileMoreMenu'
import {BlankPage} from '../components/organisms/BlankPage'

dayjs.extend(isBetween)

interface IStyledTourTimeSlotHeaderProps {
  tourTimeSlot: TourTimeSlotBaseFieldsFragment
  isMobile: boolean
  rightAction?: React.ReactNode
}

export const StyledTourTimeSlotHeader: React.FC<IStyledTourTimeSlotHeaderProps> =
  ({tourTimeSlot, isMobile, rightAction}: IStyledTourTimeSlotHeaderProps) => {
    const {t} = useTranslation()
    return (
      <TourTimeSlotHeader
        isMobile={isMobile}
        tourTimeSlot={tourTimeSlot}
        rightAction={rightAction}
        sx={{
          backgroundColor: 'background.paper',
          px: 3,
          ...(isMobile
            ? {
                py: 2
              }
            : {
                pt: 1,
                pb: 2
              }),
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`
        }}
        mobileMoreMenu={<MobileMoreMenu />}
        helpIconButton={
          <Tooltip title={t('I need help')}>
            <IconButton color="inherit">
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        }
        languageSelect={
          <Tooltip title={t('Switch language')}>
            <LanguageSelect />
          </Tooltip>
        }
      />
    )
  }
interface IChildrenOnValidTourTimeSlotProps {
  children: React.ReactNode
  tourTimeSlot: TourTimeSlotSaleFieldsFragment & TourTimeSlotBaseFieldsFragment
}

const getValidationResult = ({
  t,
  tourTimeSlot,
  isMobile
}: {
  t: TFunction
  tourTimeSlot: TourTimeSlotSaleFieldsFragment & TourTimeSlotBaseFieldsFragment
  isMobile: boolean
}) => {
  const isEcommerceSaleAndReservationDisabled =
    !tourTimeSlot.isECommerceSaleActive &&
    !tourTimeSlot.isECommerceReservationActive
  const isEcommerceSaleAndReservationOutOfTimespan =
    !dayjs().isBetween(
      tourTimeSlot.eCommerceReservationStartsAt,
      tourTimeSlot.eCommerceReservationEndsAt
    ) &&
    !dayjs().isBetween(
      tourTimeSlot.eCommerceSaleStartsAt,
      tourTimeSlot.eCommerceSaleEndsAt
    )
  const isRetailSaleOrReservationActive =
    tourTimeSlot.isRetailSaleActive || tourTimeSlot.isRetailReservationActive
  const isRetailSaleAndReservationDisabled =
    !tourTimeSlot.isRetailSaleActive && !tourTimeSlot.isRetailReservationActive
  if (tourTimeSlot.state === TourTimeSlotState.Cancelled) {
    return {
      title: t('Tour time slot is canceled'),
      description: t(
        'Tour time slot items can’t be added to cart, because event is canceled.'
      ),
      icon: (
        <EventBusyIcon sx={{width: 48, height: 48, color: 'text.primary'}} />
      ),
      header: (
        <StyledTourTimeSlotHeader
          isMobile={isMobile}
          tourTimeSlot={tourTimeSlot}
        />
      )
    }
  }
  if (tourTimeSlot.state === TourTimeSlotState.Draft) {
    return {
      title: t('Tour time slot not found'),
      description: t(
        'We are sorry, but we are not able to recognise this tour time slot. Please, check whether you have correct link.'
      ),
      icon: (
        <CalendarAlertIcon
          sx={{width: 48, height: 48, color: 'text.primary'}}
        />
      ),
      header: <BaseHeader title={t('Not found')} />
    }
  }
  if (
    (isEcommerceSaleAndReservationDisabled ||
      isEcommerceSaleAndReservationOutOfTimespan) &&
    isRetailSaleOrReservationActive
  ) {
    return {
      title: t('Time slot unavailable'),
      description: t(
        'We are sorry, but time slot settings do not allow to add items to cart right now. Please, come back later and try again, or you can visit us personally during opening hours.'
      ),
      icon: (
        <CalendarAlertIcon
          sx={{width: 48, height: 48, color: 'text.primary'}}
        />
      ),
      header: (
        <StyledTourTimeSlotHeader
          isMobile={isMobile}
          tourTimeSlot={tourTimeSlot}
        />
      )
    }
  }
  if (
    (isEcommerceSaleAndReservationDisabled &&
      isRetailSaleAndReservationDisabled) ||
    isEcommerceSaleAndReservationOutOfTimespan
  ) {
    return {
      title: t('Time slot unavailable'),
      description: t(
        'We are sorry, but time slot settings do not allow to add items to cart right now. Please, come back later and try again.'
      ),
      icon: (
        <CalendarAlertIcon
          sx={{width: 48, height: 48, color: 'text.primary'}}
        />
      ),
      header: (
        <StyledTourTimeSlotHeader
          isMobile={isMobile}
          tourTimeSlot={tourTimeSlot}
        />
      )
    }
  }
  return null
}
export const ChildrenOnValidTourTimeSlot: React.FC<IChildrenOnValidTourTimeSlotProps> =
  ({children, tourTimeSlot}: IChildrenOnValidTourTimeSlotProps) => {
    const {t} = useTranslation()
    const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
    const validationResult = getValidationResult({
      tourTimeSlot,
      t,
      isMobile
    })
    return validationResult ? (
      <BlankPage header={validationResult.header}>
        <Warning
          maxWidth={isMobile ? '100%' : 360}
          title={validationResult.title}
          description={validationResult.description}
          icon={validationResult.icon}
          colorCombination={WarningColorCombination.Warning}
        />
      </BlankPage>
    ) : (
      <>{children}</>
    )
  }
