import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useIsMediaSize} from '../../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../../components/types'
import {Warning, WarningColorCombination} from '../atoms/Warning'
import {BaseHeader} from '../molecules/BaseHeader'
import {BlankPage} from './BlankPage'

export const CartNotFoundBlankPage: React.FC = () => {
  const {t} = useTranslation()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  return (
    <BlankPage header={<BaseHeader title={t('Cart unknown')} />}>
      <Warning
        maxWidth={isMobile ? '100%' : 360}
        title={t('Cart not found')}
        description={
          <Typography>
            {t(
              'We are sorry, but we are not able to recognise any purchase or reservation.'
            )}
          </Typography>
        }
        icon={
          <ProductionQuantityLimitsIcon
            sx={{width: 48, height: 48, color: 'text.primary'}}
          />
        }
        colorCombination={WarningColorCombination.Warning}
      />
    </BlankPage>
  )
}
