import CheckIcon from '@mui/icons-material/Check'
import HourglassBottomTwoToneIcon from '@mui/icons-material/HourglassBottomTwoTone'
import RefreshIcon from '@mui/icons-material/Refresh'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import {Button, CircularProgress, Link, SxProps, Theme} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {CartState, GetBackendConfigQuery} from '../../../__generated__/schema'
import {Warning, WarningColorCombination} from '../atoms/Warning'
import {RenderOnBackendConfig} from './RenderOnBackendConfig'

export interface ICartSummaryWarningProps {
  cartState: CartState
  email?: string | null
  cartId?: number
  paymentId?: number
  sx?: SxProps<Theme>
  onRefetch?: () => void
}

export const CartSummaryWarning: React.FC<ICartSummaryWarningProps> = ({
  cartState,
  email,
  cartId,
  paymentId,
  onRefetch,
  sx
}: ICartSummaryWarningProps) => {
  const {t} = useTranslation()
  switch (cartState) {
    case CartState.Sold:
      return (
        <Warning
          title={
            email
              ? t('Tickets were send to {{email}}', {
                  email
                })
              : t('Payment was successful')
          }
          maxWidth="100%"
          description={
            email
              ? t(
                  'Payment was successful. Please, check your inbox for tickets. If you are not able to find your tickets, please, check also folders like Spam, Junk, Ads, Social networks, etc.. In any case, you can download or resend tickets further bellow on this page.'
                )
              : undefined
          }
          colorCombination={WarningColorCombination.Success}
          icon={<CheckIcon sx={{width: 48, height: 48}} color="inherit" />}
          sx={sx}
        />
      )
    case CartState.Expired:
      return (
        <Warning
          title={t('Your cart has expired')}
          maxWidth="100%"
          description={t(
            'Your order was not completed within the specified time limit. If you are still interested to get tickets, please, repeat your order again.'
          )}
          colorCombination={WarningColorCombination.Error}
          icon={
            <HourglassBottomTwoToneIcon
              sx={{width: 48, height: 48}}
              color="inherit"
            />
          }
          sx={sx}
        />
      )
    case CartState.Reserved:
      return (
        <Warning
          title={
            email
              ? t('Reservation were send to {{email}}', {
                  email
                })
              : t('Reservation was successful')
          }
          maxWidth="100%"
          description={
            email
              ? t(
                  'Reservation was successful. Check your e-mail for more details. If you are not able to find your reservation, please, check also folders such as Spam, Junk, Ads, Social networks, etc.. You can pay or resend reservation further bellow on this page.'
                )
              : undefined
          }
          colorCombination={WarningColorCombination.Info}
          icon={<CheckIcon sx={{width: 48, height: 48}} color="inherit" />}
          sx={sx}
        />
      )
    case CartState.Pending:
      return (
        <RenderOnBackendConfig>
          {(data: GetBackendConfigQuery) => (
            <Warning
              title={
                cartId
                  ? t(
                      'Payment is processing, please note your cart number {{cartId}}.',
                      {
                        cartId
                      }
                    )
                  : t('Payment is processing.')
              }
              maxWidth="100%"
              description={
                email ? (
                  <Trans
                    i18nKey={'Waiting for result of payment process. After...'}
                  >
                    Waiting for result of payment process. After successful
                    payment, your tickets will be sent to{' '}
                    <strong>{{email}}</strong>. If you are not able to find your
                    tickets in inbox, or their folders like Spam, Junk, Ads,
                    Social networks, etc., please contact us by mail on
                    <Link
                      component="a"
                      target="_blank"
                      rel="noopener"
                      href={`mailto:${data.supportEmail}?subject=Payment is pending`}
                      key="0"
                    >
                      {{supportEmail: data.supportEmail}}
                    </Link>
                    .
                  </Trans>
                ) : (
                  t('Waiting for result of payment process')
                )
              }
              colorCombination={WarningColorCombination.Warning}
              icon={<CircularProgress size={48} color="inherit" />}
              sx={sx}
              actions={
                onRefetch && (
                  <Button
                    onClick={onRefetch}
                    variant="contained"
                    startIcon={<RefreshIcon />}
                  >
                    {t('Refresh')}
                  </Button>
                )
              }
            />
          )}
        </RenderOnBackendConfig>
      )
    case CartState.Failed:
      return (
        <Warning
          title={t('Payment was not successful')}
          maxWidth="100%"
          description={
            <Trans i18nKey="FailedCartSummaryDescription">
              Your payment {{paymentId}} has failed with the following reason:{' '}
              <br />
              <i>
                The customer has exceeded the balance or credit limit available
                on their card.
              </i>
              <br />
              Please, repeat your order again.
            </Trans>
          }
          colorCombination={WarningColorCombination.Error}
          icon={
            <SentimentDissatisfiedIcon
              sx={{width: 48, height: 48}}
              color="inherit"
            />
          }
          sx={sx}
        />
      )
    default:
      return <Warning title={'Not implemented'} sx={sx} />
  }
}
