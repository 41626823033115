import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import {Badge, Fab} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {BasicCartFieldsFragment} from '../../../__generated__/schema'

interface ITentativeCurrentCartFabProps {
  currentCart: BasicCartFieldsFragment | null
}

interface ICurrentCartFabProps {
  currentCart: BasicCartFieldsFragment
}

const CurrentCartFab: React.FC<ICurrentCartFabProps> = ({
  currentCart
}: ICurrentCartFabProps) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  return (
    <Fab
      variant="extended"
      color="primary"
      sx={(theme) => ({
        position: 'fixed',
        bottom: theme.spacing(3),
        '@supports (bottom: env(safe-area-inset-bottom))': {
          bottom: (theme) =>
            `calc(${theme.spacing(3)} + env(safe-area-inset-bottom))`
        },
        right: theme.spacing(3),
        display: 'grid',
        gridAutoFlow: 'column',
        gap: 1.5
      })}
      onClick={() => {
        navigate(`/${currentCart.clientId}/cart`)
      }}
    >
      <>
        <Badge
          sx={{'& .MuiBadge-badge': {height: 16, minWidth: 16}}}
          badgeContent={currentCart.items?.length}
          color="secondary"
        >
          <ShoppingCartIcon />
        </Badge>
        <span>{t('Cart')}</span>
      </>
    </Fab>
  )
}

export const TentativeCurrentCartFab: React.FC<ITentativeCurrentCartFabProps> =
  ({currentCart}: ITentativeCurrentCartFabProps) =>
    currentCart && currentCart.items?.length ? (
      <CurrentCartFab currentCart={currentCart} />
    ) : null
