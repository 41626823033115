import {Box} from '@mui/material'
import React from 'react'
import {Loading} from '../atoms/Loading'

interface IDrawerTemplateProps {
  children: React.ReactNode
  header: React.ReactNode
  footer?: React.ReactNode
  isLoading?: boolean
  errorMessage?: string
}

export const DrawerTemplate: React.FC<IDrawerTemplateProps> = ({
  children,
  header,
  footer,
  isLoading,
  errorMessage
}: IDrawerTemplateProps) => (
  <Box
    sx={{
      display: 'grid',
      height: '100%',
      gridTemplateRows: footer ? '64px 1fr 64px' : '64px 1fr'
    }}
  >
    {header}
    {isLoading ? (
      <Loading />
    ) : errorMessage ? (
      <p>{errorMessage}</p>
    ) : (
      <Box
        sx={{
          overflow: 'auto',
          backgroundColor: 'background.default'
        }}
      >
        {children}
      </Box>
    )}
    {!isLoading && !errorMessage && footer}
  </Box>
)
