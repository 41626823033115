import {useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {
  SignInCustomerMutation,
  SignInCustomerMutationVariables,
  SignOutCustomerMutation,
  UpdateSignedInCustomerInput,
  UpdateSignedInCustomerMutation,
  UpdateSignedInCustomerMutationVariables
} from '../__generated__/schema'
import {useAuthContext} from './auth/AuthContext'
import {SIGN_IN_CUSTOMER} from './components/organisms/account/graphql'
import {SIGN_OUT_CUSTOMER, UPDATE_SIGNED_IN_CUSTOMER} from './graphql'

export const useIsCustomerSignedIn = () => {
  const {
    value: {customer}
  } = useAuthContext()
  return Boolean(customer)
}

export const useSignInCustomer = () => {
  const {setCustomer} = useAuthContext()
  const [signInCustomer] = useMutation<
    SignInCustomerMutation,
    SignInCustomerMutationVariables
  >(SIGN_IN_CUSTOMER, {
    onCompleted: (data) => {
      setCustomer(data.signInCustomer)
    }
  })
  return signInCustomer
}

export const useSignOutCustomer = () => {
  const {setCustomer} = useAuthContext()
  const [signOutCustomer] = useMutation<
    SignOutCustomerMutation,
    SignInCustomerMutationVariables
  >(SIGN_OUT_CUSTOMER, {
    onCompleted: (data) => {
      if (data.signOutCustomer) {
        setCustomer(null)
      }
    }
  })
  return signOutCustomer
}

export const useUpdateCustomer = () => {
  const {setCustomer} = useAuthContext()
  const [updateCustomer] = useMutation<
    UpdateSignedInCustomerMutation,
    UpdateSignedInCustomerMutationVariables
  >(UPDATE_SIGNED_IN_CUSTOMER, {
    onCompleted: (data) => setCustomer(data.updateSignedInCustomer)
  })
  return useCallback(
    (input: UpdateSignedInCustomerInput) =>
      updateCustomer({variables: {input}}),
    [updateCustomer]
  )
}
