import {Box, SxProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventSeatType,
  TicketItemFieldsFragment
} from '../../../__generated__/schema'

interface ILabelProps {
  title: string
  label?: string | null
}

const Label: React.FC<ILabelProps> = ({title, label}: ILabelProps) =>
  label ? (
    <>
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="subtitle2">{label}</Typography>
    </>
  ) : null

export interface IFullTicketItemLabelProps {
  eventSeat: Pick<
    TicketItemFieldsFragment['eventSeat'],
    'type' | 'label' | 'row' | 'section' | 'floor'
  >
  sx?: SxProps
}

export const FullTicketItemLabel: React.FC<IFullTicketItemLabelProps> = ({
  eventSeat,
  sx
}: IFullTicketItemLabelProps) => {
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 0.5
      }}
    >
      {[
        <Label
          key={1}
          title={eventSeat.type === EventSeatType.Seat ? t('Seat') : t('Zone')}
          label={eventSeat.label}
        />,
        <Label key={2} title={t('Row')} label={eventSeat.row} />,
        <Label key={3} title={t('Section')} label={eventSeat.section} />,
        <Label key={4} title={t('Floor')} label={eventSeat.floor} />
      ]
        .filter(
          (label) =>
            label.props.label !== null &&
            label.props.label !== undefined &&
            label.props.label !== ''
        )
        .reduce(
          (acc: React.ReactNode, label, currentIndex, array) => [
            acc,
            label,
            currentIndex !== array.length - 1 && (
              <Typography key={currentIndex} variant="subtitle2">
                {'•'}
              </Typography>
            )
          ],
          null
        )}
    </Box>
  )
}
