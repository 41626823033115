import {Chip, Paper, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CountController} from '../atoms/CountController'

interface IZoneListItemProps {
  primaryLabel: string
  secondaryLabel: string
  onAddButtonClick: () => void
  onRemoveButtonClick: () => void
  isSoldOut?: boolean
  isAddButtonDisabled: boolean
  ticketsInCartCount: number
  disabledAddButtonTooltipLabel: string
}

export const ZoneListItem: React.FC<IZoneListItemProps> = ({
  primaryLabel,
  secondaryLabel,
  onAddButtonClick,
  onRemoveButtonClick,
  ticketsInCartCount,
  isSoldOut,
  isAddButtonDisabled,
  disabledAddButtonTooltipLabel
}: IZoneListItemProps) => {
  const {t} = useTranslation()
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'grid',
        gridTemplateAreas: `
          "primaryLabel addButton"
          "secondaryLabel addButton"
        `,
        px: 2,
        py: 1,
        gridTemplateColumns: '1fr auto',
        ...(isSoldOut
          ? {
              color: '#00000061'
            }
          : {})
      }}
    >
      <Typography
        variant="subtitle2"
        color={isSoldOut ? 'inherit' : 'textPrimary'}
      >
        {primaryLabel}
      </Typography>
      <Typography
        variant="caption"
        color={isSoldOut ? 'inherit' : 'textSecondary'}
      >
        {secondaryLabel}
      </Typography>
      {isSoldOut ? (
        <Chip
          sx={{gridArea: 'addButton', color: 'grey.A700', alignSelf: 'center'}}
          label={t('Sold out')}
          size="medium"
        />
      ) : (
        <CountController
          sx={{gridArea: 'addButton'}}
          count={ticketsInCartCount}
          onAddIconButtonClick={onAddButtonClick}
          onRemoveIconButtonClick={onRemoveButtonClick}
          addIconTooltip={
            isAddButtonDisabled
              ? disabledAddButtonTooltipLabel
              : t('Add to cart')
          }
          removeIconTooltip={t('Remove from cart')}
          isAddButtonDisabled={isAddButtonDisabled}
        />
      )}
    </Paper>
  )
}
