import {Button} from '@mui/material'
import dayjs from 'dayjs'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SimpleDialog} from '../../../../components/atoms/SimpleDialog'
import {useBooleanState} from '../../../../hooks/state'

interface ICartExpiredDialogProps {
  expiresAt: string
  onGotItToButtonClick: () => void
}

export const CartExpiredDialog: React.FC<ICartExpiredDialogProps> = ({
  expiresAt,
  onGotItToButtonClick
}: ICartExpiredDialogProps) => {
  const {t} = useTranslation()
  const {
    state: isOpen,
    setFalse: closeDialog,
    setTrue: openDialog
  } = useBooleanState(!dayjs(expiresAt).isAfter(new Date()))
  useEffect(() => {
    let timeout: number
    if (dayjs(expiresAt).isAfter(new Date())) {
      const availableTime = new Date(expiresAt).getTime() - Date.now()
      timeout = window.setTimeout(() => {
        openDialog()
      }, availableTime)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [expiresAt, openDialog])
  return (
    <SimpleDialog
      isOpen={isOpen}
      title={t('Cart expired')}
      content={t(
        'We are sorry, but your cart has not been completed within the specified time limit and expired. Please, try it again from the beginning.'
      )}
      actions={
        <Button
          onClick={() => {
            closeDialog()
            onGotItToButtonClick()
          }}
        >
          {t('Got it')}
        </Button>
      }
    />
  )
}
