import {Box, useTheme} from '@mui/material'
import React from 'react'

interface IDrawerTemplateFooterProps {
  children: React.ReactNode
}

export const DrawerTemplateFooter: React.FC<IDrawerTemplateFooterProps> = ({
  children
}: IDrawerTemplateFooterProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        borderTop: `solid ${theme.palette.divider} 1px`,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        px: 3
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gap: 2
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
