import {Link} from '@mui/material'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'
import {useIsStringWithMinLength} from '../../../../hooks/formsValidations'
import {
  BasicCheckboxDescription,
  UncontrolledFormCheckbox
} from '../../../molecules/UncontrolledFormCheckbox'
import {UncontrolledFormTextInput} from '../../../molecules/UncontrolledFormTextInput'
import {AccountFormField} from '../types'
import {IEditProfileForm} from './types'

export const AccountFormPart: React.FC = () => {
  const {t} = useTranslation()
  const {errors, control} = useFormContext<IEditProfileForm>()
  const isStringWithMinLength6 = useIsStringWithMinLength(6)
  return (
    <>
      <UncontrolledFormTextInput<IEditProfileForm>
        errors={errors}
        control={control}
        name={AccountFormField.Username}
        label={t('Username')}
        helperText={t('You can sign in to account with username.')}
        fullWidth
        validationOptions={{required: true, validate: isStringWithMinLength6}}
        autoComplete="username"
      />
      <UncontrolledFormCheckbox<IEditProfileForm>
        errors={errors}
        control={control}
        name={AccountFormField.WouldLikeToReceiveLatestNews}
        label={t('I would like to receive the latest news')}
        checkboxDescription={
          <BasicCheckboxDescription>
            <Trans i18nKey="I want to be kept informed registration">
              {'I do agree with '}
              <Link color="inherit" href="#" target="_blank" rel="noopener">
                privacy policy
              </Link>
              {' for marketing purposes.'}
            </Trans>
          </BasicCheckboxDescription>
        }
      />
    </>
  )
}
