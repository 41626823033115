import {Box, Divider, Paper, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'

export interface IReadOnlyPaperProps {
  children: React.ReactNode
  title?: string
  titleVariant?: 'subtitle1' | 'h6'
  action?: React.ReactNode
  sx?: SxProps<Theme>
  paperSx?: SxProps<Theme>
  id?: string
}

export const ReadOnlyPaper: React.FC<IReadOnlyPaperProps> = ({
  children,
  title,
  titleVariant = 'subtitle1',
  action,
  id,
  sx,
  paperSx
}: IReadOnlyPaperProps) => (
  <Box id={id} sx={sx}>
    {(title || action) && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pb: 1,
          px: 3
        }}
      >
        <Typography variant={titleVariant}>{title}</Typography>
        {action}
      </Box>
    )}
    <Paper variant="outlined" sx={{px: 3, py: 2, borderRadius: 4, ...paperSx}}>
      {children}
    </Paper>
  </Box>
)

export interface IReadOnlyRowProps {
  children?: React.ReactNode
  label: string
}

export const ReadOnlyRow: React.FC<IReadOnlyRowProps> = ({
  children,
  label
}: IReadOnlyRowProps) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: '96px 1fr',
      gap: 2,
      pb: 1,
      '&:last-child': {
        pb: 0
      }
    }}
  >
    <Typography variant="body2" color="textSecondary" sx={{lineHeight: '24px'}}>
      {label}
    </Typography>
    <Typography variant="body2" component="div" sx={{lineHeight: '24px'}}>
      {children}
    </Typography>
  </Box>
)

export const EmptyReadOnlyRow: React.FC = () => <Box sx={{height: 24}} />

interface IEmptyReadOnlyLabelProps {
  label: string
}

export const ReadOnlyLabel: React.FC<IEmptyReadOnlyLabelProps> = ({
  label
}: IEmptyReadOnlyLabelProps) => (
  <Typography variant="subtitle2" sx={{lineHeight: '24px'}}>
    {label}
  </Typography>
)

interface IReadOnlyInfoColumnProps {
  label: string
  children?: React.ReactNode
}

export const ReadOnlyInfoColumn: React.FC<IReadOnlyInfoColumnProps> = ({
  label,
  children
}: IReadOnlyInfoColumnProps) => (
  <Box>
    <Typography variant="caption" color="textSecondary">
      {label}
    </Typography>
    <Typography variant="subtitle2">{children}</Typography>
  </Box>
)

interface IReadOnlyDividerSeparatedInfoRowProps {
  information: IReadOnlyInfoColumnProps[]
}

export const ReadOnlyDividerSeparatedInfoRow: React.FC<IReadOnlyDividerSeparatedInfoRowProps> =
  ({information}: IReadOnlyDividerSeparatedInfoRowProps) => (
    <Box
      sx={{
        display: 'grid',
        gap: 3,
        gridAutoFlow: 'column',
        alignItems: 'flex-start',
        justifyContent: 'start'
      }}
    >
      {(
        information.filter(
          (i) =>
            i.children !== '' && i.children !== null && i.children !== undefined
        ) as IReadOnlyInfoColumnProps[]
      ).reduce(
        (prev: React.ReactNode, curr, index) => [
          prev,
          prev !== null && (
            <Divider key={`${index}-divider`} orientation="vertical" flexItem />
          ),
          <ReadOnlyInfoColumn key={`${index}-column`} label={curr.label}>
            {curr.children}
          </ReadOnlyInfoColumn>
        ],
        null
      )}
    </Box>
  )
