import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import {Box, IconButton, SxProps} from '@mui/material'
import React from 'react'
import {Tooltip} from './Tooltip'

interface ICountControllerProps {
  count: number
  onRemoveIconButtonClick?: () => void
  onAddIconButtonClick?: () => void
  addIconTooltip: string
  removeIconTooltip: string
  isAddButtonDisabled?: boolean
  sx?: SxProps
}

export const CountController: React.FC<ICountControllerProps> = ({
  count,
  onRemoveIconButtonClick,
  onAddIconButtonClick,
  addIconTooltip,
  removeIconTooltip,
  isAddButtonDisabled,
  sx
}: ICountControllerProps) => {
  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        ...sx
      }}
    >
      {count > 0 && (
        <React.Fragment>
          <Tooltip title={removeIconTooltip}>
            <IconButton color="primary" onClick={onRemoveIconButtonClick}>
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
          <Box sx={{px: 1}}>{count}</Box>
        </React.Fragment>
      )}
      <Tooltip title={addIconTooltip}>
        <IconButton
          color="primary"
          onClick={onAddIconButtonClick}
          disabled={isAddButtonDisabled}
        >
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
