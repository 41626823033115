import {useLazyQuery, useMutation} from '@apollo/client'
import React, {useCallback, useEffect} from 'react'
import {
  GetLeadQuery,
  GetLeadQueryVariables,
  LoginLeadMutation,
  LoginLeadMutationVariables
} from '../../__generated__/schema'
import {Loading} from '../../components/atoms/Loading'
import {CURRENT_CART_EVENTS, CURRENT_CART_ID} from '../localStorageKeys'
import {useAuthContext} from './AuthContext'
import {GET_LEAD, LOGIN_LEAD} from './graphql'

const cleanLocalStorageBeforeLogin = () => {
  localStorage.removeItem(CURRENT_CART_ID)
  localStorage.removeItem(CURRENT_CART_EVENTS)
}

export const Login: React.FC = () => {
  const {
    value: {leadUser},
    setLeadUser
  } = useAuthContext()
  const [
    getLead,
    {loading: isGetLeadLoading, error: getLeadError, called: isGetLeadCalled}
  ] = useLazyQuery<GetLeadQuery, GetLeadQueryVariables>(GET_LEAD)
  const [
    loginLead,
    {
      loading: isLoginLeadLoading,
      error: loginLeadError,
      called: isLoginLeadCalled
    }
  ] = useMutation<LoginLeadMutation, LoginLeadMutationVariables>(LOGIN_LEAD)

  const loadLeadData = useCallback(async () => {
    try {
      const {data: getLeadData} = await getLead()
      if (getLeadData?.me?.isLead) {
        setLeadUser(getLeadData.me)
      } else {
        const {data: loginLeadData} = await loginLead()
        cleanLocalStorageBeforeLogin()
        if (loginLeadData?.loginLead) {
          setLeadUser(
            loginLeadData.loginLead.lead,
            loginLeadData.loginLead.eSid
          )
        }
      }
    } catch (e) {
      // do nothing
    }
  }, [getLead, loginLead, setLeadUser])

  useEffect(() => {
    if (!leadUser && !(isGetLeadCalled || isLoginLeadCalled)) {
      loadLeadData()
    }
  }, [isGetLeadCalled, isLoginLeadCalled, leadUser, loadLeadData])

  if (
    (!isGetLeadCalled && !isLoginLeadCalled) ||
    isGetLeadLoading ||
    isLoginLeadLoading
  ) {
    return <Loading />
  }
  if (getLeadError || loginLeadError) {
    return <div>Error</div>
  }
  return null
}
