import {Box} from '@mui/material'
import React from 'react'
import {CenteredLayout} from '../../../components/atoms/CenteredLayout'
import {Powered} from '../atoms/Powered'

interface IBlankPageProps {
  header: React.ReactNode
  children: React.ReactElement
}

export const BlankPage: React.FC<IBlankPageProps> = ({
  header,
  children
}: IBlankPageProps) => (
  <CenteredLayout header={header}>
    <Box
      sx={{
        display: 'grid',
        height: '100%',
        alignItems: 'center',
        justifyItems: 'center',
        gridTemplateRows: '1fr 1fr'
      }}
    >
      {children}
      <Box sx={{alignSelf: 'end'}}>
        <Powered />
      </Box>
    </Box>
  </CenteredLayout>
)
