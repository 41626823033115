import {SxProps} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetEventDetailQuery} from '../../__generated__/schema'
import {ReadOnlyPaper, ReadOnlyRow} from '../../components/atoms/ReadOnly'

interface IVenueReadOnlyPaperProps {
  event: GetEventDetailQuery['event']
  id?: string
  title?: string
  sx?: SxProps
}

export const VenueReadOnlyPaper: React.FC<IVenueReadOnlyPaperProps> = ({
  event,
  id,
  title,
  sx
}: IVenueReadOnlyPaperProps) => {
  const {t} = useTranslation()
  const hasAddressInformation =
    event.venue.address.complex ||
    event.venue.address.street ||
    event.venue.address.postalCode ||
    event.venue.address.town
  return (
    <ReadOnlyPaper sx={sx} id={id} title={title}>
      <ReadOnlyRow label={t('Venue')}>{event.venue.name}</ReadOnlyRow>
      {hasAddressInformation && (
        <ReadOnlyRow label={t('Address')}>
          {[
            event.venue.address.complex,
            event.venue.address.street,
            event.venue.address.postalCode,
            event.venue.address.town
          ]
            .filter(Boolean)
            .join(', ')}
        </ReadOnlyRow>
      )}
      <ReadOnlyRow label={t('Auditorium')}>{event.auditorium.name}</ReadOnlyRow>
    </ReadOnlyPaper>
  )
}
