import {gql} from '@apollo/client'
import {CUSTOMER_FIELDS} from '../../../graphql'

export const SIGN_UP_CUSTOMER = gql`
  ${CUSTOMER_FIELDS}
  mutation SignUpCustomer($input: CustomerInput!) {
    signUpCustomer(input: $input) {
      ...CustomerFields
    }
  }
`

export const FORGOT_CUSTOMER_PASSWORD = gql`
  mutation ForgotCustomerPassword(
    $username: NonEmptyString!
    $clientId: PositiveInt!
  ) {
    forgotCustomerPassword(username: $username, clientId: $clientId)
  }
`

export const SIGN_IN_CUSTOMER = gql`
  ${CUSTOMER_FIELDS}
  mutation SignInCustomer(
    $username: NonEmptyString!
    $password: NonEmptyString!
    $clientId: PositiveInt!
  ) {
    signInCustomer(
      username: $username
      password: $password
      clientId: $clientId
    ) {
      ...CustomerFields
    }
  }
`
