import dayjs from 'dayjs'

const formatInTwoLetters = (digit: number): string =>
  digit.toString().length === 1 ? `0${digit}` : digit.toString()

export const formatDuration = (datetimeA: string, datetimeB: string) => {
  const dayjsA = dayjs(datetimeA)
  const dayjsB = dayjs(datetimeB)
  if (!dayjsA.isValid() || !dayjsB.isValid()) {
    throw new Error('Invalid datetime')
  }
  const hoursDiff = Math.abs(dayjsB.diff(dayjsA, 'h'))
  const minutesDiff = Math.abs(dayjsB.diff(dayjsA, 'm')) % 60
  const secondsDiff = Math.abs(dayjsB.diff(dayjsA, 's')) % 60
  return hoursDiff === 0
    ? `${formatInTwoLetters(minutesDiff)}:${formatInTwoLetters(secondsDiff)}`
    : `${formatInTwoLetters(hoursDiff)}:${formatInTwoLetters(
        minutesDiff
      )}:${formatInTwoLetters(secondsDiff)}`
}
