import {
  CustomerInput,
  ExistingCountryCode
} from '../../../../__generated__/schema'

export enum AccountFormField {
  Username = 'username',
  Password = 'accountPassword',
  ConfirmPassword = 'accountConfirmPassword',
  WouldLikeToReceiveLatestNews = 'accountWouldLikeToReceiveLatestNews',
  Name = 'accountName',
  Phone = 'accountPhone',
  Email = 'accountEmail',
  Note = 'accountNote',
  InternalNote = 'accountInternalNote',
  CompanyName = 'accountCompanyName',
  CompanyIdNumber = 'accountCompanyIdNumber',
  VatId = 'accountVatId',
  TaxId = 'accountTaxId',
  BillingAddressStreet = 'accountBillingAddressStreet',
  BillingAddressTown = 'accountBillingAddressTown',
  BillingPostalCode = 'accountBillingPostalCode',
  BillingAddressCountry = 'accountBillingAddressCountry',
  DeliveryAddressee = 'accountDeliveryAddressee',
  DeliveryAddressStreet = 'accountDeliveryAddressStreet',
  DeliveryAddressTown = 'accountDeliveryAddressTown',
  DeliveryPostalCode = 'accountDeliveryPostalCode',
  DeliveryAddressCountry = 'accountDeliveryAddressCountry'
}

export interface IAccountForm {
  [AccountFormField.Username]: string
  [AccountFormField.Password]: string
  [AccountFormField.ConfirmPassword]: string
  [AccountFormField.WouldLikeToReceiveLatestNews]: boolean
  [AccountFormField.Name]: string
  [AccountFormField.Phone]: string
  [AccountFormField.Email]: string
  [AccountFormField.Note]: string
  [AccountFormField.InternalNote]: string
  [AccountFormField.CompanyName]: string
  [AccountFormField.CompanyIdNumber]: string
  [AccountFormField.VatId]: string
  [AccountFormField.TaxId]: string
  [AccountFormField.BillingAddressStreet]: string
  [AccountFormField.BillingAddressTown]: string
  [AccountFormField.BillingPostalCode]: string
  [AccountFormField.BillingAddressCountry]: ExistingCountryCode | null
  [AccountFormField.DeliveryAddressee]: string
  [AccountFormField.DeliveryAddressStreet]: string
  [AccountFormField.DeliveryAddressTown]: string
  [AccountFormField.DeliveryPostalCode]: string
  [AccountFormField.DeliveryAddressCountry]: ExistingCountryCode | null
}

export interface ISignInForm {
  [AccountFormField.Username]: string
  [AccountFormField.Password]: string
}

export enum ForgotPasswordFormField {
  Username = 'forgotPasswordUsername'
}

export interface IForgotPasswordForm {
  [ForgotPasswordFormField.Username]: string
}

export interface INewPasswordForm {
  [AccountFormField.Password]: string
  [AccountFormField.ConfirmPassword]: string
}

export const transformAccountDataToInput = (
  data: IAccountForm,
  clientId: number
): CustomerInput => ({
  username: data[AccountFormField.Email],
  password: data[AccountFormField.Password],
  clientId,
  privacyPolicyConsentGranted: Boolean(
    data[AccountFormField.WouldLikeToReceiveLatestNews]
  ),
  leadData: {
    name: data[AccountFormField.Name] || undefined,
    phone: data[AccountFormField.Phone] || undefined,
    email: data[AccountFormField.Email],
    note: data[AccountFormField.Note] || undefined,
    internalNote: data[AccountFormField.InternalNote] || undefined,
    companyName: data[AccountFormField.CompanyName] || undefined,
    companyIdNumber: data[AccountFormField.CompanyIdNumber] || undefined,
    VATId: data[AccountFormField.VatId] || undefined,
    TAXId: data[AccountFormField.TaxId] || undefined,
    billingAddressStreet:
      data[AccountFormField.BillingAddressStreet] || undefined,
    billingAddressTown: data[AccountFormField.BillingAddressTown] || undefined,
    billingPostalCode: data[AccountFormField.BillingPostalCode] || undefined,
    billingAddressCountry:
      data[AccountFormField.BillingAddressCountry] || undefined,
    deliveryAddressee: data[AccountFormField.DeliveryAddressee] || undefined,
    deliveryAddressStreet:
      data[AccountFormField.DeliveryAddressStreet] || undefined,
    deliveryAddressTown:
      data[AccountFormField.DeliveryAddressTown] || undefined,
    deliveryPostalCode: data[AccountFormField.DeliveryPostalCode] || undefined,
    deliveryAddressCountry:
      data[AccountFormField.DeliveryAddressCountry] || undefined
  }
})
