import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {IErrorNotification} from '../../types'

export interface IErrorDialogProps
  extends Omit<IErrorNotification, 'id' | 'type'> {
  onClose: () => void
}

export const ErrorDialog: React.FC<IErrorDialogProps> = ({
  title,
  body,
  description,
  onClose,
  renderActions
}: IErrorDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog open fullWidth maxWidth="xs">
      <DialogTitle>{title || t('Oops something went wrong ...')}</DialogTitle>
      <DialogContent>
        {body || (
          <DialogContentText>
            {description ||
              t(
                'We apologize for the trouble. Please refresh the app and try again.'
              )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {renderActions ? (
          renderActions(onClose)
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              onClose()
              window.location.reload()
            }}
            autoFocus
          >
            {t('Got it')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

interface IErrorDialogActionProps {
  onClose: () => void
}

export const ErrorDialogCloseButton: React.FC<IErrorDialogActionProps> = ({
  onClose
}: IErrorDialogActionProps) => {
  const {t} = useTranslation()
  return (
    <Button variant="contained" onClick={onClose} autoFocus>
      {t('Close')}
    </Button>
  )
}

export const ErrorDialogRefreshButton: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Button
      variant="contained"
      onClick={() => {
        window.location.reload()
      }}
      autoFocus
    >
      {t('Refresh')}
    </Button>
  )
}

interface IErrorDialogRedirectButtonProps {
  onClose: () => void
  to: string
}

export const ErrorDialogRedirectButton: React.FC<IErrorDialogRedirectButtonProps> =
  ({onClose, to}: IErrorDialogRedirectButtonProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    return (
      <Button
        variant="contained"
        onClick={() => {
          onClose()
          navigate(to)
        }}
        autoFocus
      >
        {t('Redirect')}
      </Button>
    )
  }
