import {Divider, Paper, styled, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetCartSummaryQuery} from '../../__generated__/schema'
import {useTranslatePrice} from '../hooks/translateCurrency'

const SpaceBetweenRow = styled('div')`
  display: flex;
  justify-content: space-between;
  min-height: 36px;
  align-items: center;
`

interface ISummaryPaperProps {
  sx?: SxProps<Theme>
  cart: GetCartSummaryQuery['cartSummary']
}

export const SummaryPaper: React.FC<ISummaryPaperProps> = ({
  cart,
  sx
}: ISummaryPaperProps) => {
  const translatePrice = useTranslatePrice(cart.client.currency)
  const {t} = useTranslation()
  return (
    <Paper sx={{borderRadius: 4, ...sx}} variant="outlined">
      <SpaceBetweenRow>
        <Typography variant="subtitle2">{t('Subtotal')}</Typography>
        <Typography variant="subtitle2">
          {translatePrice(cart.price)}
        </Typography>
      </SpaceBetweenRow>
      <SpaceBetweenRow>
        <Typography variant="body2">{t('Payment fee')}</Typography>
        <Typography variant="body2">{translatePrice(0)}</Typography>
      </SpaceBetweenRow>
      <SpaceBetweenRow>
        <Typography variant="body2">{t('Delivery fee')}</Typography>
        <Typography variant="body2">{translatePrice(0)}</Typography>
      </SpaceBetweenRow>
      <SpaceBetweenRow>
        <Typography variant="body2">{t('Processing fee')}</Typography>
        <Typography variant="body2">{translatePrice(0)}</Typography>
      </SpaceBetweenRow>
      <Divider />
      <SpaceBetweenRow sx={{height: 48}}>
        <Typography variant="subtitle1">{t('Total')}</Typography>
        <Typography variant="subtitle1">
          {translatePrice(cart.price)}
        </Typography>
      </SpaceBetweenRow>
    </Paper>
  )
}
