import * as Sentry from '@sentry/browser'
import {config} from './config'

export const isSentryEnabled = !!config.sentryDSN
export const initSentry = () => {
  if (isSentryEnabled) {
    Sentry.init({
      dsn: config.sentryDSN,
      release: `${config.eCommerceProjectName}-${config.eCommerceProjectVersion}`,
      environment: process.env.NODE_ENV
    })
  }
}

export const reportErrorToSentry = (error: any, exceptionSource: string) => {
  if (isSentryEnabled) {
    Sentry.withScope((scope) => {
      scope.setExtra('exception-source', exceptionSource)
      Sentry.captureException(error)
    })
  }
}

export const getSentry = () => Sentry
