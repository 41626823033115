import {FormHelperText, SxProps} from '@mui/material'
import React from 'react'
import {FieldError, ValidationOptions} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

interface IFormSubTextProps {
  error?: FieldError
  validationOptions?: ValidationOptions
  helperText?: string
  sx?: SxProps
}

export const FormSubText: React.FC<IFormSubTextProps> = ({
  error,
  validationOptions,
  helperText,
  sx
}: IFormSubTextProps) => {
  const {t} = useTranslation()
  return (
    <FormHelperText sx={sx}>
      {error
        ? error.type === 'required'
          ? error.message || t('This field is required!')
          : error.message
        : (validationOptions?.required || helperText) &&
          (validationOptions?.required
            ? helperText
              ? t('*Required. {{helperText}}', {
                  helperText,
                  interpolation: {
                    escapeValue: false
                  }
                })
              : t('*Required')
            : helperText)}
    </FormHelperText>
  )
}
