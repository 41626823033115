import {useMutation} from '@apollo/client'
import EditIcon from '@mui/icons-material/Edit'
import {Box, Button, Fab} from '@mui/material'
import Decimal from 'decimal.js'
import React, {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Route, Routes, useNavigate} from 'react-router-dom'
import {
  CheckoutMutation,
  CheckoutMutationVariables,
  CheckoutOptions,
  ErrorMessages,
  GetCurrentCartQuery,
  LeadField,
  PaymentIntentInput,
  PaymentServiceProvider,
  ReserveMutation,
  ReserveMutationVariables
} from '../../__generated__/schema'
import {CenteredLayout} from '../../components/atoms/CenteredLayout'
import {NavigationSection} from '../../components/atoms/NavigationSection'
import {SideNavigationList} from '../../components/atoms/SideNavigationList'
import {SignOutButton} from '../../components/atoms/SignOutButton'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {AccountActions} from '../../components/molecules/AccountActions'
import {MediaSizes} from '../../components/types'
import {AttributeAnchorTarget} from '../../types'
import {replaceUrlByTarget} from '../../utils/navigation'
import {useIsCustomerSignedIn} from '../auth'
import {useAuthContext} from '../auth/AuthContext'
import {useAppParamsContext} from '../components/atoms/AppParamsProvider'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {useLocale} from '../components/atoms/LocaleContext'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {LeadDataReadOnlyPaper} from '../components/molecules/LeadDataReadOnlyPaper'
import {EditProfileDrawer} from '../components/organisms/account/editProfile/EditProfileDrawer'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {SessionStorageKey, useSessionStorageState} from '../hooks/storage'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {
  getGraphQLErrorRelatedToErrorMessage,
  getTicketItemsByEventIdFilter,
  getTourItemsByTourTimeSlotIdFilter,
  isTicketItem,
  isTourItemPropertiesFragment
} from '../utils'
import {CartEventPaper} from './CartEventPaper'
import {CartTourPaper} from './CartTourPaper'
import {ContactInformationContent} from './ContactInformationContent'
import {getIsZeroCartPrice} from './getIsZeroCartPrice'
import {CHECKOUT, RESERVE} from './graphql'
import {PaymentMethodsContent} from './PaymentMethodsContent'
import {contentStateReducer, getDefaultContentState} from './reducer'
import {RemainingLabel} from './RemainingLabel'
import {getSelectedPMRadioOption} from './selectors'
import {SummaryPaper} from './SummaryPaper'
import {
  CartPageFormField,
  ContentActions,
  ContentActionType,
  getLeadDataInput,
  ICartPageForm,
  IContentState,
  PMRadioGroupOption,
  PMRadioGroupOptionType,
  RedeemedVoucher
} from './types'
import {UnsuccessfulPayment} from './UnsuccessfulPayment'
import {VouchersContent} from './VouchersContent'

const FORM_ID = 'cartPageForm'

interface IContentProps {
  cart: GetCurrentCartQuery['cart']
}

const getIsReservationSelected = (
  selectedPMRadioGroupOptionKey: string | null,
  pMRadioGroupOptions: PMRadioGroupOption[]
): boolean => {
  const selectedOption = getSelectedPMRadioOption(
    pMRadioGroupOptions,
    selectedPMRadioGroupOptionKey
  )
  return selectedOption
    ? [
        PMRadioGroupOptionType.Reservation,
        PMRadioGroupOptionType.ReservationForCartWithZeroPrice
      ].includes(selectedOption.type)
    : false
}

const getDefaultValuesForICartPageForm = (
  cart: GetCurrentCartQuery['cart'],
  pMRadioGroupOptions: PMRadioGroupOption[]
): ICartPageForm => {
  const lead =
    cart.lead || (cart.uniqueLeads.length === 1 ? cart.uniqueLeads[0] : null)
  return {
    [CartPageFormField.DoAgreeWithTOS]: false,
    [CartPageFormField.SelectedPMRadioGroupOptionKey]:
      pMRadioGroupOptions[0]?.key || null,
    [LeadField.Name]: lead?.data.name ?? '',
    [LeadField.Phone]: lead?.data.phone ?? '',
    [LeadField.Email]: lead?.data.email ?? '',
    [LeadField.Note]: lead?.data.note ?? '',
    [LeadField.InternalNote]: lead?.data.internalNote ?? '',
    [LeadField.CompanyName]: lead?.data.companyName ?? '',
    [LeadField.CompanyIdNumber]: lead?.data.companyIdNumber ?? '',
    [LeadField.VatId]: lead?.data.VATId ?? '',
    [LeadField.TaxId]: lead?.data.TAXId ?? '',
    [LeadField.BillingAddressStreet]: lead?.data.billingAddressStreet ?? '',
    [LeadField.BillingAddressTown]: lead?.data.billingAddressTown ?? '',
    [LeadField.BillingPostalCode]: lead?.data.billingPostalCode ?? '',
    [LeadField.BillingAddressCountry]: lead?.data.billingAddressCountry ?? null,
    [LeadField.DeliveryAddressee]: lead?.data.deliveryAddressee ?? '',
    [LeadField.DeliveryAddressStreet]: lead?.data.deliveryAddressStreet ?? '',
    [LeadField.DeliveryAddressTown]: lead?.data.deliveryAddressTown ?? '',
    [LeadField.DeliveryPostalCode]: lead?.data.deliveryPostalCode ?? '',
    [LeadField.DeliveryAddressCountry]:
      lead?.data.deliveryAddressCountry ?? null,
    [LeadField.IsPrivacyPolicyConsentGranted]:
      lead?.data.isPrivacyPolicyConsentGranted ?? false
  }
}

const DEFAULT_CHECKOUT_OPTIONS: CheckoutOptions[] = []

const getRemainingCartPrice = (contentState: IContentState) => {
  const remainingPrice = contentState.redeemedVouchers
    .reduce(
      (acc, redeemedVoucher) => acc.minus(redeemedVoucher.credit),
      new Decimal(contentState.cartPrice)
    )
    .toNumber()
  return remainingPrice > 0 ? remainingPrice : 0
}

const getPaymentIntentInputs = ({
  remainingCartPrice,
  selectedPMRadioGroupOption,
  redeemedVouchers
}: {
  selectedPMRadioGroupOption: PMRadioGroupOption
  remainingCartPrice: number
  redeemedVouchers: RedeemedVoucher[]
}): PaymentIntentInput[] => [
  ...redeemedVouchers.map((rv) => ({
    voucherId: rv.voucher.id,
    voucherPinCode: rv.pinCode,
    paymentMethodId: rv.voucher.campaign.ecommercePaymentMethod?.id ?? -1,
    amount: rv.credit
  })),
  ...(!getIsZeroCartPrice(remainingCartPrice) &&
  selectedPMRadioGroupOption.type ===
    PMRadioGroupOptionType.RegularPaymentMethod
    ? [
        {
          amount: remainingCartPrice,
          paymentMethodId: selectedPMRadioGroupOption.eCommercePaymentMethodId
        }
      ]
    : [])
]

const redirectToStatnaPokladnicaGateway = (
  redirectUrl: string,
  target: AttributeAnchorTarget
) => {
  const parsedUrl = new URL(redirectUrl)
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = parsedUrl.origin + parsedUrl.pathname
  form.target = target
  parsedUrl.searchParams.forEach((value, name) => {
    if (value) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = name
      input.value = value
      form.appendChild(input)
    }
  })
  document.body.appendChild(form)
  form.submit()
}

export const Content: React.FC<IContentProps> = ({cart}: IContentProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslatePrice(cart.client.currency)
  const eCommercePaymentMethods = useMemo(
    () => cart.client.eCommercePaymentMethods,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(cart.client.eCommercePaymentMethods)]
  )
  const checkoutOptions: CheckoutOptions[] = useMemo(
    () =>
      cart.checkoutOptions
        ? cart.checkoutOptions.reduce<CheckoutOptions[]>(
            (acc, o) => (o ? [...acc, o] : acc),
            []
          )
        : DEFAULT_CHECKOUT_OPTIONS,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(cart.checkoutOptions)]
  )
  const ticketItems = (cart.items || []).filter(isTicketItem)
  const tourItems = (cart.items || []).filter(isTourItemPropertiesFragment)
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const isCustomerSignedIn = useIsCustomerSignedIn()
  const {
    value: {customer}
  } = useAuthContext()
  const [storedRedeemedVouchers, storeRedeemedVouchers] =
    useSessionStorageState<RedeemedVoucher[]>(
      SessionStorageKey.RedeemedVouchers,
      []
    )
  const [contentState, dispatch] = useReducer<
    Reducer<IContentState, ContentActions>
  >(
    contentStateReducer,
    getDefaultContentState({
      checkoutOptions,
      eCommercePaymentMethods,
      cartPrice: cart.price,
      redeemedVouchers: storedRedeemedVouchers
    })
  )
  useEffect(() => {
    storeRedeemedVouchers(contentState.redeemedVouchers)
  }, [contentState.redeemedVouchers, storeRedeemedVouchers])
  const {pMRadioGroupOptions, doesVoucherSectionExists} = contentState
  const sideNavigationItems = useMemo(
    () => ({
      event: {
        id: 'events',
        label: t('Events')
      },
      paymentMethods: {
        id: 'paymentMethods',
        label: t('Payment methods')
      },
      ...(doesVoucherSectionExists
        ? {
            vouchers: {
              id: 'vouchers',
              label: t('Vouchers')
            }
          }
        : {}),
      contactInformation: {
        id: 'contactInformation',
        label: isCustomerSignedIn ? t('Profile') : t('Contact information')
      },
      summary: {
        id: 'summary',
        label: t('Summary')
      }
    }),
    [doesVoucherSectionExists, isCustomerSignedIn, t]
  )

  const methods = useForm<ICartPageForm>({
    defaultValues: getDefaultValuesForICartPageForm(cart, pMRadioGroupOptions),
    reValidateMode: 'onChange'
  })
  const {handleSubmit, watch, setValue} = methods
  useEffect(() => {
    setValue(
      CartPageFormField.SelectedPMRadioGroupOptionKey,
      pMRadioGroupOptions.length ? pMRadioGroupOptions[0].key : null
    )
  }, [pMRadioGroupOptions, setValue])
  const selectedPMRadioGroupOptionKey = watch(
    CartPageFormField.SelectedPMRadioGroupOptionKey
  )
  const [localeCode] = useLocale()
  const [reserve] =
    useMutation<ReserveMutation, ReserveMutationVariables>(RESERVE)
  const [checkout] =
    useMutation<CheckoutMutation, CheckoutMutationVariables>(CHECKOUT)
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const {invalidCartStateErrorHandler} = useEcommerceErrorHandlers()
  const {
    resetCurrentCart,
    removeCurrentCartEvent,
    currentCartEvents,
    currentCartTourTimeSlots,
    removeCurrentCartTourTimeSlot
  } = useCurrentCart()
  const navigate = useNavigate()
  const handleSubmitReservation = useCallback(
    async (form: ICartPageForm) => {
      setShowBackdrop(true)
      try {
        const {data} = await reserve({
          variables: {
            input: isCustomerSignedIn ? undefined : getLeadDataInput(form),
            cartId: cart.id,
            localeCode
          }
        })
        resetCurrentCart()
        if (data) {
          navigate(`/cartSummary/${data.reserve.uuid}/${data.reserve.hash}`)
        }
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error})
        }
        defaultErrorHandler(error, {description: t('Reservation failed')})
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      reserve,
      isCustomerSignedIn,
      cart.id,
      localeCode,
      resetCurrentCart,
      navigate,
      defaultErrorHandler,
      t,
      invalidCartStateErrorHandler
    ]
  )

  const remainingCartPrice = getRemainingCartPrice(contentState)

  const {appParams} = useAppParamsContext()

  const handleSubmitCheckout = useCallback(
    async (form: ICartPageForm) => {
      setShowBackdrop(true)
      try {
        const selectedPMRadioGroupOption = getSelectedPMRadioOption(
          pMRadioGroupOptions,
          form[CartPageFormField.SelectedPMRadioGroupOptionKey]
        )
        if (selectedPMRadioGroupOption) {
          const selectedECommercePaymentMethod =
            selectedPMRadioGroupOption.type ===
            PMRadioGroupOptionType.RegularPaymentMethod
              ? eCommercePaymentMethods.find(
                  (pm) =>
                    pm.id ===
                    selectedPMRadioGroupOption.eCommercePaymentMethodId
                )
              : null
          const {data} = await checkout({
            variables: {
              input: isCustomerSignedIn ? undefined : getLeadDataInput(form),
              paymentIntentInputs: getPaymentIntentInputs({
                remainingCartPrice,
                selectedPMRadioGroupOption,
                redeemedVouchers: contentState.redeemedVouchers
              }),
              cartId: cart.id,
              localeCode
            }
          })
          if (data && data.checkout.eCommercePaymentRedirectUrl) {
            navigate('/redirecting', {replace: true})
            resetCurrentCart()
            if (
              selectedECommercePaymentMethod?.serviceProvider ===
              PaymentServiceProvider.StatnaPokladnica
            ) {
              redirectToStatnaPokladnicaGateway(
                data.checkout.eCommercePaymentRedirectUrl,
                appParams.gatewayButtonTarget
              )
            } else {
              replaceUrlByTarget(
                data.checkout.eCommercePaymentRedirectUrl,
                appParams.gatewayButtonTarget
              )
            }
          } else if (
            data?.checkout.uuid &&
            data.checkout.hash &&
            selectedPMRadioGroupOption.type ===
              PMRadioGroupOptionType.PaymentForCartWithZeroPrice
          ) {
            navigate(
              `/cartSummary/${data.checkout.uuid}/${data.checkout.hash}`,
              {replace: true}
            )
            resetCurrentCart()
          }
        }
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error})
        } else {
          defaultErrorHandler(error, {description: t('Checkout failed')})
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      pMRadioGroupOptions,
      eCommercePaymentMethods,
      checkout,
      isCustomerSignedIn,
      remainingCartPrice,
      contentState.redeemedVouchers,
      cart.id,
      localeCode,
      navigate,
      resetCurrentCart,
      appParams.gatewayButtonTarget,
      invalidCartStateErrorHandler,
      defaultErrorHandler,
      t
    ]
  )
  const handleSignInButtonClick = useCallback(
    () => navigate('signIn'),
    [navigate]
  )

  const doesRemainingCartPriceEqualZero = getIsZeroCartPrice(remainingCartPrice)

  useEffect(() => {
    dispatch({
      type: ContentActionType.CartChanged,
      payload: {
        eCommercePaymentMethods,
        checkoutOptions: checkoutOptions || [],
        cartPrice: cart.price
      }
    })
  }, [checkoutOptions, eCommercePaymentMethods, dispatch, cart.price])
  const isReservationSelected = getIsReservationSelected(
    selectedPMRadioGroupOptionKey,
    pMRadioGroupOptions
  )
  const handleEditProfileExited = useCallback(
    () => navigate(`/${customer?.clientId}/cart`),
    [customer?.clientId, navigate]
  )
  return (
    <>
      <CenteredLayout
        header={
          <BaseHeader title={t('Cart #{{id}}', {id: cart.id})}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
              <RemainingLabel expiresAt={cart.expiresAt} />
              <AccountActions onSignInButtonClick={handleSignInButtonClick} />
            </Box>
          </BaseHeader>
        }
        leftSidebar={
          <SideNavigationList sx={{pl: 1, pt: 2}} items={sideNavigationItems} />
        }
      >
        <FormContext {...methods}>
          <form
            noValidate
            id={FORM_ID}
            autoComplete="off"
            onSubmit={handleSubmit(
              isReservationSelected
                ? handleSubmitReservation
                : handleSubmitCheckout
            )}
          >
            <NavigationSection
              label={sideNavigationItems.event.label}
              id={sideNavigationItems.event.id}
              sx={{pt: 1}}
            >
              {currentCartEvents.map((event) => (
                <CartEventPaper
                  event={event}
                  key={event.id}
                  cartId={cart.id}
                  isMobile={isMobile}
                  eventTicketItems={ticketItems.filter(
                    getTicketItemsByEventIdFilter(event.id)
                  )}
                  onNotInterestedClick={() => removeCurrentCartEvent(event.id)}
                />
              ))}
              {currentCartTourTimeSlots.map((timeSlot) => (
                <CartTourPaper
                  key={timeSlot.id}
                  tourTimeSlot={timeSlot}
                  tourItems={tourItems.filter(
                    getTourItemsByTourTimeSlotIdFilter(timeSlot.id)
                  )}
                  isMobile={isMobile}
                  cartId={cart.id}
                  onNotInterestedClick={() =>
                    removeCurrentCartTourTimeSlot(timeSlot.id)
                  }
                />
              ))}
            </NavigationSection>
            <NavigationSection
              label={sideNavigationItems.paymentMethods.label}
              id={sideNavigationItems.paymentMethods.id}
              sx={{pt: 4}}
            >
              <PaymentMethodsContent
                pMRadioGroupOptions={pMRadioGroupOptions}
                eCommercePaymentMethods={eCommercePaymentMethods}
                remainingPriceLabel={translatePrice(remainingCartPrice)}
                selectedPMRadioGroupOptionKey={selectedPMRadioGroupOptionKey}
                countryCode={cart.client.countryCode}
              />
            </NavigationSection>
            {sideNavigationItems.vouchers && (
              <NavigationSection
                label={sideNavigationItems.vouchers.label}
                id={sideNavigationItems.vouchers.id}
                sx={{
                  pt: 4
                }}
              >
                <VouchersContent
                  cartId={cart.id}
                  dispatch={dispatch}
                  redeemedVouchers={contentState.redeemedVouchers}
                  isAddVoucherDisabled={
                    isReservationSelected || remainingCartPrice === 0
                  }
                  currency={cart.client.currency}
                />
              </NavigationSection>
            )}
            {isCustomerSignedIn && customer ? (
              <LeadDataReadOnlyPaper
                id={sideNavigationItems.contactInformation.id}
                title={sideNavigationItems.contactInformation.label}
                leadData={customer.lead.data}
                firstSectionTitle={t<string>('Contact information')}
                sx={{display: 'flex', flexDirection: 'column', gap: 1, pt: 4}}
                action={
                  <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                    <SignOutButton />
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => navigate('editProfile')}
                    >
                      {t('Edit')}
                    </Button>
                  </Box>
                }
              />
            ) : (
              <NavigationSection
                label={sideNavigationItems.contactInformation.label}
                id={sideNavigationItems.contactInformation.id}
                sx={{
                  pt: 4
                }}
              >
                <ContactInformationContent
                  sx={{
                    '& > *': {
                      mb: 2
                    },
                    '& > *:last-child': {
                      mb: 0
                    }
                  }}
                  leadOptions={
                    selectedPMRadioGroupOptionKey === null
                      ? []
                      : isReservationSelected
                      ? cart.client.eCommerceReservationLeadOptions
                      : cart.client.eCommerceLeadOptions
                  }
                />
              </NavigationSection>
            )}
            <NavigationSection
              label={sideNavigationItems.summary.label}
              id={sideNavigationItems.summary.id}
              sx={{pt: 4, pb: 10}}
            >
              <SummaryPaper
                cart={cart}
                leadOptions={
                  selectedPMRadioGroupOptionKey === null
                    ? []
                    : isReservationSelected
                    ? cart.client.eCommerceReservationLeadOptions
                    : cart.client.eCommerceLeadOptions
                }
                sx={{
                  px: 3,
                  py: 1.5
                }}
                isCustomerSignedIn={isCustomerSignedIn}
              />
            </NavigationSection>
            <Fab
              sx={(theme) => ({
                position: 'fixed',
                right: theme.spacing(3),
                bottom: theme.spacing(3)
              })}
              variant="extended"
              type="submit"
              form={FORM_ID}
              color="primary"
              size="large"
            >
              {isReservationSelected
                ? t('Book now')
                : doesRemainingCartPriceEqualZero
                ? t('Get now')
                : t('Pay now')}
            </Fab>
          </form>
        </FormContext>
      </CenteredLayout>
      <Routes>
        {customer && (
          <Route
            path="editProfile"
            element={
              <EditProfileDrawer
                onExited={handleEditProfileExited}
                customer={customer}
              />
            }
          />
        )}
      </Routes>
      <UnsuccessfulPayment paymentIntents={cart.paymentIntents} />
    </>
  )
}
