import {useTranslation} from 'react-i18next'
import {ShowTypeCode} from '../../__generated__/schema'

export const useTranslateShowType = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowTypeCode, string> = {
    [ShowTypeCode.Movie]: t<string>('ShowTypeCode->Movie'),
    [ShowTypeCode.Theatre]: t<string>('ShowTypeCode->Theatre'),
    [ShowTypeCode.Music]: t<string>('ShowTypeCode->Music'),
    [ShowTypeCode.Education]: t<string>('ShowTypeCode->Education'),
    [ShowTypeCode.Exhibition]: t<string>('ShowTypeCode->Exhibition'),
    [ShowTypeCode.Festival]: t<string>('ShowTypeCode->Festival'),
    [ShowTypeCode.Entertainment]: t<string>('ShowTypeCode->Entertainment'),
    [ShowTypeCode.Sport]: t<string>('ShowTypeCode->Sport'),
    [ShowTypeCode.Experience]: t<string>('ShowTypeCode->Experience')
  }
  return (code: ShowTypeCode) => translatedCodes[code]
}
