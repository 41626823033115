import React from 'react'
import {CustomerFieldsFragment} from '../../__generated__/schema'
import {useAuthContext} from '../../features/auth/AuthContext'
import {PageNotFound} from '../../features/PageNotFound'

interface IChildrenOnSignedInCustomerProps {
  children: (customer: CustomerFieldsFragment) => React.ReactNode
}

export const ChildrenOnSignedInCustomer: React.FC<IChildrenOnSignedInCustomerProps> =
  ({children}: IChildrenOnSignedInCustomerProps) => {
    const {
      value: {customer}
    } = useAuthContext()
    return customer ? <>{children(customer)}</> : <PageNotFound />
  }
