import LogoutIcon from '@mui/icons-material/Logout'
import {Button, IconButton} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useSignOutCustomer} from '../../features/auth'
import {useAuthContext} from '../../features/auth/AuthContext'
import {useMutationAssistanceHooks} from '../../features/hooks/mutationAssistanceHooks'
import {MediaSizes} from '../types'
import {useIsMediaSize} from './WindowInnerWidthContext'

export const SignOutButton: React.FC = () => {
  const {t} = useTranslation()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const signOutCustomer = useSignOutCustomer()
  const navigate = useNavigate()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const {
    value: {customer}
  } = useAuthContext()
  const handleSignOutButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await signOutCustomer()
      navigate(`/${customer?.client.id}/signedOut`, {replace: true})
    } catch (error) {
      defaultErrorHandler(error, t('Error while signing out customer'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    customer?.client.id,
    defaultErrorHandler,
    navigate,
    setShowBackdrop,
    signOutCustomer,
    t
  ])
  return isMobile ? (
    <IconButton onClick={handleSignOutButtonClick} color="primary">
      <LogoutIcon />
    </IconButton>
  ) : (
    <Button onClick={handleSignOutButtonClick} startIcon={<LogoutIcon />}>
      {t('Sign out')}
    </Button>
  )
}
