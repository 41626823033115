import {useQuery} from '@apollo/client'
import {Box, GlobalStyles, Typography} from '@mui/material'
import {pick} from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  ECommerceLocaleCode,
  ErrorMessages,
  EventState,
  GetEventsListDataQuery,
  GetEventsListDataQueryVariables
} from '../../__generated__/schema'
import {CenteredLayout} from '../../components/atoms/CenteredLayout'
import {LazyLoadingList} from '../../components/atoms/LazyLoadingList'
import {RenderOnData} from '../../components/molecules/RenderOnData'
import {useAppParamsContext} from '../components/atoms/AppParamsProvider'
import {AppBackgroundColor} from '../components/atoms/AppParamsProvider/types'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from '../components/atoms/CurrentCartFab'
import {useLocale} from '../components/atoms/LocaleContext'
import {isEcommerceLocaleCode} from '../hooks/translateLocales'
import {PageNotFound} from '../PageNotFound'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {EventListItem, useGetEventListItemSize} from './EventListItem'
import {GET_EVENTS_LIST_DATA} from './graphql'
import {IEventsListProps, withEventListProps} from './withEventListProps'

const transparentBackground = (
  <GlobalStyles
    styles={{
      body: {
        backgroundColor: 'transparent'
      }
    }}
  />
)

const EventsList: React.FC<IEventsListProps> = ({
  clientId,
  searchParams
}: IEventsListProps) => {
  const {t} = useTranslation()
  const {appParams} = useAppParamsContext()
  const [, changeLocale] = useLocale()
  const {currentCart} = useCurrentCart()
  const navigate = useNavigate()
  const {data, loading, error, fetchMore} = useQuery<
    GetEventsListDataQuery,
    GetEventsListDataQueryVariables
  >(GET_EVENTS_LIST_DATA, {
    notifyOnNetworkStatusChange: true,
    variables: {
      clientId,
      filter: {
        ...pick(searchParams, [
          'divisionId',
          'venueId',
          'from',
          'to',
          'hasText',
          'typeCode',
          'ageClassificationCode',
          'formatCode',
          'soundMixCode',
          'versionCode',
          'showId',
          'eventCategoryId',
          'marketingLabelId'
        ]),
        state: EventState.Published,
        showOnWebsiteAndApi: true,
        isAvailableOnEcommerce: true
      },
      paginationInput: {limit: searchParams.limit, offset: 0}
    }
  })
  useEffect(() => {
    const clientLocale = data?.client.localeCodes[0] || ECommerceLocaleCode.En
    if (searchParams.localeCode) {
      changeLocale(searchParams.localeCode)
    } else {
      changeLocale(
        isEcommerceLocaleCode(clientLocale)
          ? clientLocale
          : ECommerceLocaleCode.En
      )
    }
  }, [changeLocale, data, data?.client.localeCodes, searchParams.localeCode])
  const itemSize = useGetEventListItemSize()
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (
      !loading &&
      data &&
      data?.paginatedEventsOnEcommerce.pagination.hasMore
    ) {
      // noinspection JSIgnoredPromiseFromCall
      fetchMore({
        variables: {
          paginationInput: {
            offset: data?.paginatedEventsOnEcommerce.pagination.offset ?? 0,
            limit: data?.paginatedEventsOnEcommerce.pagination.limit ?? 10
          }
        }
      })
    }
  }, [data, fetchMore, loading])
  const handleEventClick = useCallback(
    (id: number) => () => {
      if (appParams.eventOverviewEnabled) {
        navigate(`/event/${id}/overview`)
      }
    },
    [appParams.eventOverviewEnabled, navigate]
  )
  return (
    <>
      {appParams.backgroundColor === AppBackgroundColor.Transparent
        ? transparentBackground
        : null}
      <RenderOnData<GetEventsListDataQuery>
        loading={data === undefined && loading}
        error={error}
        data={data}
        {...(getGraphQLErrorRelatedToErrorMessage(
          error ?? null,
          ErrorMessages.ClientNotFound
        )
          ? {
              errorElement: <PageNotFound />
            }
          : {
              errorMessage: t<string>('Loading events failed')
            })}
      >
        {({paginatedEventsOnEcommerce, client}) =>
          paginatedEventsOnEcommerce.items.length > 0 ? (
            <LazyLoadingList
              sx={{pt: 2, pb: 2}}
              isLoadingMore={Boolean(data && loading)}
              onScrolledNearTheEndOfTheList={
                handleScrolledNearTheEndOfTheLayout
              }
            >
              {paginatedEventsOnEcommerce.items.map((item, index) => (
                <Box
                  key={`${index}-${item.id}`}
                  sx={{
                    maxWidth: 1019,
                    mx: 'auto',
                    pb: 2,
                    '&:last-of-type': {pb: 0},
                    cursor: appParams.eventOverviewEnabled ? 'pointer' : 'auto'
                  }}
                  onClick={handleEventClick(item.id)}
                >
                  <EventListItem
                    size={itemSize}
                    item={item}
                    primaryImageType={searchParams.primaryImageType}
                    clientLocales={client.localeCodes}
                    eventButtonTarget={appParams.eventButtonTarget}
                    eventAvailabilityOption={searchParams.showEventAvailability}
                  />
                </Box>
              ))}
            </LazyLoadingList>
          ) : (
            <CenteredLayout>
              <Box
                sx={{
                  display: 'grid',
                  height: '100%',
                  alignItems: 'center',
                  justifyItems: 'center',
                  gridTemplateRows: '1fr 1fr'
                }}
              >
                <Typography variant="h6">
                  {t('Not events have been found')}
                </Typography>
              </Box>
            </CenteredLayout>
          )
        }
      </RenderOnData>
      {appParams.showCartButton && (
        <TentativeCurrentCartFab currentCart={currentCart} />
      )}
    </>
  )
}

export default withEventListProps(EventsList)
