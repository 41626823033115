import {ThemeProvider as MuiThemeProvider} from '@mui/material'
import {createTheme, Theme, ThemeOptions} from '@mui/material/styles'
import {merge} from 'lodash'
import React, {useEffect, useState} from 'react'

import {useSearchParams} from 'react-router-dom'
import {ThemeType} from '../../types'
import {useThemeContextProvider} from './ThemeContext'

interface IThemeProviderProps {
  children: React.ReactNode
}

export const basicThemeOptions: ThemeOptions = {
  palette: {
    background: {
      default: '#fafafa'
    },
    info: {
      main: '#367BF5',
      light: '#E5EFFF'
    },
    error: {
      main: '#EA3D2F',
      light: '#FEE4E2'
    },
    success: {
      main: '#2FA84F',
      light: '#DCF7E3'
    },
    warning: {
      main: '#F3AA18',
      light: '#FCF3D7'
    },
    divider: '#E0E0E0'
  },
  typography: {
    subtitle1: {
      fontWeight: 500
    }
  }
}

export const getThemeOptions = (themeType: ThemeType | null): ThemeOptions => {
  switch (themeType) {
    case ThemeType.RubyRed:
      return merge(
        {
          palette: {
            primary: {
              main: '#E6007A'
            },
            secondary: {
              main: '#920266'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.CrimsonRed:
      return merge(
        {
          palette: {
            primary: {
              main: '#DD292C'
            },
            secondary: {
              main: '#9D070F'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.OrangeRed:
      return merge(
        {
          palette: {
            primary: {
              main: '#FF4500'
            },
            secondary: {
              main: '#B51E00'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.Coral:
      return merge(
        {
          palette: {
            primary: {
              main: '#FF7F50'
            },
            secondary: {
              main: '#BF491E'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.DarkOrange:
      return merge(
        {
          palette: {
            primary: {
              main: '#FF8C00'
            },
            secondary: {
              main: '#DE4B00'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.BronzeBrown:
      return merge(
        {
          palette: {
            primary: {
              main: '#AB9159'
            },
            secondary: {
              main: '#78664A'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.LimeGreen:
      return merge(
        {
          palette: {
            primary: {
              main: '#69B92E'
            },
            secondary: {
              main: '#026F01'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.ForestGreen:
      return merge(
        {
          palette: {
            primary: {
              main: '#1D864B'
            },
            secondary: {
              main: '#36B96D'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.SeaGreen:
      return merge(
        {
          palette: {
            primary: {
              main: '#26A65B'
            },
            secondary: {
              main: '#006330'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.EmeraldGreen:
      return merge(
        {
          palette: {
            primary: {
              main: '#38A867'
            },
            secondary: {
              main: '#1D643A'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.Teal:
      return merge(
        {
          palette: {
            primary: {
              main: '#1DA59E'
            },
            secondary: {
              main: '#004c42'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.SteelBlue:
      return merge(
        {
          palette: {
            primary: {
              main: '#218DC7'
            },
            secondary: {
              main: '#155C91'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.DodgerBlue:
      return merge(
        {
          palette: {
            primary: {
              main: '#1471EB'
            },
            secondary: {
              main: '#1F3EB9'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.ElectricIndigo:
      return merge(
        {
          palette: {
            primary: {
              main: '#4E13FF'
            },
            secondary: {
              main: '#7488FF'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.NavyBlue:
      return merge(
        {
          palette: {
            primary: {
              main: '#000080'
            },
            secondary: {
              main: '#2B8ACF'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.Purple:
      return merge(
        {
          palette: {
            primary: {
              main: '#9D38B4'
            },
            secondary: {
              main: '#4D228D'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.Amethyst:
      return merge(
        {
          palette: {
            primary: {
              main: '#9B51E0'
            },
            secondary: {
              main: '#4F00B5'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.DarkGray:
      return merge(
        {
          palette: {
            primary: {
              main: '#333333'
            },
            secondary: {
              main: '#8B8B8B'
            }
          }
        },
        basicThemeOptions
      )
    case ThemeType.DeepPurple:
    default:
      return merge(
        {
          palette: {
            primary: {
              main: '#6A1B9A'
            },
            secondary: {
              main: '#BA68C8'
            }
          }
        },
        basicThemeOptions
      )
  }
}

export const ThemeProvider: React.FC<IThemeProviderProps> = ({
  children
}: IThemeProviderProps) => {
  const [searchParams] = useSearchParams()
  const {theme: themeContext, setTheme: setThemeContext} =
    useThemeContextProvider()
  const themeType = searchParams.get('theme') as ThemeType | null
  const [theme, setTheme] = useState<Theme>(
    createTheme(getThemeOptions(themeType))
  )
  useEffect(() => {
    setTheme(createTheme(getThemeOptions(themeContext)))
  }, [themeType, themeContext])

  useEffect(() => {
    if (themeType) {
      setThemeContext(themeType)
    }
  }, [setThemeContext, themeType])

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
