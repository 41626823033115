import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {Chip, SxProps, Theme} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  TourItemFieldsFragment,
  TourTimeSlotFieldsFragment
} from '../../../__generated__/schema'
import {TourItem} from '../../cartSummary/TourItem'
import {useTranslatePrice} from '../../hooks/translateCurrency'
import {TourPaper} from './TourPaper'

export const tourSummaryPaperSx: SxProps<Theme> = {
  mb: 2,
  ':last-child': {
    mb: 0
  }
}

interface ITourSummaryPaperProps {
  children: React.ReactNode
  sx?: SxProps<Theme>
  isMobile: boolean
  tourItems: TourItemFieldsFragment[]
  topBar?: React.ReactNode
  id?: string
  tourTimeSlot: TourTimeSlotFieldsFragment
}

export const TourSummaryPaper: React.FC<ITourSummaryPaperProps> = ({
  tourTimeSlot,
  children,
  sx,
  isMobile,
  tourItems,
  topBar,
  id
}: ITourSummaryPaperProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslatePrice(tourTimeSlot.client.currency)
  const navigate = useNavigate()
  const handleGetMoreTicketsIconClick = useCallback(
    () => navigate(`/tourTimeSlot/${tourTimeSlot.id}`),
    [tourTimeSlot.id, navigate]
  )
  return (
    <TourPaper
      key={tourTimeSlot.id}
      sx={sx}
      tourTimeSlot={tourTimeSlot}
      isMobile={isMobile}
      id={id}
      topBar={topBar}
      rightAction={
        <Chip
          label={t('Get more tickets')}
          size="small"
          deleteIcon={<OpenInNewIcon />}
          onDelete={handleGetMoreTicketsIconClick}
          onClick={handleGetMoreTicketsIconClick}
          variant="outlined"
        />
      }
    >
      {tourItems.map((tourItem) => (
        <TourItem
          key={tourItem.id}
          tourItem={tourItem}
          translatePrice={translatePrice}
          sx={{px: 3}}
        />
      ))}
      {children}
    </TourPaper>
  )
}
