import {ApolloProvider} from '@apollo/client'
import React from 'react'
import {render} from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import App from './App'
import {init18next} from './i18n'
import reportWebVitals from './reportWebVitals'
import {initSentry} from './sentry'
import {client} from './utils/apollo'

initSentry()
init18next()

const rootEl = document.getElementById('root')
render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  rootEl
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
