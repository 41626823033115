import {useLazyQuery} from '@apollo/client'
import {Button, Dialog, DialogContent, Typography} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  GetVoucherByCodeQuery,
  GetVoucherByCodeQueryVariables
} from '../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../components/atoms/DialogTitleWithCloseButton'
import {PasswordInputAdornment} from '../../components/atoms/PasswordInputAdornment'
import {useBooleanState} from '../../hooks/state'
import {UncontrolledFormTextInput} from '../components/molecules/UncontrolledFormTextInput'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {GET_VOUCHER_BY_CODE} from './graphql'
import {CheckVoucherFormField, ICheckVoucherForm, RedeemData} from './types'

const defaultValues = {
  [CheckVoucherFormField.Code]: '',
  [CheckVoucherFormField.PinCode]: ''
}

interface ICheckVoucherDialogProps {
  isOpen: boolean
  onClose: () => void
  onVoucherGotten: (redeemData: RedeemData) => void
  cartId: number
}

export const CheckVoucherDialog: React.FC<ICheckVoucherDialogProps> = ({
  isOpen,
  onClose,
  onVoucherGotten,
  cartId
}: ICheckVoucherDialogProps) => {
  const {t} = useTranslation()
  const {errors, control, triggerValidation, getValues, watch, setValue} =
    useForm<ICheckVoucherForm>({
      defaultValues,
      reValidateMode: 'onBlur'
    })
  const {
    state: isInvalidVoucherCredentialsErrorVisible,
    setTrue: displayInvalidVoucherCredentialsError,
    setFalse: hideInvalidVoucherCredentialsError
  } = useBooleanState(false)
  const stringifiedForm = JSON.stringify(watch())
  const prevStringifiedFormRef = useRef(JSON.stringify(defaultValues))
  useEffect(() => {
    if (stringifiedForm !== prevStringifiedFormRef.current) {
      if (isInvalidVoucherCredentialsErrorVisible) {
        hideInvalidVoucherCredentialsError()
      }
      prevStringifiedFormRef.current = stringifiedForm
    }
  }, [
    stringifiedForm,
    hideInvalidVoucherCredentialsError,
    isInvalidVoucherCredentialsErrorVisible
  ])
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const [getVoucherByCode] = useLazyQuery<
    GetVoucherByCodeQuery,
    GetVoucherByCodeQueryVariables
  >(GET_VOUCHER_BY_CODE, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  })
  const handleSubmit = useCallback(async () => {
    try {
      const isValid = await triggerValidation()
      if (isValid) {
        const form = getValues()
        setShowBackdrop(true)
        const {data} = await getVoucherByCode({
          variables: {
            cartId,
            pinCode: form[CheckVoucherFormField.PinCode].toUpperCase(),
            code: form[CheckVoucherFormField.Code].toUpperCase()
          }
        })
        if (data) {
          onVoucherGotten({
            voucher: data.voucherByCodeOnECommerceChannel,
            pinCode: form[CheckVoucherFormField.PinCode].toUpperCase()
          })
          onClose()
        }
      }
    } catch (error) {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidVoucherCredentials
        )
      ) {
        displayInvalidVoucherCredentialsError()
      } else {
        defaultErrorHandler(error, {
          description: t('Error while checking voucher')
        })
      }
    } finally {
      setShowBackdrop(false)
    }
  }, [
    defaultErrorHandler,
    displayInvalidVoucherCredentialsError,
    getValues,
    getVoucherByCode,
    onClose,
    onVoucherGotten,
    setShowBackdrop,
    t,
    triggerValidation,
    cartId
  ])
  const {state: isPasswordVisibilityOn, toggle: togglePasswordVisibility} =
    useBooleanState(false)
  const handleCodeInputBlur = useCallback(
    () =>
      setValue(
        CheckVoucherFormField.Code,
        watch(CheckVoucherFormField.Code).replaceAll(/\s/g, '')
      ),
    [setValue, watch]
  )
  const handlePinCodeInputBlur = useCallback(
    () =>
      setValue(
        CheckVoucherFormField.PinCode,
        watch(CheckVoucherFormField.PinCode).replaceAll(/\s/g, '')
      ),
    [setValue, watch]
  )
  return (
    <Dialog onClose={onClose} maxWidth="xs" fullWidth open={isOpen}>
      <DialogTitleWithCloseButton onCloseIconClick={onClose}>
        {t('Check voucher code')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{py: 3, display: 'grid', gap: 2}} dividers>
        {isInvalidVoucherCredentialsErrorVisible && (
          <Typography variant="body1" color="error">
            {t(
              'Voucher code is misspelled or PIN is incorrect for this voucher. Please check for typos and try it again.'
            )}
          </Typography>
        )}
        <UncontrolledFormTextInput<ICheckVoucherForm>
          errors={errors}
          control={control}
          name={CheckVoucherFormField.Code}
          label={t('Code on voucher or gift card')}
          fullWidth
          autoComplete="one-time-code"
          validationOptions={{required: true}}
          helperText={t('Usually placed next to QR / barcode.')}
          onBlur={handleCodeInputBlur}
          inputProps={{style: {textTransform: 'uppercase'}}}
        />
        <UncontrolledFormTextInput<ICheckVoucherForm>
          errors={errors}
          control={control}
          name={CheckVoucherFormField.PinCode}
          label={t('PIN')}
          fullWidth
          validationOptions={{required: true}}
          helperText={t('Usually placed on voucher’s rear side.')}
          autoComplete="one-time-code"
          type={isPasswordVisibilityOn ? 'text' : 'password'}
          onBlur={handlePinCodeInputBlur}
          inputProps={{style: {textTransform: 'uppercase'}}}
          InputProps={{
            endAdornment: (
              <PasswordInputAdornment
                onClick={togglePasswordVisibility}
                isPasswordVisibilityOn={isPasswordVisibilityOn}
              />
            )
          }}
        />
        <Button
          color="primary"
          fullWidth
          variant="contained"
          onClick={handleSubmit}
        >
          {t('Check')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
