import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {Box, IconButton, SxProps} from '@mui/material'
import {CarouselSlideRenderControlProps} from 'nuka-carousel'
import React from 'react'

const HEADER_HEIGHT = 64

const rootSx: SxProps = {
  height: `calc(100vh - ${HEADER_HEIGHT * 2}px)`,
  width: '30vw',
  display: 'grid',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover>$controlArrow': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  }
}

const controlArrowSx: SxProps = {
  width: 48,
  height: 48,
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  }
}

interface ICenterLeftControlsProps
  extends Pick<
    CarouselSlideRenderControlProps,
    'previousSlide' | 'currentSlide'
  > {}

export const CenterLeftControls: React.FC<ICenterLeftControlsProps> = ({
  previousSlide,
  currentSlide
}: ICenterLeftControlsProps) =>
  currentSlide !== 0 ? (
    <Box sx={rootSx} onClick={previousSlide}>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        sx={controlArrowSx}
        onClick={previousSlide}
      >
        <NavigateBeforeIcon />
      </IconButton>
    </Box>
  ) : null

interface ICenterRightControlsProps
  extends Pick<
    CarouselSlideRenderControlProps,
    'nextSlide' | 'currentSlide' | 'slideCount'
  > {}

export const CenterRightControls: React.FC<ICenterRightControlsProps> = ({
  nextSlide: onNextButtonClick,
  currentSlide,
  slideCount
}: ICenterRightControlsProps) =>
  currentSlide < slideCount - 1 ? (
    <Box sx={rootSx} onClick={onNextButtonClick}>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        sx={{...controlArrowSx, justifySelf: 'flex-end'}}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  ) : null
