import {Box, Button, Chip, ChipProps, Paper, Typography} from '@mui/material'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  TourItemsStateCountsFieldsFragment,
  TourTimeSlotFieldsFragment
} from '../../__generated__/schema'
import {EventAvailabilityOption} from '../../features/eventsList/types'
import {
  useFormatDateWithoutYearNumeric,
  useFormatLongWeekDay,
  useFormatTime
} from '../../features/hooks/formatting'
import {useTranslateShowVersion} from '../../features/hooks/translateDistributions'
import {useGetTranslatedValue} from '../../features/hooks/translateLocales'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

const useGetAvailabilityChipColorAndLabel = (
  stateCountsTotal: number,
  eCommerceLimit: number
) => {
  const {t} = useTranslation()
  const availableCapacity = eCommerceLimit - stateCountsTotal
  const isLastFew = availableCapacity <= 10 && availableCapacity > 0
  const isLimitedAvailability =
    availableCapacity / eCommerceLimit <= 0.2 && availableCapacity > 10
  const getChipColor = useCallback(
    (): ChipProps['color'] =>
      isLimitedAvailability ? 'warning' : isLastFew ? 'error' : 'success',
    [isLastFew, isLimitedAvailability]
  )
  const getStatusLabel = useCallback(
    () =>
      isLimitedAvailability
        ? t('EventAvailability->LimitedAvailability')
        : isLastFew
        ? t('EventAvailability->LastFewTickets')
        : t('EventAvailability->Available'),
    [isLastFew, isLimitedAvailability, t]
  )
  const getCountLabel = useCallback(
    () =>
      isLastFew
        ? t('Only {{count}} tickets', {count: availableCapacity})
        : t('{{tickets}} available', {tickets: availableCapacity}),
    [availableCapacity, isLastFew, t]
  )
  return useMemo(
    () => ({
      getChipColor,
      getStatusLabel,
      getCountLabel
    }),
    [getChipColor, getCountLabel, getStatusLabel]
  )
}

interface IAvailabilityChipProps {
  eventAvailabilityOption: EventAvailabilityOption
  stateCounts: TourItemsStateCountsFieldsFragment
  eCommerceLimit: number
}

const AvailabilityChip: React.FC<IAvailabilityChipProps> = ({
  eventAvailabilityOption,
  stateCounts,
  eCommerceLimit
}: IAvailabilityChipProps) => {
  const {inCart, sold, reserved} = stateCounts
  const stateCountsTotal = inCart + sold + reserved
  const {getChipColor, getStatusLabel, getCountLabel} =
    useGetAvailabilityChipColorAndLabel(stateCountsTotal, eCommerceLimit)
  if (eventAvailabilityOption === EventAvailabilityOption.Status) {
    return (
      <Chip
        variant="outlined"
        color={getChipColor()}
        label={getStatusLabel()}
        size="small"
      />
    )
  }
  if (eventAvailabilityOption === EventAvailabilityOption.Count) {
    return (
      <Chip
        variant="outlined"
        color={getChipColor()}
        label={getCountLabel()}
        size="small"
      />
    )
  }
  return null
}

const useFormatDayName = () => {
  const {t} = useTranslation()
  const formatLongWeekDay = useFormatLongWeekDay()
  return useCallback(
    (date: Date) =>
      dayjs(date).isToday()
        ? t('Today')
        : dayjs(date).isTomorrow()
        ? t('Tomorrow')
        : formatLongWeekDay(date),
    [formatLongWeekDay, t]
  )
}

const useFormatDuration = () => {
  const formatTime = useFormatTime()
  return useCallback(
    ({startsAt, endsAt}: {startsAt: string; endsAt: string}) => {
      const startsAtDate = new Date(startsAt)
      const endsAtDate = new Date(endsAt)
      const duration = dayjs(endsAtDate).diff(dayjs(startsAtDate), 'minute')
      return [
        formatTime(startsAtDate),
        duration >= 150 && formatTime(endsAtDate)
      ]
        .filter(Boolean)
        .join(' - ')
    },
    [formatTime]
  )
}

interface ITourTimeSlotItemProps {
  tourTimeSlot: TourTimeSlotFieldsFragment
  eventAvailabilityOption: EventAvailabilityOption
  showTourName?: boolean
}

export const TourTimeSlotItem: React.FC<ITourTimeSlotItemProps> = ({
  tourTimeSlot,
  eventAvailabilityOption,
  showTourName
}: ITourTimeSlotItemProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const formatDayName = useFormatDayName()
  const formatDateWithoutYearNumeric = useFormatDateWithoutYearNumeric()
  const translateShowVersion = useTranslateShowVersion()
  const translatedValue = useGetTranslatedValue(tourTimeSlot.client.localeCodes)
  const formatDuration = useFormatDuration()
  const startsAtDate = new Date(tourTimeSlot.startsAt)
  const {inCart, sold, reserved} = tourTimeSlot.tourItemsStateCounts
  const {eCommerceAttendeesLimit, retailAttendeesLimit} = tourTimeSlot
  const stateCountsTotal = inCart + sold + reserved
  const isSoldOut =
    eCommerceAttendeesLimit &&
    retailAttendeesLimit &&
    eCommerceAttendeesLimit - stateCountsTotal <= 0 &&
    retailAttendeesLimit - stateCountsTotal <= 0
  const isAvailableAtBoxOffice =
    eCommerceAttendeesLimit &&
    retailAttendeesLimit &&
    eCommerceAttendeesLimit - stateCountsTotal <= 0 &&
    retailAttendeesLimit - stateCountsTotal >= 1
  const isDisabled = isSoldOut || isAvailableAtBoxOffice
  const handleClick = useCallback(
    () => navigate(`/tourTimeSlot/${tourTimeSlot.id}`),
    [navigate, tourTimeSlot.id]
  )
  return (
    <Paper
      variant="outlined"
      sx={{
        px: 3,
        py: 2.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        opacity: (theme) =>
          isDisabled ? theme.palette.action.disabledOpacity : undefined
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'flex-end', gap: 2}}>
        <Box>
          {showTourName && (
            <Typography variant="subtitle2">
              {translatedValue(tourTimeSlot.names)}
            </Typography>
          )}
          <Typography
            variant="subtitle2"
            sx={{
              '&:first-letter': {
                textTransform: 'capitalize'
              }
            }}
          >
            {[
              formatDayName(startsAtDate),
              formatDateWithoutYearNumeric(startsAtDate),
              formatDuration({
                startsAt: tourTimeSlot.startsAt,
                endsAt: tourTimeSlot.endsAt
              })
            ].join(' • ')}
          </Typography>
        </Box>
        {tourTimeSlot.versionCode && (
          <Typography variant="caption" color="textSecondary">
            {translateShowVersion(tourTimeSlot.versionCode)}
          </Typography>
        )}
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
        {isSoldOut ? (
          <Chip
            sx={{
              color: 'error.main',
              backgroundColor: 'error.light'
            }}
            label={t('Sold out')}
          />
        ) : (
          tourTimeSlot.eCommerceAttendeesLimit &&
          !isAvailableAtBoxOffice && (
            <AvailabilityChip
              eventAvailabilityOption={eventAvailabilityOption}
              eCommerceLimit={tourTimeSlot.eCommerceAttendeesLimit}
              stateCounts={tourTimeSlot.tourItemsStateCounts}
            />
          )
        )}
        {isAvailableAtBoxOffice ? (
          <Typography variant="caption" color="textSecondary">
            {t('Available at the box office')}
          </Typography>
        ) : (
          !isSoldOut && (
            <Button variant="contained" color="primary" onClick={handleClick}>
              {t('Get tickets')}
            </Button>
          )
        )}
      </Box>
    </Paper>
  )
}
