import EventBusyIcon from '@mui/icons-material/EventBusy'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, Chip, Divider, IconButton} from '@mui/material'
import {TFunction} from 'i18next'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  EventDetailFieldsFragment,
  EventState,
  SalesAndReservationFieldsFragment
} from '../../__generated__/schema'
import {Tooltip} from '../../components/atoms/Tooltip'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {CalendarAlertIcon} from '../../components/icons'
import {MediaSizes} from '../../components/types'
import {EventHeader} from '../components/atoms/EventHeader'
import {Warning, WarningColorCombination} from '../components/atoms/Warning'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {LanguageSelect} from '../components/molecules/LanguageSelect'
import {MobileMoreMenu} from '../components/molecules/MobileMoreMenu'
import {BlankPage} from '../components/organisms/BlankPage'

interface IStyledEventHeaderProps {
  event: EventDetailFieldsFragment & SalesAndReservationFieldsFragment
  isMobile: boolean
  rightAction: React.ReactNode
}

const StyledEventHeader: React.FC<IStyledEventHeaderProps> = ({
  event,
  isMobile,
  rightAction
}: IStyledEventHeaderProps) => {
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <EventHeader
        isMobile={isMobile}
        sx={{
          backgroundColor: 'background.paper',
          px: 3,
          ...(isMobile
            ? {
                py: 2
              }
            : {
                pt: 1,
                pb: 2
              })
        }}
        event={event}
        rightAction={rightAction}
        mobileMoreMenu={<MobileMoreMenu />}
        helpIconButton={
          <Tooltip title={t('I need help')}>
            <IconButton color="inherit">
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        }
        languageSelect={
          <Tooltip title={t('Switch language')}>
            <LanguageSelect />
          </Tooltip>
        }
      />
      <Divider />
    </Box>
  )
}

const getValidationResult = ({
  event,
  isMobile,
  rightAction,
  t
}: {
  event: EventDetailFieldsFragment & SalesAndReservationFieldsFragment
  isMobile: boolean
  rightAction: React.ReactNode
  t: TFunction
}) => {
  const isoNow = new Date().toISOString()
  const isEcommerceReservationActive =
    event.salesAndReservation.online.reservationActive &&
    event.salesAndReservation.online.reservationEnd > isoNow &&
    event.salesAndReservation.online.reservationStart < isoNow
  const isEcommerceSaleActive =
    event.salesAndReservation.online.saleActive &&
    event.salesAndReservation.online.saleEnd > isoNow &&
    event.salesAndReservation.online.saleStart < isoNow
  const isRetailReservationsActive =
    event.salesAndReservation.pointOfSale.reservationActive &&
    event.salesAndReservation.pointOfSale.reservationEnd > isoNow &&
    event.salesAndReservation.pointOfSale.reservationStart < isoNow
  const isRetailSaleActive =
    event.salesAndReservation.pointOfSale.saleActive &&
    event.salesAndReservation.pointOfSale.saleEnd > isoNow &&
    event.salesAndReservation.pointOfSale.saleStart < isoNow

  if (
    (isRetailSaleActive || isRetailReservationsActive) &&
    !isEcommerceReservationActive &&
    !isEcommerceSaleActive
  ) {
    return {
      title: t('Can’t add event to cart'),
      description: t(
        'We are sorry, but event settings do not allow to add items to cart right now. Please, come back later and try again, or you can visit us personally during opening hours.'
      ),
      icon: (
        <CalendarAlertIcon
          sx={{width: 48, height: 48, color: 'text.primary'}}
        />
      ),
      header: (
        <StyledEventHeader
          event={event}
          isMobile={isMobile}
          rightAction={rightAction}
        />
      )
    }
  }
  if (
    !isEcommerceSaleActive &&
    !isEcommerceReservationActive &&
    event.state === EventState.Published
  ) {
    return {
      title: t('Can’t add event to cart'),
      description: t(
        'We are sorry, but event settings do not allow to add items to cart right now. Please, come back later and try again.'
      ),
      icon: (
        <CalendarAlertIcon
          sx={{width: 48, height: 48, color: 'text.primary'}}
        />
      ),
      header: (
        <StyledEventHeader
          event={event}
          isMobile={isMobile}
          rightAction={rightAction}
        />
      )
    }
  }
  if (event.state === EventState.Canceled) {
    return {
      title: t('Event is canceled'),
      description: t(
        'Event items can’t be added to cart, because event is canceled.'
      ),
      icon: (
        <EventBusyIcon sx={{width: 48, height: 48, color: 'text.primary'}} />
      ),
      header: (
        <StyledEventHeader
          event={event}
          isMobile={isMobile}
          rightAction={rightAction}
        />
      )
    }
  }
  if (event.state === EventState.Deleted || event.state === EventState.Draft) {
    return {
      title: t('Event not found'),
      description: t(
        'We are sorry, but we are not able to recognise this event. Please, check whether you have correct link.'
      ),
      icon: (
        <CalendarAlertIcon
          sx={{width: 48, height: 48, color: 'text.primary'}}
        />
      ),
      header: <BaseHeader title={t('Not found')} />
    }
  }

  return null
}

interface IChildrenOnValidEventProps {
  event: EventDetailFieldsFragment & SalesAndReservationFieldsFragment
  children: React.ReactNode
}

export const ChildrenOnValidEvent: React.FC<IChildrenOnValidEventProps> = ({
  event,
  children
}: IChildrenOnValidEventProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const onMoreInfoClick = useCallback(() => navigate('eventInfo'), [navigate])
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const validationResult = getValidationResult({
    event,
    t,
    isMobile,
    rightAction: (
      <Chip
        label={t('More info')}
        size="small"
        onClick={onMoreInfoClick}
        icon={<InfoOutlinedIcon />}
        variant="outlined"
      />
    )
  })
  return validationResult ? (
    <BlankPage header={validationResult.header}>
      <Warning
        maxWidth={isMobile ? '100%' : 360}
        title={validationResult.title}
        description={validationResult.description}
        icon={validationResult.icon}
        colorCombination={WarningColorCombination.Warning}
      />
    </BlankPage>
  ) : (
    <>{children}</>
  )
}
