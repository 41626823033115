import dayjs from 'dayjs'
import {noop} from 'lodash'
import React, {createContext, useCallback, useContext, useEffect} from 'react'
import {
  CustomerFieldsFragment,
  LeadUserFieldsFragment
} from '../../__generated__/schema'
import {config} from '../../config'
import {SessionStorageKey, useSessionStorageState} from '../hooks/storage'

interface IAuthContextValue {
  leadUser: LeadUserFieldsFragment | null
  customer: CustomerFieldsFragment | null
  expiresAt: string | null
  eSid?: string
}

const initialValue: IAuthContextValue = {
  leadUser: null,
  customer: null,
  expiresAt: null
}

const AuthContext = createContext<{
  value: IAuthContextValue
  setLeadUser: (leadUser: null | LeadUserFieldsFragment, eSid?: string) => void
  setCustomer: (customer: null | CustomerFieldsFragment) => void
}>({
  value: initialValue,
  setLeadUser: noop,
  setCustomer: noop
})

interface IAuthContextProviderProps {
  children: React.ReactElement
}

export const AuthContextProvider: React.FC<IAuthContextProviderProps> = ({
  children
}: IAuthContextProviderProps) => {
  const [contextValue, setContextValue] =
    useSessionStorageState<IAuthContextValue>(
      SessionStorageKey.AuthContextValue,
      initialValue
    )

  const setLeadUser = useCallback(
    (leadUser: null | LeadUserFieldsFragment, eSid?: string) => {
      setContextValue({
        ...contextValue,
        eSid: eSid ?? contextValue.eSid,
        leadUser,
        expiresAt: leadUser
          ? dayjs().add(config.serverSessionCookieMaxAge, 'ms').toISOString()
          : null
      })
    },
    [contextValue, setContextValue]
  )

  const setCustomer = useCallback(
    (customer: null | CustomerFieldsFragment) => {
      setContextValue({
        ...contextValue,
        customer
      })
    },
    [contextValue, setContextValue]
  )

  useEffect(() => {
    let timeout: number
    if (
      contextValue.expiresAt &&
      dayjs(contextValue.expiresAt).isAfter(dayjs())
    ) {
      timeout = window.setTimeout(() => {
        setContextValue(initialValue)
      }, new Date(contextValue.expiresAt).getTime() - Date.now())
    } else if (contextValue.expiresAt) {
      setContextValue(initialValue)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [contextValue.expiresAt, setContextValue])

  return (
    <AuthContext.Provider
      value={{value: contextValue, setLeadUser, setCustomer}}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
