import {ApolloError} from '@apollo/client'
import {
  ErrorMessages,
  Item,
  Maybe,
  ProductItemFieldsFragment,
  TicketItem,
  TicketItemFieldsFragment,
  TourItem,
  TourItemFieldsFragment
} from '../__generated__/schema'

// todo: suitable for sharing
export const isTicketItem = (
  item: Pick<Item, '__typename'>
): item is TicketItem => item.__typename === 'TicketItem'

export const isTourItem = (item: Pick<Item, '__typename'>): item is TourItem =>
  item.__typename === 'TourItem'

export const isTourOrTicketItem = (
  item: Pick<Item, '__typename'>
): item is TicketItem | TourItem =>
  item.__typename === 'TicketItem' || item.__typename === 'TourItem'

export const getTicketItemsByEventIdFilter =
  (eventId: number) => (ticketItem: TicketItemFieldsFragment) =>
    ticketItem.eventSeat.event.id === eventId

export const getTourItemsByTourTimeSlotIdFilter =
  (tourTimeSlotId: number) => (tourItem: TourItemFieldsFragment) =>
    tourItem.tourTimeSlotId === tourTimeSlotId

export const getTicketItemLabel = (
  eventSeat: TicketItemFieldsFragment['eventSeat']
) =>
  [eventSeat.label, eventSeat.row, eventSeat.section, eventSeat.floor]
    .filter(Boolean)
    .join(' • ')

// todo: suitable for sharing
export const getGraphQLErrorRelatedToErrorMessage = (
  error: Maybe<ApolloError>,
  errorMessage: ErrorMessages
) => error?.graphQLErrors.find((e) => e.message === errorMessage)

export const isTourItemPropertiesFragment = (
  item:
    | TicketItemFieldsFragment
    | ProductItemFieldsFragment
    | TourItemFieldsFragment
): item is TourItemFieldsFragment => item.__typename === 'TourItem'

export const isTicketItemFieldsFragment = (
  item: TourItemFieldsFragment | TicketItemFieldsFragment
): item is TicketItemFieldsFragment => item.__typename === 'TicketItem'

export const isTicketOrTourItemFieldsFragment = (
  item:
    | TourItemFieldsFragment
    | TicketItemFieldsFragment
    | ProductItemFieldsFragment
): item is TicketItemFieldsFragment | TourItemFieldsFragment =>
  item.__typename === 'TicketItem' || item.__typename === 'TourItem'
