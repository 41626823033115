import {gql} from '@apollo/client'
import {PAGINATION_FIELDS, TRANSLATED_FIELDS} from '../graphql'

export const PRIMARY_IMAGE_FIELDS = gql`
  fragment PrimaryImageFields on ClientShowImage {
    id
    thumbnails(size: medium) {
      id
      size
      url
    }
  }
`
export const EVENT_LIST_ITEM_FIELDS = gql`
  ${TRANSLATED_FIELDS}
  ${PRIMARY_IMAGE_FIELDS}
  fragment EventListItemFields on Event {
    id
    startsAt
    endsAt
    formatCode
    soundMixCode
    versionCode
    ecommerceEventURL
    ageClassificationCode
    names {
      ...TranslatedFields
    }
    venue {
      id
      name
    }
    availableSeatsCount
    show {
      id
      translations {
        localeCode
        tagline
      }
      photoPrimaryImage: primaryImage(type: photo) {
        ...PrimaryImageFields
      }
      posterPrimaryImage: primaryImage(type: poster) {
        ...PrimaryImageFields
      }
      contentDescriptorCodes
    }
    auditoriumLayout {
      id
      capacity
    }
  }
`
export const GET_EVENTS_LIST_DATA = gql`
  ${EVENT_LIST_ITEM_FIELDS}
  ${PAGINATION_FIELDS}
  query GetEventsListData(
    $filter: EventsFilterInput!
    $paginationInput: PaginationInput!
    $clientId: Int!
  ) {
    client(id: $clientId) {
      id
      localeCodes
    }
    paginatedEventsOnEcommerce(
      filter: $filter
      paginationInput: $paginationInput
      clientId: $clientId
    ) {
      items {
        ...EventListItemFields
      }
      pagination {
        ...PaginationFields
      }
    }
  }
`
