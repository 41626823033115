import {
  ECommerceLocaleCode,
  LocaleCode,
  Translated
} from '../../__generated__/schema'
import {useLocale} from '../components/atoms/LocaleContext'

export const useTranslateLocaleCode = () => {
  const translatedCodes: Record<ECommerceLocaleCode, string> = {
    [ECommerceLocaleCode.Sk]: 'Slovenčina',
    [ECommerceLocaleCode.En]: 'English',
    [ECommerceLocaleCode.Cs]: 'Česky',
    [ECommerceLocaleCode.Hu]: 'Magyar',
    [ECommerceLocaleCode.De]: 'Deutsch',
    [ECommerceLocaleCode.Pl]: 'Polski'
  }
  return (code: ECommerceLocaleCode) => translatedCodes[code]
}

export const isLocaleCode = (
  code: ECommerceLocaleCode | LocaleCode
): code is LocaleCode => Object.values(LocaleCode).includes(code as LocaleCode)

export const isEcommerceLocaleCode = (
  code: ECommerceLocaleCode | LocaleCode
): code is ECommerceLocaleCode =>
  Object.values(ECommerceLocaleCode).includes(code as ECommerceLocaleCode)

export const useGetTranslatedValue = (clientLocale: Array<LocaleCode>) => {
  const [locale] = useLocale()

  return (field: Omit<Translated, '__typename'>): string =>
    isLocaleCode(locale)
      ? field[locale] ?? field[clientLocale[0]] ?? ''
      : field[clientLocale[0]] ?? ''
}

export const useGetTranslatedValueWithoutFallback = () => {
  const [locale] = useLocale()

  return (field: Translated) =>
    isLocaleCode(locale) ? field[locale] : undefined
}
