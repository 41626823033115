import {useMutation} from '@apollo/client'
import {
  FeSeatState,
  FeZoneState,
  LayoutPreview
} from '@attendio/shared-components'
import {Box, Button, Divider, SxProps} from '@mui/material'
import React, {useCallback, useMemo, useState} from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {
  AddSeatingTicketItemToCartMutation,
  AddSeatingTicketItemToCartMutationVariables,
  ApiSeatState,
  DecrementZoneTicketItemQuantityMutation,
  DecrementZoneTicketItemQuantityMutationVariables,
  ErrorMessages,
  EventDetailFieldsFragment,
  EventTicketType,
  IncrementZoneTicketItemQuantityMutation,
  IncrementZoneTicketItemQuantityMutationVariables,
  Scalars
} from '../../__generated__/schema'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../components/types'
import {useElementDimensions} from '../../hooks/dimensions'
import {ModifierKey, useModifierKeys} from '../../hooks/modifierKey'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {EditZoneTicketQuantityDialog} from '../components/molecules/EditZoneTicketQuantityDialog'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useGetTicketItemFromCartRemover} from '../hooks/getTicketItemFromCartRemover'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {
  ADD_SEATING_ITEM_TO_CART,
  DECREMENT_ZONE_TICKET_ITEM_QUANTITY,
  INCREMENT_ZONE_TICKET_ITEM_QUANTITY
} from './graphql'
import {useLayoutObjectTooltip} from './layoutObjectTooltip'
import {TicketPricesRow} from './TicketPricesRow'
import {ZoomPanel} from './ZoomPanel'

// todo: suitable for sharing
const useFeSeatStatesByUuid = ({
  seats,
  mapApiSeatStateToFeSeatState
}: {
  seats: {state: ApiSeatState}[]
  mapApiSeatStateToFeSeatState: (apiSeatState?: ApiSeatState) => FeSeatState
}): {[uuid: string]: FeSeatState} =>
  useMemo<{[uuid: string]: FeSeatState}>(() => {
    const acc: {[uuid: string]: FeSeatState} = {}
    const entries = Object.entries<{state: ApiSeatState}>(seats)
    for (const [uuid, {state}] of entries) {
      acc[uuid] = mapApiSeatStateToFeSeatState(state)
    }
    return acc
  }, [mapApiSeatStateToFeSeatState, seats])

// todo: suitable for sharing
const useFeZoneStatesByUuid = ({
  zones,
  mapZoneApiSeatStatesToFeZoneState
}: {
  zones: {
    states: {
      [keys in ApiSeatState]: number
    }
  }[]
  mapZoneApiSeatStatesToFeZoneState: (
    zoneStatesWithCounts?: {
      [keys in ApiSeatState]: number
    }
  ) => FeZoneState
}): {[uuid: string]: FeZoneState} =>
  useMemo<{[uuid: string]: FeZoneState}>(
    () =>
      Object.entries<{
        states: {
          [keys in ApiSeatState]: number
        }
      }>(zones).reduce<{[uuid: string]: FeZoneState}>(
        (acc, [uuid, {states}]) => ({
          ...acc,
          [uuid]: mapZoneApiSeatStatesToFeZoneState(states)
        }),
        {}
      ),
    [mapZoneApiSeatStatesToFeZoneState, zones]
  )

const getFeSeatStateInEventDetail = (
  apiSeatState?: ApiSeatState
): FeSeatState => {
  switch (apiSeatState) {
    case ApiSeatState.Available:
      return FeSeatState.Available
    case ApiSeatState.Hidden:
      return FeSeatState.NotShown
    case ApiSeatState.SelectedByOthers:
    case ApiSeatState.SelectedByMe:
    case ApiSeatState.AddedToOtherCart:
    case ApiSeatState.InOtherReservation:
    case ApiSeatState.Sold:
    case ApiSeatState.PreSold:
      return FeSeatState.UnAvailable
    case ApiSeatState.AddedToMyCart:
    case ApiSeatState.ReservedInMyCart:
      return FeSeatState.Selected
    case ApiSeatState.Disabled:
      return FeSeatState.Disabled
    case ApiSeatState.InMyReservation:
      return FeSeatState.Reserved
    default:
      return FeSeatState.Plain
  }
}

const getFeZoneStateInEventAuditoriumPreview = (
  zoneStatesWithCounts?: {
    [keys in ApiSeatState]: number
  }
): FeZoneState => {
  if (zoneStatesWithCounts) {
    const {
      [ApiSeatState.Available]: available = 0,
      [ApiSeatState.AddedToMyCart]: addedToMyCart = 0
    } = zoneStatesWithCounts
    if (addedToMyCart > 0) {
      return FeZoneState.Selected
    }
    if (available && available > 0) {
      return FeZoneState.Available
    }
    return FeZoneState.UnAvailable
  }
  return FeZoneState.UnAvailable
}

const useGetZoneLabelModifier = (eventSeats: Scalars['JSON'] = {zones: {}}) => {
  const {t} = useTranslation()
  return useCallback(
    (uuid: string) =>
      ({label}: {capacity: number; label: string}) =>
        t('Available {{availableZoneCapacity}}\n{{label}}', {
          availableZoneCapacity: eventSeats.zones[uuid].states.available || 0,
          label
        }),
    [eventSeats, t]
  )
}

interface IAuditoriumPanelProps {
  event: EventDetailFieldsFragment
  layout: EventDetailFieldsFragment['auditoriumLayout']['layout']
  sx?: SxProps
  eventSeats: Scalars['JSON']
  interruptSubscription: () => void
  resetSubscription: (cartId: null | number) => void
  getTicketTypeForUuid: (uuid: string) => EventTicketType
}

export const AuditoriumPanel: React.FC<IAuditoriumPanelProps> = ({
  event,
  layout,
  eventSeats,
  getTicketTypeForUuid,
  sx,
  interruptSubscription,
  resetSubscription
}: IAuditoriumPanelProps) => {
  const {t} = useTranslation()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const {currentCartId, initializeCurrentCart, updateCurrentCart} =
    useCurrentCart()
  const [ref, dimensions] = useElementDimensions()
  const {seats, zones} = eventSeats
  const feSeatStatesByUuid = useFeSeatStatesByUuid({
    seats,
    mapApiSeatStateToFeSeatState: getFeSeatStateInEventDetail
  })

  const feZoneStatesByUuid = useFeZoneStatesByUuid({
    zones,
    mapZoneApiSeatStatesToFeZoneState: getFeZoneStateInEventAuditoriumPreview
  })
  const [uuidOfOpenedZone, setUuidOfOpenedZone] = useState<string | null>(null)
  const getZoneLabelModifier = useGetZoneLabelModifier(eventSeats)
  const isControlPressed = useModifierKeys([ModifierKey.Control])
  const {TooltipProps, getMouseEnterHandler, getMouseLeaveHandler} =
    useLayoutObjectTooltip({
      layout,
      feSeatStatesByUuid,
      feZoneStatesByUuid,
      getTicketTypeForUuid,
      currency: event.division.client.currency
    })
  const {setShowBackdrop, defaultErrorHandler, displayInfoNotification} =
    useMutationAssistanceHooks()
  const {invalidCheckoutOptionsErrorHandler, invalidCartStateErrorHandler} =
    useEcommerceErrorHandlers()
  const [addSeatingTicketItemToCart] = useMutation<
    AddSeatingTicketItemToCartMutation,
    AddSeatingTicketItemToCartMutationVariables
  >(ADD_SEATING_ITEM_TO_CART)
  const [incrementZoneTicketItemQuantity] = useMutation<
    IncrementZoneTicketItemQuantityMutation,
    IncrementZoneTicketItemQuantityMutationVariables
  >(INCREMENT_ZONE_TICKET_ITEM_QUANTITY)
  const [decrementZoneTicketItemQuantityMutation] = useMutation<
    DecrementZoneTicketItemQuantityMutation,
    DecrementZoneTicketItemQuantityMutationVariables
  >(DECREMENT_ZONE_TICKET_ITEM_QUANTITY)
  const getAvailableSeatClickHandler = useCallback(
    (eventSeatUUID: string) => async () => {
      try {
        setShowBackdrop(true)
        if (!currentCartId) {
          interruptSubscription()
        }
        const {data} = await addSeatingTicketItemToCart({
          variables: {
            eventId: event.id,
            cartId: currentCartId,
            eventSeatUUID
          }
        })
        if (data) {
          if (!currentCartId) {
            resetSubscription(data.addSeatingTicketItemToCart.id)
            initializeCurrentCart(data.addSeatingTicketItemToCart)
          } else {
            updateCurrentCart()
          }
        }
      } catch (error) {
        if (!currentCartId) {
          resetSubscription(null)
        }
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.EventSeatNotAvailable
          )
        ) {
          defaultErrorHandler(error, {description: t('Seat is not available')})
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.MaxTicketsPerOrderExceeded
          )
        ) {
          defaultErrorHandler(error, {
            title: t('Unable to add more items to cart'),
            description: t(
              'You are allowed to add only {{count}} ticket to cart for this event at once.',
              {count: event.auditoriumLayoutPricing.maxTicketsPerOrder}
            )
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.MaxNumberOfOccupiedSeatsHasBeenReached
          )
        ) {
          defaultErrorHandler(error, {
            title: t('Unable to add more items to cart'),
            description: t(
              'We are sorry, but you are not allowed to add more items to cart, due to exceeding the maximum number of occupied seats for this event.'
            ),
            renderActions: function renderActions(onClose) {
              return (
                <Button variant="text" onClick={onClose}>
                  {t('Got it')}
                </Button>
              )
            }
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.EventHasNoValidCheckoutOptions
          )
        ) {
          invalidCheckoutOptionsErrorHandler({error})
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error})
        } else {
          defaultErrorHandler(error, {
            description: t('Adding seating ticket item failed')
          })
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addSeatingTicketItemToCart,
      currentCartId,
      defaultErrorHandler,
      event.auditoriumLayoutPricing.maxTicketsPerOrder,
      event.id,
      initializeCurrentCart,
      interruptSubscription,
      invalidCartStateErrorHandler,
      invalidCheckoutOptionsErrorHandler,
      resetSubscription,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )
  const getInMyCartSeatClickHandler = useGetTicketItemFromCartRemover()
  const getSeatClickHandler = useCallback(
    (uuid: string) => {
      const {state, id: eventSeatId}: {state: ApiSeatState; id: number} =
        seats[uuid]
      if (state === ApiSeatState.Available) {
        return getAvailableSeatClickHandler(uuid)
      } else if (state === ApiSeatState.AddedToMyCart) {
        return getInMyCartSeatClickHandler(uuid, eventSeatId)
      }
      return undefined
    },
    [getAvailableSeatClickHandler, getInMyCartSeatClickHandler, seats]
  )
  const getZoneClickHandler = useCallback(
    (uuid: string) =>
      zones[uuid].states[ApiSeatState.Available] > 0 ||
      zones[uuid].states[ApiSeatState.AddedToMyCart] > 0
        ? () => {
            setUuidOfOpenedZone(uuid)
          }
        : undefined,
    [zones]
  )
  const closeZoneDialog = useCallback(() => {
    setUuidOfOpenedZone(null)
  }, [])
  const handleZoneTicketQuantityChange = useCallback(
    async ({
      increment,
      decrement,
      zoneUuid
    }: {
      increment?: number
      decrement?: number
      zoneUuid: string
    }) => {
      setShowBackdrop(true)
      try {
        if (decrement && currentCartId) {
          const {data} = await decrementZoneTicketItemQuantityMutation({
            variables: {
              eventId: event.id,
              cartId: currentCartId,
              eventSeatUUID: zoneUuid,
              decrement
            }
          })
          if (data) {
            updateCurrentCart()
            closeZoneDialog()
          }
        } else if (increment) {
          if (!currentCartId) {
            interruptSubscription()
          }
          const {data} = await incrementZoneTicketItemQuantity({
            variables: {
              eventId: event.id,
              cartId: currentCartId,
              eventSeatUUID: zoneUuid,
              increment
            }
          })
          if (data) {
            if (
              data.incrementZoneTicketItemQuantity.__typename ===
              'IncrementZoneTicketItemQuantitySuccessResult'
            ) {
              if (!currentCartId) {
                resetSubscription(data.incrementZoneTicketItemQuantity.cart.id)
                initializeCurrentCart(data.incrementZoneTicketItemQuantity.cart)
              } else {
                updateCurrentCart()
              }
              displayInfoNotification(
                t('{{count}} tickets added to the cart', {
                  count: increment
                })
              )
            }
            closeZoneDialog()
          }
        }
      } catch (error) {
        if (increment && !currentCartId) {
          resetSubscription(null)
        }
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.MaxTicketsPerOrderExceeded
          )
        ) {
          defaultErrorHandler(error, {
            title: t('Unable to add more items to cart'),
            description: t(
              'You are allowed to add only {{count}} ticket to cart for this event at once.',
              {count: event.auditoriumLayoutPricing.maxTicketsPerOrder}
            )
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.MaxNumberOfOccupiedSeatsHasBeenReached
          )
        ) {
          defaultErrorHandler(error, {
            title: t('Unable to add more items to cart'),
            description: t(
              'We are sorry, but you are not allowed to add more items to cart, due to exceeding the maximum number of occupied seats for this event.'
            ),
            renderActions: function renderActions(onClose) {
              return (
                <Button variant="text" onClick={onClose}>
                  {t('Got it')}
                </Button>
              )
            }
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.EventHasNoValidCheckoutOptions
          )
        ) {
          invalidCheckoutOptionsErrorHandler({error})
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error})
        } else {
          defaultErrorHandler(error, {
            description: t('Updating zone tickets quantity failed')
          })
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      currentCartId,
      decrementZoneTicketItemQuantityMutation,
      event.id,
      event.auditoriumLayoutPricing.maxTicketsPerOrder,
      updateCurrentCart,
      closeZoneDialog,
      incrementZoneTicketItemQuantity,
      interruptSubscription,
      displayInfoNotification,
      t,
      resetSubscription,
      initializeCurrentCart,
      defaultErrorHandler,
      invalidCheckoutOptionsErrorHandler,
      invalidCartStateErrorHandler
    ]
  )
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        ...sx
      }}
    >
      <TicketPricesRow event={event} isMobile={isMobile} />
      <Divider />
      <Box ref={ref} sx={{overflow: 'hidden', position: 'relative'}}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
          />
        </Helmet>
        <LayoutPreview
          isControlPressed={isControlPressed}
          TooltipProps={TooltipProps}
          dimensions={dimensions}
          feSeatStatesByUuid={feSeatStatesByUuid}
          feZoneStatesByUuid={feZoneStatesByUuid}
          getLayoutObjectMouseEnterHandler={getMouseEnterHandler}
          getLayoutObjectMouseLeaveHandler={getMouseLeaveHandler}
          getSeatClickHandler={getSeatClickHandler}
          getZoneClickHandler={getZoneClickHandler}
          getZoneLabelModifier={getZoneLabelModifier}
          layout={layout}
          renderZoomPanel={({
            zoomIn,
            zoomOut,
            fitToScreen,
            isZoomOutDisabled,
            isZoomInDisabled
          }) => (
            <ZoomPanel
              sx={(theme) =>
                isMobile
                  ? {
                      position: 'absolute',
                      left: theme.spacing(2),
                      bottom: theme.spacing(1)
                    }
                  : {
                      position: 'absolute',
                      right: theme.spacing(2),
                      top: theme.spacing(2),
                      display: 'flex',
                      gap: 1
                    }
              }
              isMobile={isMobile}
              onFitToScreenClick={fitToScreen}
              onZoomInClick={zoomIn}
              onZoomOutClick={zoomOut}
              isZoomOutDisabled={isZoomOutDisabled}
              isZoomInDisabled={isZoomInDisabled}
            />
          )}
        />
      </Box>
      {uuidOfOpenedZone && (
        <EditZoneTicketQuantityDialog
          zoneUuid={uuidOfOpenedZone}
          zoneLabel={
            (event.auditoriumLayout.layout as any)[uuidOfOpenedZone].config
              .label
          }
          zoneDescription={
            getTicketTypeForUuid(uuidOfOpenedZone).description || undefined
          }
          zonePrice={getTicketTypeForUuid(uuidOfOpenedZone)?.price || 0}
          ticketsInMyCartQuantity={
            zones[uuidOfOpenedZone]?.states[ApiSeatState.AddedToMyCart] || 0
          }
          availableTicketsQuantity={
            zones[uuidOfOpenedZone]?.states[ApiSeatState.Available] || 0
          }
          currency={event.division.client.currency}
          onConfirm={handleZoneTicketQuantityChange}
          onClose={closeZoneDialog}
        />
      )}
    </Box>
  )
}
