import {
  alpha,
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  Radio,
  RadioGroup,
  RadioProps,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import React from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {FormSubText} from '../atoms/FormSubText'
import {getFormError} from '../formUtils'
import {FormFieldName} from '../types'

export interface RadioGroupOption<OptionValue extends string> {
  label: FormControlLabelProps['label']
  value: OptionValue
  isDisabled?: boolean
}

export interface IUncontrolledFormRadioGroupProps<
  FormValues extends FieldValues = FieldValues,
  OptionValue extends string = string
> extends Pick<
    FormControlProps<typeof RadioGroup>,
    'sx' | 'disabled' | 'fullWidth'
  > {
  name: FormFieldName<FormValues>
  control: FormContextValues<FormValues>['control']
  errors: FieldErrors<FormValues>
  validationOptions?: ValidationOptions
  helperText?: string
  options: RadioGroupOption<OptionValue>[]
  formControlLabelSx?: SxProps<Theme>
  selectedFormControlLabelSx?: SxProps<Theme>
  checkedIcon?: RadioProps['checkedIcon']
}

export const defaultFormControlLabelSx: SxProps<Theme> = {
  borderWidth: 1,
  display: 'flex',
  borderStyle: 'solid',
  borderColor: 'divider',
  mx: 0,
  mb: 2,
  p: 1.5,
  pr: 3,
  backgroundColor: 'background.paper',
  borderRadius: 4,
  '&:last-child': {
    margin: 0
  }
}

export const defaultSelectedFormControlLabelSx: SxProps<Theme> = (
  theme: Theme
) => ({
  ...defaultFormControlLabelSx,
  borderColor: 'primary.main',
  position: 'relative',
  '&::after': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.12)
  }
})
export const UncontrolledFormRadioGroup = <
  FormValues extends FieldValues = FieldValues,
  OptionValue extends string = string
>({
  name,
  control,
  validationOptions,
  options,
  errors,
  helperText,
  checkedIcon,
  sx,
  formControlLabelSx = defaultFormControlLabelSx,
  selectedFormControlLabelSx = defaultSelectedFormControlLabelSx,
  disabled,
  fullWidth
}: IUncontrolledFormRadioGroupProps<FormValues, OptionValue>) => {
  const error = getFormError(errors, name)
  return (
    <FormControl
      error={Boolean(error)}
      required={Boolean(validationOptions?.required)}
      sx={sx}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      <FormSubText
        error={error}
        helperText={helperText}
        validationOptions={validationOptions}
      />
      <Controller
        as={(props) => (
          <RadioGroup {...props}>
            {options.map((option) => (
              <FormControlLabel
                key={`key-${option.value}`}
                value={option.value}
                disabled={option.isDisabled}
                control={<Radio checkedIcon={checkedIcon} />}
                label={option.label}
                componentsProps={{
                  typography: {sx: {flex: 1}}
                }}
                sx={
                  props.value === option.value
                    ? selectedFormControlLabelSx
                    : formControlLabelSx
                }
              />
            ))}
          </RadioGroup>
        )}
        control={control}
        rules={validationOptions}
        name={name}
      />
    </FormControl>
  )
}
interface IBasicRadioLabelProps {
  primaryText: string
  secondaryText?: string
  rightColumnText?: string
  isDisabled?: boolean
}
export const BasicRadioLabel: React.FC<IBasicRadioLabelProps> = ({
  primaryText,
  secondaryText,
  rightColumnText,
  isDisabled
}: IBasicRadioLabelProps) => (
  <Box
    sx={{
      display: 'grid',
      width: '100%',
      pl: 0.5,
      gridTemplateAreas: `
        "primaryText rightColumnText"
        "secondaryText rightColumnText"
      `
    }}
  >
    <Typography
      variant="subtitle2"
      color={isDisabled ? 'text.disabled' : undefined}
    >
      {primaryText}
    </Typography>
    <Typography
      variant="caption"
      sx={{gridArea: 'secondaryText'}}
      color={isDisabled ? 'text.disabled' : 'textSecondary'}
    >
      {secondaryText}
    </Typography>
    <Typography
      sx={{
        gridArea: 'rightColumnText',
        alignSelf: 'center',
        justifySelf: 'flex-end'
      }}
      variant="body2"
    >
      {rightColumnText}
    </Typography>
  </Box>
)
