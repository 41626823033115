import {useMutation} from '@apollo/client'
import LoginIcon from '@mui/icons-material/Login'
import {
  Box,
  Button,
  Divider,
  Link,
  Paper,
  styled,
  Typography
} from '@mui/material'
import Decimal from 'decimal.js'
import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Navigate, useParams} from 'react-router-dom'
import {
  ErrorMessages,
  ForgotCustomerPasswordMutation,
  ForgotCustomerPasswordMutationVariables
} from '../../../../__generated__/schema'
import {PasswordInputAdornment} from '../../../../components/atoms/PasswordInputAdornment'
import {useIsMediaSize} from '../../../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../../../components/types'
import {useBooleanState} from '../../../../hooks/state'
import {useIsCustomerSignedIn} from '../../../auth'
import {useIsStringWithMinLength} from '../../../hooks/formsValidations'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../utils'
import {UncontrolledFormTextInput} from '../../molecules/UncontrolledFormTextInput'
import {ForgotPasswordDialog} from './ForgotPasswordDialog'
import {FORGOT_CUSTOMER_PASSWORD} from './graphql'
import {
  AccountFormField,
  ForgotPasswordFormField,
  IForgotPasswordForm,
  ISignInForm
} from './types'

const FORGOT_PASSWORD_FORM_ID = 'forgotPasswordForm'

const StyledForm = styled('form')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%'
}))

interface ISignInFormProps {
  formId: string
  onSubmit: (data: ISignInForm) => void
  defaultValues?: Partial<ISignInForm>
  onCreateAccountLinkClick: () => void
}

export const SignInForm: React.FC<ISignInFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  onCreateAccountLinkClick
}: ISignInFormProps) => {
  const {t} = useTranslation()
  const {errors, control, handleSubmit, getValues} = useForm<ISignInForm>({
    defaultValues
  })
  const isCustomerSignedIn = useIsCustomerSignedIn()
  const [forgotCustomerPassword] = useMutation<
    ForgotCustomerPasswordMutation,
    ForgotCustomerPasswordMutationVariables
  >(FORGOT_CUSTOMER_PASSWORD)
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const {state: isPasswordVisibilityOn, toggle: togglePasswordVisibility} =
    useBooleanState(false)
  const {
    state: isForgotPasswordDialogOpen,
    setTrue: openForgotPasswordDialog,
    setFalse: closeForgotPasswordDialog
  } = useBooleanState(false)
  const [error, setError] = useState<string | null>(null)
  const params = useParams()
  const clientId = new Decimal(params.clientId || NaN).toNumber()
  const stringWithMinLength6 = useIsStringWithMinLength(6)
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const _onSubmit = useCallback(
    async (data: ISignInForm) => {
      try {
        await onSubmit(data)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCredentials
          )
        ) {
          setError(t<string>('Username and password combination is incorrect.'))
        } else {
          defaultErrorHandler(error, {
            title: t('Error while signing up customer')
          })
        }
      }
    },
    [defaultErrorHandler, onSubmit, t]
  )
  const handleForgotPasswordFormSubmit = useCallback(
    async (data: IForgotPasswordForm) => {
      try {
        setShowBackdrop(true)
        await forgotCustomerPassword({
          variables: {
            clientId,
            username: data[ForgotPasswordFormField.Username]
          }
        })
      } finally {
        setShowBackdrop(false)
      }
    },
    [clientId, forgotCustomerPassword, setShowBackdrop]
  )

  return isCustomerSignedIn ? (
    <Navigate to="/myAccount" />
  ) : (
    <>
      <Box
        sx={{
          p: isMobile ? 1 : 3,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            px: 3,
            py: 2,
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            width: '100%',
            alignItems: 'flex-start'
          }}
        >
          <StyledForm id={formId} noValidate onSubmit={handleSubmit(_onSubmit)}>
            <UncontrolledFormTextInput<ISignInForm>
              errors={errors}
              control={control}
              name={AccountFormField.Username}
              label={t('Username')}
              fullWidth
              validationOptions={{
                required: true,
                validate: stringWithMinLength6
              }}
              autoComplete="username"
            />
            <UncontrolledFormTextInput<ISignInForm>
              errors={errors}
              control={control}
              name={AccountFormField.Password}
              label={t('Password')}
              fullWidth
              validationOptions={{required: true}}
              type={isPasswordVisibilityOn ? 'text' : 'password'}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <PasswordInputAdornment
                    onClick={togglePasswordVisibility}
                    isPasswordVisibilityOn={isPasswordVisibilityOn}
                  />
                )
              }}
            />
          </StyledForm>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            startIcon={<LoginIcon />}
            sx={{width: '100%'}}
            type="submit"
            form={formId}
          >
            {t('Sign in')}
          </Button>
          <Link
            component="button"
            color="primary"
            variant="body1"
            underline="none"
            onClick={openForgotPasswordDialog}
          >
            {t('Forgot password?')}
          </Link>
          <Divider sx={{width: '100%'}} />
          <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
            <Typography variant="body1" color="textSecondary">
              {t('Don’t have an account yet?')}
            </Typography>
            <Link
              component="button"
              color="primary"
              variant="body1"
              underline="none"
              onClick={onCreateAccountLinkClick}
            >
              {t('Create one')}
            </Link>
          </Box>
        </Paper>
      </Box>
      {isForgotPasswordDialogOpen && (
        <ForgotPasswordDialog
          formId={FORGOT_PASSWORD_FORM_ID}
          isOpen
          onClose={closeForgotPasswordDialog}
          onSubmit={handleForgotPasswordFormSubmit}
          defaultValues={{
            [ForgotPasswordFormField.Username]: getValues(
              AccountFormField.Username
            )
          }}
        />
      )}
    </>
  )
}
