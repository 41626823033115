import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Currency} from '../../__generated__/schema'

export const useTranslatePrice = (defaultCurrency: Currency) => {
  const {t} = useTranslation()

  return useCallback(
    (value: number, currency?: Currency) =>
      t<string>('formattedPrice', {
        price: {currency: currency || defaultCurrency, value}
      }),
    [defaultCurrency, t]
  )
}
