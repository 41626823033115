import {SvgIcon, SvgIconProps} from '@mui/material'
import React from 'react'

export const CalendarAlertIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path d="M16 2.667V8h-2.667A5.315 5.315 0 0 0 8 13.333v37.334A5.333 5.333 0 0 0 13.333 56h37.334A5.315 5.315 0 0 0 56 50.667V13.333A5.333 5.333 0 0 0 50.667 8H48V2.667h-5.333V8H21.333V2.667H16Zm-2.667 18.666h37.334v29.334H13.333v-32 2.666Zm16 2.667v13.333h5.334V24h-5.334Zm0 18.667V48h5.334v-5.333h-5.334Z" />
  </SvgIcon>
)
