import DownloadIcon from '@mui/icons-material/Download'
import {Box, IconButton, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Client, TourTimeSlot} from '../../__generated__/schema'
import {useFormatDateNumeric, useFormatTime} from '../hooks/formatting'
import {useGetTranslatedValue} from '../hooks/translateLocales'

interface IPurchasedTourRowProps {
  tourTimeSlot: Pick<TourTimeSlot, 'names' | 'startsAt'> & {
    client: Pick<Client, 'localeCodes'>
  }
  ticketCount: number
  price: string
  onDownloadButtonClick: () => void
  isMobile: boolean
}

export const PurchasedTourRow: React.FC<IPurchasedTourRowProps> = ({
  tourTimeSlot,
  ticketCount,
  price,
  onDownloadButtonClick,
  isMobile
}: IPurchasedTourRowProps) => {
  const {t} = useTranslation()
  const getTranslatedValue = useGetTranslatedValue(
    tourTimeSlot.client.localeCodes
  )
  const formatDateNumeric = useFormatDateNumeric()
  const formatTime = useFormatTime()
  const startsAtDate = new Date(tourTimeSlot.startsAt)
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: isMobile ? 1 : 3,
        borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
        ':last-child': {
          borderBottom: 'none'
        }
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <Typography variant="body2">
          {[formatDateNumeric(startsAtDate), formatTime(startsAtDate)].join(
            ', '
          )}
        </Typography>
        {'•'}
        <Typography variant="body2">
          {getTranslatedValue(tourTimeSlot.names)}
        </Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 3}}>
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: 'text.secondary'
            }}
          >
            <Typography variant="body2">
              {t('{{count}} tickets', {count: ticketCount})}
            </Typography>
            {'•'}
            <Typography variant="body2">{price}</Typography>
          </Box>
        )}
        <IconButton onClick={onDownloadButtonClick} color="primary">
          <DownloadIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
