import {ApolloError} from '@apollo/client'
import {Button} from '@mui/material'
import {groupBy, isNil} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CheckDiscountCodeForCartQuery,
  Discount,
  ErrorMessages
} from '../../__generated__/schema'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {
  getGraphQLErrorRelatedToErrorMessage,
  isTicketItemFieldsFragment,
  isTicketOrTourItemFieldsFragment
} from '../utils'

export const useDiscountErrorHandler = () => {
  const {t} = useTranslation()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  return (error: ApolloError, defaultErrorMessage: string) => {
    if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.DiscountCodeUsageLimitWasReached
      )
    ) {
      defaultErrorHandler(error, {
        title: t('Discount code is invalid'),
        description: t(
          "We're sorry, but this discount code has reached its usage limit and cannot be applied to selected items."
        )
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.DiscountCodeHasExceededUsageLimit
      )
    ) {
      defaultErrorHandler(error, {
        title: t('Discount code redemption limit exceeded'),
        description: t(
          "The discount code you're trying to apply is for more items than allowed. Each code can only be applied to a maximum number of items, and it seems you're attempting to exceed this limit. Please adjust the number of items you're redeeming with this code to meet its terms, or explore other available discount options."
        )
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.DiscountCodeUsageLimitPerOrderExceeded
      )
    ) {
      defaultErrorHandler(error, {
        title: t('Discount code redemption limit exceeded'),
        description: t(
          'We’re sorry, but the discount code you are trying to apply has reached its usage limit for this order. Each order can only use this discount code a certain number of times, and you have already applied the maximum allowed usage. Please review your order to ensure the discount code is applied correctly or reduce the number of times you’re using the code.'
        ),
        renderActions: function renderActions(onClose) {
          return (
            <Button
              variant="text"
              onClick={() => {
                onClose()
                window.location.reload()
              }}
            >
              {t('Got it')}
            </Button>
          )
        }
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.ItemIsAlreadyDiscounted
      )
    ) {
      defaultErrorHandler(error, {
        title: t('Adding discount failed'),
        description: t(
          'The item you are trying to discount has already been discounted. Please, reload page and review your order details to see the applied discounts.'
        ),
        renderActions: function renderActions(onClose) {
          return (
            <Button
              variant="text"
              onClick={() => {
                onClose()
                window.location.reload()
              }}
            >
              {t('Got it')}
            </Button>
          )
        }
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.DiscountUsageLimitPerOrderExceeded
      )
    ) {
      defaultErrorHandler(error, {
        title: t('Discount redemption limit exceeded'),
        description: t(
          'We’re sorry, but the discount you are trying to apply has reached its usage limit for this order. Each order can only use this discount a certain number of times, and you have already applied the maximum allowed usage. Please review your order to ensure the discount is applied correctly or reduce the number of times you’re using the discount.'
        ),
        renderActions: function renderActions(onClose) {
          return (
            <Button
              variant="text"
              onClick={() => {
                onClose()
                window.location.reload()
              }}
            >
              {t('Got it')}
            </Button>
          )
        }
      })
    } else {
      defaultErrorHandler(error, {
        title: defaultErrorMessage,
        description: error.graphQLErrors[0].message
      })
    }
  }
}

export const useDiscountCodeUsageLimitPerOrderHelper = ({
  selectedDiscountCode,
  tourTimeSlotId,
  eventId
}: {
  selectedDiscountCode: CheckDiscountCodeForCartQuery['checkDiscountCodeForCart']
  eventId?: number
  tourTimeSlotId?: number
}) => {
  const {currentCart} = useCurrentCart()
  const ticketAndTourItems = (currentCart?.items || []).filter(
    isTicketOrTourItemFieldsFragment
  )
  const groupedEventsWithAppliedSelectedDiscountCode = groupBy(
    ticketAndTourItems.filter(
      (item) =>
        isTicketItemFieldsFragment(item) &&
        item.appliedDiscounts.find(
          ({discountCode}) => discountCode?.id === selectedDiscountCode.id
        )
    ),
    'eventSeat.event.id'
  )
  const groupedTourTimeSlotsWithAppliedSelectedDiscountCode = groupBy(
    ticketAndTourItems.filter(
      (item) =>
        !isTicketItemFieldsFragment(item) &&
        item.appliedDiscounts.find(
          ({discountCode}) => discountCode?.id === selectedDiscountCode.id
        )
    ),
    'tourTimeSlotId'
  )
  const numberOfUsageLeft = isNil(selectedDiscountCode.usageLimitPerOrder)
    ? undefined
    : eventId
    ? selectedDiscountCode.usageLimitPerOrder -
      (groupedEventsWithAppliedSelectedDiscountCode[eventId] || []).length
    : tourTimeSlotId
    ? selectedDiscountCode.usageLimitPerOrder -
      (
        groupedTourTimeSlotsWithAppliedSelectedDiscountCode[tourTimeSlotId] ||
        []
      ).length
    : undefined
  return {
    numberOfUsageLeft,
    canBeUsed: isNil(numberOfUsageLeft) ? true : numberOfUsageLeft > 0
  }
}

export const useDiscountUsageLimitPerOrderHelper = () => {
  const {currentCart} = useCurrentCart()
  return useCallback(
    ({
      selectedDiscount,
      eventId,
      tourTimeSlotId
    }: {
      selectedDiscount: Pick<Discount, 'id' | 'maxUsageLimitPerOrder'>
      eventId?: number
      tourTimeSlotId?: number
    }) => {
      const ticketAndTourItems = (currentCart?.items || []).filter(
        isTicketOrTourItemFieldsFragment
      )
      const groupedEventsWithAppliedSelectedDiscountCode = groupBy(
        ticketAndTourItems.filter(
          (item) =>
            isTicketItemFieldsFragment(item) &&
            item.appliedDiscounts.find(
              ({discount}) => discount.id === selectedDiscount.id
            )
        ),
        'eventSeat.event.id'
      )
      const groupedTourTimeSlotsWithAppliedSelectedDiscountCode = groupBy(
        ticketAndTourItems.filter(
          (item) =>
            !isTicketItemFieldsFragment(item) &&
            item.appliedDiscounts.find(
              ({discount}) => discount.id === selectedDiscount.id
            )
        ),
        'tourTimeSlotId'
      )
      const numberOfUsageLeft = isNil(selectedDiscount.maxUsageLimitPerOrder)
        ? undefined
        : eventId
        ? selectedDiscount.maxUsageLimitPerOrder -
          (groupedEventsWithAppliedSelectedDiscountCode[eventId] || []).length
        : tourTimeSlotId
        ? selectedDiscount.maxUsageLimitPerOrder -
          (
            groupedTourTimeSlotsWithAppliedSelectedDiscountCode[
              tourTimeSlotId
            ] || []
          ).length
        : undefined
      return {
        numberOfUsageLeft,
        canBeUsed: isNil(numberOfUsageLeft) ? true : numberOfUsageLeft > 0
      }
    },
    [currentCart?.items]
  )
}
