import {isNil, omitBy, pick} from 'lodash'
import {
  CheckoutOptions,
  ClientDetailFieldsFragment,
  ExistingCountryCode,
  LeadDataInput,
  LeadField,
  VoucherFieldsFragment
} from '../../__generated__/schema'

export enum PMRadioGroupOptionType {
  Reservation = 'reservation',
  ReservationForCartWithZeroPrice = 'reservationForCartWithZeroPrice',
  PaymentForCartWithZeroPrice = 'paymentForCartWithZeroPrice',
  /**
   @description: regular payment method => payment method, which has paymentGateway service provider
   */
  RegularPaymentMethod = 'regularPaymentMethod'
}

export enum CartPageFormField {
  DoAgreeWithTOS = 'doAgreeWithTOS',
  SelectedPMRadioGroupOptionKey = 'selectedPMRadioGroupOptionKey'
}

export interface ICartPageForm {
  [CartPageFormField.DoAgreeWithTOS]: boolean
  [CartPageFormField.SelectedPMRadioGroupOptionKey]: string | null
  [LeadField.Name]: string
  [LeadField.Phone]: string
  [LeadField.Email]: string
  [LeadField.Note]: string
  [LeadField.InternalNote]: string
  [LeadField.CompanyName]: string
  [LeadField.CompanyIdNumber]: string
  [LeadField.VatId]: string
  [LeadField.TaxId]: string
  [LeadField.BillingAddressStreet]: string
  [LeadField.BillingAddressTown]: string
  [LeadField.BillingPostalCode]: string
  [LeadField.BillingAddressCountry]: ExistingCountryCode | null
  [LeadField.DeliveryAddressee]: string
  [LeadField.DeliveryAddressStreet]: string
  [LeadField.DeliveryAddressTown]: string
  [LeadField.DeliveryPostalCode]: string
  [LeadField.DeliveryAddressCountry]: ExistingCountryCode | null
  [LeadField.IsPrivacyPolicyConsentGranted]: boolean
}

export const getLeadDataInput = (
  form: ICartPageForm
): LeadDataInput | undefined => {
  const leadDataInput = omitBy(
    pick(form, [
      LeadField.Name,
      LeadField.Phone,
      LeadField.Email,
      LeadField.Note,
      LeadField.InternalNote,
      LeadField.CompanyName,
      LeadField.CompanyIdNumber,
      LeadField.VatId,
      LeadField.TaxId,
      LeadField.BillingAddressStreet,
      LeadField.BillingAddressTown,
      LeadField.BillingPostalCode,
      LeadField.BillingAddressCountry,
      LeadField.DeliveryAddressee,
      LeadField.DeliveryAddressStreet,
      LeadField.DeliveryAddressTown,
      LeadField.DeliveryPostalCode,
      LeadField.DeliveryAddressCountry,
      LeadField.IsPrivacyPolicyConsentGranted
    ]),
    (value) => value === '' || isNil(value)
  )
  return Object.values(leadDataInput).length ? leadDataInput : undefined
}

export type RedeemData = {
  voucher: VoucherFieldsFragment
  pinCode: string
}

export type RedeemedVoucher = RedeemData & {
  key: string
  credit: number
}

export enum ContentActionType {
  RedeemVoucher = 'redeemVoucher',
  RemoveRedeemedVoucher = 'removeRedeemedVoucher',
  CartChanged = 'cartChanged'
}

export type RedeemVoucherAction = {
  type: ContentActionType.RedeemVoucher
  payload: RedeemData
}

export type CartChangedAction = {
  type: ContentActionType.CartChanged
  payload: {
    checkoutOptions: CheckoutOptions[]
    eCommercePaymentMethods: ClientDetailFieldsFragment['eCommercePaymentMethods']
    cartPrice: number
  }
}

export type RemoveRedeemedVoucherAction = {
  type: ContentActionType.RemoveRedeemedVoucher
  payload: string
}

export type ContentActions =
  | RedeemVoucherAction
  | RemoveRedeemedVoucherAction
  | CartChangedAction

export type PMRadioGroupOption =
  | {
      key: string
      isDisabled: boolean
      type:
        | PMRadioGroupOptionType.Reservation
        | PMRadioGroupOptionType.ReservationForCartWithZeroPrice
        | PMRadioGroupOptionType.PaymentForCartWithZeroPrice
    }
  | {
      key: string
      isDisabled: boolean
      eCommercePaymentMethodId: number
      type: PMRadioGroupOptionType.RegularPaymentMethod
    }

export interface IContentState {
  pMRadioGroupOptions: PMRadioGroupOption[]
  doesVoucherSectionExists: boolean
  redeemedVouchers: RedeemedVoucher[]
  cartPrice: number
  eCommercePaymentMethods: ClientDetailFieldsFragment['eCommercePaymentMethods']
  checkoutOptions: CheckoutOptions[]
}

export enum CheckVoucherFormField {
  Code = 'code',
  PinCode = 'pinCode'
}

export interface ICheckVoucherForm {
  [CheckVoucherFormField.Code]: string
  [CheckVoucherFormField.PinCode]: string
}

export enum WhomLoyaltyIdOption {
  My = 'my',
  Another = 'another'
}

export enum LoyaltyIdFormField {
  WhomLoyaltyId = 'whomLoyaltyId',
  LoyaltyId = 'loyaltyId'
}

export interface ILoyaltyIdForm {
  [LoyaltyIdFormField.WhomLoyaltyId]: WhomLoyaltyIdOption
  [LoyaltyIdFormField.LoyaltyId]: string
}
