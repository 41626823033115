import {useQuery} from '@apollo/client'
import {Typography} from '@mui/material'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Outlet, useParams} from 'react-router-dom'
import {
  AuditoriumLayoutType,
  GetEventDetailQuery,
  GetEventDetailQueryVariables
} from '../../__generated__/schema'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {CalendarAlertIcon} from '../../components/icons'
import {RenderOnData} from '../../components/molecules/RenderOnData'
import {MediaSizes} from '../../components/types'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from '../components/atoms/CurrentCartFab'
import {Warning, WarningColorCombination} from '../components/atoms/Warning'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {BlankPage} from '../components/organisms/BlankPage'
import {ChildrenOnValidEvent} from './ChildrenOnValidEvent'
import {Content} from './Content'
import {GET_EVENT_DETAIL, useEventSeats} from './graphql'
import {EventDetailPanel} from './types'

export const EventDetail: React.FC = () => {
  const params = useParams()
  const eventId = new Decimal(params.eventId || NaN).toNumber()
  const {currentCartId, currentCart, resetCurrentCart} = useCurrentCart()

  useEffect(() => {
    if (currentCart && dayjs().isAfter(currentCart.expiresAt)) {
      resetCurrentCart()
    }
  }, [currentCart, resetCurrentCart])

  const {
    data: eventSeatsData,
    isInitialLoading: isEventSeatsInitialLoading,
    interruptSubscription,
    resetSubscription
  } = useEventSeats({
    eventId,
    myCartId: currentCartId
  })

  const [panel, setPanel] = useState<EventDetailPanel | null>(null)
  const {data, loading, error, refetch} = useQuery<
    GetEventDetailQuery,
    GetEventDetailQueryVariables
  >(GET_EVENT_DETAIL, {
    variables: {id: eventId},
    onCompleted: (data) => {
      if (
        data.event.auditoriumLayout.type ===
        AuditoriumLayoutType.SeatingFloorPlan
      ) {
        setPanel(EventDetailPanel.Auditorium)
      } else {
        setPanel(EventDetailPanel.SelectTickets)
      }
    }
  })
  const {t} = useTranslation()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  return (
    <>
      <RenderOnData<GetEventDetailQuery>
        data={data}
        loading={loading || isEventSeatsInitialLoading}
        error={error}
        dataCondition={(data) =>
          Boolean(
            data && data.event && eventSeatsData?.eventSeats && panel !== null
          )
        }
        errorElement={
          <BlankPage header={<BaseHeader title={t('Not found')} />}>
            <>
              <Warning
                maxWidth={isMobile ? '100%' : 360}
                title={t('Event not found')}
                description={
                  <Typography>
                    {t(
                      'We are sorry, but we are not able to recognise this event. Please, check whether you have correct link.'
                    )}
                  </Typography>
                }
                icon={
                  <CalendarAlertIcon
                    sx={{width: 48, height: 48, color: 'text.primary'}}
                  />
                }
                colorCombination={WarningColorCombination.Warning}
              />
              <TentativeCurrentCartFab currentCart={currentCart} />
            </>
          </BlankPage>
        }
      >
        {({event}) =>
          panel ? (
            <ChildrenOnValidEvent event={event}>
              <Content
                event={event}
                panel={panel}
                onPanelChange={(p: EventDetailPanel) => setPanel(p)}
                eventSeats={eventSeatsData!.eventSeats}
                interruptSubscription={interruptSubscription}
                resetSubscription={resetSubscription}
                refetch={refetch}
              />
              <TentativeCurrentCartFab currentCart={currentCart} />
            </ChildrenOnValidEvent>
          ) : (
            <></>
          )
        }
      </RenderOnData>
      <Outlet />
    </>
  )
}
