import {gql} from '@apollo/client'
import {BASIC_CART_FIELDS, ENABLED_DISCOUNT_FIELDS} from '../graphql'

export const RESERVE = gql`
  ${BASIC_CART_FIELDS}
  mutation Reserve(
    $input: LeadDataInput
    $cartId: Int!
    $sendEmail: Boolean = true
    $localeCode: ECommerceLocaleCode
  ) {
    reserve(
      input: $input
      cartId: $cartId
      sendEmail: $sendEmail
      localeCode: $localeCode
    ) {
      ...BasicCartFields
      uuid
      hash
    }
  }
`

export const CHECKOUT = gql`
  ${BASIC_CART_FIELDS}
  mutation Checkout(
    $input: LeadDataInput
    $paymentIntentInputs: [PaymentIntentInput!]!
    $cartId: Int!
    $sendEmail: Boolean = true
    $localeCode: ECommerceLocaleCode
  ) {
    checkout(
      input: $input
      paymentIntentInputs: $paymentIntentInputs
      cartId: $cartId
      sendEmail: $sendEmail
      localeCode: $localeCode
    ) {
      ...BasicCartFields
      eCommercePaymentRedirectUrl
      uuid
      hash
    }
  }
`

export const ADD_DISCOUNT_TO_CART_TICKET_ITEMS = gql`
  ${BASIC_CART_FIELDS}
  mutation addDiscountToCartTicketItems(
    $cartId: Int!
    $itemIds: [Int!]!
    $discountId: Int!
    $discountCodeId: Int
    $customerLoyaltyId: NonEmptyString
  ) {
    addDiscountToCartTicketItems(
      cartId: $cartId
      itemIds: $itemIds
      discountId: $discountId
      discountCodeId: $discountCodeId
      customerLoyaltyId: $customerLoyaltyId
    ) {
      ...BasicCartFields
    }
  }
`

export const REMOVE_DISCOUNT_FROM_ITEM = gql`
  ${BASIC_CART_FIELDS}
  mutation removeDiscountFromItem(
    $cartId: Int!
    $itemId: Int!
    $discountId: Int!
  ) {
    removeDiscountFromItem(
      cartId: $cartId
      itemId: $itemId
      discountId: $discountId
    ) {
      ...BasicCartFields
    }
  }
`

export const GET_ENABLED_DISCOUNTS = gql`
  ${ENABLED_DISCOUNT_FIELDS}
  query GetEnabledDiscounts(
    $eventId: Int!
    $discountsFilter: EnabledDiscountsFilterInput
  ) {
    event(id: $eventId) {
      enabledDiscounts(discountsFilter: $discountsFilter) {
        ...EnabledDiscountFields
      }
    }
  }
`

export const TOUR_TIME_SLOT_ENABLED_DISCOUNTS = gql`
  ${ENABLED_DISCOUNT_FIELDS}
  query TourTimeSlotEnabledDiscounts(
    $id: PositiveInt!
    $discountsFilter: EnabledDiscountsFilterInput
  ) {
    tourTimeSlot(id: $id) {
      enabledDiscounts(discountsFilter: $discountsFilter) {
        ...EnabledDiscountFields
      }
    }
  }
`

export const CHECK_DISCOUNT_CODE_FOR_CART = gql`
  query checkDiscountCodeForCart(
    $cartId: Int!
    $eventId: Int!
    $codeName: String!
  ) {
    checkDiscountCodeForCart(
      cartId: $cartId
      eventId: $eventId
      codeName: $codeName
    ) {
      id
      name
      state
      description
      usageLimit
      usageCount
      usageLimitPerOrder
      activationDate
      expirationDate
      discount {
        id
        name
        value
        type
        maxUsageLimitPerOrder
      }
    }
  }
`

export const VOUCHER_PROPERTIES_FIELDS = gql`
  fragment VoucherFields on Voucher {
    id
    balance
    state
    activatedAt
    code
    expirationDate
    redemptionsCount
    campaign {
      id
      name
      state
      redemptionLimit
      ecommercePaymentMethod {
        id
        state
      }
    }
  }
`

export const GET_VOUCHER_BY_CODE = gql`
  ${VOUCHER_PROPERTIES_FIELDS}
  query GetVoucherByCode(
    $code: NonEmptyString!
    $pinCode: NonEmptyString!
    $cartId: PositiveInt!
  ) {
    voucherByCodeOnECommerceChannel(
      code: $code
      pinCode: $pinCode
      cartId: $cartId
    ) {
      ...VoucherFields
    }
  }
`

export const REMOVE_TOUR_ITEM_FROM_CART = gql`
  ${BASIC_CART_FIELDS}
  mutation RemoveTourItemFromCart(
    $itemId: PositiveInt!
    $cartId: PositiveInt!
  ) {
    removeTourItemFromCart(itemId: $itemId, cartId: $cartId) {
      ...BasicCartFields
    }
  }
`

export const ADD_DISCOUNT_TO_TOUR_ITEMS = gql`
  ${BASIC_CART_FIELDS}
  mutation AddDiscountToTourItems(
    $cartId: PositiveInt!
    $itemIds: [PositiveInt!]!
    $discountId: PositiveInt!
    $discountCodeId: PositiveInt
  ) {
    addDiscountToTourItems(
      cartId: $cartId
      itemIds: $itemIds
      discountId: $discountId
      discountCodeId: $discountCodeId
    ) {
      ...BasicCartFields
    }
  }
`

export const CHECK_DISCOUNT_CODE_FOR_TOUR_ITEMS = gql`
  query CheckDiscountCodeForTourItems(
    $cartId: PositiveInt!
    $tourTimeSlotId: PositiveInt!
    $discountCodeName: NonEmptyString!
  ) {
    checkDiscountCodeForTourItems(
      cartId: $cartId
      tourTimeSlotId: $tourTimeSlotId
      discountCodeName: $discountCodeName
    ) {
      id
      name
      state
      description
      usageLimit
      usageCount
      usageLimitPerOrder
      activationDate
      expirationDate
      discount {
        id
        name
        value
        type
        maxUsageLimitPerOrder
      }
    }
  }
`
