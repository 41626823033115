import {useTranslation} from 'react-i18next'
import {ShowCrewRoleCode} from '../../__generated__/schema'

export const useTranslateShowCrewRole = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowCrewRoleCode, string> = {
    [ShowCrewRoleCode.Actor]: t<string>('ShowCrewRoleCode->Actor'),
    [ShowCrewRoleCode.Director]: t<string>('ShowCrewRoleCode->Director'),
    [ShowCrewRoleCode.Writer]: t<string>('ShowCrewRoleCode->Writer'),
    [ShowCrewRoleCode.Producer]: t<string>('ShowCrewRoleCode->Producer'),
    [ShowCrewRoleCode.Author]: t<string>('ShowCrewRoleCode->Author'),
    [ShowCrewRoleCode.Direction]: t<string>('ShowCrewRoleCode->Direction'),
    [ShowCrewRoleCode.Dramaturgy]: t<string>('ShowCrewRoleCode->Dramaturgy'),
    [ShowCrewRoleCode.Script]: t<string>('ShowCrewRoleCode->Script'),
    [ShowCrewRoleCode.Music]: t<string>('ShowCrewRoleCode->Music'),
    [ShowCrewRoleCode.Costumes]: t<string>('ShowCrewRoleCode->Costumes'),
    [ShowCrewRoleCode.Rehearsal]: t<string>('ShowCrewRoleCode->Rehearsal'),
    [ShowCrewRoleCode.Choreography]: t<string>(
      'ShowCrewRoleCode->Choreography'
    ),
    [ShowCrewRoleCode.DirectorialCollaboration]: t<string>(
      'ShowCrewRoleCode->DirectorialCollaboration'
    ),
    [ShowCrewRoleCode.Text]: t<string>('ShowCrewRoleCode->Text'),
    [ShowCrewRoleCode.SourceMaterial]: t<string>(
      'ShowCrewRoleCode->SourceMaterial'
    ),
    [ShowCrewRoleCode.Creator]: t<string>('ShowCrewRoleCode->Creator'),
    [ShowCrewRoleCode.CollectiveOfCreators]: t<string>(
      'ShowCrewRoleCode->CollectiveOfCreators'
    ),
    [ShowCrewRoleCode.CreativeTeam]: t<string>(
      'ShowCrewRoleCode->CreativeTeam'
    ),
    [ShowCrewRoleCode.StagingAdaptation]: t<string>(
      'ShowCrewRoleCode->StagingAdaptation'
    ),
    [ShowCrewRoleCode.IdeaConcept]: t<string>('ShowCrewRoleCode->IdeaConcept'),
    [ShowCrewRoleCode.Research]: t<string>('ShowCrewRoleCode->Research'),
    [ShowCrewRoleCode.Adaptation]: t<string>('ShowCrewRoleCode->Adaptation'),
    [ShowCrewRoleCode.Translation]: t<string>('ShowCrewRoleCode->Translation'),
    [ShowCrewRoleCode.RehearsalAssistance]: t<string>(
      'ShowCrewRoleCode->RehearsalAssistance'
    ),
    [ShowCrewRoleCode.Dramatization]: t<string>(
      'ShowCrewRoleCode->Dramatization'
    ),
    [ShowCrewRoleCode.DramaturgyAssistance]: t<string>(
      'ShowCrewRoleCode->DramaturgyAssistance'
    ),
    [ShowCrewRoleCode.DirectorialAssistance]: t<string>(
      'ShowCrewRoleCode->DirectorialAssistance'
    ),
    [ShowCrewRoleCode.Concept]: t<string>('ShowCrewRoleCode->Concept'),
    [ShowCrewRoleCode.TechnicalCoordination]: t<string>(
      'ShowCrewRoleCode->TechnicalCoordination'
    ),
    [ShowCrewRoleCode.TechnicalCrew]: t<string>(
      'ShowCrewRoleCode->TechnicalCrew'
    ),
    [ShowCrewRoleCode.ProductionSupervision]: t<string>(
      'ShowCrewRoleCode->ProductionSupervision'
    ),
    [ShowCrewRoleCode.ChoreographyAssistance]: t<string>(
      'ShowCrewRoleCode->ChoreographyAssistance'
    ),
    [ShowCrewRoleCode.MovementCollaboration]: t<string>(
      'ShowCrewRoleCode->MovementCollaboration'
    ),
    [ShowCrewRoleCode.RehearsalPianistCoach]: t<string>(
      'ShowCrewRoleCode->RehearsalPianistCoach'
    ),
    [ShowCrewRoleCode.AdaptationRevision]: t<string>(
      'ShowCrewRoleCode->AdaptationRevision'
    ),
    [ShowCrewRoleCode.VerseAdaptation]: t<string>(
      'ShowCrewRoleCode->VerseAdaptation'
    ),
    [ShowCrewRoleCode.Lyrics]: t<string>('ShowCrewRoleCode->Lyrics'),
    [ShowCrewRoleCode.MusicSelection]: t<string>(
      'ShowCrewRoleCode->MusicSelection'
    ),
    [ShowCrewRoleCode.OrchestralArrangement]: t<string>(
      'ShowCrewRoleCode->OrchestralArrangement'
    ),
    [ShowCrewRoleCode.MusicalRehearsal]: t<string>(
      'ShowCrewRoleCode->MusicalRehearsal'
    ),
    [ShowCrewRoleCode.MusicalArrangements]: t<string>(
      'ShowCrewRoleCode->MusicalArrangements'
    ),
    [ShowCrewRoleCode.MusicPostProduction]: t<string>(
      'ShowCrewRoleCode->MusicPostProduction'
    ),
    [ShowCrewRoleCode.Sound]: t<string>('ShowCrewRoleCode->Sound'),
    [ShowCrewRoleCode.SoundDesign]: t<string>('ShowCrewRoleCode->SoundDesign'),
    [ShowCrewRoleCode.Orchestration]: t<string>(
      'ShowCrewRoleCode->Orchestration'
    ),
    [ShowCrewRoleCode.MusicArrangement]: t<string>(
      'ShowCrewRoleCode->MusicArrangement'
    ),
    [ShowCrewRoleCode.Libretto]: t<string>('ShowCrewRoleCode->Libretto'),
    [ShowCrewRoleCode.LibrettoAdaptation]: t<string>(
      'ShowCrewRoleCode->LibrettoAdaptation'
    ),
    [ShowCrewRoleCode.VocalAssistance]: t<string>(
      'ShowCrewRoleCode->VocalAssistance'
    ),
    [ShowCrewRoleCode.Voice]: t<string>('ShowCrewRoleCode->Voice'),
    [ShowCrewRoleCode.VocalPreparation]: t<string>(
      'ShowCrewRoleCode->VocalPreparation'
    ),
    [ShowCrewRoleCode.Singing]: t<string>('ShowCrewRoleCode->Singing'),
    [ShowCrewRoleCode.EnsembleSinging]: t<string>(
      'ShowCrewRoleCode->EnsembleSinging'
    ),
    [ShowCrewRoleCode.Choir]: t<string>('ShowCrewRoleCode->Choir'),
    [ShowCrewRoleCode.Conducting]: t<string>('ShowCrewRoleCode->Conducting'),
    [ShowCrewRoleCode.ChoirMaster]: t<string>('ShowCrewRoleCode->ChoirMaster'),
    [ShowCrewRoleCode.Concertmaster]: t<string>(
      'ShowCrewRoleCode->Concertmaster'
    ),
    [ShowCrewRoleCode.PianoAccompaniment]: t<string>(
      'ShowCrewRoleCode->PianoAccompaniment'
    ),
    [ShowCrewRoleCode.Visuals]: t<string>('ShowCrewRoleCode->Visuals'),
    [ShowCrewRoleCode.SetDesign]: t<string>('ShowCrewRoleCode->SetDesign'),
    [ShowCrewRoleCode.Puppets]: t<string>('ShowCrewRoleCode->Puppets'),
    [ShowCrewRoleCode.Scene]: t<string>('ShowCrewRoleCode->Scene'),
    [ShowCrewRoleCode.ScenicDesign]: t<string>(
      'ShowCrewRoleCode->ScenicDesign'
    ),
    [ShowCrewRoleCode.StageDesign]: t<string>('ShowCrewRoleCode->StageDesign'),
    [ShowCrewRoleCode.ScenicCollaboration]: t<string>(
      'ShowCrewRoleCode->ScenicCollaboration'
    ),
    [ShowCrewRoleCode.SetDecoration]: t<string>(
      'ShowCrewRoleCode->SetDecoration'
    ),
    [ShowCrewRoleCode.SetConstruction]: t<string>(
      'ShowCrewRoleCode->SetConstruction'
    ),
    [ShowCrewRoleCode.Projection]: t<string>('ShowCrewRoleCode->Projection'),
    [ShowCrewRoleCode.VideoProduction]: t<string>(
      'ShowCrewRoleCode->VideoProduction'
    ),
    [ShowCrewRoleCode.VideoArt]: t<string>('ShowCrewRoleCode->VideoArt'),
    [ShowCrewRoleCode.Video]: t<string>('ShowCrewRoleCode->Video'),
    [ShowCrewRoleCode.VideoProcessing]: t<string>(
      'ShowCrewRoleCode->VideoProcessing'
    ),
    [ShowCrewRoleCode.VideoDesign]: t<string>('ShowCrewRoleCode->VideoDesign'),
    [ShowCrewRoleCode.Animation]: t<string>('ShowCrewRoleCode->Animation'),
    [ShowCrewRoleCode.SpecialEffects]: t<string>(
      'ShowCrewRoleCode->SpecialEffects'
    ),
    [ShowCrewRoleCode.CostumeDesign]: t<string>(
      'ShowCrewRoleCode->CostumeDesign'
    ),
    [ShowCrewRoleCode.CostumeMaking]: t<string>(
      'ShowCrewRoleCode->CostumeMaking'
    ),
    [ShowCrewRoleCode.Wardrobe]: t<string>('ShowCrewRoleCode->Wardrobe'),
    [ShowCrewRoleCode.Hairstyles]: t<string>('ShowCrewRoleCode->Hairstyles'),
    [ShowCrewRoleCode.Hats]: t<string>('ShowCrewRoleCode->Hats'),
    [ShowCrewRoleCode.Masks]: t<string>('ShowCrewRoleCode->Masks'),
    [ShowCrewRoleCode.LightingDesign]: t<string>(
      'ShowCrewRoleCode->LightingDesign'
    ),
    [ShowCrewRoleCode.Lights]: t<string>('ShowCrewRoleCode->Lights'),
    [ShowCrewRoleCode.Props]: t<string>('ShowCrewRoleCode->Props'),
    [ShowCrewRoleCode.ArtisticCollaboration]: t<string>(
      'ShowCrewRoleCode->ArtisticCollaboration'
    ),
    [ShowCrewRoleCode.Graphics]: t<string>('ShowCrewRoleCode->Graphics'),
    [ShowCrewRoleCode.Illustration]: t<string>(
      'ShowCrewRoleCode->Illustration'
    ),
    [ShowCrewRoleCode.Photography]: t<string>('ShowCrewRoleCode->Photography'),
    [ShowCrewRoleCode.Stage]: t<string>('ShowCrewRoleCode->Stage'),
    [ShowCrewRoleCode.StageManagement]: t<string>(
      'ShowCrewRoleCode->StageManagement'
    ),
    [ShowCrewRoleCode.TechnicalDirection]: t<string>(
      'ShowCrewRoleCode->TechnicalDirection'
    ),
    [ShowCrewRoleCode.LanguageCollaboration]: t<string>(
      'ShowCrewRoleCode->LanguageCollaboration'
    ),
    [ShowCrewRoleCode.LanguageAdaptation]: t<string>(
      'ShowCrewRoleCode->LanguageAdaptation'
    ),
    [ShowCrewRoleCode.EducationalCollaboration]: t<string>(
      'ShowCrewRoleCode->EducationalCollaboration'
    ),
    [ShowCrewRoleCode.ExpertCollaboration]: t<string>(
      'ShowCrewRoleCode->ExpertCollaboration'
    ),
    [ShowCrewRoleCode.Interpretation]: t<string>(
      'ShowCrewRoleCode->Interpretation'
    ),
    [ShowCrewRoleCode.Subtitles]: t<string>('ShowCrewRoleCode->Subtitles'),
    [ShowCrewRoleCode.Camera]: t<string>('ShowCrewRoleCode->Camera'),
    [ShowCrewRoleCode.Drone]: t<string>('ShowCrewRoleCode->Drone'),
    [ShowCrewRoleCode.Guest]: t<string>('ShowCrewRoleCode->Guest'),
    [ShowCrewRoleCode.Soloist]: t<string>('ShowCrewRoleCode->Soloist'),
    [ShowCrewRoleCode.TextFollower]: t<string>(
      'ShowCrewRoleCode->TextFollower'
    ),
    [ShowCrewRoleCode.Supervision]: t<string>('ShowCrewRoleCode->Supervision'),
    [ShowCrewRoleCode.Production]: t<string>('ShowCrewRoleCode->Production'),
    [ShowCrewRoleCode.CoProduction]: t<string>(
      'ShowCrewRoleCode->CoProduction'
    ),
    [ShowCrewRoleCode.InterpretationPerformance]: t<string>(
      'ShowCrewRoleCode->InterpretationPerformance'
    ),
    [ShowCrewRoleCode.Management]: t<string>('ShowCrewRoleCode->Management'),
    [ShowCrewRoleCode.Logistics]: t<string>('ShowCrewRoleCode->Logistics'),
    [ShowCrewRoleCode.ProductionManager]: t<string>(
      'ShowCrewRoleCode->ProductionManager'
    ),
    [ShowCrewRoleCode.LicensingCoordinator]: t<string>(
      'ShowCrewRoleCode->LicensingCoordinator'
    ),
    [ShowCrewRoleCode.License]: t<string>('ShowCrewRoleCode->License')
  }
  return (code: ShowCrewRoleCode) => translatedCodes[code]
}
