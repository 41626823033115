import {Box, Chip, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Reservation, ReservationState} from '../../../__generated__/schema'
import {useFormatDateTimeLong} from '../../hooks/formatting'

interface IReservationChipWithDateProps {
  reservation: Pick<Reservation, 'state' | 'updatedAt' | 'expireAt'>
}

const ReservationChipWithDate: React.FC<IReservationChipWithDateProps> = ({
  reservation
}: IReservationChipWithDateProps) => {
  const {t} = useTranslation()
  const formatDateTimeLong = useFormatDateTimeLong()
  switch (reservation.state) {
    case ReservationState.Canceled:
    default:
      return (
        <>
          <Chip
            sx={{backgroundColor: 'error.light', color: 'error.main'}}
            label={t('Deleted')}
          />
          <Typography variant="body2" sx={{color: 'error.main'}}>
            {formatDateTimeLong(reservation.updatedAt)}
          </Typography>
        </>
      )
    case ReservationState.Expired:
      return (
        <>
          <Chip
            sx={{backgroundColor: 'error.light', color: 'error.main'}}
            label={t('Expired')}
          />
          <Typography variant="body2" sx={{color: 'error.main'}}>
            {formatDateTimeLong(reservation.updatedAt)}
          </Typography>
        </>
      )
    case ReservationState.InCart:
      return (
        <Chip
          sx={{backgroundColor: 'info.light', color: 'info.main'}}
          label={t('In cart')}
        />
      )
    case ReservationState.Fulfilled:
      return (
        <>
          <Chip
            sx={{backgroundColor: 'success.light', color: 'success.main'}}
            label={t('Paid')}
          />
          <Typography variant="body2" sx={{color: 'success.main'}}>
            {formatDateTimeLong(reservation.updatedAt)}
          </Typography>
        </>
      )
    case ReservationState.Active:
      return (
        <>
          <Chip
            sx={{backgroundColor: 'info.light', color: 'info.main'}}
            label={t('Active')}
          />
          <Typography variant="body2" sx={{color: 'info.main'}}>
            {t('Expires at {{date}}', {
              date: formatDateTimeLong(reservation.expireAt)
            })}
          </Typography>
        </>
      )
  }
}

interface IReservationStateBarProps {
  reservation: Pick<Reservation, 'id' | 'state' | 'updatedAt' | 'expireAt'>
}

export const ReservationStateBar: React.FC<IReservationStateBarProps> = ({
  reservation
}: IReservationStateBarProps) => {
  const {t} = useTranslation()
  return (
    <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
      <Typography variant="subtitle1">
        {t('Reservation {{reservationId}}', {reservationId: reservation.id})}
      </Typography>
      <ReservationChipWithDate reservation={reservation} />
    </Box>
  )
}
