import {gql, useQuery} from '@apollo/client'
import {useCallback, useEffect, useState} from 'react'
import {
  GetEventSeatsQuery,
  GetEventSeatsQueryVariables,
  OnEventSeatsChangeSubscription
} from '../../__generated__/schema'
import {
  BASIC_CART_FIELDS,
  ENABLED_DISCOUNT_FIELDS,
  ERROR_PROPERTIES_FIELDS,
  EVENT_DETAIL_FIELDS,
  SALES_AND_RESERVATION_FIELDS,
  SHOW_FIELDS,
  VENUE_FIELDS
} from '../graphql'

export const GET_EVENT_DETAIL = gql`
  ${EVENT_DETAIL_FIELDS}
  ${SALES_AND_RESERVATION_FIELDS}
  ${SHOW_FIELDS}
  ${ENABLED_DISCOUNT_FIELDS}
  ${VENUE_FIELDS}
  query GetEventDetail(
    $id: Int!
    $discountsFilter: EnabledDiscountsFilterInput
  ) {
    event(id: $id) {
      ...EventDetailFields
      ...SalesAndReservationFields
      ...ShowFields
      venue {
        ...VenueFields
      }
      enabledDiscounts(discountsFilter: $discountsFilter) {
        ...EnabledDiscountFields
      }
    }
  }
`

const INCREMENT_ZONE_TICKET_ITEM_QUANTITY_SUCCESS_RESULT_FIELDS = gql`
  fragment IncrementZoneTicketItemQuantitySuccessResultFields on IncrementZoneTicketItemQuantitySuccessResult {
    cart {
      ...BasicCartFields
    }
    newlyAddedZoneTicketItemsCount
  }
  ${BASIC_CART_FIELDS}
`

export const INCREMENT_ZONE_TICKET_ITEM_QUANTITY = gql`
  ${ERROR_PROPERTIES_FIELDS}
  ${INCREMENT_ZONE_TICKET_ITEM_QUANTITY_SUCCESS_RESULT_FIELDS}
  mutation IncrementZoneTicketItemQuantity(
    $cartId: Int
    $eventId: Int!
    $eventSeatUUID: String!
    $increment: NonNegativeInt = 1
  ) {
    incrementZoneTicketItemQuantity(
      cartId: $cartId
      eventId: $eventId
      eventSeatUUID: $eventSeatUUID
      increment: $increment
    ) {
      ...IncrementZoneTicketItemQuantitySuccessResultFields
      ...ErrorResultFields
    }
  }
`

export const ADD_SEATING_ITEM_TO_CART = gql`
  ${BASIC_CART_FIELDS}
  mutation AddSeatingTicketItemToCart(
    $cartId: Int
    $eventId: Int!
    $eventSeatUUID: String!
  ) {
    addSeatingTicketItemToCart(
      cartId: $cartId
      eventId: $eventId
      eventSeatUUID: $eventSeatUUID
    ) {
      ...BasicCartFields
    }
  }
`

export const DECREMENT_ZONE_TICKET_ITEM_QUANTITY = gql`
  ${BASIC_CART_FIELDS}
  mutation DecrementZoneTicketItemQuantity(
    $cartId: Int!
    $eventId: Int!
    $eventSeatUUID: String!
    $decrement: NonNegativeInt!
  ) {
    decrementZoneTicketItemQuantity(
      cartId: $cartId
      eventId: $eventId
      eventSeatUUID: $eventSeatUUID
      decrement: $decrement
    ) {
      ...BasicCartFields
    }
  }
`

export const GET_EVENT_SEATS = gql`
  query GetEventSeats($eventId: Int!, $myCartId: Int) {
    eventSeats(eventId: $eventId, myCartId: $myCartId, myReservationIds: [])
  }
`

export const ON_EVENT_SEATS_CHANGE = gql`
  subscription OnEventSeatsChange($eventId: Int!, $myCartId: Int) {
    eventSeatsChange(
      eventId: $eventId
      myCartId: $myCartId
      myReservationIds: []
    ) {
      eventSeats
    }
  }
`

export const GET_EVENT_STATS = gql`
  query GetEventStats($id: Int!) {
    event(id: $id) {
      id
      reservedSeatsCount
      soldSeatsCount
      availableSeatsCount
      auditoriumLayout {
        id
        capacity
      }
    }
  }
`

export const useEventSeats = (variables: GetEventSeatsQueryVariables) => {
  const {eventId, myCartId} = variables

  const [subscriptionCartId, setSubscriptionCartId] = useState<
    number | null | 'unsubscribed'
  >(myCartId || null)

  const interruptSubscription = useCallback(() => {
    setSubscriptionCartId('unsubscribed')
  }, [])
  const resetSubscription = useCallback((myCartId: null | number) => {
    setSubscriptionCartId(myCartId)
  }, [])

  const {subscribeToMore, ...queryResult} = useQuery<
    GetEventSeatsQuery,
    GetEventSeatsQueryVariables
  >(GET_EVENT_SEATS, {
    variables,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    let unsubscribe: () => void
    if (subscriptionCartId !== 'unsubscribed') {
      unsubscribe = subscribeToMore<OnEventSeatsChangeSubscription>({
        document: ON_EVENT_SEATS_CHANGE,
        variables: {eventId, myCartId: subscriptionCartId},
        updateQuery: (prev, {subscriptionData}) =>
          subscriptionData?.data?.eventSeatsChange?.eventSeats
            ? {
                eventSeats: {
                  seats: {
                    ...prev.eventSeats.seats,
                    ...subscriptionData.data.eventSeatsChange.eventSeats.seats
                  },
                  zones: {
                    ...prev.eventSeats.zones,
                    ...subscriptionData.data.eventSeatsChange.eventSeats.zones
                  }
                }
              }
            : prev
      })
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [subscribeToMore, subscriptionCartId, eventId])

  return {
    ...queryResult,
    data: queryResult.data || queryResult.previousData,
    isInitialLoading: queryResult.previousData ? false : queryResult.loading,
    interruptSubscription,
    resetSubscription
  }
}
