import Decimal from 'decimal.js'
import {sortBy} from 'lodash'
import {v4 as uuidv4} from 'uuid'
import {
  CheckoutOptions,
  ClientDetailFieldsFragment,
  Maybe,
  PaymentServiceProvider
} from '../../__generated__/schema'
import {getIsZeroCartPrice} from './getIsZeroCartPrice'
import {
  CartChangedAction,
  ContentActions,
  ContentActionType,
  IContentState,
  PMRadioGroupOption,
  PMRadioGroupOptionType,
  RedeemedVoucher,
  RedeemVoucherAction,
  RemoveRedeemedVoucherAction
} from './types'

const createRegularPMRadioGroupOption = (id: number): PMRadioGroupOption => ({
  type: PMRadioGroupOptionType.RegularPaymentMethod,
  eCommercePaymentMethodId: id,
  key: uuidv4(),
  isDisabled: false
})

export const getPMRadioGroupOptions = ({
  remainingCartPrice,
  checkoutOptions,
  eCommercePaymentMethods,
  redeemedVouchersCount
}: {
  checkoutOptions: Maybe<CheckoutOptions>[]
  eCommercePaymentMethods: ClientDetailFieldsFragment['eCommercePaymentMethods']
  remainingCartPrice: number
  redeemedVouchersCount: number
}): PMRadioGroupOption[] =>
  getIsZeroCartPrice(remainingCartPrice)
    ? checkoutOptions.reduce<PMRadioGroupOption[]>((acc, checkoutOption) => {
        if (checkoutOption === CheckoutOptions.Sale) {
          return [
            {
              key: uuidv4(),
              type: PMRadioGroupOptionType.PaymentForCartWithZeroPrice,
              isDisabled: false
            },
            ...acc
          ]
        }
        if (checkoutOption === CheckoutOptions.Reservation) {
          return [
            ...acc,
            {
              key: uuidv4(),
              type: PMRadioGroupOptionType.ReservationForCartWithZeroPrice,
              isDisabled: redeemedVouchersCount > 0
            }
          ]
        }
        return acc
      }, [])
    : checkoutOptions.reduce<PMRadioGroupOption[]>((acc, checkoutOption) => {
        if (checkoutOption === CheckoutOptions.Sale) {
          return [
            ...sortBy(eCommercePaymentMethods, 'name')
              .filter(
                (pm) =>
                  pm.serviceProvider !== PaymentServiceProvider.InHouseVoucher
              )
              .map((pm) => createRegularPMRadioGroupOption(pm.id)),
            ...acc
          ]
        } else if (checkoutOption === CheckoutOptions.Reservation) {
          return [
            ...acc,
            {
              type: PMRadioGroupOptionType.Reservation,
              key: uuidv4(),
              isDisabled: redeemedVouchersCount > 0
            }
          ]
        }
        return acc
      }, [])

const getRedeemedCredit = (
  alreadyRedeemedVouchers: RedeemedVoucher[]
): number =>
  alreadyRedeemedVouchers
    .reduce((acc, rv) => acc.add(rv.credit), new Decimal(0))
    .toNumber()

const getRedeemedVouchersWithValidCredit = (
  redeemedVouchersWithoutValidCredit: RedeemedVoucher[],
  cartPrice: number
): RedeemedVoucher[] =>
  redeemedVouchersWithoutValidCredit.reduce<RedeemedVoucher[]>((acc, rv) => {
    const redeemedCredit = getRedeemedCredit(acc)
    const creditRemainingToRedeem = new Decimal(cartPrice)
      .minus(redeemedCredit)
      .toNumber()
    if (creditRemainingToRedeem === 0) {
      return acc
    } else if (creditRemainingToRedeem >= rv.voucher.balance) {
      return [...acc, {...rv, credit: rv.voucher.balance}]
    }
    return [...acc, {...rv, credit: creditRemainingToRedeem}]
  }, [])
export const getDefaultContentState = ({
  cartPrice,
  checkoutOptions,
  eCommercePaymentMethods,
  redeemedVouchers = []
}: {
  checkoutOptions: CheckoutOptions[]
  eCommercePaymentMethods: ClientDetailFieldsFragment['eCommercePaymentMethods']
  cartPrice: number
  redeemedVouchers?: RedeemedVoucher[]
}): IContentState => {
  const inHouseEcommercePaymentMethods: {id: number}[] =
    eCommercePaymentMethods.filter(
      (pm) => pm.serviceProvider === PaymentServiceProvider.InHouseVoucher
    )
  const doesVoucherSectionExists = Boolean(
    inHouseEcommercePaymentMethods.length
  )
  const filteredRedeemedVouchers = redeemedVouchers.filter((rv) =>
    inHouseEcommercePaymentMethods.find(
      (pm) => pm.id === rv.voucher.campaign.ecommercePaymentMethod?.id
    )
  )
  const validRedeemedVouchers = getRedeemedVouchersWithValidCredit(
    filteredRedeemedVouchers,
    cartPrice
  )
  return {
    pMRadioGroupOptions: getPMRadioGroupOptions({
      remainingCartPrice: new Decimal(cartPrice)
        .minus(getRedeemedCredit(validRedeemedVouchers))
        .toNumber(),
      checkoutOptions,
      eCommercePaymentMethods,
      redeemedVouchersCount: redeemedVouchers.length
    }),
    doesVoucherSectionExists,
    redeemedVouchers: validRedeemedVouchers,
    cartPrice,
    checkoutOptions,
    eCommercePaymentMethods
  }
}

const reduceRemoveRedeemedVoucherAction = (
  state: IContentState,
  action: RemoveRedeemedVoucherAction
): IContentState => {
  const redeemedVouchers = getRedeemedVouchersWithValidCredit(
    state.redeemedVouchers.filter((rv) => rv.key !== action.payload),
    state.cartPrice
  )
  const prevRedeemedCredit = getRedeemedCredit(state.redeemedVouchers)
  const redeemedCredit = getRedeemedCredit(redeemedVouchers)
  return {
    ...state,
    pMRadioGroupOptions:
      prevRedeemedCredit === state.cartPrice &&
      redeemedCredit !== state.cartPrice
        ? getPMRadioGroupOptions({
            checkoutOptions: state.checkoutOptions,
            eCommercePaymentMethods: state.eCommercePaymentMethods,
            remainingCartPrice: state.cartPrice,
            redeemedVouchersCount: redeemedVouchers.length
          })
        : redeemedCredit === 0
        ? state.pMRadioGroupOptions.map((o) => ({...o, isDisabled: false}))
        : state.pMRadioGroupOptions,
    redeemedVouchers: getRedeemedVouchersWithValidCredit(
      redeemedVouchers,
      state.cartPrice
    )
  }
}

const recalculateRedeemedVouchers = (
  state: IContentState,
  action: RedeemVoucherAction
) => {
  const redeemedVouchersWithoutValidCredit: RedeemedVoucher[] =
    state.redeemedVouchers.find(
      (rv) => rv.voucher.id === action.payload.voucher.id
    )
      ? state.redeemedVouchers.map((rv) =>
          rv.voucher.id === action.payload.voucher.id
            ? {
                ...rv,
                ...action.payload
              }
            : rv
        )
      : [
          ...state.redeemedVouchers,
          {
            key: uuidv4(),
            ...action.payload,
            credit: 0
          }
        ]
  return getRedeemedVouchersWithValidCredit(
    redeemedVouchersWithoutValidCredit,
    state.cartPrice
  )
}

const reduceRedeemVoucherAction = (
  state: IContentState,
  action: RedeemVoucherAction
) => {
  const redeemedVouchers = recalculateRedeemedVouchers(state, action)
  const prevRedeemedCredit = getRedeemedCredit(state.redeemedVouchers)
  const redeemedCredit = getRedeemedCredit(redeemedVouchers)
  return {
    ...state,
    pMRadioGroupOptions: [redeemedCredit, prevRedeemedCredit].includes(
      state.cartPrice
    )
      ? getPMRadioGroupOptions({
          remainingCartPrice: new Decimal(state.cartPrice)
            .minus(redeemedCredit)
            .toNumber(),
          checkoutOptions: state.checkoutOptions,
          eCommercePaymentMethods: state.eCommercePaymentMethods,
          redeemedVouchersCount: redeemedVouchers.length
        })
      : state.pMRadioGroupOptions.map((pMRadioGroupOption) =>
          [
            PMRadioGroupOptionType.ReservationForCartWithZeroPrice,
            PMRadioGroupOptionType.Reservation
          ].includes(pMRadioGroupOption.type)
            ? {
                ...pMRadioGroupOption,
                isDisabled: true
              }
            : pMRadioGroupOption
        ),
    redeemedVouchers
  }
}

const reduceCartChangedAction = (
  state: IContentState,
  action: CartChangedAction
) => {
  const redeemedVouchers = getRedeemedVouchersWithValidCredit(
    state.redeemedVouchers,
    action.payload.cartPrice
  )
  const redeemedCredit = getRedeemedCredit(redeemedVouchers)
  const prevRedeemedCredit = getRedeemedCredit(state.redeemedVouchers)

  if (state.redeemedVouchers.length === 0 || action.payload.cartPrice === 0) {
    return {
      ...state,
      pMRadioGroupOptions: getPMRadioGroupOptions({
        ...action.payload,
        remainingCartPrice: action.payload.cartPrice,
        redeemedVouchersCount: state.redeemedVouchers.length
      }),
      cartPrice: action.payload.cartPrice,
      checkoutOptions: action.payload.checkoutOptions,
      eCommercePaymentMethods: action.payload.eCommercePaymentMethods,
      redeemedVouchers: []
    }
  }

  if (
    (prevRedeemedCredit >= state.cartPrice &&
      redeemedCredit < action.payload.cartPrice) ||
    (prevRedeemedCredit < state.cartPrice &&
      redeemedCredit === action.payload.cartPrice)
  ) {
    return {
      ...state,
      pMRadioGroupOptions: getPMRadioGroupOptions({
        ...action.payload,
        remainingCartPrice: new Decimal(action.payload.cartPrice)
          .minus(redeemedCredit)
          .toNumber(),
        redeemedVouchersCount: redeemedVouchers.length
      }),
      cartPrice: action.payload.cartPrice,
      checkoutOptions: action.payload.checkoutOptions,
      eCommercePaymentMethods: action.payload.eCommercePaymentMethods,
      redeemedVouchers
    }
  }
  return {
    ...state,
    cartPrice: action.payload.cartPrice,
    checkoutOptions: action.payload.checkoutOptions,
    eCommercePaymentMethods: action.payload.eCommercePaymentMethods,
    redeemedVouchers
  }
}

export const contentStateReducer = (
  state: IContentState,
  action: ContentActions
): IContentState => {
  switch (action.type) {
    case ContentActionType.CartChanged:
      return reduceCartChangedAction(state, action)
    case ContentActionType.RedeemVoucher:
      return reduceRedeemVoucherAction(state, action)
    case ContentActionType.RemoveRedeemedVoucher:
      return reduceRemoveRedeemedVoucherAction(state, action)
    default:
      return state
  }
}
