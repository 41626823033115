import CloseIcon from '@mui/icons-material/Close'
import {IconButton, Snackbar, Typography} from '@mui/material'
import {noop} from 'lodash'
import React, {createContext, useCallback, useContext, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {
  IErrorNotification,
  IInfoNotification,
  NotificationType
} from '../../types'
import {ErrorDialog} from '../atoms/ErrorDialog'

const NotificationContext = createContext<{
  displayErrorDialog: (args: Omit<IErrorNotification, 'id' | 'type'>) => void
  displayInfoNotification: (message: string) => void
}>({
  displayErrorDialog: noop,
  displayInfoNotification: noop
})

interface INotificationProviderProps {
  children: React.ReactNode
}

export const NotificationProvider: React.FC<INotificationProviderProps> = ({
  children
}: INotificationProviderProps) => {
  const [notifications, setNotifications] = useState<
    (IInfoNotification | IErrorNotification)[]
  >([])
  const displayInfoNotification = useCallback((message: string) => {
    setNotifications((notifications) => [
      ...notifications,
      {id: uuidv4(), type: NotificationType.Info, message}
    ])
  }, [])
  const displayErrorDialog = useCallback(
    (errorNotification: Omit<IErrorNotification, 'id' | 'type'>) => {
      setNotifications((notifications) => [
        ...notifications,
        {id: uuidv4(), type: NotificationType.Error, ...errorNotification}
      ])
    },
    []
  )
  const getNotificationCloseHandler = useCallback(
    (id: string) => () => {
      setNotifications((notifications) =>
        notifications.filter((n) => n.id !== id)
      )
    },
    []
  )
  return (
    <NotificationContext.Provider
      value={{displayInfoNotification, displayErrorDialog}}
    >
      <>
        {notifications.map((notification) =>
          notification.type === NotificationType.Info ? (
            <Snackbar
              key={notification.id}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              open
              autoHideDuration={6000}
              onClose={getNotificationCloseHandler(notification.id)}
              message={<Typography>{notification.message}</Typography>}
              action={
                <IconButton
                  color="inherit"
                  onClick={getNotificationCloseHandler(notification.id)}
                >
                  <CloseIcon />
                </IconButton>
              }
              cypress-id="snack-bar"
            />
          ) : (
            <ErrorDialog
              key={notification.id}
              {...notification}
              onClose={getNotificationCloseHandler(notification.id)}
            />
          )
        )}
      </>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotifications = () => useContext(NotificationContext)
