import {SxProps, Typography} from '@mui/material'
import {isEmpty} from 'lodash'
import React from 'react'
import {GetEventDetailQuery} from '../../__generated__/schema'
import {ReadOnlyPaper} from '../../components/atoms/ReadOnly'
import {useLocale} from '../components/atoms/LocaleContext'
import {isLocaleCode, useGetTranslatedValue} from '../hooks/translateLocales'

interface IOverviewReadOnlyPaperProps {
  event: GetEventDetailQuery['event']
  id?: string
  title?: string
  sx?: SxProps
}

const isDescriptionEmpty = (description?: string | null) =>
  description
    ?.replace(/<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi, '')
    .replace(/(?:\\r\\n|\\r|\\n)/g, '')
    .replaceAll(/\s/g, '').length === 0 || isEmpty(description)

export const OverviewReadOnlyPaper: React.FC<IOverviewReadOnlyPaperProps> = ({
  event,
  id,
  title,
  sx
}: IOverviewReadOnlyPaperProps) => {
  const [locale] = useLocale()

  const localeCode = isLocaleCode(locale)
    ? locale
    : event.division.client.localeCodes[0]
  const translations = event.show.translations.find(
    (t) => t.localeCode === localeCode
  )
  const getTranslatedValue = useGetTranslatedValue(
    event.division.client.localeCodes
  )
  const hasDescription = !isDescriptionEmpty(translations?.description)
  return (
    <ReadOnlyPaper sx={sx} id={id} title={title}>
      <Typography variant="subtitle2">
        {getTranslatedValue(event.names)}
      </Typography>
      {hasDescription && (
        <Typography
          sx={{pt: 1}}
          variant="body2"
          dangerouslySetInnerHTML={{__html: translations?.description!}}
        />
      )}
    </ReadOnlyPaper>
  )
}
