import React from 'react'
import {TourTimeSlotFieldsFragment} from '../../../__generated__/schema'
import {IEventHeaderProps} from '../atoms/EventHeader'
import {TourHeader} from '../atoms/TourHeader'
import {CartPaperLayout, ICartPaperLayoutProps} from './CartPaperLayout'

interface ITourPaperProps extends Omit<ICartPaperLayoutProps, 'header'> {
  tourTimeSlot: TourTimeSlotFieldsFragment
  rightAction?: IEventHeaderProps['rightAction']
}

export const TourPaper: React.FC<ITourPaperProps> = ({
  tourTimeSlot,
  isMobile,
  rightAction,
  children,
  ...rest
}: ITourPaperProps) => (
  <CartPaperLayout
    {...rest}
    isMobile={isMobile}
    header={
      <TourHeader
        tourTimeSlot={tourTimeSlot}
        isMobile={isMobile}
        rightAction={rightAction}
        sx={
          isMobile
            ? {
                py: 2,
                px: 3
              }
            : {
                pt: 2,
                px: 3,
                pb: 1.5
              }
        }
      />
    }
  >
    {children}
  </CartPaperLayout>
)
