import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import TodayIcon from '@mui/icons-material/Today'
import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {TourTimeSlotFieldsFragment} from '../../../__generated__/schema'
import {Tooltip} from '../../../components/atoms/Tooltip'
import {useFormatEventDurationDate} from '../../hooks/formatting'
import {useTranslateAgeClassification} from '../../hooks/translateAgeClassification'
import {useTranslateShowVersion} from '../../hooks/translateDistributions'
import {useGetTranslatedValue} from '../../hooks/translateLocales'
import {HeaderLayout, IHeaderLayoutProps} from './HeaderLayout'

interface ITourHeaderProps
  extends Pick<IHeaderLayoutProps, 'isMobile' | 'rightAction' | 'sx'> {
  tourTimeSlot: TourTimeSlotFieldsFragment
}

export const TourHeader: React.FC<ITourHeaderProps> = ({
  tourTimeSlot,
  isMobile,
  rightAction,
  sx
}: ITourHeaderProps) => {
  const {t} = useTranslation()
  const getTranslatedValue = useGetTranslatedValue(
    tourTimeSlot.client.localeCodes
  )
  const formatEventDurationDate = useFormatEventDurationDate()
  const translateShowVersion = useTranslateShowVersion()
  const translateAgeClassification = useTranslateAgeClassification()
  const distributionLabel = [
    tourTimeSlot.versionCode && translateShowVersion(tourTimeSlot.versionCode),
    tourTimeSlot.ageClassificationCode &&
      translateAgeClassification(tourTimeSlot.ageClassificationCode)
  ]
    .filter(Boolean)
    .join(' • ')
  return (
    <HeaderLayout
      title={getTranslatedValue(tourTimeSlot.names)}
      startsAt={
        <>
          {!isMobile && (
            <Tooltip title={t('Tour start')}>
              <TodayIcon
                sx={{
                  color: 'grey.A700'
                }}
                fontSize="small"
              />
            </Tooltip>
          )}
          <Typography
            variant="body2"
            sx={{
              '&::first-letter': {
                textTransform: 'uppercase'
              }
            }}
          >
            {formatEventDurationDate(
              tourTimeSlot.startsAt,
              tourTimeSlot.endsAt,
              true
            )}
          </Typography>
        </>
      }
      venue={
        <>
          {!isMobile && (
            <Tooltip title={t('Venue')}>
              <PlaceOutlinedIcon
                sx={{
                  color: 'grey.A700'
                }}
                fontSize="small"
              />
            </Tooltip>
          )}
          <Typography variant="body2">{tourTimeSlot.venue.name}</Typography>
        </>
      }
      distributionLabel={distributionLabel}
      rightAction={rightAction}
      isMobile={isMobile}
      sx={sx}
    />
  )
}
