import {useMutation} from '@apollo/client'
import {Button, SxProps} from '@mui/material'
import {get} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApiSeatState,
  ErrorMessages,
  EventDetailFieldsFragment,
  EventTicketType,
  IncrementZoneTicketItemQuantityMutation,
  IncrementZoneTicketItemQuantityMutationVariables,
  Scalars
} from '../../__generated__/schema'
import {ListWrapper} from '../../components/atoms/ListWrapper'
import {ZoneListItem} from '../../components/molecules/ZoneListItem'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {
  getGraphQLErrorRelatedToErrorMessage,
  getTicketItemsByEventIdFilter,
  isTicketItem
} from '../utils'
import {INCREMENT_ZONE_TICKET_ITEM_QUANTITY} from './graphql'
import {useDisplayZoneTicketsAddedToCartNotification} from './utils'

interface ISelectTicketsPanelProps {
  sx?: SxProps
  event: EventDetailFieldsFragment
  zones: Scalars['JSON']
  interruptSubscription: () => void
  resetSubscription: (cartId: null | number) => void
  getZoneTicketItemQuantityDecrementer: (
    eventSeatUUID: string,
    decrement?: number
  ) => () => Promise<void>
  getTicketTypeForUuid: (uuid: string) => EventTicketType
}

const getZoneLabelByUuid = (
  event: EventDetailFieldsFragment,
  zoneUuid: string
) => get(event, `auditoriumLayout.layout[${zoneUuid}].config.label`)

export const SelectTicketsPanel: React.FC<ISelectTicketsPanelProps> = ({
  sx,
  event,
  zones,
  interruptSubscription,
  resetSubscription,
  getZoneTicketItemQuantityDecrementer,
  getTicketTypeForUuid
}: ISelectTicketsPanelProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslatePrice(event.division.client.currency)
  const [incrementZoneTicketItemQuantity] = useMutation<
    IncrementZoneTicketItemQuantityMutation,
    IncrementZoneTicketItemQuantityMutationVariables
  >(INCREMENT_ZONE_TICKET_ITEM_QUANTITY)
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const {invalidCheckoutOptionsErrorHandler, invalidCartStateErrorHandler} =
    useEcommerceErrorHandlers()
  const {currentCartId, currentCart, updateCurrentCart, initializeCurrentCart} =
    useCurrentCart()
  const displayZoneTicketsAddedToCartNotification =
    useDisplayZoneTicketsAddedToCartNotification()
  const getIncrementButtonClickHandler = useCallback(
    (eventSeatUUID: string) => async () => {
      try {
        setShowBackdrop(true)
        const increment = 1
        if (!currentCartId) {
          interruptSubscription()
        }
        const {data} = await incrementZoneTicketItemQuantity({
          variables: {
            eventId: event.id,
            cartId: currentCartId,
            eventSeatUUID,
            increment
          }
        })
        if (data) {
          if (
            data.incrementZoneTicketItemQuantity.__typename ===
            'IncrementZoneTicketItemQuantitySuccessResult'
          ) {
            if (!currentCartId) {
              resetSubscription(data.incrementZoneTicketItemQuantity.cart.id)
              initializeCurrentCart(data.incrementZoneTicketItemQuantity.cart)
            } else {
              updateCurrentCart()
            }
            displayZoneTicketsAddedToCartNotification({
              expectedTicketsCount: increment,
              insertedTicketsCount:
                data.incrementZoneTicketItemQuantity
                  .newlyAddedZoneTicketItemsCount
            })
          } else {
            displayZoneTicketsAddedToCartNotification({
              expectedTicketsCount: increment,
              insertedTicketsCount: 0
            })
          }
        }
      } catch (e) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.MaxNumberOfOccupiedSeatsHasBeenReached
          )
        ) {
          defaultErrorHandler(e, {
            title: t('Unable to add more items to cart'),
            description: t(
              'We are sorry, but you are not allowed to add more items to cart, due to exceeding the maximum number of occupied seats for this event.'
            ),
            renderActions: function renderActions(onClose) {
              return (
                <Button variant="text" onClick={onClose}>
                  {t('Got it')}
                </Button>
              )
            }
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.MaxTicketsPerOrderExceeded
          )
        ) {
          defaultErrorHandler(e, {
            title: t('Unable to add more items to cart'),
            description: t(
              'You are allowed to add only {{count}} ticket to cart for this event at once.',
              {count: event.auditoriumLayoutPricing.maxTicketsPerOrder}
            )
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.EventHasNoValidCheckoutOptions
          )
        ) {
          invalidCheckoutOptionsErrorHandler({error: e})
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error: e})
        } else {
          defaultErrorHandler(e, {
            description: t('Error while incrementing zone ticket type')
          })
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      currentCartId,
      defaultErrorHandler,
      displayZoneTicketsAddedToCartNotification,
      event.auditoriumLayoutPricing.maxTicketsPerOrder,
      event.id,
      incrementZoneTicketItemQuantity,
      initializeCurrentCart,
      interruptSubscription,
      invalidCartStateErrorHandler,
      invalidCheckoutOptionsErrorHandler,
      resetSubscription,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )
  return (
    <ListWrapper sx={sx}>
      {Object.entries(zones)
        .sort(([uuidA], [uuidB]) =>
          getZoneLabelByUuid(event, uuidA).localeCompare(
            getZoneLabelByUuid(event, uuidB)
          )
        )
        .map(([uuid, zone]) => {
          const ticketType = getTicketTypeForUuid(uuid)
          const availableCount =
            get(zone, ['states', ApiSeatState.Available]) || 0
          const addedToMyCartCount =
            get(zone, ['states', ApiSeatState.AddedToMyCart]) || 0
          return (
            <ZoneListItem
              key={uuid}
              primaryLabel={getZoneLabelByUuid(event, uuid)}
              secondaryLabel={[
                ticketType.price
                  ? translatePrice(ticketType.price)
                  : translatePrice(0),
                ticketType.description
              ]
                .filter(Boolean)
                .join(' • ')}
              onAddButtonClick={getIncrementButtonClickHandler(uuid)}
              onRemoveButtonClick={getZoneTicketItemQuantityDecrementer(uuid)}
              isSoldOut={availableCount + addedToMyCartCount === 0}
              isAddButtonDisabled={availableCount === 0}
              ticketsInCartCount={
                (currentCart?.items || [])
                  .filter(isTicketItem)
                  .filter(getTicketItemsByEventIdFilter(event.id))
                  .filter((i) => i.eventSeat.uuid === uuid).length
              }
              disabledAddButtonTooltipLabel={t(
                'Unable to add more. The maximum capacity of the zone has been reached.'
              )}
            />
          )
        })}
    </ListWrapper>
  )
}
