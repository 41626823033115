import {useTranslation} from 'react-i18next'
import {LeadField} from '../../__generated__/schema'

export const useTranslateLeadField = () => {
  const {t} = useTranslation()
  const translatedFields: Record<LeadField, string> = {
    [LeadField.Name]: t<string>('LeadField->Name'),
    [LeadField.Phone]: t<string>('LeadField->Phone'),
    [LeadField.Email]: t<string>('LeadField->Email'),
    [LeadField.Note]: t<string>('LeadField->Note'),
    [LeadField.InternalNote]: t<string>('LeadField->InternalNote'),
    [LeadField.CompanyName]: t<string>('LeadField->CompanyName'),
    [LeadField.CompanyIdNumber]: t<string>('LeadField->CompanyIdNumber'),
    [LeadField.VatId]: t<string>('LeadField->VatId'),
    [LeadField.TaxId]: t<string>('LeadField->TaxId'),
    [LeadField.BillingAddressStreet]: t<string>(
      'LeadField->BillingAddressStreet'
    ),
    [LeadField.BillingAddressTown]: t<string>('LeadField->BillingAddressTown'),
    [LeadField.BillingPostalCode]: t<string>('LeadField->BillingPostalCode'),
    [LeadField.BillingAddressCountry]: t<string>(
      'LeadField->BillingAddressCountry'
    ),
    [LeadField.DeliveryAddressee]: t<string>('LeadField->DeliveryAddressee'),
    [LeadField.DeliveryAddressStreet]: t<string>(
      'LeadField->DeliveryAddressStreet'
    ),
    [LeadField.DeliveryAddressTown]: t<string>(
      'LeadField->DeliveryAddressTown'
    ),
    [LeadField.DeliveryPostalCode]: t<string>('LeadField->DeliveryPostalCode'),
    [LeadField.DeliveryAddressCountry]: t<string>(
      'LeadField->DeliveryAddressCountry'
    ),
    [LeadField.IsPrivacyPolicyConsentGranted]: t<string>(
      'LeadField->IsPrivacyPolicyConsentGranted'
    )
  }
  return (code: LeadField) => translatedFields[code]
}
