import {Box, Stack, styled} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {ShowFieldsFragment, ShowImageType} from '../../__generated__/schema'
import {
  GalleryPreview,
  useGalleryPreview
} from '../../components/organisms/galleryPreview/GalleryPreview'
import {MediaPreviewHeader} from '../../components/organisms/galleryPreview/MediaPreviewHeader'

const getGalleryPhotos = (images: ShowFieldsFragment['show']['images']) =>
  images.map(({thumbnails, url: src, id, __typename, type}) => ({
    ...(thumbnails && thumbnails?.length > 0
      ? {
          src: thumbnails[0].url
        }
      : {
          src
        }),
    key: id.toString(),
    alt: id.toString(),
    type,
    id,
    __typename
  }))

const StyledImage = styled('img')({
  width: 'auto',
  height: 202,
  top: 0,
  left: 0,
  cursor: 'pointer',
  opacity: 1,
  transition: '0.3s ease-in-out',
  '&:hover': {
    opacity: 0.7
  }
})

interface IEventGalleryProps {
  images: ShowFieldsFragment['show']['images']
}

export const EventGallery: React.FC<IEventGalleryProps> = ({
  images
}: IEventGalleryProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isOverflown, setIsOverflown] = useState<boolean>(false)
  const sortedImages = [...images].sort((a, b) =>
    a.type === ShowImageType.Photo && b.type !== ShowImageType.Photo
      ? -1
      : a.type !== ShowImageType.Photo && b.type === ShowImageType.Photo
      ? 1
      : 0
  )
  const galleryPreviewHookProps = useGalleryPreview(
    sortedImages.map(({id, url: src}) => ({id, src}))
  )
  useEffect(() => {
    if (ref.current) {
      setIsOverflown(ref.current?.offsetWidth < ref.current?.scrollWidth)
    }
  }, [ref.current?.offsetWidth, ref.current?.scrollWidth])
  return (
    <Box sx={{mt: 1, backgroundColor: 'divider'}}>
      {images.length > 0 && (
        <>
          <Stack
            ref={ref}
            direction="row"
            justifyContent={isOverflown ? 'flex-start' : 'center'}
            sx={{
              height: 202,
              overflow: 'hidden',
              borderRadius: 1,
              position: 'relative',
              background:
                'linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, rgba(0,0,0,0.1) 100%)'
            }}
          >
            {getGalleryPhotos(sortedImages).map(({id, src, alt}) => (
              <StyledImage
                onDragStart={(e) => e.preventDefault()}
                key={id}
                src={src}
                onClick={(e) => {
                  e.stopPropagation()
                  galleryPreviewHookProps.openPreview(id)
                }}
                alt={alt}
              />
            ))}
          </Stack>
          <GalleryPreview
            {...galleryPreviewHookProps}
            header={
              <MediaPreviewHeader
                title={`${galleryPreviewHookProps.currentPhotoIndex + 1}/${
                  images.length
                }`}
                onLeftActionClick={galleryPreviewHookProps.closePreview}
              />
            }
          />
        </>
      )}
    </Box>
  )
}
