import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery
} from '@apollo/client'
import omit from 'lodash/omit'
import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaginationFieldsFragment,
  PaginationInput
} from '../__generated__/schema'
import {useMutationAssistanceHooks} from '../features/hooks/mutationAssistanceHooks'

export const extractPaginationInput = (
  pagination: PaginationFieldsFragment
): PaginationInput => omit(pagination, ['hasMore', '__typename'])

interface IResult<TData, TVariables>
  extends Omit<QueryResult<TData, TVariables>, 'fetchMore'> {
  fetchMore: () => void
  isLoadingMore: boolean
}

interface IPaginationOptions<TData> {
  updateData: (prevData: TData, fetchMoreData: TData) => TData
  mapPaginationInput: (data: TData) => PaginationInput
}

export const useQueryWithPagination = <
  TData extends object | undefined = object,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables>,
  paginationOptions: IPaginationOptions<TData>
): IResult<TData, TVariables> => {
  const {t} = useTranslation()
  const {variables} = options
  const {mapPaginationInput, updateData} = paginationOptions
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
  const {data, fetchMore, ...rest} = useQuery<TData, TVariables>(query, options)
  const {displayErrorDialog} = useMutationAssistanceHooks()
  const fetchMoreData = useCallback(async () => {
    if (data) {
      try {
        setIsLoadingMore(true)
        await fetchMore({
          variables: {
            ...variables,
            paginationInput: mapPaginationInput(data)
          },
          updateQuery: (prevData: TData, {fetchMoreResult}) => {
            if (!fetchMoreResult) return prevData
            return updateData(prevData, fetchMoreResult)
          }
        })
      } catch (error) {
        displayErrorDialog(t('Failed to fetch more data'))
      } finally {
        setIsLoadingMore(false)
      }
    }
  }, [
    data,
    displayErrorDialog,
    fetchMore,
    mapPaginationInput,
    t,
    updateData,
    variables
  ])
  return {data, fetchMore: fetchMoreData, isLoadingMore, ...rest}
}
