import {Box, CircularProgress, SxProps} from '@mui/material'
import React, {UIEvent, useCallback} from 'react'

interface ILazyLoadingWrapperProps {
  children: React.ReactNode
  sx?: SxProps
  isLoadingMore: boolean
  onScrolledNearTheEndOfTheList?: () => void
}

export const LazyLoadingList: React.FC<ILazyLoadingWrapperProps> = ({
  children,
  sx,
  isLoadingMore,
  onScrolledNearTheEndOfTheList
}: ILazyLoadingWrapperProps) => {
  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const element = e.target as HTMLDivElement
      if (e.target) {
        const {scrollTop, scrollHeight} = element
        const height = element.getBoundingClientRect().height
        const maxScrollTop = scrollHeight - height
        if (onScrolledNearTheEndOfTheList && scrollTop >= maxScrollTop - 500) {
          onScrolledNearTheEndOfTheList()
        }
      }
    },
    [onScrolledNearTheEndOfTheList]
  )
  return (
    <Box
      sx={{height: '100%', overflowY: 'auto', ...sx}}
      onScroll={onScrolledNearTheEndOfTheList ? handleScroll : undefined}
    >
      <div>{children}</div>
      {isLoadingMore && (
        <Box sx={{pt: 2, display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
