import dayjs from 'dayjs'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Currency, DiscountType, LocaleCode} from '../../__generated__/schema'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../components/types'
import {useLocale} from '../components/atoms/LocaleContext'
import {useTranslatePrice} from './translateCurrency'

export const useFormatLongWeekDay = () => {
  const [localeCode] = useLocale()
  return useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        weekday: 'long'
      }).format(date),
    [localeCode]
  )
}

export const useFormatShortWeekDay = () => {
  const [localeCode] = useLocale()
  return useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        weekday: 'short'
      }).format(date),
    [localeCode]
  )
}

export const useFormatDateNumeric = () => {
  const [localeCode] = useLocale()
  return useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }).format(date),
    [localeCode]
  )
}

export const useFormatDateWithoutYearNumeric = () => {
  const [localeCode] = useLocale()
  return useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        month: 'numeric',
        day: 'numeric'
      }).format(date),
    [localeCode]
  )
}

export const useFormatTime = () => {
  const [localeCode] = useLocale()
  return useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        hour: '2-digit',
        minute: '2-digit'
      }).format(date),
    [localeCode]
  )
}

export const useFormatDiscountValue = (
  currency: Currency,
  startsWithMinus: boolean = false
) => {
  const translatePrice = useTranslatePrice(currency)
  return ({value, type}: {value: number; type: DiscountType}) => {
    switch (type) {
      case DiscountType.FixedAmount:
        return startsWithMinus
          ? `- ${translatePrice(value)}`
          : translatePrice(value)
      case DiscountType.Percentage:
        return startsWithMinus
          ? `- ${value.toFixed(2)}%`
          : `${value.toFixed(2)}%`
      default:
        return String(value)
    }
  }
}

export const useFormatDateTimeLong = (locale?: LocaleCode) => {
  const [localeCode] = useLocale()
  return useCallback(
    (date: Date | string) =>
      Intl.DateTimeFormat(locale || localeCode, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(new Date(date)),
    [locale, localeCode]
  )
}

const durationToHoursAndMinutesStrings = (
  duration: number
): {hours: string; minutes: string} => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  return {
    hours: `${hours}`,
    minutes: minutes > 9 ? `${minutes}` : `0${minutes}`
  }
}

export const useShowDurationFormatters = () => {
  const {t} = useTranslation()

  const durationToMinutesFormat = useCallback(
    (duration: number) =>
      t('{{count}} minute', {
        count: duration
      }),
    [t]
  )

  const durationToHoursMinutesLongFormat = useCallback(
    (duration: number) => {
      const {hours, minutes} = durationToHoursAndMinutesStrings(duration)

      return `${hours}:${minutes} ${t('hours', {
        postProcess: 'interval',
        count: duration,
        context: 'show_duration'
      })}`
    },
    [t]
  )

  const durationToHoursMinutesShortFormat = useCallback((duration: number) => {
    const {hours, minutes} = durationToHoursAndMinutesStrings(duration)
    return `${hours}:${minutes}`
  }, [])

  const durationToMinutesWithAbbreviatedUnitFormat = useCallback(
    (duration: number) => t('{{duration}} m', {duration}),
    [t]
  )

  return {
    durationToMinutesFormat,
    durationToHoursMinutesShortFormat,
    durationToHoursMinutesLongFormat,
    durationToMinutesWithAbbreviatedUnitFormat
  }
}

export const useFormatEventDurationDate = () => {
  const isDesktop = useIsMediaSize(MediaSizes.DesktopPlus)
  const formatLongWeekDay = useFormatLongWeekDay()
  const formatShortWeekDay = useFormatShortWeekDay()
  const formatDateNumeric = useFormatDateNumeric()
  const formatDateWithoutYearNumeric = useFormatDateWithoutYearNumeric()
  const formatTime = useFormatTime()
  return useCallback(
    (startsAt: string, endsAt: string, isTourTimeSlot?: boolean) => {
      const startsAtDate = new Date(startsAt)
      const endsAtDate = new Date(endsAt)
      const duration = dayjs(endsAtDate).diff(dayjs(startsAtDate), 'minute')
      const showEndsAtTime = isTourTimeSlot && duration >= 240
      const isImminentEnd = dayjs(endsAtDate).isAfter(startsAtDate, 'day')
      const startDate = [
        isDesktop
          ? formatLongWeekDay(startsAtDate)
          : formatShortWeekDay(startsAtDate),
        formatDateNumeric(startsAtDate),
        [
          formatTime(startsAtDate),
          showEndsAtTime && !isImminentEnd && formatTime(endsAtDate)
        ]
          .filter(Boolean)
          .join(' - ')
      ].join(' • ')
      const endDate = [
        isDesktop
          ? formatLongWeekDay(endsAtDate)
          : formatShortWeekDay(endsAtDate),
        isDesktop
          ? formatDateNumeric(endsAtDate)
          : formatDateWithoutYearNumeric(endsAtDate),
        formatTime(endsAtDate)
      ].join(' • ')
      return isImminentEnd ? [startDate, endDate].join(' - ') : startDate
    },
    [
      formatDateNumeric,
      formatDateWithoutYearNumeric,
      formatLongWeekDay,
      formatShortWeekDay,
      formatTime,
      isDesktop
    ]
  )
}

export const useFormatTourDate = () => {
  const isDesktop = useIsMediaSize(MediaSizes.DesktopPlus)
  const formatLongWeekDay = useFormatLongWeekDay()
  const formatShortWeekDay = useFormatShortWeekDay()
  const formatDateNumeric = useFormatDateNumeric()
  const formatTime = useFormatTime()
  return useCallback(
    (startsAt: string) => {
      const startsAtDate = new Date(startsAt)
      return [
        isDesktop
          ? formatLongWeekDay(startsAtDate)
          : formatShortWeekDay(startsAtDate),
        formatDateNumeric(startsAtDate),
        formatTime(startsAtDate)
      ].join(' • ')
    },
    [
      formatDateNumeric,
      formatLongWeekDay,
      formatShortWeekDay,
      formatTime,
      isDesktop
    ]
  )
}
