import React from 'react'
import {useAuthContext} from './AuthContext'
import {Login} from './Login'

interface IRequireAuthProps {
  children: React.ReactElement
}

export const RequireAuth = ({children}: IRequireAuthProps) => {
  const {
    value: {leadUser}
  } = useAuthContext()
  return leadUser ? children : <Login />
}
