import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {Box, Button, IconButton, Paper, Typography} from '@mui/material'
import React, {Dispatch, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Currency} from '../../__generated__/schema'
import {SimpleDialog} from '../../components/atoms/SimpleDialog'
import {Tooltip} from '../../components/atoms/Tooltip'
import {useBooleanState} from '../../hooks/state'
import {useFormatDateNumeric} from '../hooks/formatting'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {CheckVoucherDialog} from './CheckVoucherDialog'
import {
  ContentActions,
  ContentActionType,
  RedeemData,
  RedeemedVoucher
} from './types'
import {VoucherBalanceDialog} from './VoucherBalanceDialog'

interface IVoucherItemProps {
  onRemoveButtonClick: () => void
  primaryLabel: string
  secondaryLabel: string
  rightColumnLabel: string
}

export const VoucherItem: React.FC<IVoucherItemProps> = ({
  onRemoveButtonClick,
  primaryLabel,
  secondaryLabel,
  rightColumnLabel
}: IVoucherItemProps) => {
  const {t} = useTranslation()
  return (
    <Paper
      sx={{
        borderRadius: 4,
        px: 3,
        py: 1.5,
        display: 'grid',
        width: '100%',
        gridTemplateAreas: `
          "icon primaryLabel rightColumnLabel"
          "icon secondaryLabel rightColumnLabel"
        `,
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        columnGap: 1
      }}
      variant="outlined"
    >
      <Tooltip
        title={t('Remove voucher')}
        sx={{gridArea: 'icon', alignSelf: 'center'}}
      >
        <IconButton edge="start" color="primary" onClick={onRemoveButtonClick}>
          <CancelIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="subtitle2" sx={{gridArea: 'primaryLabel'}}>
        {primaryLabel}
      </Typography>
      {secondaryLabel && (
        <Typography
          variant="caption"
          sx={{gridArea: 'secondaryLabel'}}
          color="textSecondary"
        >
          {secondaryLabel}
        </Typography>
      )}
      <Typography
        sx={{
          gridArea: 'rightColumnLabel',
          alignSelf: 'center',
          justifySelf: 'flex-end'
        }}
        variant="body2"
      >
        {rightColumnLabel}
      </Typography>
    </Paper>
  )
}

interface IVouchersContentProps {
  dispatch: Dispatch<ContentActions>
  isAddVoucherDisabled: boolean
  redeemedVouchers: RedeemedVoucher[]
  currency: Currency
  cartId: number
}

export const VouchersContent: React.FC<IVouchersContentProps> = ({
  isAddVoucherDisabled,
  redeemedVouchers,
  currency,
  dispatch,
  cartId
}: IVouchersContentProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslatePrice(currency)
  const [toBeRemovedVoucherKey, setToBeRemovedVoucherKey] =
    useState<null | string>(null)
  const getRemoveButtonClickHandler = useCallback(
    (key: string) => () => {
      setToBeRemovedVoucherKey(key)
    },
    []
  )
  const handleCloseRemoveVoucherDialog = useCallback(() => {
    setToBeRemovedVoucherKey(null)
  }, [])
  const handleConfirmVoucherRemoval = useCallback(() => {
    if (toBeRemovedVoucherKey) {
      dispatch({
        type: ContentActionType.RemoveRedeemedVoucher,
        payload: toBeRemovedVoucherKey
      })
      setToBeRemovedVoucherKey(null)
    }
  }, [dispatch, toBeRemovedVoucherKey])
  const {
    state: isCheckVoucherDialogOpen,
    setTrue: openCheckVoucherDialog,
    setFalse: closeCheckVoucherDialog
  } = useBooleanState(false)
  const [redeemData, setRedeemData] = useState<null | RedeemData>(null)
  const handleVoucherGotten = useCallback((data: RedeemData) => {
    setRedeemData(data)
  }, [])
  const formatDateNumeric = useFormatDateNumeric()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      {redeemedVouchers.map((redeemedVoucher) => (
        <VoucherItem
          key={redeemedVoucher.key}
          onRemoveButtonClick={getRemoveButtonClickHandler(redeemedVoucher.key)}
          primaryLabel={`${redeemedVoucher.voucher.campaign.name} ${redeemedVoucher.voucher.code}`}
          secondaryLabel={[
            redeemedVoucher.voucher.expirationDate
              ? t('Expires: {{date}}', {
                  date: formatDateNumeric(
                    new Date(redeemedVoucher.voucher.expirationDate)
                  ),
                  interpolation: {
                    escapeValue: false
                  }
                })
              : t('Expires: never'),
            redeemedVoucher.voucher.campaign.redemptionLimit
              ? t('Remained redemptions: {{remainedRedemptions}}', {
                  remainedRedemptions:
                    redeemedVoucher.voucher.campaign.redemptionLimit -
                    redeemedVoucher.voucher.redemptionsCount
                })
              : t('Remained redemptions: Unlimited')
          ].join(' • ')}
          rightColumnLabel={translatePrice(redeemedVoucher.credit)}
        />
      ))}

      <Paper
        sx={{
          cursor: isAddVoucherDisabled ? 'normal' : 'pointer',
          borderRadius: 4,
          px: 3,
          py: 0.5,
          display: 'grid',
          width: '100%',
          gridTemplateColumns: 'auto 1fr',
          alignItems: 'center',
          columnGap: 1
        }}
        variant="outlined"
        onClick={isAddVoucherDisabled ? undefined : openCheckVoucherDialog}
      >
        <Tooltip title={t('Add voucher')}>
          <IconButton
            edge="start"
            color="primary"
            disabled={isAddVoucherDisabled}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
        <Typography
          variant="subtitle2"
          color={isAddVoucherDisabled ? 'text.disabled' : undefined}
        >
          {t('I have a voucher code or gift card')}
        </Typography>
      </Paper>
      <CheckVoucherDialog
        cartId={cartId}
        isOpen={isCheckVoucherDialogOpen}
        onClose={closeCheckVoucherDialog}
        onVoucherGotten={handleVoucherGotten}
      />
      {redeemData && (
        <VoucherBalanceDialog
          currency={currency}
          dispatch={dispatch}
          redeemData={redeemData}
          onClose={() => {
            setRedeemData(null)
          }}
        />
      )}
      <SimpleDialog
        isOpen={Boolean(toBeRemovedVoucherKey)}
        title={t('Remove payment method from cart?')}
        content={t(
          'Do you want to remove this voucher from payment methods applied in cart? You will be able to redeem this voucher later.'
        )}
        actions={
          <>
            <Button variant="text" onClick={handleCloseRemoveVoucherDialog}>
              {t('Cancel')}
            </Button>
            <Button variant="text" onClick={handleConfirmVoucherRemoval}>
              {t('Remove')}
            </Button>
          </>
        }
      />
    </Box>
  )
}
