import {useApolloClient, useLazyQuery} from '@apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {noop, sortBy, uniqBy} from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  BasicCartFieldsFragment,
  ErrorMessages,
  GetCurrentCartQuery,
  GetCurrentCartQueryVariables,
  TourTimeSlotFieldsFragment
} from '../../../__generated__/schema'
import {BASIC_CART_FIELDS, GET_CURRENT_CART_QUERY} from '../../graphql'
import {
  SessionStorageKey,
  useLocalStorageState,
  useSessionStorageState
} from '../../hooks/storage'
import {
  CURRENT_CART_EVENTS,
  CURRENT_CART_ID,
  CURRENT_CART_TOUR_TIME_SLOTS
} from '../../localStorageKeys'
import {
  getGraphQLErrorRelatedToErrorMessage,
  isTicketItem,
  isTourItemPropertiesFragment
} from '../../utils'
import {EventDetailWithEnabledDiscountsFields} from '../types'

dayjs.extend(utc)

interface ICurrentCartContext {
  currentCart: BasicCartFieldsFragment | null
  currentCartId: number | null
  initializeCurrentCart(cart: BasicCartFieldsFragment | null): void
  updateCurrentCart: () => void
  resetCurrentCart: () => void
  currentCartEvents: EventDetailWithEnabledDiscountsFields[]
  currentCartTourTimeSlots: TourTimeSlotFieldsFragment[]
  removeCurrentCartEvent: (eventId: number) => void
  removeCurrentCartTourTimeSlot: (slotId: number) => void
}

const CurrentCartContext = createContext<ICurrentCartContext>({
  currentCart: null,
  currentCartId: null,
  initializeCurrentCart: noop,
  updateCurrentCart: noop,
  resetCurrentCart: noop,
  currentCartEvents: [],
  currentCartTourTimeSlots: [],
  removeCurrentCartEvent: noop,
  removeCurrentCartTourTimeSlot: noop
})

interface ICurrentCartContextProviderProps {
  children: React.ReactNode
}

const expandEventsByCartEvents = (
  events: EventDetailWithEnabledDiscountsFields[],
  cart: BasicCartFieldsFragment
) =>
  sortBy(
    uniqBy(
      [
        ...(cart.items || [])
          .filter(isTicketItem)
          .map((ticketItem) => ticketItem.eventSeat.event),
        ...events
      ],
      'id'
    ),
    ['startsAt', 'id']
  )

const expandTourTimeSlotsByCartTourTimeSlots = (
  tourTimeSlots: TourTimeSlotFieldsFragment[],
  cart: BasicCartFieldsFragment
) =>
  sortBy(
    uniqBy(
      [
        ...(cart.items || [])
          .filter(isTourItemPropertiesFragment)
          .map((tourItem) => tourItem.tourTimeSlot),
        ...tourTimeSlots
      ],
      'id'
    ),
    ['startsAt']
  )

export const CurrentCartContextProvider: React.FC<ICurrentCartContextProviderProps> =
  ({children}: ICurrentCartContextProviderProps) => {
    const [currentCartId, setCurrentCartId] = useLocalStorageState<
      number | null
    >(CURRENT_CART_ID, null)
    const client = useApolloClient()
    const [currentCart, setCurrentCart] =
      useState<BasicCartFieldsFragment | null>(null)
    const [currentCartEvents, setCurrentCartEvents] = useLocalStorageState<
      EventDetailWithEnabledDiscountsFields[]
    >(CURRENT_CART_EVENTS, [])
    const [currentCartTourTimeSlots, setCurrentCartTourTimeSlots] =
      useLocalStorageState<TourTimeSlotFieldsFragment[]>(
        CURRENT_CART_TOUR_TIME_SLOTS,
        []
      )

    const [queryCurrentCart] = useLazyQuery<
      GetCurrentCartQuery,
      GetCurrentCartQueryVariables
    >(GET_CURRENT_CART_QUERY, {
      onCompleted: (data) => {
        setCurrentCart(data.cart)
      },
      onError: (error) => {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CartNotFound
          )
        ) {
          setCurrentCartId(null)
        }
      }
    })
    useEffect(() => {
      if (currentCartId !== null && currentCart === null) {
        queryCurrentCart({variables: {cartId: currentCartId}})
        // todo: handle case, cart is expired
      }
    }, [currentCart, currentCartId, queryCurrentCart])

    const updateCurrentCart = useCallback(() => {
      if (currentCartId) {
        const cart = client.readFragment({
          id: `Cart:${currentCartId}`,
          fragment: BASIC_CART_FIELDS,
          fragmentName: 'BasicCartFields'
        })
        setCurrentCart(cart)
        const nce = expandEventsByCartEvents(currentCartEvents, cart)
        setCurrentCartEvents(nce)
        setCurrentCartTourTimeSlots(
          expandTourTimeSlotsByCartTourTimeSlots(currentCartTourTimeSlots, cart)
        )
      }
    }, [
      client,
      currentCartEvents,
      currentCartId,
      currentCartTourTimeSlots,
      setCurrentCartEvents,
      setCurrentCartTourTimeSlots
    ])
    const initializeCurrentCart = useCallback(
      (cart: BasicCartFieldsFragment) => {
        setCurrentCart((c) => c || cart)
        setCurrentCartId(cart.id)
        setCurrentCartEvents(expandEventsByCartEvents([], cart))
        setCurrentCartTourTimeSlots(
          expandTourTimeSlotsByCartTourTimeSlots([], cart)
        )
      },
      [setCurrentCartEvents, setCurrentCartId, setCurrentCartTourTimeSlots]
    )
    const [, storeRedeemedVouchers] = useSessionStorageState<any[]>(
      SessionStorageKey.RedeemedVouchers,
      []
    )
    const resetCurrentCart = useCallback(() => {
      setCurrentCartId(null)
      setCurrentCart(null)
      setCurrentCartEvents([])
      setCurrentCartTourTimeSlots([])
      storeRedeemedVouchers([])
    }, [
      setCurrentCartEvents,
      setCurrentCartId,
      setCurrentCartTourTimeSlots,
      storeRedeemedVouchers
    ])
    const removeCurrentCartEvent = useCallback(
      (eventId: number) => {
        setCurrentCartEvents(
          currentCartEvents.filter((event) => event.id !== eventId)
        )
      },
      [currentCartEvents, setCurrentCartEvents]
    )
    const removeCurrentCartTourTimeSlot = useCallback(
      (slotId: number) =>
        setCurrentCartTourTimeSlots(
          currentCartTourTimeSlots.filter(({id}) => id !== slotId)
        ),
      [currentCartTourTimeSlots, setCurrentCartTourTimeSlots]
    )
    return (
      <CurrentCartContext.Provider
        value={{
          currentCart,
          currentCartId,
          updateCurrentCart,
          initializeCurrentCart,
          resetCurrentCart,
          currentCartEvents,
          currentCartTourTimeSlots,
          removeCurrentCartEvent,
          removeCurrentCartTourTimeSlot
        }}
      >
        {children}
      </CurrentCartContext.Provider>
    )
  }

export const useCurrentCart = () => useContext(CurrentCartContext)
