import {Drawer} from '@mui/material'
import Decimal from 'decimal.js'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {DrawerTemplateHeader} from '../../../../components/atoms/DrawerTemplateHeader'
import {DrawerTemplate} from '../../../../components/molecules/DrawerTemplate'
import {useBooleanState} from '../../../../hooks/state'
import {useSignInCustomer} from '../../../auth'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {SignInForm} from './SignInForm'
import {AccountFormField, ISignInForm} from './types'

const SIGN_IN_FORM_ID = 'signInForm'

interface ISignInDrawerProps {
  onExited: () => void
  onCreateAccountLinkClick: () => void
  clientId?: number
}

export const SignInDrawer: React.FC<ISignInDrawerProps> = ({
  onExited,
  onCreateAccountLinkClick,
  clientId
}: ISignInDrawerProps) => {
  const {t} = useTranslation()
  const signInCustomer = useSignInCustomer()
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const navigate = useNavigate()
  const params = useParams()
  const _clientId = clientId || new Decimal(params.clientId || NaN).toNumber()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  useEffect(() => {
    openDrawer()
  }, [openDrawer])
  const handleSubmit = useCallback(
    async (data: ISignInForm) => {
      try {
        setShowBackdrop(true)
        await signInCustomer({
          variables: {
            clientId: _clientId,
            username: data[AccountFormField.Username],
            password: data[AccountFormField.Password]
          }
        })
        navigate('/myAccount')
      } finally {
        setShowBackdrop(false)
      }
    },
    [_clientId, navigate, setShowBackdrop, signInCustomer]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={closeDrawer}
      anchor="right"
      SlideProps={{onExited}}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 480
        }
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Sign in to account')}
          />
        }
      >
        <SignInForm
          formId={SIGN_IN_FORM_ID}
          onSubmit={handleSubmit}
          defaultValues={{
            [AccountFormField.Username]: '',
            [AccountFormField.Password]: ''
          }}
          onCreateAccountLinkClick={onCreateAccountLinkClick}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
