import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetEventOverviewQuery} from '../../__generated__/schema'
import {useLocale} from '../components/atoms/LocaleContext'
import {useShowDurationFormatters} from '../hooks/formatting'
import {useTranslateAgeClassification} from '../hooks/translateAgeClassification'
import {useTranslateShowCrewRole} from '../hooks/translateCrewRole'
import {
  useTranslateShowFormat,
  useTranslateShowSoundMix,
  useTranslateShowVersion
} from '../hooks/translateDistributions'
import {isLocaleCode} from '../hooks/translateLocales'
import {useTranslateShowContentDescriptorCode} from '../hooks/translateShowContentDescriptorCode'

interface IDetailRowProps {
  label: string
  children: React.ReactNode
}

const DetailRow: React.FC<IDetailRowProps> = ({
  label,
  children
}: IDetailRowProps) => (
  <Box
    sx={(theme) => ({
      display: 'grid',
      gridTemplateColumns: '96px 1fr',
      gap: 2,
      p: 1.5,
      alignItems: 'center',
      borderBottom: `solid ${theme.palette.divider} 1px`,
      ':last-child': {
        borderBottom: 'none'
      }
    })}
  >
    <Typography variant="subtitle2">{label}</Typography>
    <Typography variant="body2" component="div">
      {children}
    </Typography>
  </Box>
)

interface IEventDetailsProps {
  event: GetEventOverviewQuery['event']
}

export const EventDetails: React.FC<IEventDetailsProps> = ({
  event
}: IEventDetailsProps) => {
  const {t} = useTranslation()
  const [locale] = useLocale()
  const localeCode = isLocaleCode(locale)
    ? locale
    : event.division.client.localeCodes[0]
  const translateAgeClassification = useTranslateAgeClassification()
  const translateShowFormat = useTranslateShowFormat()
  const translateShowVersion = useTranslateShowVersion()
  const translateShowSoundMix = useTranslateShowSoundMix()
  const {durationToMinutesFormat, durationToHoursMinutesLongFormat} =
    useShowDurationFormatters()
  const translateShowCrewRole = useTranslateShowCrewRole()
  const translateShowContentDescriptorCode =
    useTranslateShowContentDescriptorCode()
  const translations = event.show.translations.find(
    (t) => t.localeCode === localeCode
  )
  const hasAdditionalInfo =
    event.formatCode || event.versionCode || event.soundMixCode
  return (
    <Box>
      <DetailRow label={t('Venue')}>{event.venue.name}</DetailRow>
      <DetailRow label={t('Address')}>
        {[
          event.venue.address.complex,
          event.venue.address.street,
          event.venue.address.postalCode,
          event.venue.address.town
        ]
          .filter(Boolean)
          .join(', ')}
      </DetailRow>
      {event.ageClassificationCode && (
        <DetailRow label={t('Age restrictions')}>
          {[
            translateAgeClassification(event.ageClassificationCode),
            event.show.contentDescriptorCodes &&
              event.show.contentDescriptorCodes.length > 0 &&
              event.show.contentDescriptorCodes
                .map((code) => translateShowContentDescriptorCode(code))
                .join(', ')
          ]
            .filter(Boolean)
            .join(' • ')}
        </DetailRow>
      )}
      {hasAdditionalInfo && (
        <DetailRow label={t('Event details')}>
          {[
            event.formatCode && translateShowFormat(event.formatCode),
            event.versionCode && translateShowVersion(event.versionCode),
            event.soundMixCode && translateShowSoundMix(event.soundMixCode)
          ]
            .filter(Boolean)
            .join(' • ')}
        </DetailRow>
      )}
      <DetailRow label={t('Duration')}>
        {`${durationToMinutesFormat(
          event.duration
        )} (${durationToHoursMinutesLongFormat(event.duration)})`}
      </DetailRow>
      {translations?.tagline && (
        <Box sx={{p: 1.5}}>
          <Typography variant="subtitle2">{translations.tagline}</Typography>
        </Box>
      )}
      {translations?.description && (
        <Box sx={{px: 1.5}}>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{__html: translations.description}}
          />
        </Box>
      )}
      {event.show.crew.map(({crewRoleCode, persons}) => (
        <Box key={crewRoleCode} sx={{p: 1.5}}>
          <Typography variant="subtitle2">
            {translateShowCrewRole(crewRoleCode)}
          </Typography>
          <Typography variant="body2">
            {persons.map(
              (person, index, array) =>
                `${[
                  person.name,
                  person.description && `(${person.description})`
                ]
                  .filter(Boolean)
                  .join(' ')}${index !== array.length - 1 ? ' • ' : ''}`
            )}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
