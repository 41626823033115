import {noop} from 'lodash'
import React, {createContext, useCallback, useContext} from 'react'
import {SessionStorageKey, useSessionStorageState} from '../../hooks/storage'
import {ThemeType} from '../../types'

const ThemeContext = createContext<{
  theme: ThemeType | null
  setTheme: (themeType: ThemeType | null) => void
}>({theme: null, setTheme: noop})

interface IThemeContextProviderProps {
  children: React.ReactElement
}

export const ThemeContextProvider: React.FC<IThemeContextProviderProps> = ({
  children
}: IThemeContextProviderProps) => {
  const [contextValue, setContextValue] =
    useSessionStorageState<ThemeType | null>(
      SessionStorageKey.ThemeContextValue,
      null
    )
  const setTheme = useCallback(
    (theme: ThemeType | null) => setContextValue(theme),
    [setContextValue]
  )
  return (
    <ThemeContext.Provider value={{theme: contextValue, setTheme}}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useThemeContextProvider = () => useContext(ThemeContext)
