import {gql} from '@apollo/client'

export const NOTIFY_USER_RETURNED_FROM_PAYMENT_GATEWAY = gql`
  mutation NotifyUserReturnedFromPaymentGateway(
    $paymentServiceProvider: PaymentServiceProvider!
    $uuid: String!
    $hash: String!
    $paymentIntentId: PositiveInt!
  ) {
    notifyUserReturnedFromPaymentGateway(
      paymentServiceProvider: $paymentServiceProvider
      uuid: $uuid
      hash: $hash
      paymentIntentId: $paymentIntentId
    )
  }
`
