import {Box, SxProps} from '@mui/material'
import React from 'react'

interface IJoinedChildrenProps {
  children: React.ReactElement[]
  joinElement?: React.ReactElement
  sx?: SxProps
}

export const JoinedChildren: React.FC<IJoinedChildrenProps> = ({
  children,
  joinElement = <span>•</span>,
  sx
}: IJoinedChildrenProps) => {
  const count = React.Children.count(children)
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        ...sx
      }}
    >
      {React.Children.toArray(children).reduce(
        (acc, comp, i) => [
          acc,
          comp,
          i < count - 1
            ? React.cloneElement(joinElement, {key: `join-element-${i}`})
            : null
        ],
        []
      )}
    </Box>
  )
}
