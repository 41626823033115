import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {IconButton, InputAdornment} from '@mui/material'
import React from 'react'

interface IPasswordInputAdornmentProps {
  onClick: () => void
  isPasswordVisibilityOn: boolean
}

export const PasswordInputAdornment: React.FC<IPasswordInputAdornmentProps> = ({
  onClick,
  isPasswordVisibilityOn
}: IPasswordInputAdornmentProps) => (
  <InputAdornment position="end">
    <IconButton onClick={onClick}>
      {isPasswordVisibilityOn ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </IconButton>
  </InputAdornment>
)
