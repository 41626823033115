import {Box, Typography} from '@mui/material'
import React from 'react'

interface IPriceRowProps {
  primaryPrice: string
  children?: React.ReactNode
}

export const PriceRow: React.FC<IPriceRowProps> = ({
  children,
  primaryPrice
}: IPriceRowProps) => (
  <Box
    minHeight={60}
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      px: 3
    }}
  >
    {children}
    <Box sx={{flexGrow: 1}} />
    <Typography variant="subtitle2">{primaryPrice}</Typography>
  </Box>
)
