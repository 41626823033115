import {debounce} from '@mui/material'
import React, {createContext, useContext, useEffect, useState} from 'react'
import {MediaSizes} from '../types'

const WindowInnerWidthContext = createContext<number>(window.innerWidth)

interface IWindowInnerWidthProviderProps {
  children: React.ReactNode
}

export const WindowInnerWidthContextProvider: React.FC<IWindowInnerWidthProviderProps> =
  ({children}: IWindowInnerWidthProviderProps) => {
    const [value, setValue] = useState(window.innerWidth)
    useEffect(() => {
      const debouncedResizeChangeHandler = debounce(() => {
        setValue(window.innerWidth)
      }, 300)
      window.addEventListener('resize', debouncedResizeChangeHandler)
      return () => {
        window.removeEventListener('resize', debouncedResizeChangeHandler)
        debouncedResizeChangeHandler.clear()
      }
    }, [])

    return (
      <WindowInnerWidthContext.Provider value={value}>
        {children}
      </WindowInnerWidthContext.Provider>
    )
  }

export const useIsMediaSize = (mediaSize: MediaSizes): boolean => {
  const currentWidth = useContext(WindowInnerWidthContext)
  switch (mediaSize) {
    case MediaSizes.SmallMobile:
      return currentWidth <= 575
    case MediaSizes.LargeMobilePlus:
      return currentWidth >= 576
    case MediaSizes.TabletPlus:
      return currentWidth >= 768
    case MediaSizes.DesktopPlus:
      return currentWidth >= 960
    case MediaSizes.LargeDesktop:
      return currentWidth >= 1365
    default:
      return false
  }
}
