import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  styled,
  Typography
} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../components/atoms/DialogTitleWithCloseButton'
import {ResponsiveDialog} from '../../components/molecules/ResponsiveDialog'
import {useAuthContext} from '../auth/AuthContext'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../components/molecules/UncontrolledFormRadioGroup'
import {UncontrolledFormTextInput} from '../components/molecules/UncontrolledFormTextInput'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {ILoyaltyIdForm, LoyaltyIdFormField, WhomLoyaltyIdOption} from './types'

const SELECT_LOYALTY_ID_FORM = 'selectLoyaltyIdForm'

const StyledForm = styled('form')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  padding: theme.spacing(3)
}))

interface ISelectLoyaltyIdDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (formData: ILoyaltyIdForm) => Promise<void>
}

export const SelectLoyaltyIdDialog: React.FC<ISelectLoyaltyIdDialogProps> = ({
  isOpen,
  onClose,
  onSubmit
}: ISelectLoyaltyIdDialogProps) => {
  const {t} = useTranslation()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const {
    value: {customer}
  } = useAuthContext()
  const {control, errors, setValue, watch, handleSubmit, setError} =
    useForm<ILoyaltyIdForm>({
      defaultValues: {
        [LoyaltyIdFormField.WhomLoyaltyId]: customer
          ? WhomLoyaltyIdOption.My
          : WhomLoyaltyIdOption.Another,
        [LoyaltyIdFormField.LoyaltyId]: customer?.loyaltyId || ''
      }
    })
  const watchedWhomLoyaltyId = watch(LoyaltyIdFormField.WhomLoyaltyId)
  const _onSubmit = useCallback(
    async (formData: ILoyaltyIdForm) => {
      try {
        await onSubmit(formData)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CustomerNotFound
          )
        ) {
          setError(LoyaltyIdFormField.LoyaltyId, '', t('Customer not found'))
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CustomerIsNotActive
          )
        ) {
          setError(
            LoyaltyIdFormField.LoyaltyId,
            '',
            t('Customer is not active')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CustomerLoyaltyLevelExpired
          )
        ) {
          defaultErrorHandler(error, {
            title: t('Loyalty benefits have expired'),
            description: t(
              'The loyalty benefits associated with your loyalty ID have expired. To regain access to these benefits, please visit us in person to renew your loyalty membership.'
            )
          })
        } else {
          defaultErrorHandler(error, {title: t('Error while adding discount')})
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, t]
  )
  useEffect(() => {
    if (customer) {
      setValue(LoyaltyIdFormField.WhomLoyaltyId, WhomLoyaltyIdOption.My)
    } else {
      setValue(LoyaltyIdFormField.WhomLoyaltyId, WhomLoyaltyIdOption.Another)
    }
  }, [customer, setValue])
  useEffect(() => {
    if (watchedWhomLoyaltyId === WhomLoyaltyIdOption.My) {
      setValue(LoyaltyIdFormField.LoyaltyId, customer?.loyaltyId || '')
    }
    if (watchedWhomLoyaltyId === WhomLoyaltyIdOption.Another) {
      setValue(LoyaltyIdFormField.LoyaltyId, '')
    }
  }, [customer?.loyaltyId, setValue, watchedWhomLoyaltyId])
  return (
    <ResponsiveDialog isOpen={isOpen} onClose={onClose}>
      <>
        <DialogTitleWithCloseButton onCloseIconClick={onClose}>
          {t('Check your loyalty ID')}
        </DialogTitleWithCloseButton>
        <DialogContent dividers sx={{p: 0}}>
          <StyledForm
            id={SELECT_LOYALTY_ID_FORM}
            onSubmit={(e) => {
              e.stopPropagation()
              handleSubmit(_onSubmit)(e)
            }}
          >
            <Typography variant="caption" color="textSecondary">
              {t(
                'This special discount is available only to our registered customers who meet certain conditions.'
              )}
            </Typography>
            <Box sx={{display: customer ? 'initial' : 'none'}}>
              <UncontrolledFormRadioGroup<ILoyaltyIdForm, WhomLoyaltyIdOption>
                fullWidth
                name={LoyaltyIdFormField.WhomLoyaltyId}
                control={control}
                errors={errors}
                options={[
                  {
                    value: WhomLoyaltyIdOption.My,
                    label: (
                      <BasicRadioLabel
                        primaryText={t('Use my loyalty id')}
                        secondaryText={customer?.loyaltyId}
                      />
                    )
                  },
                  {
                    value: WhomLoyaltyIdOption.Another,
                    label: (
                      <BasicRadioLabel
                        primaryText={t('Use another loyalty ID')}
                      />
                    )
                  }
                ]}
              />
            </Box>
            <Box
              sx={{
                display:
                  watchedWhomLoyaltyId === WhomLoyaltyIdOption.My
                    ? 'none'
                    : 'initial'
              }}
            >
              <UncontrolledFormTextInput<ILoyaltyIdForm>
                fullWidth
                errors={errors}
                control={control}
                name={LoyaltyIdFormField.LoyaltyId}
                validationOptions={{
                  required: watchedWhomLoyaltyId === WhomLoyaltyIdOption.Another
                }}
                label={t('Type your loyalty ID here')}
                inputProps={{
                  sx: {textTransform: 'uppercase'}
                }}
              />
            </Box>
          </StyledForm>
        </DialogContent>
        <DialogActions sx={{px: 3, py: 1.75}}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form={SELECT_LOYALTY_ID_FORM}
          >
            {t('Apply')}
          </Button>
        </DialogActions>
      </>
    </ResponsiveDialog>
  )
}
