import {SxProps} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetEventDetailQuery} from '../../__generated__/schema'
import {
  EmptyReadOnlyRow,
  ReadOnlyLabel,
  ReadOnlyPaper,
  ReadOnlyRow
} from '../../components/atoms/ReadOnly'
import {
  useFormatDateNumeric,
  useFormatTime,
  useShowDurationFormatters
} from '../hooks/formatting'
import {useTranslateAgeClassification} from '../hooks/translateAgeClassification'
import {
  useTranslateShowFormat,
  useTranslateShowSoundMix,
  useTranslateShowVersion
} from '../hooks/translateDistributions'
import {useTranslateGenre} from '../hooks/translateGenres'
import {useGetTranslatedValue} from '../hooks/translateLocales'
import {useTranslateShowContentDescriptorCode} from '../hooks/translateShowContentDescriptorCode'
import {useTranslateShowType} from '../hooks/translateShowTypes'

interface IEventReadOnlyPaperProps {
  event: GetEventDetailQuery['event']
  id?: string
  title?: string
  sx?: SxProps
}

export const EventReadOnlyPaper: React.FC<IEventReadOnlyPaperProps> = ({
  event,
  id,
  title,
  sx
}: IEventReadOnlyPaperProps) => {
  const {t} = useTranslation()
  const getTranslatedValue = useGetTranslatedValue(
    event.division.client.localeCodes
  )
  const translateGenre = useTranslateGenre()
  const translateShowType = useTranslateShowType()
  const formatDate = useFormatDateNumeric()
  const formatTime = useFormatTime()
  const translateShowFormat = useTranslateShowFormat()
  const translateShowVersion = useTranslateShowVersion()
  const translateShowSoundMix = useTranslateShowSoundMix()
  const translateAgeClassification = useTranslateAgeClassification()
  const translateShowContentDescriptorCode =
    useTranslateShowContentDescriptorCode()
  const {durationToMinutesFormat, durationToHoursMinutesLongFormat} =
    useShowDurationFormatters()
  const hasAdditionalInfo =
    event.formatCode ||
    event.soundMixCode ||
    event.versionCode ||
    event.ageClassificationCode ||
    event.show.globalReleaseDate ||
    event.show.productionYear
  return (
    <ReadOnlyPaper sx={sx} id={id} title={title}>
      <ReadOnlyRow label={t('Title')}>
        {getTranslatedValue(event.names)}
      </ReadOnlyRow>
      <ReadOnlyRow label={t('Genre')}>
        {[
          translateShowType(event.show.typeCode),
          event.show.genreCodes
            .filter(Boolean)
            .map((gc) => translateGenre(gc))
            .join(', ')
        ]
          .filter(Boolean)
          .join(' • ')}
      </ReadOnlyRow>
      <ReadOnlyRow label={t('Date')}>
        {formatDate(new Date(event.startsAt))}
      </ReadOnlyRow>
      <ReadOnlyRow label={t('Time')}>
        {formatTime(new Date(event.startsAt))}
      </ReadOnlyRow>
      <ReadOnlyRow label={t('Duration')}>{`${durationToHoursMinutesLongFormat(
        event.duration
      )} (${durationToMinutesFormat(event.duration)})`}</ReadOnlyRow>
      {hasAdditionalInfo && (
        <>
          <EmptyReadOnlyRow />
          <ReadOnlyLabel label={t('Additional info')} />
          {event.formatCode && (
            <ReadOnlyRow label={t('Format')}>
              {translateShowFormat(event.formatCode)}
            </ReadOnlyRow>
          )}
          {event.soundMixCode && (
            <ReadOnlyRow label={t('Sound')}>
              {translateShowSoundMix(event.soundMixCode)}
            </ReadOnlyRow>
          )}
          {event.versionCode && (
            <ReadOnlyRow label={t('Version')}>
              {translateShowVersion(event.versionCode)}
            </ReadOnlyRow>
          )}
          {event.ageClassificationCode && (
            <ReadOnlyRow label={t('Age restrictions')}>
              {translateAgeClassification(event.ageClassificationCode)}
            </ReadOnlyRow>
          )}
          {event.show.contentDescriptorCodes &&
            event.show.contentDescriptorCodes.length > 0 && (
              <ReadOnlyRow label={t('Content descriptors')}>
                {event.show.contentDescriptorCodes
                  .map((code) => translateShowContentDescriptorCode(code))
                  .join(', ')}
              </ReadOnlyRow>
            )}
          {event.show.globalReleaseDate && (
            <ReadOnlyRow label={t('Premiere')}>
              {formatDate(new Date(event.show.globalReleaseDate))}
            </ReadOnlyRow>
          )}
          {event.show.productionYear && (
            <ReadOnlyRow label={t('Production year')}>
              {event.show.productionYear}
            </ReadOnlyRow>
          )}
        </>
      )}
    </ReadOnlyPaper>
  )
}
