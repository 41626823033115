import {debounce} from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'

export interface IDimensions {
  width: number
  height: number
}

/**
 * // todo: suitable for sharing
 */
export const useElementDimensions = <
  T extends HTMLElement = HTMLDivElement
>(): [React.MutableRefObject<T | null>, IDimensions] => {
  const ref = useRef<null | T>(null)
  const [dimensions, setDimensions] = useState({width: 0, height: 0})

  const onResize = useCallback(() => {
    const current = ref && ref.current
    if (current) {
      const domRect = current.getBoundingClientRect()
      const newDimensions: IDimensions = {
        width: Math.floor(domRect.width),
        height: Math.floor(domRect.height)
      }
      setDimensions(newDimensions)
    }
  }, [])

  useEffect(() => {
    const current = ref && ref.current
    const debouncedOnResize = debounce(onResize, 400)
    if (current) {
      onResize()
      window.addEventListener('resize', debouncedOnResize)
    }
    return () => {
      if (current) {
        window.removeEventListener('resize', debouncedOnResize)
        debouncedOnResize.cancel()
      }
    }
  }, [onResize])

  return [ref, dimensions]
}
