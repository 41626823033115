import {gql} from '@apollo/client'
import {PAGINATION_FIELDS, TOUR_TIME_SLOT_FIELDS} from '../graphql'

export const GET_TOUR_TIME_SLOTS_LIST_DATA = gql`
  ${TOUR_TIME_SLOT_FIELDS}
  ${PAGINATION_FIELDS}
  query GetTourTimeSlotsListData(
    $filter: TourTimeSlotsFilter!
    $paginationInput: PaginationInput!
  ) {
    tourTimeSlots(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...TourTimeSlotFields
      }
      pagination {
        ...PaginationFields
      }
    }
  }
`
