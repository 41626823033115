import {Button, styled, Typography} from '@mui/material'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {PasswordInputAdornment} from '../../components/atoms/PasswordInputAdornment'
import {useBooleanState} from '../../hooks/state'
import {UncontrolledFormTextInput} from '../components/molecules/UncontrolledFormTextInput'
import {useIsValidPassword} from '../hooks/formsValidations'
import {IRecoverPasswordForm, RecoverPasswordFormField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%'
}))

interface IRecoverPasswordFormProps {
  formId: string
  onSubmit: (data: IRecoverPasswordForm) => void
  defaultValues?: Partial<IRecoverPasswordForm>
}

export const RecoverPasswordForm: React.FC<IRecoverPasswordFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IRecoverPasswordFormProps) => {
  const {t} = useTranslation()
  const {control, errors, getValues, handleSubmit} =
    useForm<IRecoverPasswordForm>({
      defaultValues,
      reValidateMode: 'onBlur'
    })
  const {state: isPasswordVisibilityOn, toggle: togglePasswordVisibility} =
    useBooleanState(false)
  const {
    state: isConfirmPasswordVisibilityOn,
    toggle: toggleConfirmPasswordVisibility
  } = useBooleanState(false)
  const isValidPassword = useIsValidPassword()
  return (
    <StyledForm id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Typography variant="caption" color="textSecondary">
        {t('Enter your new password')}
      </Typography>
      <UncontrolledFormTextInput<IRecoverPasswordForm>
        errors={errors}
        control={control}
        name={RecoverPasswordFormField.Password}
        label={t('New password')}
        fullWidth
        validationOptions={{required: true, validate: isValidPassword}}
        type={isPasswordVisibilityOn ? 'text' : 'password'}
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <PasswordInputAdornment
              onClick={togglePasswordVisibility}
              isPasswordVisibilityOn={isPasswordVisibilityOn}
            />
          )
        }}
      />
      <UncontrolledFormTextInput<IRecoverPasswordForm>
        errors={errors}
        control={control}
        name={RecoverPasswordFormField.ConfirmPassword}
        label={t('Confirm new password')}
        fullWidth
        autoComplete="new-password"
        validationOptions={{
          required: true,
          validate: (confirmPassword) => {
            const password = getValues(RecoverPasswordFormField.Password)
            return password === confirmPassword || t('Passwords did not match.')
          }
        }}
        type={isConfirmPasswordVisibilityOn ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <PasswordInputAdornment
              onClick={toggleConfirmPasswordVisibility}
              isPasswordVisibilityOn={isConfirmPasswordVisibilityOn}
            />
          )
        }}
      />
      <Button type="submit" form={formId} color="primary" variant="contained">
        {t('Save')}
      </Button>
    </StyledForm>
  )
}
