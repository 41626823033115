import {ApolloError} from '@apollo/client'
import {useCallback} from 'react'
import {reportErrorToSentry} from '../../sentry'
import {useBackdrop} from '../components/atoms/BackdropContext'
import {useNotifications} from '../components/molecules/NotificationContext'
import {IErrorNotification} from '../types'

export const useDefaultErrorHandler = () => {
  const {displayErrorDialog} = useNotifications()

  return useCallback<
    (
      error: ApolloError,
      errorDialogProps: Omit<IErrorNotification, 'id' | 'type'>
    ) => void
  >(
    (
      error: ApolloError,
      errorDialogProps: Omit<IErrorNotification, 'id' | 'type'>
    ) => {
      console.error(errorDialogProps.description, error) // eslint-disable-line no-console
      reportErrorToSentry(error, 'defaultErrorHandler')
      displayErrorDialog(errorDialogProps)
    },
    [displayErrorDialog]
  )
}

export const useMutationAssistanceHooks = () => {
  const defaultErrorHandler = useDefaultErrorHandler()
  const {displayInfoNotification, displayErrorDialog} = useNotifications()
  const [, setShowBackdrop] = useBackdrop()
  return {
    defaultErrorHandler,
    displayInfoNotification,
    displayErrorDialog,
    setShowBackdrop
  }
}
