import React, {useMemo} from 'react'
import {FieldValues, useFormContext} from 'react-hook-form'
import {ExistingCountryCode, LeadField} from '../../../../__generated__/schema'
import {useTranslateCountry} from '../../../hooks/translateCountry'
import {useTranslateLeadField} from '../../../hooks/translateLeadField'
import {FormAutocomplete} from '../../molecules/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../molecules/UncontrolledFormTextInput'
import {FormFieldName} from '../../types'
import {AccountFormField} from './types'

export const BillingAddressFormPart = <
  FormValues extends FieldValues = FieldValues
>() => {
  const translateLeadField = useTranslateLeadField()
  const translateCountry = useTranslateCountry()
  const {errors, control} = useFormContext<FormValues>()
  const autocompleteOptions = useMemo(
    () =>
      Object.values(ExistingCountryCode).map((code: ExistingCountryCode) => ({
        value: code as FormValues[FormFieldName<FormValues>],
        name: translateCountry(code)
      })),
    [translateCountry]
  )
  return (
    <>
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={
          AccountFormField.BillingAddressStreet as FormFieldName<FormValues>
        }
        label={translateLeadField(LeadField.BillingAddressStreet)}
        fullWidth
      />
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.BillingAddressTown as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.BillingAddressTown)}
        fullWidth
      />
      <UncontrolledFormTextInput<FormValues>
        errors={errors}
        control={control}
        name={AccountFormField.BillingPostalCode as FormFieldName<FormValues>}
        label={translateLeadField(LeadField.BillingPostalCode)}
        fullWidth
      />
      <FormAutocomplete<FormValues>
        fullWidth
        errors={errors}
        control={control}
        autocompleteOptions={autocompleteOptions}
        label={translateLeadField(LeadField.BillingAddressCountry)}
        name={
          AccountFormField.BillingAddressCountry as FormFieldName<FormValues>
        }
      />
    </>
  )
}
