import {useQuery} from '@apollo/client'
import {Typography} from '@mui/material'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
  GetTourTimeSlotDetailQuery,
  GetTourTimeSlotDetailQueryVariables
} from '../../__generated__/schema'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {CalendarAlertIcon} from '../../components/icons'
import {RenderOnData} from '../../components/molecules/RenderOnData'
import {MediaSizes} from '../../components/types'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from '../components/atoms/CurrentCartFab'
import {Warning, WarningColorCombination} from '../components/atoms/Warning'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {BlankPage} from '../components/organisms/BlankPage'
import {ChildrenOnValidTourTimeSlot} from './ChildrenOnValidTourTimeSlot'
import {Content} from './Content'
import {GET_TOUR_TIME_SLOT_DETAIL} from './graphql'

export const TourTimeSlotDetail: React.FC = () => {
  const params = useParams<{tourTimeSlotId: string}>()
  const tourTimeSlotId = new Decimal(params.tourTimeSlotId || NaN).toNumber()
  const {t} = useTranslation()
  const {currentCart, resetCurrentCart} = useCurrentCart()

  useEffect(() => {
    if (currentCart && dayjs().isAfter(currentCart.expiresAt)) {
      resetCurrentCart()
    }
  }, [currentCart, resetCurrentCart])

  const {data, loading, error} = useQuery<
    GetTourTimeSlotDetailQuery,
    GetTourTimeSlotDetailQueryVariables
  >(GET_TOUR_TIME_SLOT_DETAIL, {
    variables: {id: tourTimeSlotId}
  })

  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  return (
    <RenderOnData<GetTourTimeSlotDetailQuery>
      data={data}
      error={error}
      loading={loading}
      errorElement={
        <BlankPage header={<BaseHeader title={t('Not found')} />}>
          <>
            <Warning
              maxWidth={isMobile ? '100%' : 360}
              title={t('Tour time slot not found')}
              description={
                <Typography>
                  {t(
                    'We are sorry, but we are not able to recognise this tour time slot. Please, check whether you have correct link.'
                  )}
                </Typography>
              }
              icon={
                <CalendarAlertIcon
                  sx={{width: 48, height: 48, color: 'text.primary'}}
                />
              }
              colorCombination={WarningColorCombination.Warning}
            />
            <TentativeCurrentCartFab currentCart={currentCart} />
          </>
        </BlankPage>
      }
    >
      {({tourTimeSlot}) => (
        <ChildrenOnValidTourTimeSlot tourTimeSlot={tourTimeSlot}>
          <Content tourTimeSlot={tourTimeSlot} isMobile={isMobile} />
          <TentativeCurrentCartFab currentCart={currentCart} />
        </ChildrenOnValidTourTimeSlot>
      )}
    </RenderOnData>
  )
}
