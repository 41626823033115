import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const Powered: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Typography
      component="div"
      variant="caption"
      color="textSecondary"
      sx={{
        textAlign: 'center'
      }}
    >
      {t('we run on')}&nbsp;<strong>{t('attendio')}</strong>
    </Typography>
  )
}
