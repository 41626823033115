import {Box, CircularProgress, Typography} from '@mui/material'
import React from 'react'

interface ICountdownProps {
  text?: string | null
}

export const Countdown: React.FC<ICountdownProps> = ({
  text
}: ICountdownProps) => (
  <Box sx={{position: 'relative'}}>
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {text && (
        <Typography sx={{color: 'text.primary', pb: 1}} variant="h6">
          {text}
        </Typography>
      )}
    </Box>
    <CircularProgress size={48} color="inherit" />
  </Box>
)
