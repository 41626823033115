import {gql} from '@apollo/client'
import {
  BASIC_CART_FIELDS,
  EVENT_DETAIL_FIELDS,
  LEAD_DATA_FIELDS,
  TOUR_ITEM_FIELDS,
  TOUR_TIME_SLOT_FIELDS
} from '../graphql'

export const CART_SUMMARY_TICKET_ITEM_FIELDS = gql`
  fragment CartSummaryTicketItemFields on TicketItem {
    id
    price
    priceBeforeDiscount
    appliedDiscounts {
      discount {
        id
        name
      }
    }
    eventSeat {
      id
      uuid
      label
      row
      section
      floor
      type
    }
  }
`

export const GET_CART_SUMMARY = gql`
  ${LEAD_DATA_FIELDS}
  ${CART_SUMMARY_TICKET_ITEM_FIELDS}
  ${BASIC_CART_FIELDS}
  ${EVENT_DETAIL_FIELDS}
  ${TOUR_TIME_SLOT_FIELDS}
  ${TOUR_ITEM_FIELDS}
  query GetCartSummary($uuid: String!, $hash: String!) {
    cartSummary(uuid: $uuid, hash: $hash) {
      ...BasicCartFields
      id
      state
      price
      sales {
        id
        price
        event {
          ...EventDetailFields
        }
        tourTimeSlot {
          ...TourTimeSlotFields
        }
        items {
          ...CartSummaryTicketItemFields
          ...TourItemFields
        }
      }
      reservations {
        id
        price
        event {
          ...EventDetailFields
        }
        items {
          ...CartSummaryTicketItemFields
          ...TourItemFields
        }
        tourTimeSlot {
          ...TourTimeSlotFields
        }
        relatedSale {
          id
          items {
            ... on TicketItem {
              id
            }
            ... on ProductItem {
              id
            }
            ... on TourItem {
              id
            }
          }
        }
        state
        uuid
        hash
        updatedAt
        expireAt
      }
      lead {
        id
        data {
          ...LeadDataFields
        }
      }
      items {
        ...CartSummaryTicketItemFields
        ...TourItemFields
        ... on TicketItem {
          eventSeat {
            id
            event {
              ...EventDetailFields
            }
          }
        }
      }
      client {
        id
        currency
      }
      uuid
      hash
    }
  }
`

export const CANCEL_RESERVATION = gql`
  mutation CancelReservation(
    $reservationId: Int!
    $reservationEcommerceIdentifiers: EcommerceIdentifiers
  ) {
    cancelReservation(
      reservationId: $reservationId
      reservationEcommerceIdentifiers: $reservationEcommerceIdentifiers
    ) {
      id
    }
  }
`

export const ADD_RESERVATION_TO_CART = gql`
  ${BASIC_CART_FIELDS}
  mutation AddReservationToCart(
    $reservationId: Int!
    $cartId: Int
    $reservationEcommerceIdentifiers: EcommerceIdentifiers
  ) {
    addReservationToCart(
      reservationId: $reservationId
      cartId: $cartId
      reservationEcommerceIdentifiers: $reservationEcommerceIdentifiers
    ) {
      ...BasicCartFields
    }
  }
`
