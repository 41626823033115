import {useMutation} from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, Button, Chip, Typography} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  AddDiscountToCartTicketItemsMutation,
  AddDiscountToCartTicketItemsMutationVariables,
  EnabledDiscountFieldsFragment,
  ErrorMessages,
  RemoveDiscountFromItemMutation,
  RemoveDiscountFromItemMutationVariables,
  TicketItemFieldsFragment
} from '../../__generated__/schema'
import {safeSum} from '../../utils/math'
import {EventPaper} from '../components/molecules/EventPaper'
import {EventDetailWithEnabledDiscountsFields} from '../components/types'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useGetTicketItemFromCartRemover} from '../hooks/getTicketItemFromCartRemover'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {useDiscountCodeContext} from './DiscountCodeContext'
import {EventTicketItem} from './EventTicketItem'
import {
  ADD_DISCOUNT_TO_CART_TICKET_ITEMS,
  REMOVE_DISCOUNT_FROM_ITEM
} from './graphql'
import {SelectLoyaltyIdDialog} from './SelectLoyaltyIdDialog'
import {ILoyaltyIdForm, LoyaltyIdFormField} from './types'
import {useDiscountErrorHandler} from './utils'

interface ICartEventPaperProps {
  eventTicketItems: TicketItemFieldsFragment[]
  event: EventDetailWithEnabledDiscountsFields
  cartId: number
  isMobile: boolean
  onNotInterestedClick: () => void
}

export const CartEventPaper: React.FC<ICartEventPaperProps> = ({
  eventTicketItems,
  event,
  cartId,
  isMobile,
  onNotInterestedClick
}: ICartEventPaperProps) => {
  const {t} = useTranslation()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const {invalidCartStateErrorHandler} = useEcommerceErrorHandlers()
  const discountErrorHandler = useDiscountErrorHandler()
  const {closeSelectItemsForDiscountModal, checkAndApplyDiscountCode} =
    useDiscountCodeContext()
  const total = safeSum(eventTicketItems.map((ticketItem) => ticketItem.price))
  const translatePrice = useTranslatePrice(event.division.client.currency)
  const navigate = useNavigate()
  const handleAddMoreClick = useCallback(() => {
    navigate(`/event/${event.id}`)
  }, [event.id, navigate])
  const getTicketItemFromCartRemover = useGetTicketItemFromCartRemover()
  const [removeDiscountFromItem] = useMutation<
    RemoveDiscountFromItemMutation,
    RemoveDiscountFromItemMutationVariables
  >(REMOVE_DISCOUNT_FROM_ITEM)
  const [selectedCusomterDiscount, setSelectedCustomerDiscount] =
    useState<(EnabledDiscountFieldsFragment & {ticketItemId: number}) | null>(
      null
    )
  const [addDiscountToCartTicketItems] = useMutation<
    AddDiscountToCartTicketItemsMutation,
    AddDiscountToCartTicketItemsMutationVariables
  >(ADD_DISCOUNT_TO_CART_TICKET_ITEMS)
  const handleMoreInfoClick = useCallback(
    () => navigate(`${event.id}/eventInfo`, {replace: true}),
    [event.id, navigate]
  )
  const handleOnAppliedDiscountChipDeleteClick = useCallback(
    (ticketItemId: number, discountId: number) => async () => {
      setShowBackdrop(true)
      try {
        await removeDiscountFromItem({
          variables: {cartId, discountId, itemId: ticketItemId}
        })
      } catch (e) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error: e})
        } else {
          defaultErrorHandler(e, {
            title: t('Unable to remove discount'),
            description: e.graphQLErrors[0].message
          })
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      cartId,
      defaultErrorHandler,
      invalidCartStateErrorHandler,
      removeDiscountFromItem,
      setShowBackdrop,
      t
    ]
  )
  const handleSubmitItemsForDiscount = useCallback(
    async (itemIds: number[], discountId: number, discountCodeId: number) => {
      try {
        setShowBackdrop(true)
        await addDiscountToCartTicketItems({
          variables: {
            cartId,
            discountId,
            itemIds,
            discountCodeId
          }
        })
        closeSelectItemsForDiscountModal()
      } catch (error) {
        discountErrorHandler(error, t('Error while adding discount'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addDiscountToCartTicketItems,
      cartId,
      closeSelectItemsForDiscountModal,
      discountErrorHandler,
      setShowBackdrop,
      t
    ]
  )
  const handleOpenDiscountCodeModal = useCallback(() => {
    checkAndApplyDiscountCode({
      items: eventTicketItems,
      eventId: event.id,
      onSubmit: handleSubmitItemsForDiscount
    })
  }, [
    checkAndApplyDiscountCode,
    event.id,
    eventTicketItems,
    handleSubmitItemsForDiscount
  ])
  const handleSelectLoyaltyIdSubmit = useCallback(
    async (formData: ILoyaltyIdForm) => {
      if (selectedCusomterDiscount) {
        try {
          setShowBackdrop(true)
          await addDiscountToCartTicketItems({
            variables: {
              cartId,
              discountId: selectedCusomterDiscount.id,
              itemIds: [selectedCusomterDiscount.ticketItemId],
              customerLoyaltyId:
                formData[LoyaltyIdFormField.LoyaltyId].toUpperCase()
            }
          })
          setSelectedCustomerDiscount(null)
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addDiscountToCartTicketItems,
      cartId,
      selectedCusomterDiscount,
      setShowBackdrop
    ]
  )
  return (
    <EventPaper
      rightAction={
        <Chip
          label={t('More info')}
          size="small"
          onClick={handleMoreInfoClick}
          icon={<InfoOutlinedIcon />}
          variant="outlined"
        />
      }
      isMobile={isMobile}
      sx={{
        mb: 2,
        ':last-child': {
          mb: 0
        }
      }}
      event={event}
    >
      {eventTicketItems.map((ticketItem) => (
        <EventTicketItem
          sx={{px: 3}}
          key={ticketItem.id}
          ticketItem={ticketItem}
          translatePrice={translatePrice}
          isRemoveButtonHidden={Boolean(ticketItem.reservation)}
          getRemoveButtonClickHandler={getTicketItemFromCartRemover}
          enabledDiscounts={event.enabledDiscounts}
          removeButtonTooltip={t('Remove from cart')}
          isMobile={isMobile}
          cartId={cartId}
          onIHaveDiscountCodeClick={handleOpenDiscountCodeModal}
          handleOnAppliedDiscountChipDeleteClick={
            handleOnAppliedDiscountChipDeleteClick
          }
          onCustomerDiscountClick={setSelectedCustomerDiscount}
        />
      ))}
      <Box
        minHeight={60}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 3
        }}
      >
        <Button
          startIcon={isMobile ? undefined : <AddIcon />}
          variant="contained"
          onClick={handleAddMoreClick}
        >
          {isMobile ? t('Add') : t('Add more')}
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifySelf: 'flex-end',
            gap: 1
          }}
        >
          {eventTicketItems.length > 0 && (
            <Chip
              variant="outlined"
              label={t('Discount code')}
              onClick={handleOpenDiscountCodeModal}
            />
          )}
          {eventTicketItems.length > 0 ? (
            <Typography variant="subtitle2" sx={{fontWeight: 500}}>
              {translatePrice(total)}
            </Typography>
          ) : (
            <Button onClick={onNotInterestedClick}>
              {t('Not interested')}
            </Button>
          )}
        </Box>
      </Box>
      <SelectLoyaltyIdDialog
        isOpen={Boolean(selectedCusomterDiscount)}
        onClose={() => setSelectedCustomerDiscount(null)}
        onSubmit={handleSelectLoyaltyIdSubmit}
      />
    </EventPaper>
  )
}
