import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import {Box, Divider, IconButton, SxProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '../../../components/atoms/Tooltip'
import {useIsMediaSize} from '../../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../../components/types'
import {LanguageSelect} from './LanguageSelect'
import {MobileMoreMenu} from './MobileMoreMenu'

export interface IBaseHeaderProps {
  sx?: SxProps
  title: string
  children?: React.ReactNode
}

export const BaseHeader: React.FC<IBaseHeaderProps> = ({
  sx,
  title,
  children
}: IBaseHeaderProps) => {
  const {t} = useTranslation()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 1,
        rowGap: 0.5,
        backgroundColor: 'background.paper',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        alignItems: 'center',
        px: 3,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        height: 64,
        ...sx
      }}
    >
      <Typography variant="h6" sx={{pr: 1}}>
        {title}
      </Typography>
      {children}
      {children && <Divider orientation="vertical" sx={{maxHeight: 32}} />}
      {isMobile ? (
        <MobileMoreMenu />
      ) : (
        <>
          <Tooltip title={t('I need help')}>
            <IconButton color="inherit">
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip placement="bottom-start" title={t('Switch language')}>
            <LanguageSelect />
          </Tooltip>
        </>
      )}
    </Box>
  )
}
