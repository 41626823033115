import {useMutation} from '@apollo/client'
import {CanvasObjectType} from '@attendio/shared-components'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, Chip, Divider, IconButton, Tab, Tabs} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  AuditoriumLayoutType,
  DecrementZoneTicketItemQuantityMutation,
  DecrementZoneTicketItemQuantityMutationVariables,
  ErrorMessages,
  EventDetailFieldsFragment,
  EventTicketType,
  Scalars,
  ShowFieldsFragment
} from '../../__generated__/schema'
import {Tooltip} from '../../components/atoms/Tooltip'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../components/types'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {EventHeader} from '../components/atoms/EventHeader'
import {Powered} from '../components/atoms/Powered'
import {LanguageSelect} from '../components/molecules/LanguageSelect'
import {MobileMoreMenu} from '../components/molecules/MobileMoreMenu'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {AuditoriumPanel} from './AuditoriumPanel'
import {DECREMENT_ZONE_TICKET_ITEM_QUANTITY} from './graphql'
import {OpenGraphTags} from './OpenGraphTags'
import {SelectTicketsPanel} from './SelectTicketsPanel'
import {EventDetailPanel} from './types'

interface IContentProps {
  event: EventDetailFieldsFragment & ShowFieldsFragment
  panel: EventDetailPanel
  onPanelChange: (panel: EventDetailPanel) => void
  eventSeats: Scalars['JSON']
  interruptSubscription: () => void
  resetSubscription: (cartId: null | number) => void
  refetch: () => void
}

const useLayout = (
  event: EventDetailFieldsFragment,
  ticketTypesByTicketTypeId: {[id: string]: EventTicketType}
) =>
  useMemo(() => {
    const entries = Object.entries(event.auditoriumLayout.layout)
    const acc: {[key: string]: any} = {}
    for (const [key, o] of entries) {
      if (![CanvasObjectType.Zone, CanvasObjectType.Seat].includes(o.type)) {
        acc[key] = o
      } else {
        // @ts-ignore
        const ticketTypeId = event.auditoriumLayoutPricing.pricing[key].id
        acc[key] = {
          ...o,
          color: ticketTypesByTicketTypeId[String(ticketTypeId)].color,
          ticketTypeId
        }
      }
    }
    return acc
  }, [
    event.auditoriumLayout.layout,
    event.auditoriumLayoutPricing.pricing,
    ticketTypesByTicketTypeId
  ])

export const Content: React.FC<IContentProps> = ({
  event,
  panel,
  onPanelChange,
  eventSeats,
  interruptSubscription,
  resetSubscription
}: IContentProps) => {
  const {t} = useTranslation()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const navigate = useNavigate()
  const onMoreInfoClick = useCallback(() => navigate('eventInfo'), [navigate])
  const handleTabsChange = useCallback(
    (e, p: EventDetailPanel) => {
      e.preventDefault()
      onPanelChange(p)
    },
    [onPanelChange]
  )
  const [decrementZoneTicketItemQuantityMutation] = useMutation<
    DecrementZoneTicketItemQuantityMutation,
    DecrementZoneTicketItemQuantityMutationVariables
  >(DECREMENT_ZONE_TICKET_ITEM_QUANTITY)
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const {invalidCartStateErrorHandler} = useEcommerceErrorHandlers()
  const {currentCartId, updateCurrentCart} = useCurrentCart()
  const getZoneTicketItemQuantityDecrementer = useCallback(
    (eventSeatUUID: string, decrement: number = 1) =>
      async () => {
        try {
          setShowBackdrop(true)
          if (currentCartId) {
            const {data} = await decrementZoneTicketItemQuantityMutation({
              variables: {
                eventId: event.id,
                cartId: currentCartId,
                eventSeatUUID,
                decrement
              }
            })
            if (data) {
              updateCurrentCart()
            }
          }
        } catch (error) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.InvalidCartState
            )
          ) {
            invalidCartStateErrorHandler({error})
          } else {
            defaultErrorHandler(error, {
              description: t('Updating zone tickets quantity failed')
            })
          }
        } finally {
          setShowBackdrop(false)
        }
      },
    [
      currentCartId,
      decrementZoneTicketItemQuantityMutation,
      defaultErrorHandler,
      event.id,
      invalidCartStateErrorHandler,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )

  const ticketTypesByTicketTypeId: {[id: string]: EventTicketType} = useMemo(
    () =>
      event.activePricing.ticketTypes.reduce(
        (acc: {[id: string]: EventTicketType}, ticketType) => ({
          ...acc,
          [ticketType.ticketTypeId]: ticketType
        }),
        {}
      ),
    [event.activePricing.ticketTypes]
  )
  const layout = useLayout(event, ticketTypesByTicketTypeId)
  const getTicketTypeForUuid = useCallback(
    (uuid: string) =>
      ticketTypesByTicketTypeId[String((layout as any)[uuid].ticketTypeId)] ??
      {},
    [layout, ticketTypesByTicketTypeId]
  )
  return (
    <>
      <OpenGraphTags event={event} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          overflow: 'hidden'
        }}
      >
        <EventHeader
          isMobile={isMobile}
          sx={{
            backgroundColor: 'background.paper',
            px: 3,
            ...(isMobile
              ? {
                  py: 2
                }
              : {
                  pt: 1,
                  pb: 2
                })
          }}
          event={event}
          rightAction={
            <Chip
              label={t('More info')}
              size="small"
              onClick={onMoreInfoClick}
              icon={<InfoOutlinedIcon />}
              variant="outlined"
            />
          }
          mobileMoreMenu={<MobileMoreMenu />}
          helpIconButton={
            <Tooltip title={t('I need help')}>
              <IconButton color="inherit">
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          }
          languageSelect={
            <Tooltip title={t('Switch language')}>
              <LanguageSelect />
            </Tooltip>
          }
        />
        <Divider />
        {event.auditoriumLayout.type === AuditoriumLayoutType.ZoneFloor && (
          <>
            <Tabs
              value={panel}
              onChange={handleTabsChange}
              centered
              sx={{
                backgroundColor: 'background.paper'
              }}
            >
              <Tab
                label={t('Select tickets')}
                value={EventDetailPanel.SelectTickets}
              />
              <Tab
                label={t('View auditorium')}
                value={EventDetailPanel.Auditorium}
              />
            </Tabs>
            <Divider />
          </>
        )}
        {panel === EventDetailPanel.Auditorium && (
          <AuditoriumPanel
            eventSeats={eventSeats}
            event={event}
            layout={layout}
            getTicketTypeForUuid={getTicketTypeForUuid}
            interruptSubscription={interruptSubscription}
            resetSubscription={resetSubscription}
            sx={{
              flex: 1
            }}
          />
        )}
        {panel === EventDetailPanel.SelectTickets && (
          <SelectTicketsPanel
            event={event}
            zones={eventSeats.zones}
            interruptSubscription={interruptSubscription}
            resetSubscription={resetSubscription}
            getZoneTicketItemQuantityDecrementer={
              getZoneTicketItemQuantityDecrementer
            }
            getTicketTypeForUuid={getTicketTypeForUuid}
            sx={{
              flex: 1
            }}
          />
        )}
        <Powered />
      </Box>
    </>
  )
}
