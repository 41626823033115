import {useQuery} from '@apollo/client'
import {
  Box,
  Drawer,
  LinearProgress,
  LinearProgressProps,
  Typography
} from '@mui/material'
import Decimal from 'decimal.js'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {
  GetEventStatsQuery,
  GetEventStatsQueryVariables
} from '../../__generated__/schema'
import {DrawerTemplateHeader} from '../../components/atoms/DrawerTemplateHeader'
import {ReadOnlyPaper, ReadOnlyRow} from '../../components/atoms/ReadOnly'
import {useBooleanState} from '../../hooks/state'
import {useLocale} from '../components/atoms/LocaleContext'
import {DrawerTemplateWithSideNavigation} from '../components/molecules/DrawerTemplateWithSideNavigation'
import {WIDE_DRAWER_SX_PROPS} from './EventDetailDrawer'
import {GET_EVENT_STATS} from './graphql'

interface IStatRowProps {
  count: number
  percentage: number
  isRightAligned?: boolean
  color: LinearProgressProps['color']
}

const StatRow: React.FC<IStatRowProps> = ({
  count,
  percentage,
  isRightAligned = false,
  color
}: IStatRowProps) => {
  const [locale] = useLocale()
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '99px 1fr',
        alignItems: 'center',
        gap: 2
      }}
    >
      <Typography variant="body2">
        {[
          count,
          `${percentage.toLocaleString(locale, {maximumFractionDigits: 2})}%`
        ].join(' • ')}
      </Typography>
      <LinearProgress
        variant="determinate"
        color={color}
        value={percentage}
        sx={{
          transform: isRightAligned ? 'scale(-1)' : undefined,
          '&.MuiLinearProgress-determinate': {
            backgroundColor: 'divider'
          }
        }}
      />
    </Box>
  )
}

const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {overview: {id: string; label: string}} => ({
      overview: {
        id: 'overview',
        label: t('Overview')
      }
    }),
    [t]
  )
}

export const EventStatsDrawer: React.FC = () => {
  const {t} = useTranslation()
  const params = useParams()
  const navigationItems = useNavigationItems()
  const navigate = useNavigate()
  const eventId = new Decimal(params.eventId || NaN).toNumber()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)

  useEffect(() => {
    openDrawer()
  }, [openDrawer])

  const onExited = useCallback(
    () => navigate(`/event/${eventId}`, {replace: true}),
    [eventId, navigate]
  )

  const {data, loading, error} = useQuery<
    GetEventStatsQuery,
    GetEventStatsQueryVariables
  >(GET_EVENT_STATS, {
    variables: {id: eventId}
  })
  return (
    <Drawer
      open={isOpen}
      onClose={closeDrawer}
      anchor="right"
      SlideProps={{onExited}}
      PaperProps={{sx: WIDE_DRAWER_SX_PROPS}}
    >
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          isLoading: loading,
          errorMessage: error && t('Loading event data failed'),
          header: (
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Event statistics')}
            />
          )
        }}
        navigationItems={navigationItems}
      >
        {data?.event && (
          <ReadOnlyPaper
            id={navigationItems.overview.id}
            title={navigationItems.overview.label}
            sx={{py: 2}}
          >
            <ReadOnlyRow label={t('Available')}>
              <StatRow
                count={data.event.availableSeatsCount}
                percentage={
                  (data.event.availableSeatsCount /
                    data.event.auditoriumLayout.capacity) *
                  100
                }
                color="success"
                isRightAligned
              />
            </ReadOnlyRow>
            <ReadOnlyRow label={t('Reserved')}>
              <StatRow
                count={data.event.reservedSeatsCount}
                percentage={
                  (data.event.reservedSeatsCount /
                    data.event.auditoriumLayout.capacity) *
                  100
                }
                color="info"
              />
            </ReadOnlyRow>
            <ReadOnlyRow label={t('Sold')}>
              <StatRow
                count={data.event.soldSeatsCount}
                percentage={
                  (data.event.soldSeatsCount /
                    data.event.auditoriumLayout.capacity) *
                  100
                }
                color="error"
              />
            </ReadOnlyRow>
            <ReadOnlyRow label={t('Capacity')}>
              {data.event.auditoriumLayout.capacity}
            </ReadOnlyRow>
          </ReadOnlyPaper>
        )}
      </DrawerTemplateWithSideNavigation>
    </Drawer>
  )
}
