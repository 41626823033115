import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  ButtonProps,
  Divider,
  IconButton,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import React from 'react'
import {Tooltip} from '../../components/atoms/Tooltip'

interface ICartItemLayoutProps {
  title: string
  price: string
  priceBeforeDiscount?: string
  isMobile: boolean
  isRemoveButtonHidden: boolean
  removeButtonTooltip: string
  RemoveButtonProps?: ButtonProps
  addDiscountComponent?: React.ReactNode
  sx?: SxProps<Theme>
}

export const CartItemLayout: React.FC<ICartItemLayoutProps> = ({
  title,
  price,
  priceBeforeDiscount,
  isMobile,
  isRemoveButtonHidden,
  removeButtonTooltip,
  RemoveButtonProps,
  addDiscountComponent,
  sx
}: ICartItemLayoutProps) => (
  <Box sx={sx}>
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: isMobile ? '40px 1fr auto' : '40px 1fr auto auto',
        gridTemplateAreas: isMobile
          ? `
                "removeIcon title       price"
                "removeIcon addDiscount price"
              `
          : `
                "removeIcon title addDiscount price"
              `,
        columnGap: 1,
        alignItems: 'center',
        minHeight: 56,
        my: isMobile ? 1 : 0
      }}
    >
      <Box sx={{gridArea: 'removeIcon'}}>
        {!isRemoveButtonHidden && (
          <Tooltip title={removeButtonTooltip}>
            <IconButton color="primary" {...RemoveButtonProps}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          gridArea: 'addDiscount',
          justifySelf: isMobile ? 'start' : 'end'
        }}
      >
        {addDiscountComponent}
      </Box>
      <Box sx={{gridArea: 'title'}}>
        <Typography variant="subtitle2">{title}</Typography>
      </Box>
      <Box
        sx={{
          gridArea: 'price',
          alignSelf: isMobile ? 'start' : undefined,
          textAlign: 'right'
        }}
      >
        <Typography variant="body2">{price}</Typography>
        {priceBeforeDiscount && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{textDecorationLine: 'line-through'}}
          >
            {priceBeforeDiscount}
          </Typography>
        )}
      </Box>
    </Box>
    <Divider />
  </Box>
)
