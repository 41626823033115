import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  Typography
} from '@mui/material'
import React, {useRef} from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {FormSubText} from '../atoms/FormSubText'
import {getFormError} from '../formUtils'
import {FormFieldName} from '../types'

export interface IUncontrolledFormCheckboxProps<
  FormValues extends FieldValues = FieldValues
> extends Pick<FormControlProps<typeof Checkbox>, 'sx' | 'disabled'> {
  errors: FieldErrors<FormValues>
  control: FormContextValues<FormValues>['control']
  validationOptions?: ValidationOptions
  checkboxDescription?: React.ReactNode
  helperText?: string
  name: FormFieldName<FormValues>
  defaultChecked?: boolean
  label: FormControlLabelProps['label']
}

export const UncontrolledFormCheckbox = <
  FormValues extends FieldValues = FieldValues
>({
  errors,
  control,
  validationOptions,
  helperText,
  checkboxDescription,
  name,
  defaultChecked,
  disabled,
  label,
  sx
}: IUncontrolledFormCheckboxProps<FormValues>) => {
  const error = getFormError(errors, name)
  const inputRef = useRef<HTMLInputElement | null>(null)
  return (
    <>
      <FormControl
        sx={sx}
        disabled={disabled}
        error={Boolean(error)}
        size="small"
        required={Boolean(validationOptions?.required)}
      >
        <FormControlLabel
          sx={{alignItems: 'flex-start'}}
          label={
            <>
              <Typography
                variant="subtitle2"
                sx={{pt: '11px'}}
                color={error ? 'error' : 'textPrimary'}
              >
                {label}
              </Typography>
              {checkboxDescription}
              <FormSubText
                error={error}
                validationOptions={validationOptions}
                helperText={helperText}
                sx={{mx: 0}}
              />
            </>
          }
          control={
            <Controller
              as={
                <Checkbox
                  id={name}
                  sx={error ? {color: 'error.main'} : {}}
                  inputRef={inputRef}
                />
              }
              onFocus={() => {
                inputRef.current?.focus()
              }}
              control={control}
              defaultValue={Boolean(defaultChecked)}
              rules={validationOptions}
              name={name}
            />
          }
        />
      </FormControl>
    </>
  )
}

interface IBasicCheckboxDescriptionProps {
  children: React.ReactNode
}

export const BasicCheckboxDescription: React.FC<IBasicCheckboxDescriptionProps> =
  ({children}: IBasicCheckboxDescriptionProps) => (
    <Typography
      variant="caption"
      color="textSecondary"
      sx={{lineHeight: '133%', display: 'block'}}
    >
      {children}
    </Typography>
  )
