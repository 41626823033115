import {Box, Divider, Paper, SxProps, Theme} from '@mui/material'
import React from 'react'

export interface ICartPaperLayoutProps {
  isMobile: boolean
  children: React.ReactNode
  topBar?: React.ReactNode
  id?: string
  sx?: SxProps<Theme>
  header: React.ReactNode
}

export const CartPaperLayout: React.FC<ICartPaperLayoutProps> = ({
  isMobile,
  children,
  topBar,
  id,
  sx,
  header
}: ICartPaperLayoutProps) => (
  <Paper
    sx={{
      borderRadius: 4,
      ...sx
    }}
    variant="outlined"
    id={id}
  >
    {topBar && (
      <>
        <Box
          sx={
            isMobile
              ? {
                  py: 2,
                  px: 3
                }
              : {
                  pt: 2,
                  px: 3,
                  pb: 1.5
                }
          }
        >
          {topBar}
        </Box>
        <Divider />
      </>
    )}
    {header}
    <Divider />
    {children}
  </Paper>
)
