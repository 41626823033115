import {
  FormControl,
  FormControlProps,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps
} from '@mui/material'
import React, {useEffect, useRef} from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {FormSubText} from '../atoms/FormSubText'
import {getFormError} from '../formUtils'
import {FormFieldName} from '../types'

export interface IUncontrolledFormTextInputProps<
  FormValues extends FieldValues = FieldValues
> extends Pick<
    FormControlProps<typeof OutlinedInput>,
    'inputMode' | 'label' | 'fullWidth' | 'sx' | 'disabled'
  > {
  errors: FieldErrors<FormValues>
  control: FormContextValues<FormValues>['control']
  validationOptions?: ValidationOptions
  helperText?: string
  name: FormFieldName<FormValues>
  InputProps?: Omit<OutlinedInputProps, 'id' | 'label' | 'inputRef' | 'type'>
  inputProps?: InputBaseComponentProps
  type?: 'text' | 'password'
  /* List of available options https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values */
  autoComplete?: string
  onBlur?: () => void
  focusOnError?: boolean
}

export const UncontrolledFormTextInput = <
  FormValues extends FieldValues = FieldValues
>({
  errors,
  control,
  validationOptions,
  helperText,
  name,
  disabled,
  inputMode,
  label,
  fullWidth,
  type,
  sx,
  InputProps,
  inputProps,
  autoComplete,
  onBlur,
  focusOnError
}: IUncontrolledFormTextInputProps<FormValues>) => {
  const error = getFormError(errors, name)
  const inputRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (focusOnError && error && inputRef.current) {
      inputRef.current.focus()
    }
  }, [error, focusOnError])
  return (
    <>
      <FormControl
        sx={sx}
        onBlur={onBlur}
        fullWidth={fullWidth}
        disabled={disabled}
        error={Boolean(error)}
        size="small"
        inputMode={inputMode}
        required={Boolean(validationOptions?.required)}
      >
        <InputLabel htmlFor={name} variant="outlined">
          {label}
        </InputLabel>
        <Controller
          as={
            <OutlinedInput
              id={name}
              label={label}
              type={type}
              inputRef={inputRef}
              {...InputProps}
              inputProps={inputProps}
              autoComplete={autoComplete}
            />
          }
          onFocus={() => {
            inputRef.current?.focus()
          }}
          control={control}
          rules={validationOptions}
          name={name}
        />
        <FormSubText
          error={error}
          helperText={helperText}
          validationOptions={validationOptions}
        />
      </FormControl>
    </>
  )
}
