import {changeLanguage} from 'i18next'
import {noop} from 'lodash'
import React, {createContext, useCallback, useContext} from 'react'
import {ECommerceLocaleCode} from '../../../__generated__/schema'
import {getInitialLocale} from '../../../i18n'
import {useLocalStorageState} from '../../hooks/storage'
import {LOCALE_CODE} from '../../localStorageKeys'

const LocaleContext = createContext<
  [ECommerceLocaleCode, (code: ECommerceLocaleCode) => void]
>([getInitialLocale(), noop])

interface ILocaleContextProviderProps {
  children: React.ReactElement
}

export const LocaleContextProvider: React.FC<ILocaleContextProviderProps> = ({
  children
}: ILocaleContextProviderProps) => {
  const [locale, setLocale] = useLocalStorageState<ECommerceLocaleCode>(
    LOCALE_CODE,
    getInitialLocale()
  )

  const changeLocale = useCallback(
    async (newLocale: ECommerceLocaleCode) => {
      try {
        await changeLanguage(newLocale)
        setLocale(newLocale)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    [setLocale]
  )
  return (
    <LocaleContext.Provider value={[locale, changeLocale]}>
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocale = () => useContext(LocaleContext)
