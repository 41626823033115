import {useTranslation} from 'react-i18next'
import {ShowGenreCode} from '../../__generated__/schema'

export const useTranslateGenre = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowGenreCode, string> = {
    [ShowGenreCode.Family]: t<string>('ShowGenreCode->Family'),
    [ShowGenreCode.Kids]: t<string>('ShowGenreCode->Kids'),
    [ShowGenreCode.Miscellaneous]: t<string>('ShowGenreCode->Miscellaneous'),
    [ShowGenreCode.FineArt]: t<string>('ShowGenreCode->FineArt'),
    [ShowGenreCode.Painting]: t<string>('ShowGenreCode->Painting'),
    [ShowGenreCode.Drawing]: t<string>('ShowGenreCode->Drawing'),
    [ShowGenreCode.Mosaic]: t<string>('ShowGenreCode->Mosaic'),
    [ShowGenreCode.Calligraphy]: t<string>('ShowGenreCode->Calligraphy'),
    [ShowGenreCode.Photography]: t<string>('ShowGenreCode->Photography'),
    [ShowGenreCode.Architecture]: t<string>('ShowGenreCode->Architecture'),
    [ShowGenreCode.Pottery]: t<string>('ShowGenreCode->Pottery'),
    [ShowGenreCode.Sculpture]: t<string>('ShowGenreCode->Sculpture'),
    [ShowGenreCode.Illusions]: t<string>('ShowGenreCode->Illusions'),
    [ShowGenreCode.Multimedia]: t<string>('ShowGenreCode->Multimedia'),
    [ShowGenreCode.Nature]: t<string>('ShowGenreCode->Nature'),
    [ShowGenreCode.Flora]: t<string>('ShowGenreCode->Flora'),
    [ShowGenreCode.Fauna]: t<string>('ShowGenreCode->Fauna'),
    [ShowGenreCode.ContemporaryArt]: t<string>(
      'ShowGenreCode->ContemporaryArt'
    ),
    [ShowGenreCode.Science]: t<string>('ShowGenreCode->Science'),
    [ShowGenreCode.Toys]: t<string>('ShowGenreCode->Toys'),
    [ShowGenreCode.Prehistory]: t<string>('ShowGenreCode->Prehistory'),
    [ShowGenreCode.Antiquity]: t<string>('ShowGenreCode->Antiquity'),
    [ShowGenreCode.MiddleAges]: t<string>('ShowGenreCode->MiddleAges'),
    [ShowGenreCode.ModernTimes]: t<string>('ShowGenreCode->ModernTimes'),
    [ShowGenreCode.Fashion]: t<string>('ShowGenreCode->Fashion'),
    [ShowGenreCode.StandupComedy]: t<string>('ShowGenreCode->StandupComedy'),
    [ShowGenreCode.FashionShow]: t<string>('ShowGenreCode->FashionShow'),
    [ShowGenreCode.Action]: t<string>('ShowGenreCode->Action'),
    [ShowGenreCode.Animation]: t<string>('ShowGenreCode->Animation'),
    [ShowGenreCode.Adult]: t<string>('ShowGenreCode->Adult'),
    [ShowGenreCode.Adventure]: t<string>('ShowGenreCode->Adventure'),
    [ShowGenreCode.Biography]: t<string>('ShowGenreCode->Biography'),
    [ShowGenreCode.Crime]: t<string>('ShowGenreCode->Crime'),
    [ShowGenreCode.Documentary]: t<string>('ShowGenreCode->Documentary'),
    [ShowGenreCode.Fantasy]: t<string>('ShowGenreCode->Fantasy'),
    [ShowGenreCode.Filmnoir]: t<string>('ShowGenreCode->Filmnoir'),
    [ShowGenreCode.History]: t<string>('ShowGenreCode->History'),
    [ShowGenreCode.Horror]: t<string>('ShowGenreCode->Horror'),
    [ShowGenreCode.Musical]: t<string>('ShowGenreCode->Musical'),
    [ShowGenreCode.Music]: t<string>('ShowGenreCode->Music'),
    [ShowGenreCode.Mystery]: t<string>('ShowGenreCode->Mystery'),
    [ShowGenreCode.Romance]: t<string>('ShowGenreCode->Romance'),
    [ShowGenreCode.Scify]: t<string>('ShowGenreCode->Scify'),
    [ShowGenreCode.Short]: t<string>('ShowGenreCode->Short'),
    [ShowGenreCode.Sport]: t<string>('ShowGenreCode->Sport'),
    [ShowGenreCode.Talkshow]: t<string>('ShowGenreCode->Talkshow'),
    [ShowGenreCode.Thriller]: t<string>('ShowGenreCode->Thriller'),
    [ShowGenreCode.War]: t<string>('ShowGenreCode->War'),
    [ShowGenreCode.Western]: t<string>('ShowGenreCode->Western'),
    [ShowGenreCode.Disaster]: t<string>('ShowGenreCode->Disaster'),
    [ShowGenreCode.Erotic]: t<string>('ShowGenreCode->Erotic'),
    [ShowGenreCode.RoadMovie]: t<string>('ShowGenreCode->RoadMovie'),
    [ShowGenreCode.SilentFilm]: t<string>('ShowGenreCode->SilentFilm'),
    [ShowGenreCode.Psychological]: t<string>('ShowGenreCode->Psychological'),
    [ShowGenreCode.BlackHumor]: t<string>('ShowGenreCode->BlackHumor'),
    [ShowGenreCode.Parody]: t<string>('ShowGenreCode->Parody'),
    [ShowGenreCode.Politics]: t<string>('ShowGenreCode->Politics'),
    [ShowGenreCode.Dance]: t<string>('ShowGenreCode->Dance'),
    [ShowGenreCode.Comedy]: t<string>('ShowGenreCode->Comedy'),
    [ShowGenreCode.Drama]: t<string>('ShowGenreCode->Drama'),
    [ShowGenreCode.Tragedy]: t<string>('ShowGenreCode->Tragedy'),
    [ShowGenreCode.Melodrama]: t<string>('ShowGenreCode->Melodrama'),
    [ShowGenreCode.Satire]: t<string>('ShowGenreCode->Satire'),
    [ShowGenreCode.Opera]: t<string>('ShowGenreCode->Opera'),
    [ShowGenreCode.Ballet]: t<string>('ShowGenreCode->Ballet'),
    [ShowGenreCode.Cabaret]: t<string>('ShowGenreCode->Cabaret'),
    [ShowGenreCode.Farce]: t<string>('ShowGenreCode->Farce'),
    [ShowGenreCode.Operetta]: t<string>('ShowGenreCode->Operetta'),
    [ShowGenreCode.Grotesque]: t<string>('ShowGenreCode->Grotesque'),
    [ShowGenreCode.Tragicomedy]: t<string>('ShowGenreCode->Tragicomedy'),
    [ShowGenreCode.Experimental]: t<string>('ShowGenreCode->Experimental'),
    [ShowGenreCode.Modern]: t<string>('ShowGenreCode->Modern'),
    [ShowGenreCode.Variety]: t<string>('ShowGenreCode->Variety'),
    [ShowGenreCode.Pantomime]: t<string>('ShowGenreCode->Pantomime'),
    [ShowGenreCode.Folklore]: t<string>('ShowGenreCode->Folklore'),
    [ShowGenreCode.Puppetry]: t<string>('ShowGenreCode->Puppetry'),
    [ShowGenreCode.Circus]: t<string>('ShowGenreCode->Circus'),
    [ShowGenreCode.Magic]: t<string>('ShowGenreCode->Magic'),
    [ShowGenreCode.Show]: t<string>('ShowGenreCode->Show'),
    [ShowGenreCode.Gala]: t<string>('ShowGenreCode->Gala'),
    [ShowGenreCode.Ball]: t<string>('ShowGenreCode->Ball'),
    [ShowGenreCode.Prom]: t<string>('ShowGenreCode->Prom'),
    [ShowGenreCode.TravestyShow]: t<string>('ShowGenreCode->TravestyShow'),
    [ShowGenreCode.Tasting]: t<string>('ShowGenreCode->Tasting'),
    [ShowGenreCode.Striptease]: t<string>('ShowGenreCode->Striptease'),
    [ShowGenreCode.Party]: t<string>('ShowGenreCode->Party'),
    [ShowGenreCode.Ceremony]: t<string>('ShowGenreCode->Ceremony'),
    [ShowGenreCode.Carnival]: t<string>('ShowGenreCode->Carnival'),
    [ShowGenreCode.Discoteque]: t<string>('ShowGenreCode->Discoteque'),
    [ShowGenreCode.Decade_20s]: t<string>('ShowGenreCode->Decade_20s'),
    [ShowGenreCode.Decade_30s]: t<string>('ShowGenreCode->Decade_30s'),
    [ShowGenreCode.Decade_40s]: t<string>('ShowGenreCode->Decade_40s'),
    [ShowGenreCode.Decade_50s]: t<string>('ShowGenreCode->Decade_50s'),
    [ShowGenreCode.Decade_60s]: t<string>('ShowGenreCode->Decade_60s'),
    [ShowGenreCode.Decade_70s]: t<string>('ShowGenreCode->Decade_70s'),
    [ShowGenreCode.Decade_80s]: t<string>('ShowGenreCode->Decade_80s'),
    [ShowGenreCode.Decade_90s]: t<string>('ShowGenreCode->Decade_90s'),
    [ShowGenreCode.BeautyContest]: t<string>('ShowGenreCode->BeautyContest'),
    [ShowGenreCode.Blues]: t<string>('ShowGenreCode->Blues'),
    [ShowGenreCode.Country]: t<string>('ShowGenreCode->Country'),
    [ShowGenreCode.Disco]: t<string>('ShowGenreCode->Disco'),
    [ShowGenreCode.BrassBand]: t<string>('ShowGenreCode->BrassBand'),
    [ShowGenreCode.Jazz]: t<string>('ShowGenreCode->Jazz'),
    [ShowGenreCode.ElectricBoogie]: t<string>('ShowGenreCode->ElectricBoogie'),
    [ShowGenreCode.ElectronicMusic]: t<string>(
      'ShowGenreCode->ElectronicMusic'
    ),
    [ShowGenreCode.Techno]: t<string>('ShowGenreCode->Techno'),
    [ShowGenreCode.Trance]: t<string>('ShowGenreCode->Trance'),
    [ShowGenreCode.Folk]: t<string>('ShowGenreCode->Folk'),
    [ShowGenreCode.Funk]: t<string>('ShowGenreCode->Funk'),
    [ShowGenreCode.Metal]: t<string>('ShowGenreCode->Metal'),
    [ShowGenreCode.Rock]: t<string>('ShowGenreCode->Rock'),
    [ShowGenreCode.RockAndRoll]: t<string>('ShowGenreCode->RockAndRoll'),
    [ShowGenreCode.Punk]: t<string>('ShowGenreCode->Punk'),
    [ShowGenreCode.Pop]: t<string>('ShowGenreCode->Pop'),
    [ShowGenreCode.Rap]: t<string>('ShowGenreCode->Rap'),
    [ShowGenreCode.HipHop]: t<string>('ShowGenreCode->HipHop'),
    [ShowGenreCode.Soul]: t<string>('ShowGenreCode->Soul'),
    [ShowGenreCode.Swing]: t<string>('ShowGenreCode->Swing'),
    [ShowGenreCode.RhythmAndBlues]: t<string>('ShowGenreCode->RhythmAndBlues'),
    [ShowGenreCode.Reggae]: t<string>('ShowGenreCode->Reggae'),
    [ShowGenreCode.House]: t<string>('ShowGenreCode->House'),
    [ShowGenreCode.Instrumental]: t<string>('ShowGenreCode->Instrumental'),
    [ShowGenreCode.ClassicalMusic]: t<string>('ShowGenreCode->ClassicalMusic'),
    [ShowGenreCode.ChamberMusic]: t<string>('ShowGenreCode->ChamberMusic'),
    [ShowGenreCode.WorldMusic]: t<string>('ShowGenreCode->WorldMusic'),
    [ShowGenreCode.ACappella]: t<string>('ShowGenreCode->ACappella'),
    [ShowGenreCode.Alternative]: t<string>('ShowGenreCode->Alternative'),
    [ShowGenreCode.ChansonFrancaise]: t<string>(
      'ShowGenreCode->ChansonFrancaise'
    ),
    [ShowGenreCode.Medieval]: t<string>('ShowGenreCode->Medieval'),
    [ShowGenreCode.Renaissance]: t<string>('ShowGenreCode->Renaissance'),
    [ShowGenreCode.NewAge]: t<string>('ShowGenreCode->NewAge'),
    [ShowGenreCode.Beatbox]: t<string>('ShowGenreCode->Beatbox'),
    [ShowGenreCode.Sing]: t<string>('ShowGenreCode->Sing'),
    [ShowGenreCode.ChoirSinging]: t<string>('ShowGenreCode->ChoirSinging'),
    [ShowGenreCode.Religious]: t<string>('ShowGenreCode->Religious'),
    [ShowGenreCode.Hockey]: t<string>('ShowGenreCode->Hockey'),
    [ShowGenreCode.Football]: t<string>('ShowGenreCode->Football'),
    [ShowGenreCode.Volleyball]: t<string>('ShowGenreCode->Volleyball'),
    [ShowGenreCode.Basketball]: t<string>('ShowGenreCode->Basketball'),
    [ShowGenreCode.Badminton]: t<string>('ShowGenreCode->Badminton'),
    [ShowGenreCode.Biathlon]: t<string>('ShowGenreCode->Biathlon'),
    [ShowGenreCode.BodyBuilding]: t<string>('ShowGenreCode->BodyBuilding'),
    [ShowGenreCode.Boxing]: t<string>('ShowGenreCode->Boxing'),
    [ShowGenreCode.Curling]: t<string>('ShowGenreCode->Curling'),
    [ShowGenreCode.Cycling]: t<string>('ShowGenreCode->Cycling'),
    [ShowGenreCode.Esports]: t<string>('ShowGenreCode->Esports'),
    [ShowGenreCode.FieldHockey]: t<string>('ShowGenreCode->FieldHockey'),
    [ShowGenreCode.Fitness]: t<string>('ShowGenreCode->Fitness'),
    [ShowGenreCode.Floorball]: t<string>('ShowGenreCode->Floorball'),
    [ShowGenreCode.Golf]: t<string>('ShowGenreCode->Golf'),
    [ShowGenreCode.Gymnastics]: t<string>('ShowGenreCode->Gymnastics'),
    [ShowGenreCode.Handball]: t<string>('ShowGenreCode->Handball'),
    [ShowGenreCode.IceSkating]: t<string>('ShowGenreCode->IceSkating'),
    [ShowGenreCode.MartialArts]: t<string>('ShowGenreCode->MartialArts'),
    [ShowGenreCode.Racing]: t<string>('ShowGenreCode->Racing'),
    [ShowGenreCode.Motosport]: t<string>('ShowGenreCode->Motosport'),
    [ShowGenreCode.Skiing]: t<string>('ShowGenreCode->Skiing'),
    [ShowGenreCode.SkiJumping]: t<string>('ShowGenreCode->SkiJumping'),
    [ShowGenreCode.Squash]: t<string>('ShowGenreCode->Squash'),
    [ShowGenreCode.Swimming]: t<string>('ShowGenreCode->Swimming'),
    [ShowGenreCode.TableTennis]: t<string>('ShowGenreCode->TableTennis'),
    [ShowGenreCode.Tennis]: t<string>('ShowGenreCode->Tennis'),
    [ShowGenreCode.Athletics]: t<string>('ShowGenreCode->Athletics'),
    [ShowGenreCode.Waterpolo]: t<string>('ShowGenreCode->Waterpolo'),
    [ShowGenreCode.Food]: t<string>('ShowGenreCode->Food'),
    [ShowGenreCode.Streetfood]: t<string>('ShowGenreCode->Streetfood'),
    [ShowGenreCode.Gastronomy]: t<string>('ShowGenreCode->Gastronomy'),
    [ShowGenreCode.Theatre]: t<string>('ShowGenreCode->Theatre'),
    [ShowGenreCode.Film]: t<string>('ShowGenreCode->Film'),
    [ShowGenreCode.Wine]: t<string>('ShowGenreCode->Wine'),
    [ShowGenreCode.Tradeshow]: t<string>('ShowGenreCode->Tradeshow'),
    [ShowGenreCode.Fair]: t<string>('ShowGenreCode->Fair'),
    [ShowGenreCode.Drinks]: t<string>('ShowGenreCode->Drinks'),
    [ShowGenreCode.Beer]: t<string>('ShowGenreCode->Beer'),
    [ShowGenreCode.Literature]: t<string>('ShowGenreCode->Literature'),
    [ShowGenreCode.JobFair]: t<string>('ShowGenreCode->JobFair'),
    [ShowGenreCode.Conference]: t<string>('ShowGenreCode->Conference'),
    [ShowGenreCode.Business]: t<string>('ShowGenreCode->Business'),
    [ShowGenreCode.Finance]: t<string>('ShowGenreCode->Finance'),
    [ShowGenreCode.Accounting]: t<string>('ShowGenreCode->Accounting'),
    [ShowGenreCode.Communication]: t<string>('ShowGenreCode->Communication'),
    [ShowGenreCode.PersonalDevelopment]: t<string>(
      'ShowGenreCode->PersonalDevelopment'
    ),
    [ShowGenreCode.Marketing]: t<string>('ShowGenreCode->Marketing'),
    [ShowGenreCode.Design]: t<string>('ShowGenreCode->Design'),
    [ShowGenreCode.Lifestyle]: t<string>('ShowGenreCode->Lifestyle'),
    [ShowGenreCode.Yoga]: t<string>('ShowGenreCode->Yoga'),
    [ShowGenreCode.Traveling]: t<string>('ShowGenreCode->Traveling'),
    [ShowGenreCode.Wellness]: t<string>('ShowGenreCode->Wellness'),
    [ShowGenreCode.Spa]: t<string>('ShowGenreCode->Spa'),
    [ShowGenreCode.Tourism]: t<string>('ShowGenreCode->Tourism'),
    [ShowGenreCode.Relax]: t<string>('ShowGenreCode->Relax'),
    [ShowGenreCode.Rafting]: t<string>('ShowGenreCode->Rafting'),
    [ShowGenreCode.EscapeRoom]: t<string>('ShowGenreCode->EscapeRoom'),
    [ShowGenreCode.Sightseeing]: t<string>('ShowGenreCode->Sightseeing'),
    [ShowGenreCode.Tour]: t<string>('ShowGenreCode->Tour'),
    [ShowGenreCode.Flight]: t<string>('ShowGenreCode->Flight'),
    [ShowGenreCode.Cruise]: t<string>('ShowGenreCode->Cruise'),
    [ShowGenreCode.Educational]: t<string>('ShowGenreCode->Educational'),
    [ShowGenreCode.Ride]: t<string>('ShowGenreCode->Ride'),
    [ShowGenreCode.Adrenaline]: t<string>('ShowGenreCode->Adrenaline'),
    [ShowGenreCode.Paintball]: t<string>('ShowGenreCode->Paintball'),
    [ShowGenreCode.Lasertag]: t<string>('ShowGenreCode->Lasertag'),
    [ShowGenreCode.Learning]: t<string>('ShowGenreCode->Learning'),
    [ShowGenreCode.Massage]: t<string>('ShowGenreCode->Massage')
  }
  return (code: ShowGenreCode) => translatedCodes[code]
}
