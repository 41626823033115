import {Link} from '@mui/material'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'
import {LeadField} from '../../../../__generated__/schema'
import {PasswordInputAdornment} from '../../../../components/atoms/PasswordInputAdornment'
import {useBooleanState} from '../../../../hooks/state'
import {
  useIsValidEmail,
  useIsValidPassword
} from '../../../hooks/formsValidations'
import {useTranslateLeadField} from '../../../hooks/translateLeadField'
import {
  BasicCheckboxDescription,
  UncontrolledFormCheckbox
} from '../../molecules/UncontrolledFormCheckbox'
import {UncontrolledFormTextInput} from '../../molecules/UncontrolledFormTextInput'
import {AccountFormField, IAccountForm} from './types'

export const AccountFormPart: React.FC = () => {
  const {t} = useTranslation()
  const {state: isPasswordVisibilityOn, toggle: togglePasswordVisibility} =
    useBooleanState(false)
  const {
    state: isConfirmPasswordVisibilityOn,
    toggle: toggleConfirmPasswordVisibility
  } = useBooleanState(false)
  const {errors, control, getValues} = useFormContext<IAccountForm>()
  const translateLeadField = useTranslateLeadField()
  const isValidEmail = useIsValidEmail()
  const isValidPassword = useIsValidPassword()
  return (
    <>
      <UncontrolledFormTextInput<IAccountForm>
        errors={errors}
        control={control}
        name={AccountFormField.Email}
        label={translateLeadField(LeadField.Email)}
        helperText={t('You can sign in to account with e-mail.')}
        fullWidth
        validationOptions={{required: true, validate: isValidEmail}}
        autoComplete="username"
        focusOnError
      />
      <UncontrolledFormTextInput<IAccountForm>
        errors={errors}
        control={control}
        name={AccountFormField.Password}
        label={t('Password')}
        fullWidth
        validationOptions={{required: true, validate: isValidPassword}}
        type={isPasswordVisibilityOn ? 'text' : 'password'}
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <PasswordInputAdornment
              onClick={togglePasswordVisibility}
              isPasswordVisibilityOn={isPasswordVisibilityOn}
            />
          )
        }}
      />
      <UncontrolledFormTextInput<IAccountForm>
        errors={errors}
        control={control}
        name={AccountFormField.ConfirmPassword}
        label={t('Confirm password')}
        fullWidth
        autoComplete="new-password"
        validationOptions={{
          required: true,
          validate: (confirmPassword) => {
            const password = getValues(AccountFormField.Password)
            return password === confirmPassword || t('Passwords did not match.')
          }
        }}
        type={isConfirmPasswordVisibilityOn ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <PasswordInputAdornment
              onClick={toggleConfirmPasswordVisibility}
              isPasswordVisibilityOn={isConfirmPasswordVisibilityOn}
            />
          )
        }}
      />
      <UncontrolledFormCheckbox<IAccountForm>
        errors={errors}
        control={control}
        name={AccountFormField.WouldLikeToReceiveLatestNews}
        label={t('I would like to receive the latest news')}
        checkboxDescription={
          <BasicCheckboxDescription>
            <Trans i18nKey="I want to be kept informed registration">
              {'I do agree with '}
              <Link color="inherit" href="#" target="_blank" rel="noopener">
                privacy policy
              </Link>
              {' for marketing purposes.'}
            </Trans>
          </BasicCheckboxDescription>
        }
      />
      <BasicCheckboxDescription>
        <Trans i18nKey="Accept TOS registration">
          {'By clicking "Create account", I agree to '}
          <Link color="inherit" href="#" target="_blank" rel="noopener">
            terms of service
          </Link>
          .
        </Trans>
      </BasicCheckboxDescription>
    </>
  )
}
