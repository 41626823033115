import {Box} from '@mui/material'
import React from 'react'
import {SideNavigationList} from '../../../components/atoms/SideNavigationList'
import {DrawerTemplate} from '../../../components/molecules/DrawerTemplate'
import {MediaSizes} from '../../../components/types'

export interface IDrawerTemplateWithSideNavigationProps {
  DrawerTemplateProps: {
    className?: string
    header: React.ReactNode
    footer?: React.ReactNode
    isLoading?: boolean
    errorMessage?: string
  }
  navigationItems: Record<string, {id: string; label: string}>
  children: React.ReactNode
}

export const DrawerTemplateWithSideNavigation: React.FC<IDrawerTemplateWithSideNavigationProps> =
  ({
    DrawerTemplateProps,
    navigationItems,
    children
  }: IDrawerTemplateWithSideNavigationProps) => {
    return (
      <DrawerTemplate {...DrawerTemplateProps}>
        <Box
          sx={{
            display: 'grid',
            minHeight: '100%',
            maxWidth: '100%',
            width: '100%',
            p: 1,
            [`@media ${MediaSizes.TabletPlus}`]: {
              gridTemplateColumns: '226px 1fr',
              pr: 3,
              pb: 3
            }
          }}
        >
          <Box
            sx={{
              display: 'none',
              [`@media ${MediaSizes.TabletPlus}`]: {
                display: 'block'
              }
            }}
          >
            <SideNavigationList
              items={navigationItems}
              sx={{
                pr: 1,
                top: 0,
                position: 'sticky'
              }}
            />
          </Box>
          <div>{children}</div>
        </Box>
      </DrawerTemplate>
    )
  }
