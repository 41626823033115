import DownloadIcon from '@mui/icons-material/Download'
import {Box, IconButton, SxProps, Tab, Tabs, Typography} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Currency,
  CustomerPurchaseHistoryQuery,
  EventDetailFieldsFragment,
  ItemType
} from '../../__generated__/schema'
import {ReadOnlyPaper} from '../../components/atoms/ReadOnly'
import {useDownloadSalesPdf} from '../hooks/downloadSalesPdf'
import {useFormatDateNumeric, useFormatTime} from '../hooks/formatting'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {useGetTranslatedValue} from '../hooks/translateLocales'
import {isTicketItem, isTourItem} from '../utils'
import {PurchasedTourRow} from './PurchasedTourRow'

interface IPurchasedEventRowProps {
  event: EventDetailFieldsFragment
  ticketCount: number
  price: string
  onDownloadButtonClick: () => void
  isMobile: boolean
}

export const PurchasedEventRow: React.FC<IPurchasedEventRowProps> = ({
  event,
  ticketCount,
  price,
  onDownloadButtonClick,
  isMobile
}: IPurchasedEventRowProps) => {
  const {t} = useTranslation()
  const formatDateNumeric = useFormatDateNumeric()
  const formatTime = useFormatTime()
  const getTranslatedValue = useGetTranslatedValue(
    event.division.client.localeCodes
  )
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: isMobile ? 1 : 3,
        borderBottom: `solid ${theme.palette.divider} 1px`,
        ':last-child': {
          borderBottom: 'none'
        }
      })}
    >
      <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <Typography variant="body2">
          {[
            formatDateNumeric(new Date(event.startsAt)),
            formatTime(new Date(event.startsAt))
          ].join(', ')}
        </Typography>
        {'•'}
        <Typography variant="body2">
          {getTranslatedValue(event.names)}
        </Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 3}}>
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: 'text.secondary'
            }}
          >
            <Typography variant="body2">
              {t('{{count}} tickets', {count: ticketCount})}
            </Typography>
            {'•'}
            <Typography variant="body2">{price}</Typography>
          </Box>
        )}
        <IconButton onClick={onDownloadButtonClick} color="primary">
          <DownloadIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

enum PurchasedTab {
  Upcoming = 'upcoming',
  Past = 'past'
}

interface IPurchaseHistorySectionProps {
  id: string
  title: string
  sx?: SxProps
  sales: CustomerPurchaseHistoryQuery['signedInCustomer']['sales']
  currency: Currency
  isMobile: boolean
}

export const PurchaseHistorySection: React.FC<IPurchaseHistorySectionProps> = ({
  id,
  title,
  sx,
  sales,
  currency,
  isMobile
}: IPurchaseHistorySectionProps) => {
  const {t} = useTranslation()
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const [tab, setTab] = useState<PurchasedTab>(PurchasedTab.Upcoming)
  const handleTabChange = useCallback(
    (e: React.SyntheticEvent, newTab: PurchasedTab) => setTab(newTab),
    []
  )
  const translatePrice = useTranslatePrice(currency)
  const downloadSalesPdf = useDownloadSalesPdf()
  const handleDownloadButtonClick = useCallback(
    (sale) => async () => {
      setShowBackdrop(true)
      await downloadSalesPdf([sale])
      setShowBackdrop(false)
    },
    [downloadSalesPdf, setShowBackdrop]
  )
  const filteredTicketSales =
    sales.filter((sale) =>
      sale.itemType === ItemType.Ticket && tab === PurchasedTab.Upcoming
        ? dayjs(sale.event?.startsAt).isAfter(dayjs())
        : dayjs(sale.event?.startsAt).isBefore(dayjs())
    ) || []
  const filteredTourSales =
    sales.filter((sale) =>
      sale.itemType === ItemType.Tour && tab === PurchasedTab.Upcoming
        ? dayjs(sale.tourTimeSlot?.startsAt).isAfter(dayjs())
        : dayjs(sale.tourTimeSlot?.startsAt).isBefore(dayjs())
    ) || []
  return (
    <ReadOnlyPaper id={id} title={title} sx={sx}>
      <Typography variant="body1" color="textSecondary">
        {t('You can find your successful purchases listed below.')}
      </Typography>
      <Box sx={{borderBottom: 1, borderColor: 'divider', pt: 2}}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={t('Upcoming')} value={PurchasedTab.Upcoming} />
          <Tab label={t('Past')} value={PurchasedTab.Past} />
        </Tabs>
      </Box>
      {filteredTicketSales.map(
        (sale) =>
          sale.event && (
            <PurchasedEventRow
              key={sale.id}
              event={sale.event}
              price={translatePrice(sale.price)}
              ticketCount={sale.items?.filter(isTicketItem).length || 0}
              onDownloadButtonClick={handleDownloadButtonClick(sale)}
              isMobile={isMobile}
            />
          )
      )}
      {filteredTourSales.map(
        (sale) =>
          sale.tourTimeSlot && (
            <PurchasedTourRow
              key={sale.id}
              tourTimeSlot={sale.tourTimeSlot}
              ticketCount={sale.items?.filter(isTourItem).length || 0}
              price={translatePrice(sale.price)}
              onDownloadButtonClick={handleDownloadButtonClick(sale)}
              isMobile={isMobile}
            />
          )
      )}
      {filteredTicketSales.length === 0 && filteredTourSales.length === 0 && (
        <Typography variant="body2" color="textSecondary" sx={{pt: 2}}>
          {tab === PurchasedTab.Upcoming
            ? t('No upcoming purchases have been found')
            : t('No previous purchases have been found')}
        </Typography>
      )}
    </ReadOnlyPaper>
  )
}
