import {AttributeAnchorTarget, EventButtonTarget} from '../../../../types'

export enum AppBackgroundColor {
  Transparent = 'transparent',
  Grey = 'grey'
}

export interface IAppParams {
  showCartButton: boolean
  gatewayButtonTarget:
    | AttributeAnchorTarget.Self
    | AttributeAnchorTarget.Top
    | AttributeAnchorTarget.Blank
  eventOverviewEnabled: boolean
  eventButtonTarget: EventButtonTarget
  backgroundColor: AppBackgroundColor
}
