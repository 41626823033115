import {Box, Typography} from '@mui/material'
import {isEqual} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  BesteronPaymentInstrument,
  ClientDetailFieldsFragment,
  ExistingCountryCode,
  GetCurrentCartQuery,
  GoPayPaymentInstrument
} from '../../__generated__/schema'
import {BasicRadioLabel} from '../components/molecules/UncontrolledFormRadioGroup'
import {useGetTranslatedValueWithoutFallback} from '../hooks/translateLocales'
import {PMRadioGroupOption, PMRadioGroupOptionType} from './types'

interface IPaymentMethodRadioLabelProps {
  primaryText: string
  secondaryText?: string
  rightColumnText?: string
  iconSrc?: string
}

const getAvailableBesteronBanksByCountryCode = (
  countryCode: ExistingCountryCode
) => {
  switch (countryCode) {
    case ExistingCountryCode.Sk:
      return [
        BesteronPaymentInstrument.Gibaskbx,
        BesteronPaymentInstrument.Pobnskba,
        BesteronPaymentInstrument.Subaskbx,
        BesteronPaymentInstrument.Tatrskbx,
        BesteronPaymentInstrument.Uncrskbx
      ]
    case ExistingCountryCode.Cz:
      return [
        BesteronPaymentInstrument.Agbaczpp,
        BesteronPaymentInstrument.Bacxczpp,
        BesteronPaymentInstrument.Brexczpp,
        BesteronPaymentInstrument.Fiobczpp,
        BesteronPaymentInstrument.Gibaczpx,
        BesteronPaymentInstrument.Kombczpp,
        BesteronPaymentInstrument.Rzbcczpp
      ]
    default:
      return []
  }
}

const getBesteronIconSrc = (
  instruments: BesteronPaymentInstrument[],
  countryCode: ExistingCountryCode
) => {
  const availableBesteronBanksByCountryCode =
    getAvailableBesteronBanksByCountryCode(countryCode).sort()
  const sortedInstruments = instruments.sort()
  const paymentCardInstruments = [
    BesteronPaymentInstrument.Card,
    BesteronPaymentInstrument.Applepay,
    BesteronPaymentInstrument.Googlepay
  ].sort()
  if (
    isEqual(
      sortedInstruments,
      [...paymentCardInstruments, ...availableBesteronBanksByCountryCode].sort()
    )
  ) {
    return '/paymentLogos/BESTERON.png'
  }
  if (isEqual(sortedInstruments, paymentCardInstruments)) {
    return '/paymentLogos/PAYMENT_CARD.png'
  }
  return undefined
}

const getGoPayIconSrc = (instrument?: GoPayPaymentInstrument | null) => {
  switch (instrument) {
    case GoPayPaymentInstrument.Bitcoin:
      return '/paymentLogos/BITCOIN.png'
    case GoPayPaymentInstrument.ApplePay:
      return '/paymentLogos/APPLE_PAY.png'
    case GoPayPaymentInstrument.ClickToPay:
      return '/paymentLogos/CLICK_TO_PAY.png'
    case GoPayPaymentInstrument.Gpay:
      return '/paymentLogos/GPAY.png'
    case GoPayPaymentInstrument.Paypal:
      return '/paymentLogos/PAYPAL.png'
    case GoPayPaymentInstrument.Gopay:
      return '/paymentLogos/GOPAY.png'
    case GoPayPaymentInstrument.Paysafecard:
      return '/paymentLogos/PAYSAFECARD.png'
    case GoPayPaymentInstrument.PaymentCard:
    case null:
      return '/paymentLogos/PAYMENT_CARD.png'
    case GoPayPaymentInstrument.Mpayment:
    case GoPayPaymentInstrument.BankAccount:
    case GoPayPaymentInstrument.Prsms:
    default:
      return undefined
  }
}

const getPaymentMethodIconSrc = (
  paymentMethod: GetCurrentCartQuery['cart']['client']['eCommercePaymentMethods'][number],
  countryCode: ExistingCountryCode
) => {
  switch (paymentMethod.paymentServiceProviderConfig?.__typename) {
    case 'GoPayPaymentServiceProviderConfig':
      return getGoPayIconSrc(
        paymentMethod.paymentServiceProviderConfig.defaultPaymentInstrument
      )
    case 'BesteronPaymentServiceProviderConfig':
      return getBesteronIconSrc(
        paymentMethod.paymentServiceProviderConfig.paymentInstruments,
        countryCode
      )
    case 'StatnaPokladnicaPaymentServiceProviderConfig':
    default:
      return undefined
  }
}

const PaymentMethodRadioLabel = ({
  primaryText,
  secondaryText,
  rightColumnText,
  iconSrc
}: IPaymentMethodRadioLabelProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        pl: 0.5,
        columnGap: 1,
        gridTemplateAreas: `
        "primaryText rightColumnText"
        "secondaryText rightColumnText"
        "icon rightColumnText"
      `
      }}
    >
      <Typography variant="subtitle2">{primaryText}</Typography>
      <Typography
        variant="caption"
        sx={{gridArea: 'secondaryText'}}
        color="textSecondary"
      >
        {secondaryText}
      </Typography>
      {iconSrc && (
        <Box sx={{gridArea: 'icon', pt: 1, display: 'flex'}}>
          <img src={iconSrc} alt="payment cart" />
        </Box>
      )}
      <Typography
        sx={{
          gridArea: 'rightColumnText',
          alignSelf: 'center',
          justifySelf: 'flex-end'
        }}
        variant="body2"
      >
        {rightColumnText}
      </Typography>
    </Box>
  )
}

interface IPaymentMethodOptionLabelProps {
  pMRadioGroupOption: PMRadioGroupOption
  eCommercePaymentMethod?: ClientDetailFieldsFragment['eCommercePaymentMethods'][number]
  remainingPriceLabel?: string
  countryCode: ExistingCountryCode
}

export const PaymentMethodOptionLabel: React.FC<IPaymentMethodOptionLabelProps> =
  ({
    eCommercePaymentMethod,
    pMRadioGroupOption,
    remainingPriceLabel,
    countryCode
  }: IPaymentMethodOptionLabelProps) => {
    const {t} = useTranslation()
    const getTranslatedValueWithoutFallback =
      useGetTranslatedValueWithoutFallback()
    switch (pMRadioGroupOption.type) {
      case PMRadioGroupOptionType.Reservation:
        return (
          <BasicRadioLabel
            primaryText={t('Reservation')}
            secondaryText={t('You can pay for your reservation later.')}
            isDisabled={pMRadioGroupOption.isDisabled}
          />
        )
      case PMRadioGroupOptionType.ReservationForCartWithZeroPrice:
        return (
          <BasicRadioLabel
            primaryText={t('Reservation')}
            secondaryText={t('You can confirm your reservation later.')}
            isDisabled={pMRadioGroupOption.isDisabled}
          />
        )
      case PMRadioGroupOptionType.PaymentForCartWithZeroPrice:
        return (
          <BasicRadioLabel
            primaryText={t('Get it now')}
            secondaryText={t('No payment required.')}
            isDisabled={pMRadioGroupOption.isDisabled}
          />
        )
      case PMRadioGroupOptionType.RegularPaymentMethod:
        return eCommercePaymentMethod ? (
          <PaymentMethodRadioLabel
            primaryText={eCommercePaymentMethod.name}
            secondaryText={getTranslatedValueWithoutFallback(
              eCommercePaymentMethod.description
            )}
            rightColumnText={remainingPriceLabel}
            iconSrc={getPaymentMethodIconSrc(
              eCommercePaymentMethod,
              countryCode
            )}
          />
        ) : (
          // this should never happen
          <BasicRadioLabel
            primaryText={pMRadioGroupOption.type}
            secondaryText={String(pMRadioGroupOption.eCommercePaymentMethodId)}
            isDisabled={pMRadioGroupOption.isDisabled}
          />
        )
      default:
        // this should never happen
        return null
    }
  }
