import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../../../components/atoms/DialogTitleWithCloseButton'
import {SimpleDialog} from '../../../../components/atoms/SimpleDialog'
import {useIsStringWithMinLength} from '../../../hooks/formsValidations'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../utils'
import {UncontrolledFormTextInput} from '../../molecules/UncontrolledFormTextInput'
import {ForgotPasswordFormField, IForgotPasswordForm} from './types'

enum DialogContentType {
  ForgotPassword,
  SuccessfulReset
}

interface IForgotPasswordDialogProps {
  formId: string
  isOpen: boolean
  onClose: () => void
  defaultValues?: Partial<IForgotPasswordForm>
  onSubmit: (data: IForgotPasswordForm) => void
}

export const ForgotPasswordDialog: React.FC<IForgotPasswordDialogProps> = ({
  formId,
  isOpen,
  onClose,
  defaultValues,
  onSubmit
}: IForgotPasswordDialogProps) => {
  const {t} = useTranslation()
  const {control, errors, handleSubmit, setError} =
    useForm<IForgotPasswordForm>({
      defaultValues,
      reValidateMode: 'onBlur'
    })
  const [contentType, setContentType] = useState<DialogContentType>(
    DialogContentType.ForgotPassword
  )
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const isStringWithMinLength6 = useIsStringWithMinLength(6)
  const _onSubmit = useCallback(
    async (data: IForgotPasswordForm) => {
      try {
        await onSubmit(data)
        setContentType(DialogContentType.SuccessfulReset)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CustomerNotFound
          )
        ) {
          setError(
            ForgotPasswordFormField.Username,
            '',
            t("Account with this username doesn't exist")
          )
        } else {
          defaultErrorHandler(error, {
            title: t('Error while performing forgot customer password mutation')
          })
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, t]
  )
  return contentType === DialogContentType.ForgotPassword ? (
    <Dialog open={isOpen} maxWidth="xs" scroll="paper" fullWidth>
      <DialogTitleWithCloseButton onCloseIconClick={onClose}>
        {t<string>('Forgot password?')}
      </DialogTitleWithCloseButton>
      <DialogContent
        sx={(theme) => ({
          borderTop: `solid ${theme.palette.divider} 1px`,
          pb: 1
        })}
      >
        <Box sx={{pt: 3, display: 'flex', flexDirection: 'column', gap: 2}}>
          <Typography variant="body1" color="textSecondary">
            {t('Enter your username to reset password')}
          </Typography>
          <form id={formId} noValidate onSubmit={handleSubmit(_onSubmit)}>
            <UncontrolledFormTextInput<IForgotPasswordForm>
              errors={errors}
              control={control}
              name={ForgotPasswordFormField.Username}
              label={t('Username')}
              fullWidth
              validationOptions={{
                required: true,
                validate: isStringWithMinLength6
              }}
              autoComplete="username"
              InputProps={{autoFocus: true}}
            />
          </form>
        </Box>
      </DialogContent>
      <DialogActions sx={{px: 3, pb: 3, justifyContent: 'flex-start'}}>
        <Button
          type="submit"
          form={formId}
          color="primary"
          variant="contained"
          sx={{width: '100%'}}
        >
          {t('Reset password')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <SimpleDialog
      isOpen
      title={t('Reset instructions was sent')}
      content={t(
        "You will get an email with instructions on resetting your password. If email doesn't arrive, be sure to check your spam folder."
      )}
      actions={<Button onClick={onClose}>{t('Got it')}</Button>}
    />
  )
}
