import {Box, Paper, Typography} from '@mui/material'
import React, {ReactNode, useCallback} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../__generated__/schema'
import {useIsMediaSize} from '../../../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../../../components/types'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../utils'
import {AccountFormPart} from './AccountFormPart'
import {BillingAddressFormPart} from './BillingAddressFormPart'
import {CompanyFormPart} from './CompanyFormPart'
import {ContactInformationFormPart} from './ContactInformationFormPart'
import {DeliveryAddressFormPart} from './DeliveryAddressFormPart'
import {AccountFormField, IAccountForm} from './types'

interface ISectionProps {
  title: string
  children: ReactNode
}

const Section: React.FC<ISectionProps> = ({title, children}: ISectionProps) => (
  <Paper
    variant="outlined"
    sx={{
      px: 3,
      py: 2,
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      gap: 3
    }}
  >
    <Typography variant="subtitle2">{title}</Typography>
    {children}
  </Paper>
)

interface IAccountFormProps {
  formId: string
  onSubmit: (data: IAccountForm) => void
  defaultValues?: Partial<IAccountForm>
}

export const AccountForm: React.FC<IAccountFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IAccountFormProps) => {
  const {t} = useTranslation()
  const methods = useForm<IAccountForm>({
    defaultValues,
    reValidateMode: 'onBlur'
  })
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const {handleSubmit, setError} = methods
  const _handleSubmit = useCallback(
    async (data: IAccountForm) => {
      try {
        await onSubmit(data)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CustomerAlreadyExists
          )
        ) {
          setError(
            AccountFormField.Email,
            '',
            t('This username is taken. Try another one or sign in to account.')
          )
        } else {
          defaultErrorHandler(error, {
            title: t('Error while signing up customer')
          })
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, t]
  )
  return (
    <FormContext {...methods}>
      <form id={formId} noValidate onSubmit={handleSubmit(_handleSubmit)}>
        <Box
          sx={{
            p: isMobile ? 1 : 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}
        >
          <Section title={t('Account')}>
            <AccountFormPart />
          </Section>
          <Section title={t('Contact information')}>
            <ContactInformationFormPart<IAccountForm> />
          </Section>
          <Section title={t('Company')}>
            <CompanyFormPart<IAccountForm> />
          </Section>
          <Section title={t('Billing address')}>
            <BillingAddressFormPart<IAccountForm> />
          </Section>
          <Section title={t('Delivery address')}>
            <DeliveryAddressFormPart<IAccountForm> />
          </Section>
        </Box>
      </form>
    </FormContext>
  )
}
