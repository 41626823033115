import {useMutation} from '@apollo/client'
import DoneIcon from '@mui/icons-material/Done'
import {Box, Button} from '@mui/material'
import Joi from 'joi'
import queryString from 'query-string'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate, useLocation} from 'react-router-dom'
import {
  ErrorMessages,
  RecoverCustomerPasswordMutation,
  RecoverCustomerPasswordMutationVariables
} from '../../__generated__/schema'
import {SimpleDialog} from '../../components/atoms/SimpleDialog'
import {useBooleanState} from '../../hooks/state'
import {useIsCustomerSignedIn} from '../auth'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from '../components/atoms/CurrentCartFab'
import {Warning, WarningColorCombination} from '../components/atoms/Warning'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {CreateAccountDrawer} from '../components/organisms/account/CreateAccountDrawer'
import {SignInDrawer} from '../components/organisms/account/SignInDrawer'
import {BlankPage} from '../components/organisms/BlankPage'
import {RECOVER_CUSTOMER_PASSWORD} from '../graphql'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {PageNotFound} from '../PageNotFound'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {RecoverPasswordForm} from './RecoverPasswordForm'
import {IRecoverPasswordForm, RecoverPasswordFormField} from './types'

enum ContentType {
  PasswordRecoverForm = 'PasswordRecoverForm',
  PasswordChanged = 'PasswordChanged'
}

const RECOVER_PASSWORD_FORM_ID = 'recoverPasswordForm'

const recoverPasswordParamsSchema = Joi.object<{token: string}>({
  token: Joi.string().required()
}).options({stripUnknown: true})

export const RecoverPassword: React.FC = () => {
  const {t} = useTranslation()
  const [recoverCustomerPassword] = useMutation<
    RecoverCustomerPasswordMutation,
    RecoverCustomerPasswordMutationVariables
  >(RECOVER_CUSTOMER_PASSWORD)
  const [contentType, setContentType] = useState<ContentType>(
    ContentType.PasswordRecoverForm
  )
  const {
    state: isSignInDrawerOpen,
    setFalse: closeSignInDrawer,
    setTrue: openSignInDrawer
  } = useBooleanState(false)
  const {
    state: isSignUpDrawerOpen,
    setFalse: closeSignUpDrawer,
    setTrue: openSignUpDrawer
  } = useBooleanState(false)
  const {
    state: isErrorDialogOpen,
    setTrue: openErrorDialog,
    setFalse: closeErrorDialog
  } = useBooleanState(false)
  const handleCreateAccountLinkClick = useCallback(() => {
    closeSignInDrawer()
    openSignUpDrawer()
  }, [closeSignInDrawer, openSignUpDrawer])
  const handleSignInLinkClick = useCallback(() => {
    closeSignUpDrawer()
    openSignInDrawer()
  }, [closeSignUpDrawer, openSignInDrawer])
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const location = useLocation()
  const isCustomerSignedIn = useIsCustomerSignedIn()
  const {currentCart} = useCurrentCart()
  const validationResult = recoverPasswordParamsSchema.validate(
    queryString.parse(location.search)
  )
  const handleSubmit = useCallback(
    async (data: IRecoverPasswordForm) => {
      if (validationResult.value?.token) {
        try {
          setShowBackdrop(true)
          await recoverCustomerPassword({
            variables: {
              token: validationResult.value.token,
              password: data[RecoverPasswordFormField.Password]
            }
          })
          setContentType(ContentType.PasswordChanged)
        } catch (error) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.InvalidToken
            )
          ) {
            openErrorDialog()
          } else {
            defaultErrorHandler(error, {
              title: t('Error while recovering customer password')
            })
          }
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      defaultErrorHandler,
      openErrorDialog,
      recoverCustomerPassword,
      setShowBackdrop,
      t,
      validationResult.value?.token
    ]
  )
  if (isCustomerSignedIn) {
    return <Navigate to="/myAccount" />
  }
  if (validationResult.error) {
    return <PageNotFound />
  }
  return (
    <>
      <BlankPage header={<BaseHeader title={t('Recover password')} />}>
        <>
          {contentType === ContentType.PasswordRecoverForm ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
                borderStyle: 'solid',
                borderWidth: 1,
                bgcolor: 'background.paper',
                borderColor: 'divider',
                width: 360,
                p: 3
              }}
            >
              <RecoverPasswordForm
                formId={RECOVER_PASSWORD_FORM_ID}
                onSubmit={handleSubmit}
                defaultValues={{
                  [RecoverPasswordFormField.Password]: '',
                  [RecoverPasswordFormField.ConfirmPassword]: ''
                }}
              />
            </Box>
          ) : (
            <Warning
              colorCombination={WarningColorCombination.White}
              title={t('Password successfully changed')}
              icon={<DoneIcon sx={{width: 64, height: 64}} />}
              actions={
                <Button
                  onClick={openSignInDrawer}
                  variant="contained"
                  color="primary"
                >
                  {t('Sign in')}
                </Button>
              }
            />
          )}
          <TentativeCurrentCartFab currentCart={currentCart} />
        </>
      </BlankPage>
      {isSignInDrawerOpen && (
        <SignInDrawer
          onExited={closeSignInDrawer}
          onCreateAccountLinkClick={handleCreateAccountLinkClick}
        />
      )}
      {isSignUpDrawerOpen && (
        <CreateAccountDrawer
          onExited={closeSignUpDrawer}
          onSignInLinkClick={handleSignInLinkClick}
        />
      )}
      <SimpleDialog
        isOpen={isErrorDialogOpen}
        title={t('Password recovery have failed')}
        content={t(
          'Token you try to use was used already, doesn’t exist at all or has expired. Please, visit login page and try to reset password again.'
        )}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={closeErrorDialog}
          >
            {t('Got it')}
          </Button>
        }
      />
    </>
  )
}
