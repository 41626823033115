import {CssBaseline, GlobalStyles} from '@mui/material'
import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {Loading} from './components/atoms/Loading'
import {WindowInnerWidthContextProvider} from './components/atoms/WindowInnerWidthContext'
import {AuthContextProvider} from './features/auth/AuthContext'
import {RequireAuth} from './features/auth/RequireAuth'
import {Cart} from './features/cart'
import {CartSummary} from './features/cartSummary'
import {AppParamsProvider} from './features/components/atoms/AppParamsProvider'
import {BackdropProvider} from './features/components/atoms/BackdropContext'
import {CurrentCartContextProvider} from './features/components/atoms/CurrentCartContext'
import {LocaleContextProvider} from './features/components/atoms/LocaleContext'
import {ThemeContextProvider} from './features/components/atoms/ThemeContext'
import {ThemeProvider} from './features/components/atoms/ThemeProvider'
import {CartExpirationController} from './features/components/molecules/CartExpirationController'
import {NotificationProvider} from './features/components/molecules/NotificationContext'
import {EventDetail} from './features/eventDetail'
import {EventDetailDrawer} from './features/eventDetail/EventDetailDrawer'
import {EventStatsDrawer} from './features/eventDetail/EventStatsDrawer'
import {EventOverview} from './features/eventOverview'
import EventsList from './features/eventsList'
import {MyAccount} from './features/myAccount'
import {RecoverPassword} from './features/myAccount/RecoverPassword'
import {SignedOut} from './features/myAccount/SignedOut'
import {PageNotFound} from './features/PageNotFound'
import {PaymentProcessing} from './features/paymentProcessing'
import ToursTimeSlotsList from './features/toursTimeSlots'
import {TourTimeSlotDetail} from './features/tourTimeSlot'
import TourTimeSlotsList from './features/tourTimeSlots'

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      'html, body, #root': {height: '100%'}
    }}
  />
)
const App = () => {
  const navigate = useNavigate()
  return (
    <HelmetProvider>
      <AppParamsProvider>
        <ThemeContextProvider>
          <ThemeProvider>
            <CssBaseline />
            {inputGlobalStyles}
            <BackdropProvider>
              <LocaleContextProvider>
                <NotificationProvider>
                  <WindowInnerWidthContextProvider>
                    <Routes>
                      <Route
                        element={
                          <AuthContextProvider>
                            <RequireAuth>
                              <CurrentCartContextProvider>
                                <CartExpirationController>
                                  <Outlet />
                                </CartExpirationController>
                              </CurrentCartContextProvider>
                            </RequireAuth>
                          </AuthContextProvider>
                        }
                      >
                        <Route
                          path="/cartSummary/:uuid/:hash/*"
                          element={<CartSummary />}
                        />
                        <Route
                          path={
                            '/paymentProcessing/:paymentServiceProvider/:uuid/:hash/:cartId/:paymentIntentId'
                          }
                          element={<PaymentProcessing />}
                        />
                        <Route path="/event/:eventId" element={<EventDetail />}>
                          <Route
                            path="eventInfo"
                            element={
                              <EventDetailDrawer
                                onExited={() => navigate(-1)}
                              />
                            }
                          />
                          <Route path="stats" element={<EventStatsDrawer />} />
                        </Route>
                        <Route
                          path="/tourTimeSlot/:tourTimeSlotId"
                          element={<TourTimeSlotDetail />}
                        />
                        <Route
                          path="/event/:eventId/overview"
                          element={<EventOverview />}
                        />
                        <Route path="/:clientId/cart/*" element={<Cart />} />
                        <Route
                          path="/:clientId/events"
                          element={<EventsList />}
                        />
                        <Route
                          path="/:clientId/timeSlots"
                          element={<ToursTimeSlotsList />}
                        />
                        <Route
                          path="/:clientId/tour/:tourId"
                          element={<TourTimeSlotsList />}
                        />
                        <Route path="/myAccount/*" element={<MyAccount />} />
                        <Route
                          path="/:clientId/signedOut"
                          element={<SignedOut />}
                        />
                        <Route
                          path="/:clientId/recoverPassword"
                          element={<RecoverPassword />}
                        />
                        <Route path="*" element={<PageNotFound />} />
                      </Route>
                      <Route path="/redirecting" element={<Loading />} />
                    </Routes>
                  </WindowInnerWidthContextProvider>
                </NotificationProvider>
              </LocaleContextProvider>
            </BackdropProvider>
          </ThemeProvider>
        </ThemeContextProvider>
      </AppParamsProvider>
    </HelmetProvider>
  )
}

export default App
