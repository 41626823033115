import {ApolloError} from '@apollo/client'
import {Typography} from '@mui/material'
import React from 'react'
import {Loading} from '../atoms/Loading'

type RenderOnDataProps<TData> = (
  | {
      errorElement: React.ReactElement
      errorMessage?: never
    }
  | {
      errorElement?: never
      errorMessage: string
    }
) & {
  error?: ApolloError
  loading: boolean
  data?: TData | null
  children: (data: TData) => React.ReactElement
  dataCondition?: (data: TData) => boolean
}

export const RenderOnData = <TData extends object>({
  loading,
  error,
  errorMessage,
  errorElement,
  data,
  children,
  dataCondition
}: RenderOnDataProps<TData>) => {
  if (dataCondition ? loading || (data && !dataCondition(data)) : loading) {
    return <Loading />
  }
  if (error || !data) {
    return errorMessage ? (
      <Typography variant={'h6'} sx={{color: 'error.main'}}>
        {errorMessage}
      </Typography>
    ) : (
      <>{errorElement}</>
    )
  }
  return children(data)
}
