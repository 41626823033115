import {useMutation} from '@apollo/client'
import {Chip, SxProps, Theme} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Currency,
  EnabledDiscountFieldsFragment,
  ErrorMessages,
  RemoveDiscountFromItemMutation,
  RemoveDiscountFromItemMutationVariables,
  TourItemFieldsFragment
} from '../../__generated__/schema'
import {ChipWithListOfItems} from '../components/molecules/ChipWithListOfItems'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {AddTourItemDiscountChip} from './AddTourItemDiscountChip'
import {CartItemLayout} from './CartItemLayout'
import {REMOVE_DISCOUNT_FROM_ITEM} from './graphql'

interface IAddDiscountProps {
  appliedDiscounts: TourItemFieldsFragment['appliedDiscounts']
  enabledDiscounts: EnabledDiscountFieldsFragment[]
  isMobile: boolean
  tourTimeSlotId: number
  currency: Currency
  cartId: number
  tourItemId: number
  onRemoveAppliedDiscountClick: (
    tourItemId: number,
    discountId: number
  ) => () => void
  onIHaveDiscountCodeClick: () => void
}
const AddDiscount: React.FC<IAddDiscountProps> = ({
  appliedDiscounts,
  enabledDiscounts,
  isMobile,
  tourTimeSlotId,
  currency,
  cartId,
  tourItemId,
  onRemoveAppliedDiscountClick,
  onIHaveDiscountCodeClick
}: IAddDiscountProps) => {
  const {t} = useTranslation()
  return appliedDiscounts.length > 0 && appliedDiscounts[0].discount ? (
    <Chip
      sx={{
        p: 0.5,
        maxWidth: 184,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }}
      label={appliedDiscounts[0].discount.name}
      onDelete={onRemoveAppliedDiscountClick(
        tourItemId,
        appliedDiscounts[0].discount.id
      )}
    />
  ) : enabledDiscounts.length ? (
    <ChipWithListOfItems
      label={t('Add discount')}
      isMobile={isMobile}
      mobileHeaderTitle={t('Select discount')}
    >
      {(closeMenu) => (
        <AddTourItemDiscountChip
          isMobile={isMobile}
          currency={currency}
          tourTimeSlotId={tourTimeSlotId}
          cartId={cartId}
          tourItemId={tourItemId}
          onIHaveDiscountCodeClick={() => {
            onIHaveDiscountCodeClick()
            closeMenu()
          }}
        />
      )}
    </ChipWithListOfItems>
  ) : null
}

interface ITourItemProps {
  tourItem: TourItemFieldsFragment
  isMobile: boolean
  getRemoveButtonClickHandler: (id: number) => () => Promise<void>
  sx?: SxProps<Theme>
  cartId: number
  onIHaveDiscountCodeClick: () => void
}

export const TourItem: React.FC<ITourItemProps> = ({
  tourItem,
  isMobile,
  getRemoveButtonClickHandler,
  sx,
  cartId,
  onIHaveDiscountCodeClick
}: ITourItemProps) => {
  const {t} = useTranslation()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const {invalidCartStateErrorHandler} = useEcommerceErrorHandlers()
  const [removeDiscountFromItem] = useMutation<
    RemoveDiscountFromItemMutation,
    RemoveDiscountFromItemMutationVariables
  >(REMOVE_DISCOUNT_FROM_ITEM)
  const translatePrice = useTranslatePrice(tourItem.division.client.currency)
  const handleRemoveAppliedDiscountClick = useCallback(
    (ticketItemId: number, discountId: number) => async () => {
      try {
        setShowBackdrop(true)
        await removeDiscountFromItem({
          variables: {cartId, discountId, itemId: ticketItemId}
        })
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error})
        } else {
          defaultErrorHandler(error, {
            title: t('Unable to remove discount'),
            description: error.graphQLErrors[0].message
          })
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      cartId,
      defaultErrorHandler,
      invalidCartStateErrorHandler,
      removeDiscountFromItem,
      setShowBackdrop,
      t
    ]
  )
  return (
    <CartItemLayout
      title={tourItem.name}
      price={translatePrice(tourItem.price)}
      isMobile={isMobile}
      isRemoveButtonHidden={false}
      removeButtonTooltip={t('Remove from cart')}
      RemoveButtonProps={{onClick: getRemoveButtonClickHandler(tourItem.id)}}
      priceBeforeDiscount={
        tourItem.priceBeforeDiscount !== tourItem.price
          ? translatePrice(tourItem.priceBeforeDiscount)
          : undefined
      }
      sx={sx}
      addDiscountComponent={
        tourItem.admissionTypeAssignment.hasEnabledDiscountsOnECommerce ? (
          <AddDiscount
            isMobile={isMobile}
            appliedDiscounts={tourItem.appliedDiscounts}
            enabledDiscounts={tourItem.tourTimeSlot.enabledDiscounts}
            tourTimeSlotId={tourItem.tourTimeSlotId}
            currency={tourItem.tourTimeSlot.client.currency}
            cartId={cartId}
            tourItemId={tourItem.id}
            onRemoveAppliedDiscountClick={handleRemoveAppliedDiscountClick}
            onIHaveDiscountCodeClick={onIHaveDiscountCodeClick}
          />
        ) : undefined
      }
    />
  )
}
