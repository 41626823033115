import React, {createContext, useContext} from 'react'
import {SessionStorageKey, useSessionStorageState} from '../../../hooks/storage'
import {useInitialAppParams} from './initialAppParams'
import {IAppParams} from './types'

const AppParamsContext = createContext<{
  appParams: IAppParams
}>({
  appParams: {} as unknown as IAppParams
})

type IAppParamsContextProviderProps = React.PropsWithChildren<{}>

export const AppParamsProvider: React.FC<IAppParamsContextProviderProps> = ({
  children
}: IAppParamsContextProviderProps) => {
  const initialAppParams = useInitialAppParams()
  const [appParams] = useSessionStorageState<IAppParams>(
    SessionStorageKey.AppParams,
    initialAppParams
  )
  return (
    <AppParamsContext.Provider
      value={{
        appParams
      }}
    >
      {children}
    </AppParamsContext.Provider>
  )
}

export const useAppParamsContext = () => useContext(AppParamsContext)
