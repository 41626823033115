import {Box, GlobalStyles, Typography} from '@mui/material'
import {pick, uniqBy} from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ECommerceLocaleCode,
  ErrorMessages,
  GetTourTimeSlotsListDataQuery,
  GetTourTimeSlotsListDataQueryVariables,
  TourTimeSlotState
} from '../../__generated__/schema'
import {CenteredLayout} from '../../components/atoms/CenteredLayout'
import {LazyLoadingList} from '../../components/atoms/LazyLoadingList'
import {RenderOnData} from '../../components/molecules/RenderOnData'
import {TourTimeSlotItem} from '../../components/molecules/TourTimeSlotItem'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../utils/pagination'
import {useAppParamsContext} from '../components/atoms/AppParamsProvider'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {TentativeCurrentCartFab} from '../components/atoms/CurrentCartFab'
import {useLocale} from '../components/atoms/LocaleContext'
import {isEcommerceLocaleCode} from '../hooks/translateLocales'
import {PageNotFound} from '../PageNotFound'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {GET_TOUR_TIME_SLOTS_LIST_DATA} from './graphql'
import {
  ITourTimeSlotsListProps,
  withTourTimeSlotsListProps
} from './withTourTimeSlotsListProps'

const transparentBackground = (
  <GlobalStyles
    styles={{
      body: {
        backgroundColor: 'transparent'
      }
    }}
  />
)

const TourTimeSlotsList: React.FC<ITourTimeSlotsListProps> = ({
  clientId,
  tourId,
  searchParams
}: ITourTimeSlotsListProps) => {
  const {t} = useTranslation()
  const {appParams} = useAppParamsContext()
  const [, changeLocale] = useLocale()
  const {currentCart} = useCurrentCart()
  const {data, loading, error, fetchMore, isLoadingMore} =
    useQueryWithPagination<
      GetTourTimeSlotsListDataQuery,
      GetTourTimeSlotsListDataQueryVariables
    >(
      GET_TOUR_TIME_SLOTS_LIST_DATA,
      {
        variables: {
          filter: {
            showOnWebsiteAndApi: true,
            state: TourTimeSlotState.Published,
            isAvailableOnECommerce: true,
            tourId,
            clientId,
            ...pick(searchParams, [
              'startsAtFrom',
              'startsAtTo',
              'versionCode',
              'ageClassificationCode',
              'guideId',
              'eventCategoryId',
              'marketingLabelId',
              'venueId'
            ])
          },
          paginationInput: {limit: searchParams.limit, offset: 0}
        }
      },
      {
        mapPaginationInput: (data) =>
          extractPaginationInput(data.tourTimeSlots.pagination),
        updateData: (prevData, fetchMoreResult) => {
          if (!fetchMoreResult) return prevData
          return {
            ...fetchMoreResult,
            tourTimeSlots: {
              ...fetchMoreResult.tourTimeSlots,
              items: uniqBy(
                [
                  ...prevData.tourTimeSlots.items,
                  ...fetchMoreResult.tourTimeSlots.items
                ],
                ({id}) => id
              )
            }
          }
        }
      }
    )
  useEffect(() => {
    const clientLocale =
      (data?.tourTimeSlots.items.length &&
        data.tourTimeSlots.items[0].client.localeCodes[0]) ||
      ECommerceLocaleCode.En
    if (searchParams.localeCode) {
      changeLocale(searchParams.localeCode)
    } else {
      changeLocale(
        isEcommerceLocaleCode(clientLocale)
          ? clientLocale
          : ECommerceLocaleCode.En
      )
    }
  }, [changeLocale, data?.tourTimeSlots.items, searchParams.localeCode])
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.tourTimeSlots.pagination.hasMore) {
      // noinspection JSIgnoredPromiseFromCall
      fetchMore()
    }
  }, [data?.tourTimeSlots.pagination.hasMore, fetchMore, isLoadingMore])
  return (
    <>
      {searchParams.backgroundColor === 'transparent'
        ? transparentBackground
        : null}
      <RenderOnData<GetTourTimeSlotsListDataQuery>
        data={data}
        loading={data === undefined && loading}
        error={error}
        {...(getGraphQLErrorRelatedToErrorMessage(
          error ?? null,
          ErrorMessages.TourNotFound
        )
          ? {errorElement: <PageNotFound />}
          : {errorMessage: t<string>('Loading tour time slots failed')})}
        dataCondition={(data) => Array.isArray(data.tourTimeSlots.items)}
      >
        {({tourTimeSlots}) =>
          tourTimeSlots.items.length > 0 ? (
            <LazyLoadingList
              sx={{pt: 2, pb: 2}}
              isLoadingMore={isLoadingMore}
              onScrolledNearTheEndOfTheList={
                handleScrolledNearTheEndOfTheLayout
              }
            >
              {tourTimeSlots.items.map((tourTimeSlot, index) => (
                <Box
                  key={`${index}-${tourTimeSlot.id}`}
                  sx={{
                    maxWidth: 1019,
                    mx: 'auto',
                    pb: 2,
                    '&:last-of-type': {pb: 0}
                  }}
                >
                  <TourTimeSlotItem
                    tourTimeSlot={tourTimeSlot}
                    eventAvailabilityOption={searchParams.showEventAvailability}
                  />
                </Box>
              ))}
            </LazyLoadingList>
          ) : (
            <CenteredLayout>
              <Box
                sx={{
                  display: 'grid',
                  height: '100%',
                  alignItems: 'center',
                  justifyItems: 'center',
                  gridTemplateRows: '1fr 1fr'
                }}
              >
                <Typography variant="h6">
                  {t('Not tour time slots have been found')}
                </Typography>
              </Box>
            </CenteredLayout>
          )
        }
      </RenderOnData>
      {appParams.showCartButton && (
        <TentativeCurrentCartFab currentCart={currentCart} />
      )}
    </>
  )
}

export default withTourTimeSlotsListProps(TourTimeSlotsList)
