import {Box} from '@mui/material'
import {sortBy, uniqBy} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {BasicCartFieldsFragment, CartState} from '../../__generated__/schema'
import {CenteredLayout} from '../../components/atoms/CenteredLayout'
import {NavigationSection} from '../../components/atoms/NavigationSection'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {AccountActions} from '../../components/molecules/AccountActions'
import {MediaSizes} from '../../components/types'
import {Powered} from '../components/atoms/Powered'
import {BaseHeader} from '../components/molecules/BaseHeader'
import {CartSummaryWarning} from '../components/molecules/CartSummaryWarning'
import {isTicketItem, isTourItemPropertiesFragment} from '../utils'
import {ExpiredPaper} from './ExpiredPaper'

interface IExpiredCartPageProps {
  cart: BasicCartFieldsFragment
}

export const ExpiredCartPage: React.FC<IExpiredCartPageProps> = ({
  cart
}: IExpiredCartPageProps) => {
  const {t} = useTranslation()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const navigate = useNavigate()
  const ticketItems = (cart.items || []).filter(isTicketItem)
  const events = sortBy(
    uniqBy(
      ticketItems.map((ticketItem) => ticketItem.eventSeat.event),
      'id'
    ),
    'startsAt'
  )
  const uniqTourTimeSlots = sortBy(
    uniqBy(
      [
        ...(cart.items || [])
          .filter(isTourItemPropertiesFragment)
          .map((tourItem) => tourItem.tourTimeSlot)
      ],
      'id'
    ),
    'startsAt'
  )
  const handleSignInButtonClick = useCallback(
    () => navigate('signIn'),
    [navigate]
  )
  return (
    <CenteredLayout
      header={
        <BaseHeader title={t('Cart #{{id}} • Unsuccessful', {id: cart.id})}>
          <AccountActions onSignInButtonClick={handleSignInButtonClick} />
        </BaseHeader>
      }
      childrenSx={{display: 'flex', flexDirection: 'column'}}
    >
      <CartSummaryWarning cartState={CartState.Expired} sx={{pt: 4}} />
      <NavigationSection id="" label={t('Events')} sx={{pt: 4, pb: 3}}>
        {events.map((event) => (
          <ExpiredPaper key={event.id} event={event} isMobile={isMobile} />
        ))}
        {uniqTourTimeSlots.map((tourTimeSlot) => (
          <ExpiredPaper
            key={tourTimeSlot.id}
            tourTimeSlot={tourTimeSlot}
            isMobile={isMobile}
          />
        ))}
      </NavigationSection>
      <Box sx={{flex: 1}} />
      <Powered />
    </CenteredLayout>
  )
}
