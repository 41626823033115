import {Box, Button, SxProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CustomerFieldsFragment} from '../../__generated__/schema'
import {
  ReadOnlyDividerSeparatedInfoRow,
  ReadOnlyPaper
} from '../../components/atoms/ReadOnly'
import {useBooleanState} from '../../hooks/state'
import {useFormatDateTimeLong} from '../hooks/formatting'
import {NewPasswordDialog} from './NewPasswordDialog'

interface IAccountSectionProps {
  id: string
  sx?: SxProps
  customer: CustomerFieldsFragment
  isMobile: boolean
}

export const AccountSection: React.FC<IAccountSectionProps> = ({
  id,
  sx,
  customer,
  isMobile
}: IAccountSectionProps) => {
  const {t} = useTranslation()
  const {
    state: isNewPasswordDialogOpen,
    setTrue: openNewPasswordDialog,
    setFalse: closeNewPasswordDialog
  } = useBooleanState(false)
  const formatDateTimeLong = useFormatDateTimeLong()
  return (
    <>
      <ReadOnlyPaper
        id={id}
        sx={sx}
        paperSx={{display: 'flex', flexDirection: 'column', gap: 2}}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: isMobile ? 'flex-start' : 'center',
            flexDirection: isMobile ? 'column' : 'row'
          }}
        >
          <Typography variant="h6">{customer.username}</Typography>
          <Button
            variant="text"
            color="primary"
            onClick={openNewPasswordDialog}
            sx={{alignSelf: isMobile ? 'flex-end' : 'auto'}}
          >
            {t('New password')}
          </Button>
        </Box>
        <ReadOnlyDividerSeparatedInfoRow
          information={[
            {
              label: t('Privacy policy consent'),
              children: customer.privacyPolicyConsentGrantedAt
                ? t('Granted')
                : t('Not granted')
            },
            {
              label: t('Account created at'),
              children: formatDateTimeLong(customer.createdAt)
            }
          ]}
        />
      </ReadOnlyPaper>
      <NewPasswordDialog
        isOpen={isNewPasswordDialogOpen}
        onClose={closeNewPasswordDialog}
      />
    </>
  )
}
