import {Box, Dialog, styled} from '@mui/material'
import Carousel from 'nuka-carousel'
import React, {useCallback, useState} from 'react'
import {CenterLeftControls, CenterRightControls} from './PreviewControls'

const StyledImage = styled('img')`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`

export interface IGalleryPreviewPhoto {
  id: number
  src: string
}

interface IGalleryPreviewHookProps {
  openPreview: (photoId: number) => void
  closePreview: () => void
  currentPhotoId: number | null
  /**
   * currentPhotoIndex is -1, if not available
   */
  currentPhotoIndex: number
  photos: IGalleryPreviewPhoto[]
  onCurrentPhotoIndexChange: (slideIndex: number) => void
}

export const useGalleryPreview = (
  photos: IGalleryPreviewPhoto[]
): IGalleryPreviewHookProps => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(-1)
  const openPreview = useCallback(
    (photoId: number) => {
      setCurrentPhotoIndex(photos.findIndex((p) => p.id === photoId))
    },
    [photos]
  )
  const closePreview = useCallback(() => {
    setCurrentPhotoIndex(-1)
  }, [])
  const onCurrentPhotoIndexChange = useCallback((slideIndex: number) => {
    setCurrentPhotoIndex(slideIndex)
  }, [])
  return {
    currentPhotoId:
      currentPhotoIndex > -1 && currentPhotoIndex < photos.length
        ? photos[currentPhotoIndex].id
        : null,
    openPreview,
    closePreview,
    photos,
    onCurrentPhotoIndexChange,
    currentPhotoIndex
  }
}
interface IGalleryPreviewProps extends IGalleryPreviewHookProps {
  header?: React.ReactNode
}

export const GalleryPreview: React.FC<IGalleryPreviewProps> = ({
  currentPhotoId,
  photos,
  currentPhotoIndex,
  header,
  onCurrentPhotoIndexChange
}: IGalleryPreviewProps) => {
  const renderCenterLeftControls = useCallback(
    (props) => <CenterLeftControls {...props} />,
    []
  )
  const renderCenterRightControls = useCallback(
    (props) => <CenterRightControls {...props} />,
    []
  )
  return (
    <Dialog open={Boolean(currentPhotoId)} fullScreen>
      <Carousel
        dragging={false}
        renderBottomCenterControls={null}
        slideIndex={currentPhotoIndex}
        renderTopCenterControls={
          header ? () => <Box sx={{width: '100vw'}}>{header}</Box> : null
        }
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        afterSlide={onCurrentPhotoIndexChange}
      >
        {photos.map((photo) => (
          <Box
            key={photo.id}
            sx={{
              height: '100vh',
              width: '100vw',
              background: 'black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <StyledImage src={photo.src} alt={photo.src} />
          </Box>
        ))}
      </Carousel>
    </Dialog>
  )
}
