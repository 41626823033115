import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {Chip, SxProps, Theme} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  EventDetailFieldsFragment,
  TicketItemFieldsFragment
} from '../../../__generated__/schema'
import {EventTicketItem} from '../../cartSummary/EventTicketItem'
import {useTranslatePrice} from '../../hooks/translateCurrency'
import {EventPaper, IEventPaperProps} from './EventPaper'

export const eventSummaryPaperSx: SxProps<Theme> = {
  mb: 2,
  ':last-child': {
    mb: 0
  }
}

interface IEventSummaryPaperProps
  extends Pick<IEventPaperProps, 'sx' | 'children'> {
  event: EventDetailFieldsFragment
  isMobile: boolean
  eventTicketItems: TicketItemFieldsFragment[]
  topBar?: React.ReactNode
  id?: string
}

export const EventSummaryPaper: React.FC<IEventSummaryPaperProps> = ({
  event,
  isMobile,
  eventTicketItems,
  children,
  topBar,
  id,
  sx
}: IEventSummaryPaperProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslatePrice(event.division.client.currency)
  const navigate = useNavigate()
  const handleGetMoreTicketsIconClick = useCallback(
    () => navigate(`/event/${event.id}`),
    [event.id, navigate]
  )
  return (
    <EventPaper
      key={event.id}
      sx={sx}
      event={event}
      isMobile={isMobile}
      topBar={topBar}
      id={id}
      rightAction={
        <Chip
          label={t('Get more tickets')}
          size="small"
          deleteIcon={<OpenInNewIcon />}
          onDelete={handleGetMoreTicketsIconClick}
          onClick={handleGetMoreTicketsIconClick}
          variant="outlined"
        />
      }
    >
      {eventTicketItems.map((ticketItem) => (
        <EventTicketItem
          translatePrice={translatePrice}
          key={ticketItem.id}
          sx={{px: 3}}
          ticketItem={ticketItem}
        />
      ))}
      {children}
    </EventPaper>
  )
}
