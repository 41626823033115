import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  SxProps,
  Typography
} from '@mui/material'
import React, {Dispatch, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Currency,
  PaymentMethodState,
  VoucherCampaignState,
  VoucherState
} from '../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../components/atoms/DialogTitleWithCloseButton'
import {useFormatDateNumeric} from '../hooks/formatting'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {ContentActions, ContentActionType, RedeemData} from './types'

interface IInfoProps {
  label: string
  text: string
  sx?: SxProps
}

const Info: React.FC<IInfoProps> = ({sx, label, text}: IInfoProps) => {
  return (
    <Box sx={sx}>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="subtitle1">{text}</Typography>
    </Box>
  )
}

interface IVoucherBalanceDialogProps {
  redeemData: RedeemData
  dispatch: Dispatch<ContentActions>
  onClose: () => void
  currency: Currency
}

export const VoucherBalanceDialog: React.FC<IVoucherBalanceDialogProps> = ({
  redeemData,
  onClose,
  currency,
  dispatch
}: IVoucherBalanceDialogProps) => {
  const {voucher} = redeemData
  const {t} = useTranslation()
  const formatDateNumeric = useFormatDateNumeric()
  const translatePrice = useTranslatePrice(currency)
  const isValid =
    voucher.state === VoucherState.Active &&
    voucher.balance > 0 &&
    voucher.campaign?.state === VoucherCampaignState.Active &&
    voucher.campaign?.ecommercePaymentMethod?.state ===
      PaymentMethodState.Active

  const handleSubmit = useCallback(() => {
    dispatch({
      type: ContentActionType.RedeemVoucher,
      payload: redeemData
    })
    onClose()
  }, [dispatch, onClose, redeemData])
  return (
    <Dialog open maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onCloseIconClick={onClose}>
        {voucher.campaign.name} {voucher.code}
      </DialogTitleWithCloseButton>
      <Divider />
      <DialogContent
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateAreas: `
            "activation balance"
            "expiration ."
            "validityRow validityRow"
          `
        }}
      >
        <Info
          label={t('Activation')}
          text={
            voucher.activatedAt
              ? formatDateNumeric(new Date(voucher.activatedAt))
              : t('Not activated yet')
          }
        />
        <Info
          sx={{
            gridTemplateAreas: 'balance',
            justifySelf: 'flex-end'
          }}
          label={t('Balance')}
          text={translatePrice(voucher.balance)}
        />
        <Info
          label={t('Valid until')}
          text={
            voucher.expirationDate
              ? formatDateNumeric(new Date(voucher.expirationDate))
              : t('Undefined')
          }
        />
        {!isValid && (
          <Box
            sx={{
              gridArea: 'validityRow',
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <Typography color="textSecondary" component="div">
              <Chip
                label={t('Invalid')}
                sx={{backgroundColor: 'error.light', color: 'error.main'}}
              />
              &nbsp;
              {t('Unable to redeem.')}
            </Typography>
          </Box>
        )}
      </DialogContent>
      {isValid && (
        <>
          <Divider />
          <DialogActions
            sx={{
              py: 1,
              px: 3
            }}
          >
            <Button onClick={handleSubmit} color="primary" variant="contained">
              {t('Redeem')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
