import CloseIcon from '@mui/icons-material/Close'
import {DialogTitle, IconButton} from '@mui/material'
import React from 'react'

interface IDialogTitleWithCloseButtonProps {
  children: React.ReactNode
  onCloseIconClick: () => void
}

export const DialogTitleWithCloseButton: React.FC<IDialogTitleWithCloseButtonProps> =
  ({children, onCloseIconClick}: IDialogTitleWithCloseButtonProps) => {
    return (
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 3,
          py: 2.5,
          height: 64
        }}
      >
        {children}
        <IconButton onClick={onCloseIconClick} color="inherit">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    )
  }
