import {gql} from '@apollo/client'

export const LEAD_USER_FIELDS = gql`
  fragment LeadUserFields on User {
    id
    isLead
    permissions {
      code
    }
  }
`

export const LOGIN_LEAD = gql`
  ${LEAD_USER_FIELDS}
  mutation LoginLead {
    loginLead {
      lead {
        ...LeadUserFields
      }
      eSid
    }
  }
`

export const GET_LEAD = gql`
  ${LEAD_USER_FIELDS}
  query GetLead {
    me {
      ...LeadUserFields
    }
  }
`
