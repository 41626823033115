import dayjs from 'dayjs'
import i18next from 'i18next'
import React from 'react'
import {Helmet} from 'react-helmet-async'
import {
  EventDetailFieldsFragment,
  LocaleCode,
  ShowFieldsFragment,
  ShowImageType
} from '../../__generated__/schema'
import {useFormatDateTimeLong} from '../hooks/formatting'

const translateLocale = (locale: LocaleCode) => {
  switch (locale) {
    case LocaleCode.En:
      return 'en_US'
    case LocaleCode.Sk:
      return 'sk_SK'
    case LocaleCode.Cs:
      return 'cs_CZ'
    case LocaleCode.Hu:
      return 'hu_HU'
    default:
      return ''
  }
}

interface IOpenGraphTagsProps {
  event: EventDetailFieldsFragment & ShowFieldsFragment
}

export const OpenGraphTags: React.FC<IOpenGraphTagsProps> = ({
  event
}: IOpenGraphTagsProps) => {
  const localeCode = event.division.client.localeCodes[0]
  const t = i18next.getFixedT(localeCode)
  const formatDateTimeLong = useFormatDateTimeLong(localeCode)
  const title = [event.names[localeCode], formatDateTimeLong(event.startsAt)]
    .filter(Boolean)
    .join(' • ')
  const tagline = event.show.translations.find(
    (translation) => translation.localeCode === localeCode
  )?.tagline
  const image =
    event.show.images?.find((image) => image.type === ShowImageType.Photo) ||
    event.show.images?.find((image) => image.type === ShowImageType.Poster)
  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="event" />
      <meta
        property="og:type.event.start_time"
        content={dayjs(event.startsAt).toISOString()}
      />
      <meta property="og:type.event.venue" content={event.venue.name} />
      <meta property="og:url" content={event.ecommerceEventURL} />
      <meta
        property="og:description"
        content={tagline || t('Get your tickets now')}
      />
      <meta
        property="og:updated_time"
        content={dayjs(event.updatedAt).toISOString()}
      />
      <meta
        property="og:locale"
        content={translateLocale(event.division.client.localeCodes[0])}
      />
      {image && <meta property="og:image" content={image.url} />}
      {event.division.client.localeCodes.slice(1).map((localeCode) => (
        <meta
          key={localeCode}
          property="og:locale:alternate"
          content={translateLocale(localeCode)}
        />
      ))}
      <meta property="og:site_name" content={document.location.hostname} />
    </Helmet>
  )
}
