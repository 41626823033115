import {useMutation} from '@apollo/client'
import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button, Paper, SxProps, Typography} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
  AddReservationToCartMutation,
  AddReservationToCartMutationVariables,
  CancelReservationMutation,
  CancelReservationMutationVariables,
  Currency,
  CustomerPurchaseHistoryQuery,
  Maybe,
  ReservationState
} from '../../__generated__/schema'
import {SimpleDialog} from '../../components/atoms/SimpleDialog'
import {useIsMediaSize} from '../../components/atoms/WindowInnerWidthContext'
import {MediaSizes} from '../../components/types'
import {useBooleanState} from '../../hooks/state'
import {
  ADD_RESERVATION_TO_CART,
  CANCEL_RESERVATION
} from '../cartSummary/graphql'
import {useCurrentCart} from '../components/atoms/CurrentCartContext'
import {PriceRow} from '../components/atoms/PriceRow'
import {
  EventSummaryPaper,
  eventSummaryPaperSx
} from '../components/molecules/EventSummaryPaper'
import {ReservationStateBar} from '../components/molecules/ReservationStateBar'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {
  ReservationErrorHandlerLocation,
  useReservationErrorHandler
} from '../hooks/reservationErrorHandler'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {isTicketItem} from '../utils'

interface IMyReservationsSectionProps {
  id: string
  title: string
  sx?: SxProps
  reservations: CustomerPurchaseHistoryQuery['signedInCustomer']['reservations']
  currency: Currency
  onRefetch: () => void
}

export const MyReservationsSection: React.FC<IMyReservationsSectionProps> = ({
  id,
  title,
  sx,
  reservations,
  currency,
  onRefetch
}: IMyReservationsSectionProps) => {
  const {t} = useTranslation()
  const {initializeCurrentCart} = useCurrentCart()
  const {
    state: isDeleteReservationDialogOpen,
    setTrue: openDeleteReservationDialog,
    setFalse: closeDeleteReservationDialog
  } = useBooleanState(false)
  const [reservationIdentifiers, setReservationIdentifiers] =
    useState<{id: number; uuid: string; hash: string} | undefined>(undefined)
  const {setShowBackdrop, displayInfoNotification} =
    useMutationAssistanceHooks()
  const [cancelReservation] =
    useMutation<CancelReservationMutation, CancelReservationMutationVariables>(
      CANCEL_RESERVATION
    )
  const [addReservationToCart] = useMutation<
    AddReservationToCartMutation,
    AddReservationToCartMutationVariables
  >(ADD_RESERVATION_TO_CART)
  const reservationErrorHandler = useReservationErrorHandler()
  const isMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const navigate = useNavigate()
  const translatePrice = useTranslatePrice(currency)
  const handleDeleteReservationButtonClick = useCallback(
    (reservationId: number) => () => {
      setReservationIdentifiers(
        reservations.find((reservation) => reservation.id === reservationId)
      )
      openDeleteReservationDialog()
    },
    [reservations, openDeleteReservationDialog]
  )
  const getPayNowButtonClickHandler = useCallback(
    (reservation: {
        id: number
        uuid: string
        hash: string
        event?: Maybe<{id: number}>
      }) =>
      async () => {
        try {
          setShowBackdrop(true)
          const {data} = await addReservationToCart({
            variables: {
              reservationId: reservation.id,
              reservationEcommerceIdentifiers: {
                uuid: reservation.uuid,
                hash: reservation.hash
              }
            }
          })
          if (data?.addReservationToCart) {
            initializeCurrentCart(data.addReservationToCart)
            navigate(`/${data.addReservationToCart.clientId}/cart`)
          }
        } catch (error) {
          if (reservation.event) {
            reservationErrorHandler({
              error,
              location: ReservationErrorHandlerLocation.Pay,
              onRefetch,
              eventId: reservation.event.id
            })
          } else {
            // todo: handle tour reservation pay now error
          }
        } finally {
          setShowBackdrop(false)
        }
      },
    [
      addReservationToCart,
      initializeCurrentCart,
      navigate,
      onRefetch,
      reservationErrorHandler,
      setShowBackdrop
    ]
  )
  const handleDeleteReservationConfirmation = useCallback(async () => {
    if (reservationIdentifiers) {
      try {
        setShowBackdrop(true)
        await cancelReservation({
          variables: {
            reservationId: reservationIdentifiers.id,
            reservationEcommerceIdentifiers: {
              uuid: reservationIdentifiers.uuid,
              hash: reservationIdentifiers.hash
            }
          }
        })
        displayInfoNotification(t('Reservation has been deleted'))
        onRefetch()
      } catch (error) {
        reservationErrorHandler({
          error,
          location: ReservationErrorHandlerLocation.Delete,
          onRefetch
        })
      } finally {
        setShowBackdrop(false)
        closeDeleteReservationDialog()
      }
    }
  }, [
    cancelReservation,
    closeDeleteReservationDialog,
    displayInfoNotification,
    onRefetch,
    reservationErrorHandler,
    reservationIdentifiers,
    setShowBackdrop,
    t
  ])
  return (
    <>
      <Box id={id} sx={sx}>
        <Typography variant="subtitle1" sx={{pb: 1, px: 3}}>
          {title}
        </Typography>
        {(reservations || []).map((reservation) =>
          reservation.event ? (
            <EventSummaryPaper
              key={reservation.event.id}
              isMobile={isMobile}
              eventTicketItems={(reservation.items || []).filter(isTicketItem)}
              event={reservation.event}
              topBar={<ReservationStateBar reservation={reservation} />}
              sx={eventSummaryPaperSx}
            >
              <PriceRow primaryPrice={translatePrice(reservation.price)}>
                {reservation.state === ReservationState.Active && (
                  <>
                    <Button
                      onClick={getPayNowButtonClickHandler(reservation)}
                      color="primary"
                      variant="contained"
                    >
                      {reservation.price === 0
                        ? t('Confirm now')
                        : t('Pay now')}
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={handleDeleteReservationButtonClick(
                        reservation.id
                      )}
                      variant="text"
                    >
                      {t('Delete')}
                    </Button>
                  </>
                )}
              </PriceRow>
            </EventSummaryPaper>
          ) : // todo: display tours reservations
          null
        )}
        {reservations.length === 0 && (
          <Paper variant="outlined" sx={{px: 3, py: 2, borderRadius: 4}}>
            <Typography variant="body2" color="textSecondary">
              {t('No active reservations found')}
            </Typography>
          </Paper>
        )}
      </Box>
      <SimpleDialog
        isOpen={isDeleteReservationDialogOpen}
        title={t('Delete reservation?')}
        content={t(
          'Do you really want to delete this reservation? This action is permanent and irreversible.'
        )}
        actions={
          <>
            <Button variant="text" onClick={closeDeleteReservationDialog}>
              {t('Cancel')}
            </Button>
            <Button
              variant="text"
              onClick={handleDeleteReservationConfirmation}
            >
              {t('Delete')}
            </Button>
          </>
        }
      />
    </>
  )
}
