import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
  Typography
} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {ECommerceLocaleCode} from '../../../__generated__/schema'
import {useTranslateLocaleCode} from '../../hooks/translateLocales'
import {useLocale} from '../atoms/LocaleContext'

interface IVerticalMobileMoreMenuProps {
  sx?: SxProps
}

export const MobileMoreMenu: React.FC<IVerticalMobileMoreMenuProps> = ({
  sx
}: IVerticalMobileMoreMenuProps) => {
  const {t} = useTranslation()
  const translateLocaleCode = useTranslateLocaleCode()
  const [locale, changeLocale] = useLocale()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  )
  const handleClose = useCallback(() => setAnchorEl(null), [])

  const getMenuItemClickHandler = useCallback(
    (localeCode: ECommerceLocaleCode) => async () => {
      await changeLocale(localeCode)
      setAnchorEl(null)
    },
    [changeLocale]
  )

  return (
    <>
      <IconButton edge="end" sx={sx} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="vertical-mobile-more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          px: 3,
          py: 2
        }}
        PaperProps={{
          sx: {
            maxHeight: '100%',
            width: 180
          }
        }}
      >
        <MenuItem divider>
          <Typography variant="body2" color="text.primary">
            {t('Help')}
          </Typography>
        </MenuItem>
        {Object.entries(ECommerceLocaleCode).map(([label, localeCode]) => (
          <MenuItem
            key={localeCode}
            onClick={getMenuItemClickHandler(localeCode)}
            selected={localeCode === locale}
          >
            <ListItemText>
              <Typography variant="body2" color="text.primary">
                {translateLocaleCode(localeCode)}
              </Typography>
            </ListItemText>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{textTransform: 'uppercase'}}
            >
              {label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
