import {Box, SxProps, Theme} from '@mui/material'
import React from 'react'
import {MediaSizes} from '../types'
import {useIsMediaSize} from './WindowInnerWidthContext'

interface ICenteredLayoutProps {
  children: React.ReactNode
  childrenSx?: SxProps<Theme>
  header?: React.ReactNode
  leftSidebar?: React.ReactNode
  sidebarWidth?: number
}

export const CenteredLayout: React.FC<ICenteredLayoutProps> = ({
  children,
  childrenSx,
  header,
  leftSidebar,
  sidebarWidth = 200
}: ICenteredLayoutProps) => {
  const isSmallMobile = useIsMediaSize(MediaSizes.SmallMobile)
  const isTabletPlus = useIsMediaSize(MediaSizes.TabletPlus)
  const isLargeDesktop = useIsMediaSize(MediaSizes.LargeDesktop)
  return (
    <Box
      sx={{
        display: 'grid',
        height: '100vh',
        gridTemplateRows: header ? 'auto 1fr' : '1fr',
        overflow: 'hidden'
      }}
    >
      {header}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            pl:
              isLargeDesktop || isSmallMobile
                ? 0
                : isTabletPlus && leftSidebar
                ? `${sidebarWidth + 24}px`
                : 3,
            pr: isLargeDesktop || isSmallMobile ? 0 : 3
          }}
        >
          <Box
            sx={{
              height: '100%',
              ...(isLargeDesktop
                ? {
                    maxWidth: 960,
                    mx: 'auto'
                  }
                : {}),
              ...childrenSx
            }}
          >
            {children}
          </Box>
        </Box>
        {isTabletPlus && leftSidebar && (
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              top: 0,
              left: 0,
              width: sidebarWidth
            }}
          >
            {leftSidebar}
          </Box>
        )}
      </Box>
    </Box>
  )
}
