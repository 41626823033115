import {ApolloError, useMutation, useQuery} from '@apollo/client'
import {
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Skeleton,
  Typography
} from '@mui/material'
import {TFunction} from 'i18next'
import {isEmpty, isNil} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AddDiscountToCartTicketItemsMutation,
  AddDiscountToCartTicketItemsMutationVariables,
  Currency,
  DiscountApplication,
  EnabledDiscountFieldsFragment,
  ErrorMessages,
  GetEnabledDiscountsQuery,
  GetEnabledDiscountsQueryVariables,
  SellingChannel
} from '../../__generated__/schema'
import {ChipWithListOfItems} from '../components/molecules/ChipWithListOfItems'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useFormatDiscountValue} from '../hooks/formatting'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {
  ADD_DISCOUNT_TO_CART_TICKET_ITEMS,
  GET_ENABLED_DISCOUNTS
} from './graphql'
import {useDiscountUsageLimitPerOrderHelper} from './utils'

const getErrorDescription = (error: ApolloError, t: TFunction) => {
  const errorMessage = error?.graphQLErrors[0].message
  switch (errorMessage) {
    case ErrorMessages.ItemsBelongToMultipleEvents:
      return t('Items belong to multiple events')
    case ErrorMessages.ItemPricingDisablesDiscount:
      return t('Item pricing disables discount')
    case ErrorMessages.ItemIsAlreadyDiscounted:
      return t('Item is already discounted')
    case ErrorMessages.DiscountNotFound:
      return t('Discount not found')
    case ErrorMessages.EventDoesntComplyWithDiscountRules:
      return t('Event does not comply with discount rules')
    case ErrorMessages.DiscountUsageLimitPerOrderExceeded:
      return t(
        'We’re sorry, but the discount you are trying to apply has reached its usage limit for this order. Each order can only use this discount a certain number of times, and you have already applied the maximum allowed usage. Please review your order to ensure the discount is applied correctly or reduce the number of times you’re using the discount.'
      )
    default:
      return t('Error')
  }
}

const getErrorTitle = (error: ApolloError, t: TFunction) => {
  const errorMessage = error?.graphQLErrors[0].message
  switch (errorMessage) {
    case ErrorMessages.DiscountUsageLimitPerOrderExceeded:
      return t('Discount redemption limit exceeded')
    default:
      return t('Unable to add discount')
  }
}

interface IEnabledDiscountsProps {
  eventId: number
  cartId: number
  ticketItemId: number
  isMobile: boolean
  currency: Currency
  onIHaveDiscountCodeClick: () => void
  closeMenu: () => void
  onCustomerDiscountClick: (
    discount: (EnabledDiscountFieldsFragment & {ticketItemId: number}) | null
  ) => void
}

const EnabledDiscounts: React.FC<IEnabledDiscountsProps> = ({
  eventId,
  cartId,
  ticketItemId,
  isMobile,
  currency,
  onIHaveDiscountCodeClick,
  closeMenu,
  onCustomerDiscountClick
}: IEnabledDiscountsProps) => {
  const {t} = useTranslation()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const {invalidCartStateErrorHandler} = useEcommerceErrorHandlers()
  const formatDiscountValue = useFormatDiscountValue(currency)
  const discountUsageLimitPerOrderHelper = useDiscountUsageLimitPerOrderHelper()
  const {data, loading} = useQuery<
    GetEnabledDiscountsQuery,
    GetEnabledDiscountsQueryVariables
  >(GET_ENABLED_DISCOUNTS, {
    fetchPolicy: 'network-only',
    variables: {
      eventId,
      discountsFilter: {
        channel: SellingChannel.ECommerce,
        discountApplications: [
          DiscountApplication.Selectable,
          DiscountApplication.Customer
        ]
      }
    },
    onError: (error) => {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidCartState
        )
      ) {
        invalidCartStateErrorHandler({error})
      } else {
        defaultErrorHandler(error, {
          title: t('Error while loading discounts')
        })
      }
    }
  })
  const [addDiscountToCartTicketItems] = useMutation<
    AddDiscountToCartTicketItemsMutation,
    AddDiscountToCartTicketItemsMutationVariables
  >(ADD_DISCOUNT_TO_CART_TICKET_ITEMS)
  const handleOnDiscountClick = useCallback(
    (discountId: number) => async () => {
      try {
        setShowBackdrop(true)
        await addDiscountToCartTicketItems({
          variables: {
            cartId,
            itemIds: [ticketItemId],
            discountId
          }
        })
      } catch (e) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error: e})
        } else {
          defaultErrorHandler(e, {
            title: getErrorTitle(e, t),
            description: getErrorDescription(e, t)
          })
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addDiscountToCartTicketItems,
      cartId,
      defaultErrorHandler,
      invalidCartStateErrorHandler,
      setShowBackdrop,
      t,
      ticketItemId
    ]
  )
  const width = isMobile ? undefined : 320
  return (
    <>
      {loading ? (
        <Skeleton sx={{mx: 2}} width={width} height={56} />
      ) : !isEmpty(data?.event.enabledDiscounts) ? (
        <MenuList
          disablePadding
          sx={{
            width,
            '& .MuiMenuItem-root': {
              whiteSpace: 'normal'
            }
          }}
        >
          <MenuItem
            sx={{minHeight: 56, py: 1}}
            onClick={() => {
              onIHaveDiscountCodeClick()
              closeMenu()
            }}
          >
            <ListItemText
              primary={t('I have discount code')}
              primaryTypographyProps={{variant: 'body2'}}
            />
          </MenuItem>
          {data?.event.enabledDiscounts.map((discount) => {
            const {numberOfUsageLeft, canBeUsed} =
              discountUsageLimitPerOrderHelper({
                selectedDiscount: discount,
                eventId
              })
            return (
              <MenuItem
                disabled={!canBeUsed}
                key={discount.id}
                onClick={
                  discount.application === DiscountApplication.Customer
                    ? () => {
                        onCustomerDiscountClick({...discount, ticketItemId})
                        closeMenu()
                      }
                    : handleOnDiscountClick(discount.id)
                }
                sx={{py: 1, minHeight: 56}}
              >
                <ListItemText
                  primary={discount.name}
                  primaryTypographyProps={{variant: 'body2'}}
                  secondary={[
                    formatDiscountValue({
                      value: discount.value,
                      type: discount.type
                    }),
                    !isNil(discount.maxUsageLimitPerOrder) &&
                      t('Can be redeemed {{count}} times per order.', {
                        count: numberOfUsageLeft
                      })
                  ]
                    .filter(Boolean)
                    .join(' • ')}
                  secondaryTypographyProps={{variant: 'caption'}}
                />
              </MenuItem>
            )
          })}
        </MenuList>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: 56,
            width,
            px: 2
          }}
        >
          <Typography variant="body2">
            {t('No available discounts were found')}
          </Typography>
        </Box>
      )}
    </>
  )
}

interface IAddDiscountChipProps {
  eventId: number
  cartId: number
  ticketItemId: number
  isMobile: boolean
  currency: Currency
  onIHaveDiscountCodeClick: () => void
  onCustomerDiscountClick: (
    discount: (EnabledDiscountFieldsFragment & {ticketItemId: number}) | null
  ) => void
}

export const AddDiscountChip: React.FC<IAddDiscountChipProps> = ({
  eventId,
  cartId,
  ticketItemId,
  isMobile,
  currency,
  onIHaveDiscountCodeClick,
  onCustomerDiscountClick
}: IAddDiscountChipProps) => {
  const {t} = useTranslation()
  return (
    <ChipWithListOfItems
      label={t('Add discount')}
      isMobile={isMobile}
      mobileHeaderTitle={t('Select discount')}
    >
      {(closeMenu) => (
        <EnabledDiscounts
          ticketItemId={ticketItemId}
          cartId={cartId}
          eventId={eventId}
          isMobile={isMobile}
          currency={currency}
          onIHaveDiscountCodeClick={onIHaveDiscountCodeClick}
          closeMenu={closeMenu}
          onCustomerDiscountClick={onCustomerDiscountClick}
        />
      )}
    </ChipWithListOfItems>
  )
}
