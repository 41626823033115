import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {Box, SxProps, Typography} from '@mui/material'
import {sortBy} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetEventDetailQuery} from '../../__generated__/schema'
import {ReadOnlyPaper, ReadOnlyRow} from '../../components/atoms/ReadOnly'
import {useFormatDiscountValue} from '../hooks/formatting'
import {useTranslatePrice} from '../hooks/translateCurrency'

interface IPricesAndDiscountsReadOnlyPaperProps {
  event: GetEventDetailQuery['event']
  id?: string
  title?: string
  sx?: SxProps
}

export const PricesAndDiscountsReadOnlyPaper: React.FC<IPricesAndDiscountsReadOnlyPaperProps> =
  ({event, id, title, sx}: IPricesAndDiscountsReadOnlyPaperProps) => {
    const currency = event.division.client.currency
    const translatePrice = useTranslatePrice(currency)
    const formatDiscountValue = useFormatDiscountValue(currency, true)
    const {t} = useTranslation()
    return (
      <ReadOnlyPaper sx={sx} id={id} title={title}>
        <ReadOnlyRow label={t('Ticket types')}>
          {sortBy(event.activePricing.ticketTypes, 'price').map(
            (ticketType) => (
              <Box
                key={ticketType.id}
                sx={{display: 'flex', alignItems: 'center', gap: 2, pb: 1}}
              >
                <FiberManualRecordIcon
                  fontSize="inherit"
                  htmlColor={ticketType.color || undefined}
                />
                <Typography variant="body2">
                  {t('{{ticketPrice}} • {{ticketName}}', {
                    ticketPrice: translatePrice(ticketType.price),
                    ticketName: ticketType.name
                  })}
                </Typography>
              </Box>
            )
          )}
        </ReadOnlyRow>
        {event.enabledDiscounts.length > 0 && (
          <ReadOnlyRow label={t('Discounts')}>
            {[...event.enabledDiscounts]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((discount) => (
                <Typography
                  key={discount.id}
                  sx={{
                    pb: 1
                  }}
                  variant="body2"
                >
                  {t('{{discountName}} ({{discountValue}})', {
                    discountName: discount.name,
                    discountValue: formatDiscountValue({
                      value: discount.value,
                      type: discount.type
                    })
                  })}
                </Typography>
              ))}
          </ReadOnlyRow>
        )}
      </ReadOnlyPaper>
    )
  }
