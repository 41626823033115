import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton, Typography} from '@mui/material'
import React from 'react'

interface IMediaPreviewHeaderProps {
  title: string
  onLeftActionClick: () => void
  hasArrowBackIcon?: boolean
}

export const MediaPreviewHeader: React.FC<IMediaPreviewHeaderProps> = ({
  title,
  onLeftActionClick,
  hasArrowBackIcon
}: IMediaPreviewHeaderProps) => {
  return (
    <Box
      sx={{
        height: 64,
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 150%)',
        color: 'primary.contrastText',
        borderBottom: 'none',
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        px: 1.5,
        gridTemplateColumns: 'auto 228px 1fr auto'
      }}
    >
      <IconButton
        onClick={onLeftActionClick}
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)'
          }
        }}
        color="inherit"
      >
        {hasArrowBackIcon ? <ArrowBackIcon /> : <CloseIcon />}
      </IconButton>
      <Typography sx={{pl: 1.5, gridColumns: '2/4'}} variant="h6" noWrap>
        {title}
      </Typography>
    </Box>
  )
}
